import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import IconRegisterStartup from '~/presentation/base/icons/iconRegisterStartup';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconUserRegistered } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterUserSuccess = ({ name, fullname, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'SUBMMIT_USER'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconUserRegistered />}
      title='Usuário(a) pré-cadastrado!'
      topButtonText='Cadastrar outro usuário'
      onOpenRegister={onOpenRegister}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>
              O(A) usuário(a) foi pré-cadastrado{' '}
              <Text display='inline' color='#E74D39'>
                *
              </Text>{' '}
              com sucesso.
            </Text>
            <Text>
              <Text display='inline' color='#E74D39'>
                *
              </Text>
              Lembre-se: O(A) usuário(a) só terá acesso ao sistema quando
              aceitar o convite enviado por e-mail e concluir seu cadastro.
            </Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default RegisterUserSuccess;
