import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalCloseButton,
  ModalBody,
  Stack,
  Divider,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { History } from '~/main/routes';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import ActionButton from '../../ListActions/ActionButton';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenRegister?: any;
  title?: string;
  topButtonText?: string;
  returnPage?: string;
  icon: React.ReactNode;
  body: React.ReactNode;
  height?: string;
  buttonText?: string;
  errorConfirmation?: boolean;
  showTopButton?: boolean;
}

const Confirmation = ({
  isOpen,
  onClose,
  onOpenRegister,
  title,
  topButtonText,
  returnPage,
  buttonText = 'Voltar para o painel',
  errorConfirmation = false,
  showTopButton = true,
  icon,
  body,
  height,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        w='350px'
        h={height ? height : 'fit-content'}
        position={{ base: 'fixed', md: 'inherit' }}
        top={{ base: '345px', md: 'auto' }}
        minW={{ base: 'fit-content', md: 'auto' }}
      >
        <ModalHeader pb={'0px'}>
          <Flex
            justifyContent={'center'}
            marginTop={'32px'}
            marginBottom={'0px'}
          >
            {icon}
          </Flex>
          <Flex mt={'15px'} justifyContent={'center'} fontSize={'16px'}>
            {title}
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            showConfirmModal('');
            onClose();
          }}
        />
        <ModalBody>
          <Text textAlign={'center'}>{body}</Text>
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent='start' paddingBottom='22px'>
          <Flex flexDirection={'column'} w={'100%'} gap='10px'>
            {showTopButton && (
              <ActionButton
                fontWeight={'normal'}
                w={'100%'}
                id='understood'
                backgroundColor='#222222'
                border={'1px'}
                borderColor={'#9C9C9C'}
                _hover={{ background: '#484848' }}
                _active={{ background: '#303950' }}
                color='#FDFDFD'
                onClick={() => {
                  showConfirmModal('');
                  onClose();
                  onOpenRegister && onOpenRegister();
                }}
              >
                <Text fontWeight={'500'}>{topButtonText}</Text>
              </ActionButton>
            )}
            <Button
              fontWeight={'normal'}
              w={'100%'}
              id='understood'
              bg={showTopButton ? '#FDFDFD' : '#222222'}
              border={'1px'}
              borderColor={'#9C9C9C'}
              _hover={{ background: '#EDEDED' }}
              _active={{ background: '#FDFDFD' }}
              color={showTopButton ? '#484848' : '#FDFDFD'}
              onClick={() => {
                showConfirmModal('');
                onClose();
                returnPage && History.push(returnPage);
              }}
            >
              <Text fontWeight={'500'}>{buttonText}</Text>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Confirmation;
