import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
// import { LogoutAuth as UsecaseRemoteLogoutAuth } from '~/domain/usecases/auth/remote';
import { LogoutAuth as UsecaseReduxLogoutAuth } from '~/domain/usecases/auth/redux';

// import { RemoteLogoutAuth } from '~/data/repository/auth';
import { ReduxLogoutAuth } from '~/data/store/reducer/auth/usecases';

/**
 * send request via API.
 */
/* export const makeRemoteLogoutAuth = (): UsecaseRemoteLogoutAuth =>
  new RemoteLogoutAuth(makeApiUrl('/users/{user}'), makeHttpClient()); */

/**
 * send request via REDUX.
 */
export const makeReduxLogoutAuth = (): UsecaseReduxLogoutAuth =>
  new ReduxLogoutAuth();
