import React from 'react';
import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  ModalFooter,
} from '@chakra-ui/react';
import { IconAttention, IconDeleteDocs } from '~/presentation/base/icons';
import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';
import { makeRemoteDeleteFile } from '~/main/factories/usecases/file/DeleteFileFactory';
import { makeReduxListFolderContentDocument } from '~/main/factories/usecases/document/ListFolderContentDocumentFactory';
import { withAccess } from '~/presentation/hooks/acess';

interface ownProps {
  id: number;
  name: string;
  pathId: number;
}

const DeleteDocument = ({ name, id, pathId }: ownProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { initial, validators } = schemaRegisterMember;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  return (
    <Box>
      <Flex gap='2' h='40px' align='center' px='3' onClick={onOpen}>
        <IconDeleteDocs />
        <Text>Excluir</Text>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW='408px' bg='#F9F8FA'>
          <Box marginTop={'24px'}>
            <ModalHeader py='3' whiteSpace='nowrap' padding='0px 0px 0px 24px'>
              <Flex
                flexDirection='row'
                justifyContent={'space-between'}
                alignItems='center'
              >
                <Text
                  fontWeight='700'
                  fontFamily='Inter'
                  fontSize='18px'
                  color={'#303950'}
                >
                  Excluir documento
                </Text>
                <ModalCloseButton
                  size='md'
                  position={'inherit'}
                  marginRight='24px'
                  color={'#60656D'}
                ></ModalCloseButton>
              </Flex>
            </ModalHeader>
            <ModalBody paddingBottom={'0px'}>
              <Flex flexDir={'column'} justify='space-between'>
                <Flex flexDir='column' gap='20px'>
                  <Text
                    fontFamily='Inter'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='sm'
                    color='#676D75'
                  >
                    {`Tem certeza de que quer excluir o documento ${name}?`}
                  </Text>
                  <Flex
                    backgroundColor='#FFEFEA'
                    borderRadius='5px'
                    padding='16px 12px'
                    gap='10px'
                  >
                    <IconAttention></IconAttention>
                    <Flex flexDirection='column'>
                      <Text
                        fontFamily='Inter'
                        fontStyle='bold'
                        fontWeight='700'
                        fontSize='sm'
                        color='#C23422'
                      >
                        Atenção
                      </Text>
                      <Text
                        fontFamily='Inter'
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize='sm'
                        color='#C23422'
                      >
                        Não há como recuperar qualquer arquivo removido do
                        sistema.
                      </Text>
                    </Flex>
                  </Flex>
                  <Box borderTop='1px solid #E9ECEE '></Box>
                </Flex>
              </Flex>
            </ModalBody>
            <Flex flexDir='row' justifyContent='flex-end'>
              <ModalFooter paddingTop={'20px'} paddingBottom={'24px'}>
                <Flex
                  flexDir='row'
                  justifyContent='center'
                  alignItems='center'
                  gap='10px'
                >
                  <Button
                    width='100px'
                    bg='#F9F8FA'
                    color='#60656D'
                    _hover={{ background: '#E9ECEE', color: '#303950' }}
                    _active={{ background: '#CECECE', color: '#303950' }}
                    boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                    padding='10px, 22px, 10px, 22px'
                    border='1px solid #CECECE'
                  >
                    <Text
                      fontFamily='Inter'
                      fontStyle='normal'
                      fontWeight='500'
                      fontSize='sm'
                    >
                      Cancelar
                    </Text>
                  </Button>
                  <Button
                    width='89px'
                    bg='#C23422'
                    color='#F9F8FA'
                    _hover={{ background: '#E74D39', color: '' }}
                    _active={{ background: '#9B3457', color: '#F9F8FA' }}
                    boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                    padding='10px, 22px, 10px, 22px'
                    onClick={() => {
                      makeRemoteDeleteFile()
                        .delete({ id })
                        .then(() => {
                          makeReduxListFolderContentDocument().listFolderContent(
                            {
                              id: pathId,
                            }
                          );
                        })
                        .finally(() => {
                          onClose();
                        });
                    }}
                  >
                    <Text
                      fontFamily='Inter'
                      fontStyle='normal'
                      fontWeight='500'
                      fontSize='sm'
                    >
                      Excluir
                    </Text>
                  </Button>
                </Flex>
              </ModalFooter>
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default withAccess(DeleteDocument);
