import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconApproved = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#EBF3D9' />
      <path
        d='M17 28.766C17 35.8166 22.7153 41.5319 29.766 41.5319C30.6967 41.5319 31.6043 41.4323 32.4784 41.2432C32.3739 40.7173 32.3191 40.1736 32.3191 39.617C32.3191 39.2863 32.3385 38.9602 32.3761 38.6396C31.5315 38.8629 30.6547 38.9787 29.766 38.9787C27.0574 38.9787 24.4597 37.9027 22.5444 35.9875C20.6292 34.0722 19.5532 31.4746 19.5532 28.766C19.5532 26.0574 20.6292 23.4597 22.5444 21.5444C24.4597 19.6292 27.0574 18.5532 29.766 18.5532C32.4746 18.5532 35.0722 19.6292 36.9875 21.5444C38.9027 23.4597 39.9787 26.0574 39.9787 28.766C39.9787 29.6547 39.8629 30.5315 39.6396 31.3761C39.9602 31.3385 40.2863 31.3191 40.617 31.3191C41.1736 31.3191 41.7173 31.3739 42.2432 31.4784C42.4323 30.6043 42.5319 29.6967 42.5319 28.766C42.5319 21.7153 36.8166 16 29.766 16C22.7153 16 17 21.7153 17 28.766Z'
        fill='#5B7A17'
      />
      <path
        d='M41.694 33.3245C41.3439 33.265 40.9841 33.234 40.617 33.234C39.9931 33.234 39.3902 33.3235 38.8203 33.4904C38.3442 34.4029 37.7287 35.2463 36.9875 35.9875C36.2463 36.7287 35.4029 37.3442 34.4904 37.8203C34.3235 38.3902 34.234 38.9931 34.234 39.617C34.234 39.9841 34.265 40.3439 34.3245 40.694C37.7081 39.4002 40.4002 36.7081 41.694 33.3245Z'
        fill='#5B7A17'
      />
      <path
        d='M32.3191 31.3191H25.2979V33.8723H28.4894V36.4255H31.0426V33.8723H32.3191C33.1656 33.8723 33.9774 33.5361 34.5759 32.9376C35.1744 32.3391 35.5106 31.5273 35.5106 30.6809C35.5106 29.8344 35.1744 29.0226 34.5759 28.4241C33.9774 27.8256 33.1656 27.4894 32.3191 27.4894H27.2128C27.0435 27.4894 26.8811 27.4221 26.7614 27.3024C26.6417 27.1827 26.5745 27.0204 26.5745 26.8511C26.5745 26.6818 26.6417 26.5194 26.7614 26.3997C26.8811 26.28 27.0435 26.2128 27.2128 26.2128H34.234V23.6596H31.0426V21.1064H28.4894V23.6596H27.2128C26.3663 23.6596 25.5546 23.9958 24.956 24.5943C24.3575 25.1929 24.0213 26.0046 24.0213 26.8511C24.0213 27.6975 24.3575 28.5093 24.956 29.1078C25.5546 29.7063 26.3663 30.0426 27.2128 30.0426H32.3191C32.4884 30.0426 32.6508 30.1098 32.7705 30.2295C32.8902 30.3492 32.9574 30.5116 32.9574 30.6809C32.9574 30.8501 32.8902 31.0125 32.7705 31.1322C32.6508 31.2519 32.4884 31.3191 32.3191 31.3191Z'
        fill='#5B7A17'
      />
      <path
        d='M39.6918 42.4893L44.128 38.0851L42.7876 36.7446L39.6918 39.7766L38.4471 38.5319L37.1067 39.9042L39.6918 42.4893ZM40.6174 46C38.8514 46 37.3459 45.3774 36.1008 44.1323C34.8557 42.8872 34.2335 41.3821 34.2344 39.617C34.2344 37.851 34.8569 36.3455 36.102 35.1004C37.3471 33.8553 38.8522 33.2332 40.6174 33.234C42.3833 33.234 43.8888 33.8566 45.134 35.1017C46.3791 36.3468 47.0012 37.8519 47.0003 39.617C47.0003 41.3829 46.3778 42.8885 45.1327 44.1336C43.8876 45.3787 42.3825 46.0008 40.6174 46Z'
        fill='#5B7A17'
      />
    </svg>
  );
};

export default IconApproved;
