import { Box, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconDeleteDefault } from '~/presentation/base/icons';
import { iUser } from '~/domain/interfaces/models/User';

interface ownProps {
  user?: iUser['records'][0];
}

const DeleteUserSuccess = ({ user }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'DELETE_USER'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconDeleteDefault />}
      title={'Usuário(a) excluído(a)!'}
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text textAlign={'center'}>
              {`O(a) Usuário(a)`} <b>{user?.fullname}</b>{' '}
              {`foi excluído(a) com sucesso!`}
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default DeleteUserSuccess;
