import {
  Text,
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList,
  FormLabel,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import Select from '~/presentation/components/UI/select';
import { withAccess } from '~/presentation/hooks/acess';
import { ErrorMessage } from 'formik';

interface ownProps {
  formik: any;
  actions: any;
  resourcers: any;
  setSelectedTab: (index: number) => void;
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTab: number;
  selectedOptions: string[];
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (
    fieldName: string
  ) => (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disableTabs: boolean;
}

const TypeEvent = ({
  formik,
  selectedTab,
  selectedOptions,
  setSelectedOptions,
  setSelectedTab,
  handleCheckboxChange,
  handleSelect,
  disableTabs,
  actions,
  resourcers,
}: ownProps): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);
  const mentors = useSelector((store: iStore) =>
    store.user.records.filter((user) => user.role_.name === 'MENTOR')
  );
  const notices = useSelector((store: iStore) => store.notice.records);

  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [disableMentoring, setDisableMentoring] = useState(false);
  const [disableResponsable, setDisableResponsable] = useState(false);

  const userNotices = useMemo(() => {
    const userId =
      user?.role_.name === 'MENTOR'
        ? user?.id
        : Number(formik.values.responsable);

    return notices.filter(
      (notice) =>
        Array.isArray(notice.mentors) &&
        notice.mentors.some((mentor) => mentor.user === userId)
    );
  }, [user?.role_.name, formik.values.responsable]);

  useEffect(() => {
    if (user?.role_.name === 'MANAGER' || user?.role_.name === 'MEMBER') {
      setDisableCheckbox(true);
      setDisableMentoring(true);
    } else {
      setDisableCheckbox(false);
      setDisableMentoring(false);
    }
    if (user?.role_.name === 'MENTOR') {
      setDisableResponsable(true);
      formik.setFieldValue('responsable', user?.id);
    }
  }, [user?.role_.name]);

  return (
    <Box w='50%'>
      <Text fontWeight='bold' fontSize='sm' mb='2'>
        Tipo de evento<span style={{ color: 'red' }}>*</span>
      </Text>
      <Tabs
        variant='unstyled'
        onChange={(index) => {
          setSelectedTab(index);
          formik.resetForm({
            values: {
              ...formik.values,
              notice: '',
              noticeStep: '',
              responsable: '',
            },
            errors: {},
            touched: {},
          });
        }}
        index={selectedTab}
        defaultIndex={user?.role_.name === 'MENTOR' ? 1 : 0}
      >
        <Flex direction='column' rowGap='12px'>
          <TabList gap={3} h='40px' color='#303950'>
            <Tab
              w='94px'
              id='meeting'
              bg='#F9F8FA'
              border='1px solid rgba(91, 100, 107, 0.5)'
              borderRadius='5px'
              fontWeight='500'
              display={user?.role_.name === 'MENTOR' ? 'none' : 'flex'}
              isDisabled={disableTabs}
              _selected={{
                bg: '#E4F0F6',
                border: '1px solid #1997F3',
                color: '#1863A8',
              }}
            >
              Reunião
            </Tab>
            <Tab
              w='100px'
              id='mentoring'
              bg='#F9F8FA'
              border='1px solid rgba(91, 100, 107, 0.5)'
              borderRadius='5px'
              fontWeight='500'
              isDisabled={disableTabs || disableMentoring}
              _selected={{
                bg: '#F6E1FA',
                border: '1px solid #AC38A8',
                color: '#7D2B7B',
              }}
            >
              Mentoria
            </Tab>
            <Tab
              w='92px'
              id='delivery'
              bg='#F9F8FA'
              border='1px solid rgba(91, 100, 107, 0.5)'
              borderRadius='5px'
              fontWeight='500'
              isDisabled={disableTabs}
              _selected={{
                bg: '#FFF5E0',
                border: '1px solid #FF9F2F',
                color: '#BD5114',
              }}
            >
              Entrega
            </Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel px={0}>
            <Stack>
              <Stack mb='16px'>
                <Text fontWeight='semibold' fontSize='sm' mb='1'>
                  Convidados<span style={{ color: 'red' }}>*</span>
                </Text>
                <CheckboxGroup
                  isDisabled={disableCheckbox}
                  value={formik.values.guests}
                >
                  <Stack rowGap={'8px'}>
                    <HStack columnGap={'4px'}>
                      <Checkbox value='ADMIN' onChange={handleCheckboxChange}>
                        Admins
                      </Checkbox>
                      <Checkbox
                        value='SUPERVISOR'
                        onChange={handleCheckboxChange}
                      >
                        Supervisores
                      </Checkbox>
                      <Checkbox value='MENTOR' onChange={handleCheckboxChange}>
                        Mentores
                      </Checkbox>
                    </HStack>
                    <Checkbox value='STARTUP' onChange={handleCheckboxChange}>
                      Startups
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
                {formik.errors.selectedOptions && (
                  <ErrorMessage name='guests' component='div' />
                )}
              </Stack>
              <Text fontWeight='semibold' fontSize='sm' mb='1'>
                É importante lembrar que:
              </Text>
              <UnorderedList pl='4' color='#747C86' fontSize='sm' spacing={15}>
                <ListItem>
                  Não há como convidar admins, mentores ou supervisores
                  específicos. O convite sempre se estenderá a todos;
                </ListItem>
                <ListItem>
                  Se a opção “Startups” for selecionada, todos os gestores e
                  membros serão convidados;
                </ListItem>
              </UnorderedList>
            </Stack>
          </TabPanel>
          <TabPanel px={0} pt='20px'>
            <Stack>
              <Box w='360px' mb='12px'>
                <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
                  Mentor Responsável<span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Select
                  marginBottom={'0px'}
                  id='responsable'
                  name='responsable'
                  w='360px'
                  placeholder='Selecione'
                  bg='#F9F8FA'
                  onChange={handleSelect('responsable')}
                  isDisabled={disableResponsable}
                  value={
                    disableResponsable ? user?.id : formik.values.responsable
                  }
                  label={''}
                  formik={formik}
                >
                  {mentors.map((mentor) => (
                    <option key={mentor.id} value={mentor.id}>
                      {mentor.fullname}
                    </option>
                  ))}
                </Select>

                <FormLabel
                  fontWeight={'700'}
                  fontSize='14px'
                  mb='0px'
                  mt='20px'
                >
                  Edital Associado<span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Select
                  marginBottom={'0px'}
                  id='notice'
                  name='notice'
                  w='360px'
                  placeholder='Selecione'
                  bg='#F9F8FA'
                  onChange={handleSelect('notice')}
                  value={formik.values.notice}
                  label={''}
                  formik={formik}
                >
                  {userNotices
                    .sort((a, b) => b.id - a.id)
                    .filter((el) => datefns.isPast(new Date(el.end)))
                    .map((notice) => (
                      <option key={notice.id} value={notice.id}>
                        {notice.title}
                      </option>
                    ))}
                </Select>
              </Box>
              <Text fontWeight='semibold' fontSize='sm' mb='1'>
                É importante lembrar que:
              </Text>
              <UnorderedList pl='4' color='#747C86' fontSize='sm' spacing={15}>
                <ListItem>
                  Não há como convidar startups específicas para mentorias. O
                  convite sempre se estenderá a todas que forem parte do edital
                  selecionado;
                </ListItem>
              </UnorderedList>
            </Stack>
          </TabPanel>
          <TabPanel px={0} pt='20px'>
            <Stack>
              <Box w='360px' mb='12px'>
                <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
                  Edital Associado<span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Select
                  marginBottom={'0px'}
                  id='notice'
                  name='notice'
                  w='360px'
                  placeholder='Selecione'
                  bg='#F9F8FA'
                  onChange={handleSelect('notice')}
                  value={formik.values.notice}
                  label={''}
                  formik={formik}
                >
                  {[...notices]
                    .sort((a, b) => -a.id + b.id)
                    .filter((el) => datefns.isPast(new Date(el.end)))
                    .map((notice) => (
                      <option key={notice.id} value={notice.id}>
                        {notice.code}
                      </option>
                    ))}
                </Select>
                {formik.values.notice !== '' && (
                  <div>
                    <FormLabel
                      fontWeight={'700'}
                      fontSize='14px'
                      mb='0px'
                      mt='20px'
                    >
                      Etapa Associada<span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <Select
                      marginBottom={'0px'}
                      id='noticeStep'
                      name='noticeStep'
                      w='360px'
                      placeholder='Selecione'
                      bg='#F9F8FA'
                      onChange={handleSelect('noticeStep')}
                      value={formik.values.noticeStep}
                      label={''}
                      formik={formik}
                    >
                      {formik.values.notice &&
                        notices
                          .filter((el) => {
                            return el.id === Number(formik.values.notice);
                          })[0]
                          .steps.map((notice) => (
                            <option key={notice.id} value={notice.id}>
                              {notice.name}
                            </option>
                          ))}
                    </Select>
                  </div>
                )}
              </Box>
              <Text fontWeight='semibold' fontSize='sm' mb='1'>
                É importante lembrar que:
              </Text>
              <UnorderedList pl='4' color='#747C86' fontSize='sm' spacing={15}>
                <ListItem>
                  Todos os mentores e startups vinculadas a este edital serão
                  notificados;
                </ListItem>
                <ListItem>
                  Um evento do tipo “Entrega” é apenas um lembrete. A consulta
                  ou envio de arquivos se dará pelo fluxo de “Documentos”.
                </ListItem>
              </UnorderedList>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default withAccess(TypeEvent);
