import React from 'react';

const IconTwitter = (): JSX.Element => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle opacity='0.3' cx='20' cy='20' r='19.5' stroke='#EFEFEF' />
      <path
        d='M31 14.025C30.2277 14.3672 29.3981 14.5984 28.526 14.7029C29.4259 14.1645 30.0991 13.317 30.4201 12.3186C29.5746 12.8208 28.6493 13.1743 27.6844 13.3637C27.0355 12.6709 26.176 12.2117 25.2393 12.0573C24.3027 11.903 23.3413 12.0623 22.5044 12.5103C21.6675 12.9584 21.002 13.6702 20.6111 14.5353C20.2203 15.4003 20.1259 16.3702 20.3428 17.2944C18.6296 17.2084 16.9537 16.7631 15.4238 15.9875C13.8939 15.2119 12.5442 14.1232 11.4622 12.7922C11.0923 13.4303 10.8795 14.1702 10.8795 14.9582C10.8791 15.6676 11.0538 16.3661 11.3881 16.9917C11.7224 17.6174 12.206 18.1509 12.7959 18.5448C12.1117 18.5231 11.4427 18.3382 10.8444 18.0056V18.0611C10.8443 19.056 11.1885 20.0203 11.8185 20.7904C12.4484 21.5605 13.3254 22.0888 14.3006 22.2859C13.666 22.4577 13.0006 22.483 12.3547 22.3599C12.6299 23.216 13.1658 23.9646 13.8876 24.5009C14.6093 25.0372 15.4807 25.3344 16.3797 25.3509C14.8535 26.549 12.9687 27.1989 11.0285 27.196C10.6848 27.1961 10.3413 27.176 10 27.1359C11.9695 28.4022 14.2621 29.0743 16.6035 29.0716C24.5296 29.0716 28.8626 22.507 28.8626 16.8135C28.8626 16.6285 28.858 16.4417 28.8497 16.2567C29.6925 15.6472 30.42 14.8924 30.9982 14.0278L31 14.025Z'
        fill='#F9F8FA'
      />
    </svg>
  );
};

export default IconTwitter;
