import { iActivity } from '~/domain/interfaces/models/Activity';
import { ActivityTypes } from '~/domain/interfaces/redux/activity/types';
import { ActivityActions } from './actions';

export const initialState: iActivity = {
  loagind: false,
  records: [],
};

const reducer = (state = initialState, action: ActivityActions): iActivity => {
  switch (action.type) {
    case ActivityTypes.GET:
      return { ...state, loagind: true };
    case ActivityTypes.GET_FAILED:
      return { ...state, loagind: false };
    case ActivityTypes.GET_SUCCESS: {
      return { ...action.payload, loagind: false };
    }
    default:
      return state;
  }
};

export default reducer;
