import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ConfirmMattermostUser as UsecaseRemoteConfirmMattermostUser } from '~/domain/usecases/user/remote';
// import { ConfirmMattermostUser as UsecaseReduxConfirmMattermostUser } from '~/domain/usecases/user/redux';

import { RemoteConfirmMattermostUser } from '~/data/repository/user';
// import { ReduxConfirmMattermostUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteConfirmMattermostUser =
  (): UsecaseRemoteConfirmMattermostUser =>
    new RemoteConfirmMattermostUser(
      makeApiUrl('/users/{user}/confirmation/mattermost'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxConfirmMattermostUser =
  (): UsecaseReduxConfirmMattermostUser =>
    new ReduxConfirmMattermostUser(); */
