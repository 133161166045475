import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Divider,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FormRegisterStartup from '~/presentation/components/Form/RegisterStartup/FormRegisterStartup';
import CameraButton from './StartupPicture';
import { schemaRegisterStartup } from '~/validators/RegisterStartup/RegisterStartupValidator';
import { makeRemoteCreateStartup } from '~/main/factories/usecases/startup/CreateStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Conflict } from '~/domain/errors/Conflict';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterStartup = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterStartup;
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [image, setImage] = React.useState<File | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { accessToken, user } = useSelector((store: iStore) => store.auth);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      if (!user) return;

      setIsLoading(true);
      makeRemoteCreateStartup()
        .create({
          body: {
            name: values.name,
            supervisor: user.id, //1
            cnpj: values.cnpj.replace(/[./-]/g, ''),
            fullname: values.nameManager,
            description: 'mock',
            activity: Number(values.activity),
            city: values.city,
            address: {
              type: 'APT',
              zipcode: values?.cep,
              province: 'PB',
              city: 'João Pessoa',
              country: 'BRA',
              neighborhood: 'Mangabeira',
              street: values?.address,
              number: '120',
              complement: 'CI',
            },
            gender: values.gender,
            manager: {
              fullname: values.nameManager,
              email: values.emailManager,
              phone: '83988888888',
              cpf: '62522172042',
              job: 'mock',
              workload: 'mock',
            },
          },
        })
        .then(async ({ id }) => {
          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);

            await axios.post(makeApiUrl(`/startups/${id}/logo`), formData, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
          }

          makeReduxListStartup().list({});
          showConfirmModal('SUBMMIT_STARTUP');
        })
        .catch((error) => {
          if (error instanceof Conflict) {
            showConfirmModal('CONFLICT_ERROR');
          } else {
            showConfirmModal('APPLICATION_ERROR');
          }
        })
        .finally(() => {
          onCloseModal();
          setIsLoading(false);
        });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();

    const handleFieldsErrors = () => {
      formik.setTouched({
        name: true,
        cep: true,
        address: true,
        nameManager: true,
        emailManager: true,
        phoneManager: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: 'fit-content', md: '528px' }}
        minH={{ base: '700px', md: 'auto' }}
        position={{ base: 'fixed', md: 'inherit' }}
        top={{ base: '5px', md: 'auto' }}>
        <ModalHeader pb={0}>Nova startup</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={'4px'}>
          <Box maxH={{ base: '600px', md: 'auto' }} overflowY={{ base: 'scroll', md: 'initial' }}>
            <Text pb={4} fontSize={'14px'}>
              Para continuar, insira algumas informações gerais
              sobre a startup e o gestor responsável por ela.
            </Text>
            {/* <CameraButton onChange={setImage} /> */}
            {/* <Divider
            orientation='vertical'
            display='inline-flex'
            height='442px'
            position='relative'
            left='15px'
          /> */}
            <FormRegisterStartup formik={formik} />
            <Divider
              orientation='horizontal'
              display='inline-flex'
              width={'100%'}
              position='relative'
              marginTop={'0px'}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            {!isMobile && (
              <Button
                fontWeight='medium'
                variant='outline'
                color='#303950'
                borderColor='#303950'
                onClick={() => {
                  formik.resetForm();
                  setIsLoading(false);
                  onClose();
                }}
              >
                Cancelar
              </Button>
            )}
            {
              <Button
                id='submit'
                bg='#222222'
                w={{ base: '100%', md: 'auto' }}
                _hover={{ background: '#484848' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                loadingText='Concluindo cadastro'
                fontWeight='500'
                fontSize='14px'
                onClick={() => {
                  { formik.handleSubmit() }
                }}
              >
                Concluir pré-cadastro
              </Button>
            }
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RegisterStartup;
