import { Box, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { Tooltip } from '../Tooltip';
import { IconButtonProps } from './types';

type IconButtonSizes = 'sm' | 'md' | 'lg';
type IconButtonStyleOptions = ButtonProps;

const iconButtonSizes: Record<IconButtonSizes, IconButtonStyleOptions> = {
  sm: {
    width: 6,
    minWidth: 6,
  },
  md: {
    width: 9,
    minWidth: 9,
  },
  lg: {
    width: 10,
    minWidth: 10,
  },
};

export const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
  ({ size = 'md', hintPlacement = 'auto', ...props }, ref) => {
    return (
      <Tooltip label={props.hint} placement={hintPlacement}>
        <Box
          as={'button'}
          display='flex'
          justifyContent='center'
          alignItems='center'
          ref={ref}
          sx={{
            width: iconButtonSizes[size].width,
            height: iconButtonSizes[size].width,
            '&>svg path': {
              fill: props.color || 'black.1',
            },
            '&:disabled>svg path': {
              fill: 'black.5',
            },
            '&>svg': {
              width: size === 'sm' ? '12px' : '16px',
              height: size === 'sm' ? '12px' : '16px',
            },
          }}
          bgColor='white.1'
          border='1px solid'
          borderColor='black.5'
          borderRadius='5px'
          _hover={{
            bgColor: 'white.2',
          }}
          _focusVisible={{
            outline: '2px solid',
            outlineColor: 'blue.1',
            outlineOffset: 0,
            boxShadow: '0 0 15px 0px rgba(25, 151, 243, 0.6)',
          }}
          _disabled={{
            borderColor: 'black.5',
            bgColor: 'black.6',
            pointerEvents: 'none',
          }}
          {...props}
        />
      </Tooltip>
    );
  }
);
IconButton.displayName = 'IconButton';
