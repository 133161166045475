import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/application/actions/list';

import { iActionList } from '~/domain/interfaces/redux/application/list';

import { ListApplication } from '~/domain/usecases/application/redux';

export class ReduxListApplication implements ListApplication {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListApplication.Params): iActionList =>
    this.send(listRequest(params));
}
