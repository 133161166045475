import * as yup from 'yup';
import { validateCPF, validatePhone } from 'validations-br';

export const schemaConfirmationUser = {
  initial: {
    name: '',
    datepicker: new Date(),
    rg: '',
    adress: '',
    email: '',
    role: '',
    gender: '',
    cpf: '',
    cep: '',
    phone: '',
    workload: '',
    permission: 'ADMIN',
    password: '',
    confirm_password: '',
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    datepicker: yup.date().required('Campo obrigatório'),
    rg: yup.string().required('Campo obrigatório'),
    adress: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório').email('Email inválido'),
    role: yup.string().required('Campo obrigatório'),
    gender: yup
      .string()
      .oneOf(['MALE', 'FEMALE', 'OTHER'], 'Selecione uma opção válida')
      .required('Campo obrigatório'),
    cpf: yup
      .string()
      .required('Campo obrigatório')
      .test('is-cpf', 'CPF inválido', (cpf) => validateCPF(cpf!)),
    cep: yup
      .string()
      .required('Campo obrigatório')
      .matches(/(?!^0{5}-0{3}$)(^[0-9]{5}-[0-9]{3}$)/, 'CEP inválido'),
    phone: yup.string().required('Campo obrigatório'),
    workload: yup
      .string()
      .oneOf(['20h/semana', '40h/semana'], 'Selecione uma opção válida')
      .required('Campo obrigatório'),
    permission: yup.string().required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .required('Confirmação de senha é obrigatória'),
  }),
};
