import React from 'react';

const IconDelete = () => {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00282 5.22599V14.3192H5.22599V5.22599H7.00282Z" fill="#C23422" />
      <path d="M11.1836 5.22599V14.3192H9.40678V5.22599H11.1836Z" fill="#C23422" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1.0452H5.22599V0H11.1836V1.0452H16.4096V2.82203H15.3644V16.5664C15.3644 17.0792 15.1607 17.571 14.7981 17.9337C14.4354 18.2963 13.9436 18.5 13.4308 18.5H2.97881C2.46599 18.5 1.97416 18.2963 1.61154 17.9337C1.24892 17.571 1.0452 17.0792 1.0452 16.5664V2.82203H0V1.0452ZM13.5876 2.82203H2.82203V16.7232H13.5876V2.82203Z" fill="#C23422" />
    </svg>

  );
};

export default IconDelete;
