import React from 'react';

const IconArrowLeft = (): JSX.Element => {
  return (
    <svg
      width='7'
      height='10'
      viewBox='0 0 7 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.13555 0.263092C6.21857 0.345946 6.28444 0.444362 6.32939 0.552705C6.37433 0.661048 6.39746 0.77719 6.39746 0.894485C6.39746 1.01178 6.37433 1.12792 6.32939 1.23626C6.28444 1.34461 6.21857 1.44302 6.13555 1.52588L2.66065 5.00078L6.13555 8.47568C6.30301 8.64314 6.39708 8.87026 6.39708 9.10707C6.39708 9.34389 6.30301 9.57101 6.13555 9.73847C5.96809 9.90593 5.74098 10 5.50416 10C5.26734 10 5.04022 9.90593 4.87276 9.73847L0.76199 5.6277C0.678965 5.54484 0.613096 5.44642 0.568154 5.33808C0.523212 5.22974 0.50008 5.1136 0.50008 4.9963C0.50008 4.87901 0.523212 4.76287 0.568154 4.65452C0.613096 4.54618 0.678965 4.44776 0.76199 4.36491L4.87276 0.254135C5.21309 -0.08619 5.78627 -0.0861898 6.13555 0.263092Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default IconArrowLeft;
