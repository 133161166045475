import React, { useContext, useState } from 'react';
import * as datefns from 'date-fns';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';

import { iStore } from '~/domain/interfaces/models';
import { makeRemoteCreateAccountability } from '~/main/factories/usecases/accountability/CreateAccountabilityFactory';
import FormRegisterInform from '../../../Form/RegisterInform/FormRegisterInform';
import { schemaRegisterInform } from '~/validators/RegisterInform/RegisterInformValidator';
import { useSelector } from 'react-redux';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { makeReduxListAccountability } from '~/main/factories/usecases/accountability/ListAccountabilityFactory';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterInform = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);
  const { startups } = useSelector((store: iStore) => store.auth);
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onCloseModal = () => {
    onClose();
    setFiles([]);
    formik.resetForm({ values: initial });
    setIsLoading(false);
  };

  const { initial, validators } = schemaRegisterInform;
  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      setIsLoading(true);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      await axios
        .post(makeApiUrl('/files/upload'), formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          const f = res.data.map((el: any) => el.id);

          startups &&
            makeRemoteCreateAccountability()
              .create({
                body: {
                  code: values.code,
                  startup: startups[0].id,
                  notice: Number(values.notice),
                  type: values.type,
                  value: Number(values.value),
                  begin: datefns.format(new Date(values.begin), 'yyyy-MM-dd'),
                  end: datefns.format(new Date(values.end), 'yyyy-MM-dd'),
                  files: f,
                },
              })
              .then(() => {
                showConfirmModal('SUBMMIT_INFORM');
                makeReduxListAccountability().list({});
              })
              .catch(() => {
                showConfirmModal('APPLICATION_ERROR');
              })
              .finally(() => {
                onCloseModal();
              });
        })
        .catch(() => {
          showConfirmModal('APPLICATION_ERROR');
        })
        .finally(() => {
          onCloseModal();
        });
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        formik.resetForm();
        onCloseModal();
      }}
      size='3xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg='#F9F8FA'>
        <ModalHeader>Novo Informe</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH='416px' paddingBottom={'4px'} paddingTop={'6.5px'}>
          <Divider w={'100%'} marginBottom={'20px'}></Divider>
          <FormRegisterInform formik={formik} setFiles={setFiles} />
          <Divider w={'100%'}></Divider>
        </ModalBody>
        <ModalFooter>
          <Flex justify='space-between' w='100%'>
            <Button
              fontWeight='medium'
              variant='outline'
              color='#303950'
              borderColor='#E9ECEE'
              onClick={() => {
                onCloseModal();
              }}
            >
              Cancelar
            </Button>
            {
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                loadingText=' Cadastrando informe'
                fontWeight='500'
                fontSize='14px'
                disabled={files.length === 0}
                onClick={() => formik.handleSubmit()}
              >
                Cadastrar informe
              </Button>
            }
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterInform;
