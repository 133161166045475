import React, { useContext, useEffect } from 'react';
import * as datefns from 'date-fns';
import {
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { History } from '~/main/routes';
import CameraButton from '../../startup/RegisterStartup/StartupPicture';
import FormUserSignUpFinalization from '~/presentation/components/Form/FormUserSignUpFinalization/FormUserSignUpFinalization';
import { schemaConfirmationUser } from '~/validators/ConfirmationUser/ConfirmationUser';
import { makeRemoteConfirmUser } from '~/main/factories/usecases/user/ConfirmUserFactory';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  token: string;
}

const SignUpFinalization = ({
  isOpen,
  onClose,
  token,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userID, setUserID] = React.useState(0);
  const [image, setImage] = React.useState<File | null>(null);

  useEffect(() => {
    const user: any = jwtDecode(token);

    if (user) {
      setUserID(user?.id);

      formik.setValues({
        ...formik.values,
        name: user?.fullname,
        email: user?.email,
        workload: '20h/semana',
        role: user?.roleId?.toString(),
        cpf: user?.cpf || '',
        phone: user?.phone || '',
      });
    }
  }, []);

  const { initial, validators } = schemaConfirmationUser;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      makeRemoteConfirmUser()
        .confirm({
          body: {
            token: token,
            password: values.password,
            cpf: values.cpf.replace(/[./-]/g, ''),
            phone: values.phone.replace(/\D/g, ''),
            avatar: '',
            rg: values.rg,
            gender: values.gender,
            birthdate: datefns.format(
              new Date(values.datepicker),
              'yyyy-MM-dd'
            ),
            address: {
              type: 'OFFICE',
              zipcode: values.cep,
              province: '--',
              city: '',
              country: '--',
              neighborhood: 'string',
              street: values.adress,
              number: 'string',
              complement: 'string',
            },
          },
        })
        .then(async () => {
          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);

            await axios.post(makeApiUrl(`/users/${userID}/avatar`), formData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          showConfirmModal('SIGN_UP_USER_CONFIRMATION', {
            fullname: values.name,
          });
        })
        .catch(() => {
          showConfirmModal('APPLICATION_ERROR');
        })
        .finally(() => {
          onCloseModal();
          setIsLoading(false);
        });
    },
  });

  const onCloseModal = () => {
    formik.resetForm({ values: initial });
    onClose();
    History.push('/');
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} size='3xl' isCentered>
        <ModalOverlay />
        <ModalContent bg='#F9F8FA'>
          <ModalHeader>Finalizar pré-cadastro</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            minH='416px'
            paddingBottom={'4px'}
            paddingTop={'6.5px'}
            display={'flex'}
            flexDirection={'column'}
          >
            <Flex flexDir={'row'} gap={'20px'} marginBottom={'32px'}>
              <CameraButton onChange={setImage}></CameraButton>
              <Divider orientation='vertical' h={'442px'}></Divider>
              <FormUserSignUpFinalization formik={formik} />
            </Flex>
            <Divider w={'100%'}></Divider>
          </ModalBody>
          <ModalFooter>
            <Flex justify='space-between' w='100%'>
              <Button
                variant='outline'
                color='#303950'
                borderColor='#E9ECEE'
                onClick={() => {
                  onClose;
                }}
                fontWeight='500'
                fontSize='14px'
              >
                Cancelar
              </Button>
              {
                <Button
                  id='submit'
                  bg='#303950'
                  _hover={{ background: '#788FC9' }}
                  _active={{ background: '#303950' }}
                  color='white'
                  isLoading={isLoading}
                  loadingText='Concluindo cadastro'
                  fontWeight='500'
                  fontSize='14px'
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Concluir cadastro
                </Button>
              }
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignUpFinalization;
