import * as yup from 'yup';

interface MainSchema {
  initial: {
    type: 'MEETING' | 'MENTORING' | 'DELIVERY';
    name: string;
    description?: string;
    link?: string;
    date: Date;
    time: Date;
    responsable?: number;
    notice?: number;
    noticeStep?: number;
    guests?: string[];
  };
  validators: yup.ObjectSchema<any>;
}

interface Variation {
  initial: {
    guests?: string[];
    responsable?: number;
    notice?: number;
    noticeStep?: number;
  };
  validators: yup.ObjectSchema<any>;
}

const mainSchema: MainSchema = {
  initial: {
    type: 'MEETING',
    name: '',
    description: '',
    link: '',
    date: new Date(),
    time: new Date(),
    guests: [],
    responsable: undefined,
    notice: undefined,
    noticeStep: undefined,
  },
  validators: yup.object({
    type: yup.string().oneOf(['MEETING', 'MENTORING', 'DELIVERY']).required(),
    name: yup.string().required('Campo obrigatório'),
    description: yup.string(),
    link: yup.string().url('URL inválida'),
    date: yup.date().required('Campo obrigatório'),
    time: yup.date().required('Campo obrigatório'),
  }),
};

const meetingVariation: Variation = {
  initial: {
    guests: [],
  },
  validators: yup.object({
    guests: yup.array().min(1, 'Campo obrigatório'),
  }),
};

const mentoringVariation: Variation = {
  initial: {
    responsable: undefined,
    notice: undefined,
  },
  validators: yup.object({
    responsable: yup.number().required('Campo obrigatório'),
    notice: yup.number().required('Campo obrigatório'),
  }),
};

const deliveryVariation: Variation = {
  initial: {
    notice: undefined,
    noticeStep: undefined,
  },
  validators: yup.object({
    notice: yup.number().required('Campo obrigatório'),
    noticeStep: yup.number().required('Campo obrigatório'),
  }),
};

const schemaRegisterEvent: Record<number, yup.ObjectSchema<any>> = {
  0: yup
    .object()
    .shape({
      ...mainSchema.validators.fields,
      ...meetingVariation.validators.fields,
    }),
  1: yup
    .object()
    .shape({
      ...mainSchema.validators.fields,
      ...mentoringVariation.validators.fields,
    }),
  2: yup
    .object()
    .shape({
      ...mainSchema.validators.fields,
      ...deliveryVariation.validators.fields,
    }),
};

export { mainSchema, schemaRegisterEvent };
