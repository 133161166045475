const getEventTypeString = (eventType: string): string => {
  switch (eventType) {
    case 'MEETING':
      return 'Reunião';
    case 'MENTORING':
      return 'Mentoria';
    case 'DELIVERY':
      return 'Entrega';
    default:
      return 'Reunião';
  }
};

export default getEventTypeString;
