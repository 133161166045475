import React, { useEffect } from 'react';
import Team from '~/presentation/pages/team';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';
import { makeReduxListUser } from '../../usecases/user/ListUserFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';

export const makeTeamFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListStartup().list({});
    makeReduxListNotice().list({ query: { limit: 999 } });
    makeReduxListUser().list({ query: { limit: 999 } });
  }, []);

  return <Team />;
};
