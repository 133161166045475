import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateUser as UsecaseRemoteCreateUser } from '~/domain/usecases/user/remote';
// import { CreateUser as UsecaseReduxCreateUser } from '~/domain/usecases/user/redux';

import { RemoteCreateUser } from '~/data/repository/user';
// import { ReduxCreateUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateUser = (): UsecaseRemoteCreateUser =>
  new RemoteCreateUser(makeApiUrl('/users/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateUser =
  (): UsecaseReduxCreateUser =>
    new ReduxCreateUser(); */
