import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { EvaluateAccountability as UsecaseRemoteEvaluateAccountability } from '~/domain/usecases/accountability/remote';
// import { EvaluateAccountability as UsecaseReduxEvaluateAccountability } from '~/domain/usecases/accountability/redux';

import { RemoteEvaluateAccountability } from '~/data/repository/accountability';
// import { ReduxEvaluateAccountability } from '~/data/store/reducer/accountability/usecases';

/**
 * send request via API.
 */
export const makeRemoteEvaluateAccountability =
  (): UsecaseRemoteEvaluateAccountability =>
    new RemoteEvaluateAccountability(
      makeApiUrl('/accountabilities/{accountability}/evaluate'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxEvaluateAccountability =
  (): UsecaseReduxEvaluateAccountability =>
    new ReduxEvaluateAccountability(); */
