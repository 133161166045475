import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/notice/list';
import { ListNotice } from '~/domain/usecases/notice/remote/ListNotice';
import { makeRemoteListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/notice/actions/list';

export function* onList(action: iActionList) {
  const remoteListNotice = makeRemoteListNotice();
  // eslint-disable-next-line no-console
  console.log('...action.payload sagas', action.payload);

  try {
    const response: ListNotice.Model = yield call(
      remoteListNotice.list,
      action.payload
    );
    yield put(
      listSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
