import React, { useEffect } from 'react';
import Accountability from '~/presentation/pages/accountability';
import { makeReduxListApplication } from '../../usecases/application/ListApplicationFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxListAccountability } from '../../usecases/accountability/ListAccountabilityFactory';

export const makeAccountabilityFactory: React.FC = () => {
  const { user, startups } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    makeReduxListApplication().list({
      query: {
        ...(user?.role_.name === 'LEADER' && startups?.length
          ? { startup: startups[0].id }
          : {}),
      },
    });
    makeReduxListAccountability().list({});
  }, []);
  return <Accountability />;
};
