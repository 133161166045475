import { Avatar, Flex, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { iStartup } from '~/domain/interfaces/models';
import IconStartupProfileImg from '~/presentation/base/icons/IconStartupProfileImg';
import IconTeamUserImg from '~/presentation/base/icons/IconTeamUserImg';
import GenericModalLayout from '~/presentation/components/Modals/GenericModalLayout';
import StringMask from '~/utils/getStringMask';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const DetailsStartup = ({ isOpen, onClose, startup }: ownProps) => {
  const getMainLeader = useCallback(() => {
    if (startup?.members) {
      for (const member of startup.members) {
        if (member.role === 'LEADER' && member.major) {
          return member;
        }
      }
    }
    return undefined;
  }, [startup]);

  return (
    <GenericModalLayout
      isOpen={isOpen}
      onClose={() => onClose()}
      headerTitle='Detalhes da Startup'
      modalSize='large'
      isPartitioned={true}
      centerContent={
        <Flex flexDirection={'row'} gap='32px'>
          <Flex flexDirection={'column'} gap='20px'>
            <Flex key='' flexDirection={'column'} gap='8px'>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Nome da Startup
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {startup?.name}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Gestor responsável
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {getMainLeader()?.user_.fullname}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Endereço
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {startup?.address.street}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Edital associado
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {startup?.applications.find((el) => el.status === 'APPROVED')
                  ?.notice_?.code || 'Não associado'}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Documentos
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                <Link
                  style={{ textDecoration: 'underline', color: '#1997F3' }}
                  to={{
                    pathname: '/documentos',
                    state: {
                      path: [
                        {
                          name: 'STARTUPS',
                          id: 0,
                        },
                        {
                          name: startup?.name,
                          id: startup?.folder,
                        },
                      ],
                    },
                  }}
                >
                  Ver documentos
                </Link>
              </Text>
              {/*TODO <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Recursos
              </Text> */}
              {/* <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                ----
              </Text> */}
            </Flex>
          </Flex>
          <Flex flexDirection={'column'} gap='20px'>
            <Flex key='' flexDirection={'column'} gap='8px'>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
                display={startup?.cnpj ? 'normal' : 'none'}
              >
                CNPJ
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
                display={startup?.cnpj ? 'normal' : 'none'}
              >
                {new StringMask('00.000.000/0000-00').apply(startup?.cnpj)}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                CPF
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {new StringMask('000.000.000-00').apply(
                  getMainLeader()?.user_.cpf
                )}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                CEP
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                {startup?.address.zipcode}
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='700'
                fontSize='sm'
                color='#303950'
              >
                Etapa atual
              </Text>
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='400'
                fontSize='sm'
                color='#303950'
              >
                Não há etapas
              </Text>
            </Flex>
          </Flex>
        </Flex>
      }
      startupImg={
        <Avatar size='2xl' name={startup?.name} src={startup?.logo} />
      }
      /*TODO rightContent={
        <Flex flexDirection={'column'} gap='15px'>
          <Text
            fontFamily='Inter'
            fontStyle='normal'
            fontWeight='700'
            fontSize='sm'
            color='#303950'
          >
            Equipe
          </Text>
          <Flex flexDirection={'column'} gap='12px'>
            <Flex flexDirection={'row'} gap='15px'>
              <IconTeamUserImg></IconTeamUserImg>
              <Flex flexDirection='column'>
                {startupDataMock.map((item, key) => (
                  <Text
                    key=''
                    fontFamily='Inter'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='sm'
                    color='#60656D'
                  >{`${
                    item.equipe.users.user1.userName +
                    '\n' +
                    item.equipe.users.user1.userEmail
                  }`}</Text>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      } */
    ></GenericModalLayout>
  );
};

export default DetailsStartup;
