import { iStartup } from '~/domain/interfaces/models/Startup';
import { StartupTypes } from '~/domain/interfaces/redux/startup/types';
import { StartupActions } from './actions';

export const initialState: iStartup = {
  loading: false,
  records: [],
};

const reducer = (state = initialState, action: StartupActions): iStartup => {
  switch (action.type) {
    case StartupTypes.LIST:
      return { ...state, loading: true };
    case StartupTypes.LIST_FAILED:
      return { ...state, loading: false };
    case StartupTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
