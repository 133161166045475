import { CreateStartup } from '~/domain/usecases/startup/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteCreateStartup implements CreateStartup {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateStartup.Model>;

  constructor(url: string, httClient: HttpClient<CreateStartup.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateStartup.Params
  ): Promise<CreateStartup.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      body: params.body,
      method: 'post',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
