import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Flex, HStack, Text, Button } from '@chakra-ui/react';

import { History } from '~/main/routes';
import { makeRemoteCreateStartup } from '~/main/factories/usecases/startup/CreateStartupFactory';
import { useFormik } from 'formik';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { schemaRegisterStartup } from '~/validators/RegisterStartup/RegisterStartupValidator';
import FormRegisterStartup from '~/presentation/components/Form/RegisterStartup/FormRegisterStartup';
import { iStore } from '~/domain/interfaces/models/Store';

const EditStartup = (): JSX.Element => {
  const { initial, validators } = schemaRegisterStartup;
  const { id } = useParams<{ id: string }>();
  const startup = useSelector((store: iStore) =>
    store.startup.records.find((el) => el.id === Number(id))
  );

  const formik = useFormik({
    initialValues: startup
      ? {
          ...startup,
          address: '',
          number: '',
          cep: '',
          notice: startup.description,
          nameManager: startup.fullname,
          emailManager: '',
        }
      : initial,
    validateOnChange: false,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);

     /*  makeRemoteCreateStartup()
        .create({
          body: {
            cnpj: values.cnpj.replace(/[./-]/g, ''),
            name: values.name,
            fullname: values.nameManager,
            description: values.notice,
            supervisor: 1,
            address: {
              type: 'APT',
              zipcode: values.cep,
              province: 'PB',
              city: 'string',
              country: 'BRA',
              neighborhood: 'string',
              street: values.address,
              number: values.number,
              complement: 'string',
            },
            manager: {
              fullname: values.nameManager,
              //email: values.,
            },
          },
        })
        .then(() => {
          makeReduxListStartup().list({});
          resetForm({
            values: initial,
          });
          History.push('/startups');
        }); */
    },
  });

  return (
    <Box flex='1'>
      <Flex justify='space-between' mx='8'>
        <Text color='#1c294b' fontWeight='bold' fontSize='2xl'>
          Editar Startup
        </Text>
        <HStack spacing='4'>
          <Button
            variant='outline'
            color='#303950'
            borderColor='#303950'
            onClick={() => History.push('/startups')}
          >
            Cancelar
          </Button>
          <Button
            id='submit'
            bg='#303950'
            _hover={{ background: '#788FC9' }}
            _active={{ background: '#303950' }}
            fontSize={14}
            color='white'
            onClick={() => formik.handleSubmit()}
          >
            Concluir edição
          </Button>
        </HStack>
      </Flex>
      <FormRegisterStartup formik={formik} />
    </Box>
  );
};

export default EditStartup;
