import React, { useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Flex,
  Button,
  HStack,
  ModalFooter,
  Stack,
  Avatar,
} from '@chakra-ui/react';
import { iApplication } from '~/domain/interfaces/models/Application';
import getDate from '~/utils/getDate';
import { useFormik } from 'formik';
import RevenueEvaluation from '~/presentation/components/RevenueEvaluation';
import TextArea from '~/presentation/components/UI/textArea';
import { makeReduxListApplication } from '~/main/factories/usecases/application/ListApplicationFactory';
import StringMask from '~/utils/getStringMask';
import { iStartup, iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { schemaEvaluateApplication } from '~/validators/EvaluateApplication/EvaluateApplicationValidator';
import { makeRemoteEvaluateApplication } from '~/main/factories/usecases/application/EvaluateApplicationFactory';
interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  application?: iApplication['records'][0];
}

const EvaluateApplication = ({ isOpen, onClose, application }: ownProps) => {
  const { initial, validators } = schemaEvaluateApplication;
  const [status, setStatus] = React.useState<string>(application?.status || '');
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [thrownError, setThrownError] = React.useState<boolean>(false);

  const { records } = useSelector((store: iStore) => store.startup);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('>>> application', application);

    // eslint-disable-next-line no-console
    console.log('Valores recebidos', formik.values);

    return;
  }, [application]);

  const evaluateApplication = async (application: number, status: string) => {
    setIsLoading(true);
    await makeRemoteEvaluateApplication()
      .evaluate({
        application,
        body: { status: status },
      })
      .then(() => {
        makeReduxListApplication().list({});
        formik.setFieldValue('status', status);
        if (thrownError === true) {
          showConfirmModal('APPLICATION_ERROR');
        } else if (thrownError === false && status === 'APPROVED') {
          showConfirmModal('APPLICATION_APPROVED');
          // eslint-disable-next-line no-console
          console.log('Aprovado dentro do IF');
        } else if (thrownError === false && status === 'REJECTED') {
          showConfirmModal('APPLICATION_REJECTED');
        } else {
          showConfirmModal('APPLICATION_ERROR');
        }
      })
      .catch((error) => {
        setThrownError(true);
        showConfirmModal('APPLICATION_ERROR');
        // eslint-disable-next-line no-console
        console.log('console Catch Error', error);
      })
      .finally(() => {
        setIsLoading(false);
        handleOnClose();
      });
  };

  const handleOnClose = () => {
    // Cleaning the status buttons first
    if (status !== 'SUBMITTED') {
      application?.status && setStatus(application?.status ?? 'SUBMITTED');
    }
    // Resetting the form values
    formik.resetForm({ values: initial });
    return onClose();
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      application && evaluateApplication(application?.id, status);
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
      <ModalOverlay />
      <ModalContent minW='800px'>
        <ModalHeader>Avaliar Inscrição</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify='space-between' gap='4'>
            <Flex direction='column' gap='5'>
              <Flex w='100%' fontSize='sm' gap='6'>
                <Flex w='50%'>
                  <Box w='50%'>
                    <Text fontWeight='700' color='#303950' mb='2'>
                      ID
                    </Text>
                    <Text color='#60656D'>{application?.id}</Text>
                  </Box>
                  <Box w='50%'>
                    <Text fontWeight='700' color='#303950' mb='2'>
                      Startup
                    </Text>
                    <Flex flexDir={'row'} gap={'5px'}>
                      <Avatar
                        name={application?.startup_.fullname}
                        src={application?.startup_?.logo ?? ''}
                        size={'xs'}
                      />
                      <Text color='#60656D'>{application?.startup_?.name}</Text>
                    </Flex>
                  </Box>
                </Flex>
                <Box w='50%'>
                  <Text fontWeight='700' color='#303950' mb='2'>
                    CNPJ
                  </Text>
                  <Text color='#60656D'>
                    {new StringMask('00.000.000/0000-00').apply(
                      application?.startup_?.cnpj
                    )}
                  </Text>
                </Box>
              </Flex>
              <Flex w='100%' fontSize='sm' gap='6'>
                <Box w='50%'>
                  <Text fontWeight='700' color='#303950' mb='2'>
                    Gestor responsável
                  </Text>
                  <Text color='#60656D'>
                    {
                      application?.startup_.members
                        .filter((member) => member?.major === true)
                        .at(0)?.user_?.fullname
                    }
                  </Text>
                </Box>
                <Box w='50%'>
                  <Text fontWeight='700' color='#303950' mb='2'>
                    CPF
                  </Text>
                  <Text color='#60656D'>
                    {new StringMask('000.000.000-00').apply(
                      application?.startup_.members
                        .filter((member) => member?.major === true)
                        .at(0)?.user_?.cpf
                    )}
                  </Text>
                </Box>
              </Flex>
              <Flex w='100%' fontSize='sm' gap='6'>
                <Box w='50%'>
                  <Text fontWeight='700' color='#303950' mb='2'>
                    Edital associado
                  </Text>
                  <Text color='#60656D'>{`Nº ${application?.notice_?.code}`}</Text>
                  <Text color='#60656D'>{application?.notice_?.title}</Text>
                </Box>
                <Box w='50%'>
                  <Box w='50%'>
                    <Text fontWeight='700' color='#303950' mb='2'>
                      Inscrições
                    </Text>
                    <Text color='#60656D'>
                      {`De ${getDate(application?.notice_?.start)}`}
                      {`até ${getDate(application?.notice_?.end)}`}
                    </Text>
                  </Box>
                </Box>
              </Flex>
              <Flex w='100%' fontSize='sm' gap='6'>
                <Box w='50%'>
                  <Text fontWeight='700' color='#303950' mb='2'>
                    Documentos
                  </Text>
                  <Link
                    to={{
                      pathname: '/documentos',
                      state: {
                        path: [
                          { name: 'STARTUPS', id: 0 },
                          {
                            name: `Inscrição ${application?.startup_?.name} no Edital ${application?.notice_?.code}`,
                            id: application?.folder?.id,
                          },
                        ],
                      },
                    }}
                  >
                    <Text color='#1997F3' textDecor='underline'>
                      Ver documentos
                    </Text>
                  </Link>
                </Box>
                <Box w='50%'>
                  <Box w='50%'>
                    <Text fontWeight='700' color='#303950' mb='2'>
                      Realizada em
                    </Text>
                    <Text color='#60656D'>
                      {getDate(application?.createdAt)}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Flex>
            <Stack w='50%' spacing='5'>
              <Box>
                <Text fontWeight='bold' fontSize='sm' mb='3'>
                  Papel do usuário<span style={{ color: 'red' }}>*</span>
                </Text>
                <HStack spacing='3'>
                  <RevenueEvaluation
                    status='APPROVED'
                    isHilighted={status === 'APPROVED'}
                    onClick={() => {
                      setStatus('APPROVED');
                      formik.setFieldValue('status', 'APPROVED');
                    }}
                  >
                    Deferida
                  </RevenueEvaluation>
                  <RevenueEvaluation
                    status='REJECTED'
                    isHilighted={status === 'REJECTED'}
                    onClick={() => {
                      setStatus('REJECTED');
                      formik.setFieldValue('status', 'REJECTED');
                    }}
                  >
                    Indeferida
                  </RevenueEvaluation>
                </HStack>
              </Box>
              <Box>
                <Text as={Flex} fontWeight='bold' fontSize='sm' mb='3'>
                  Justificativa{' '}
                  <Text fontWeight='normal' ml='1'>{` (opcional)`}</Text>
                </Text>
                <TextArea
                  id='justify'
                  name='justify'
                  label=''
                  value={formik.values.justify}
                  formik={formik}
                  marginTop='0px'
                  resize='none'
                  minH={'186px'}
                />
              </Box>
            </Stack>
          </Flex>
        </ModalBody>
        <ModalFooter pt='8'>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button
              w='100px'
              variant='outline'
              color='#60656D'
              borderColor='#CECECE'
              onClick={() => {
                onClose();
                formik.resetForm();
              }}
            >
              <Text fontWeight='500' fontSize='14px'>
                Cancelar
              </Text>
            </Button>
            {
              <Button
                w='220px'
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                /* disabled={application?.status !== 'SUBMITTED'} */
                loadingText='Submetendo avaliação'
                onClick={async () => {
                  formik.resetForm();
                  formik.handleSubmit();
                }}
                fontWeight='500'
                fontSize='14px'
              >
                Submeter avaliação
              </Button>
            }
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EvaluateApplication;
