import React from 'react';

const IconRegistrationsNotice = (): JSX.Element => {
  return (
    <svg
      width='262'
      height='196'
      viewBox='0 0 262 196'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M262 171.557H0V171.688H262V171.557Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M44.8441 176.546H27.4893V176.677H44.8441V176.546Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M117.9 176.614H89.6772V176.745H117.9V176.614Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M65.4794 179.25H55.4238V179.381H65.4794V179.25Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M233.704 179.187H223.628V179.318H233.704V179.187Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M218.958 179.187H203.086V179.318H218.958V179.187Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M194.346 178.323H172.045V178.454H194.346V178.323Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M124.188 148.187H23.0088C22.2161 148.186 21.4565 147.87 20.8965 147.309C20.3365 146.748 20.022 145.988 20.022 145.195V2.96584C20.0289 2.17776 20.3464 1.42422 20.9057 0.868906C21.4649 0.313592 22.2207 0.00135003 23.0088 0H124.188C124.981 0 125.743 0.315232 126.304 0.876348C126.865 1.43746 127.18 2.1985 127.18 2.99204V145.195C127.18 145.989 126.865 146.75 126.304 147.311C125.743 147.872 124.981 148.187 124.188 148.187ZM23.0088 0.1048C22.2509 0.106188 21.5245 0.40823 20.9891 0.944629C20.4537 1.48103 20.153 2.20795 20.153 2.96584V145.195C20.153 145.953 20.4537 146.68 20.9891 147.216C21.5245 147.753 22.2509 148.055 23.0088 148.056H124.188C124.946 148.055 125.673 147.753 126.209 147.217C126.746 146.68 127.048 145.954 127.049 145.195V2.96584C127.048 2.20747 126.746 1.48056 126.209 0.944309C125.673 0.40806 124.946 0.106186 124.188 0.1048H23.0088Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M237.534 148.187H136.35C135.557 148.186 134.797 147.87 134.236 147.309C133.675 146.749 133.359 145.988 133.358 145.195V2.96584C133.366 2.17728 133.685 1.42375 134.245 0.868579C134.805 0.313409 135.561 0.0013353 136.35 0H237.534C238.322 0.00272716 239.076 0.315579 239.634 0.870745C240.192 1.42591 240.509 2.17866 240.516 2.96584V145.195C240.516 145.987 240.202 146.746 239.643 147.307C239.084 147.868 238.326 148.184 237.534 148.187ZM136.35 0.1048C135.592 0.106186 134.865 0.40806 134.328 0.944309C133.792 1.48056 133.49 2.20747 133.489 2.96584V145.195C133.49 145.954 133.792 146.68 134.328 147.217C134.865 147.753 135.592 148.055 136.35 148.056H237.534C238.293 148.055 239.02 147.753 239.556 147.217C240.092 146.68 240.394 145.954 240.395 145.195V2.96584C240.394 2.20747 240.092 1.48056 239.556 0.944309C239.02 0.40806 238.293 0.106186 237.534 0.1048H136.35Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M220.017 18.874H159.181V91.5109H220.017V18.874Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M221.537 18.874H160.701V91.5109H221.537V18.874Z'
        fill='#F9F8FA'
      />
      <path
        d='M218.812 88.4452V21.9338L163.43 21.9338V88.4452H218.812Z'
        fill='#F9F8FA'
      />
      <path
        d='M218.806 88.4452V21.9338H217.659V88.4452H218.806Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M220.405 72.6891H161.827V76.8025H220.405V72.6891Z'
        fill='#F9F8FA'
      />
      <path
        d='M224.507 13.807H156.209C156.098 13.807 155.991 13.8512 155.913 13.9298C155.834 14.0084 155.79 14.115 155.79 14.2262C155.79 14.3374 155.834 14.444 155.913 14.5226C155.991 14.6012 156.098 14.6454 156.209 14.6454H224.507C224.619 14.6454 224.725 14.6012 224.804 14.5226C224.882 14.444 224.927 14.3374 224.927 14.2262C224.927 14.115 224.882 14.0084 224.804 13.9298C224.725 13.8512 224.619 13.807 224.507 13.807Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M224.927 16.3229C226.085 16.3229 227.023 15.3844 227.023 14.2269C227.023 13.0693 226.085 12.1309 224.927 12.1309C223.769 12.1309 222.831 13.0693 222.831 14.2269C222.831 15.3844 223.769 16.3229 224.927 16.3229Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M159.747 16.5743C159.596 16.5743 159.453 16.5147 159.346 16.4086C159.24 16.3024 159.181 16.1585 159.181 16.0084V14.7927C159.182 14.6431 159.242 14.4999 159.348 14.3941C159.454 14.2882 159.597 14.2282 159.747 14.2268C159.897 14.2268 160.041 14.2864 160.147 14.3926C160.253 14.4987 160.313 14.6426 160.313 14.7927V16.0084C160.313 16.1585 160.253 16.3024 160.147 16.4086C160.041 16.5147 159.897 16.5743 159.747 16.5743Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M218.177 16.5743C218.027 16.5743 217.883 16.5147 217.777 16.4086C217.671 16.3024 217.611 16.1585 217.611 16.0084V14.7927C217.613 14.6431 217.673 14.4999 217.779 14.3941C217.884 14.2882 218.028 14.2282 218.177 14.2268C218.327 14.2268 218.471 14.2864 218.577 14.3926C218.684 14.4987 218.743 14.6426 218.743 14.7927V16.0084C218.743 16.1585 218.684 16.3024 218.577 16.4086C218.471 16.5147 218.327 16.5743 218.177 16.5743Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        opacity='0.5'
        d='M224.371 14.2268H161.827V44.7341H224.371V14.2268Z'
        fill='#D3DCF4'
      />
      <path
        d='M166.496 16.3229C167.653 16.3229 168.592 15.3844 168.592 14.2269C168.592 13.0693 167.653 12.1309 166.496 12.1309C165.338 12.1309 164.4 13.0693 164.4 14.2269C164.4 15.3844 165.338 16.3229 166.496 16.3229Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M47.0078 99.3133H24.8164L26.7814 73.3386H45.0428L47.0078 99.3133Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M42.1401 99.3133H38.3254L37.5132 73.3386H41.0345L42.1401 99.3133Z'
        fill='#F9F8FA'
      />
      <path
        d='M37.026 99.3141H34.8042V171.558H37.026V99.3141Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M36.7536 109.955H35.0768C34.2607 109.955 33.5991 110.616 33.5991 111.432V116.887C33.5991 117.703 34.2607 118.365 35.0768 118.365H36.7536C37.5697 118.365 38.2313 117.703 38.2313 116.887V111.432C38.2313 110.616 37.5697 109.955 36.7536 109.955Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M36.7536 123.433H35.0768C34.2607 123.433 33.5991 124.094 33.5991 124.91V130.365C33.5991 131.181 34.2607 131.843 35.0768 131.843H36.7536C37.5697 131.843 38.2313 131.181 38.2313 130.365V124.91C38.2313 124.094 37.5697 123.433 36.7536 123.433Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M39.4258 171.557H32.3989C32.3989 171.16 32.5568 170.779 32.8379 170.498C33.1189 170.217 33.5001 170.059 33.8976 170.059H37.9271C38.3246 170.059 38.7058 170.217 38.9868 170.498C39.2679 170.779 39.4258 171.16 39.4258 171.557Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M37.026 99.3141H34.8042V100.671H37.026V99.3141Z'
        fill='#D3DCF4'
      />
      <path
        d='M50.4664 77.7614L111.795 77.7614L111.795 16.2647L50.4664 16.2647L50.4664 77.7614Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M50.4667 16.2643H49.4082V77.7609H50.4667V16.2643Z'
        fill='#D3DCF4'
      />
      <path
        d='M55.8844 73.8733H106.382V20.1476L55.8844 20.1476L55.8844 73.8733Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M78.2277 61.968H84.0441C90.2025 61.968 95.1948 56.9757 95.1948 50.8173V43.2036C95.1948 37.0452 90.2025 32.0529 84.0441 32.0529H78.2277C72.0693 32.0529 67.077 37.0452 67.077 43.2036V50.8173C67.077 56.9757 72.0693 61.968 78.2277 61.968Z'
        fill='#F9F8FA'
      />
      <path
        d='M213.54 100.839H190.196V171.553H213.54V100.839Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M202.143 171.557H213.54V161.795H190.196L202.143 171.557Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M120.441 100.839H97.0972V171.553H120.441V100.839Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M213.535 100.839H110.496V168.194H213.535V100.839Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M121.893 171.557H110.501V161.795H133.845L121.893 171.557Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M204.7 125.938H119.341V142.795H204.7V125.938Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M204.7 146.4H119.341V163.257H204.7V146.4Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M184.37 127.741H139.667C138.803 127.741 137.974 127.397 137.363 126.786C136.752 126.175 136.408 125.346 136.408 124.481H187.629C187.63 124.91 187.546 125.334 187.383 125.729C187.219 126.125 186.979 126.485 186.676 126.788C186.373 127.09 186.014 127.33 185.618 127.494C185.222 127.657 184.798 127.741 184.37 127.741Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M204.7 105.481H119.341V122.338H204.7V105.481Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M184.37 107.283H139.667C138.803 107.282 137.974 106.937 137.362 106.325C136.751 105.713 136.408 104.884 136.408 104.019H187.629C187.63 104.447 187.546 104.872 187.383 105.268C187.219 105.664 186.979 106.024 186.676 106.327C186.374 106.63 186.014 106.871 185.618 107.035C185.223 107.199 184.798 107.283 184.37 107.283Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        d='M184.37 148.203H139.667C138.803 148.202 137.974 147.857 137.362 147.245C136.751 146.633 136.408 145.803 136.408 144.939H187.629C187.63 145.367 187.546 145.791 187.383 146.187C187.219 146.584 186.979 146.943 186.676 147.247C186.374 147.55 186.014 147.79 185.618 147.955C185.223 148.119 184.798 148.203 184.37 148.203Z'
        fill='#E7EDFC'
        fillOpacity='0.75'
      />
      <path
        opacity='0.4'
        d='M131 195.222C187.111 195.222 232.598 192.566 232.598 189.29C232.598 186.014 187.111 183.358 131 183.358C74.8885 183.358 29.4014 186.014 29.4014 189.29C29.4014 192.566 74.8885 195.222 131 195.222Z'
        fill='#D3DCF4'
      />
      <path
        d='M39.5888 85.1457H117.791C115.469 85.1171 113.244 84.2162 111.557 82.622C109.87 81.0279 108.844 78.8574 108.683 76.5417L107.3 53.2341H29.1035L30.4816 76.5417C30.642 78.8574 31.6678 81.0279 33.3551 82.622C35.0424 84.2162 37.2677 85.1171 39.5888 85.1457Z'
        fill='#4B587A'
      />
      <path
        opacity='0.8'
        d='M39.5888 85.1457H117.791C115.469 85.1171 113.244 84.2162 111.557 82.622C109.87 81.0279 108.844 78.8574 108.683 76.5417L107.3 53.2341H29.1035L30.4816 76.5417C30.642 78.8574 31.6678 81.0279 33.3551 82.622C35.0424 84.2162 37.2677 85.1171 39.5888 85.1457Z'
        fill='#D3DCF4'
      />
      <path
        d='M123.586 37.7287H53.9723C52.8625 37.7157 51.7621 37.9331 50.7408 38.3674C49.7194 38.8017 48.7994 39.4433 48.0388 40.2516C47.2783 41.0599 46.6939 42.0173 46.3226 43.0632C45.9513 44.1091 45.8012 45.2207 45.8818 46.3276L47.6843 76.5467C47.7665 77.6539 47.6175 78.7662 47.2468 79.8128C46.8761 80.8594 46.2917 81.8175 45.5309 82.6261C44.77 83.4348 43.8493 84.0763 42.8272 84.5101C41.8051 84.9438 40.704 85.1602 39.5938 85.1455H117.79C118.9 85.1602 120.002 84.9438 121.024 84.5101C122.046 84.0763 122.967 83.4348 123.727 82.6261C124.488 81.8175 125.073 80.8594 125.443 79.8128C125.814 78.7662 125.963 77.6539 125.881 76.5467L126.075 79.8164L124.094 46.3485C124.009 45.2396 124.155 44.125 124.525 43.0759C124.894 42.0268 125.478 41.0662 126.239 40.2552C127 39.4442 127.922 38.8008 128.946 38.3658C129.969 37.9308 131.072 37.7138 132.185 37.7287H123.586Z'
        fill='#4B587A'
      />
      <path
        d='M132.184 37.728H53.9722C56.2931 37.7542 58.5191 38.6535 60.2068 40.247C61.8945 41.8405 62.92 44.0112 63.0793 46.3269L69.8127 159.872H148.009L141.292 46.3478C141.135 44.0293 140.111 41.8549 138.423 40.2575C136.735 38.6601 134.508 37.757 132.184 37.728Z'
        fill='#4B587A'
      />
      <path
        opacity='0.9'
        d='M132.184 37.728H53.9722C56.2931 37.7542 58.5191 38.6535 60.2068 40.247C61.8945 41.8405 62.92 44.0112 63.0793 46.3269L69.8127 159.872H148.009L141.292 46.3478C141.135 44.0293 140.111 41.8549 138.423 40.2575C136.735 38.6601 134.508 37.757 132.184 37.728Z'
        fill='#F7F9FF'
      />
      <path
        d='M89.426 70.0496H74.9322L74.0571 55.5453H88.5562L89.426 70.0496ZM76.5042 68.3414H87.5973L86.9842 57.2483H75.8702L76.5042 68.3414Z'
        fill='#F9F8FA'
      />
      <path
        d='M91.4485 104.167H76.9389L76.0796 89.657H90.5892L91.4485 104.167ZM78.5424 102.458H89.6355L88.9805 91.3652H77.8874L78.5424 102.458Z'
        fill='#F9F8FA'
      />
      <path
        d='M93.466 138.284H78.9565L78.0762 123.774H92.5857L93.466 138.284ZM80.5652 136.575H91.6583L90.998 125.482H79.9049L80.5652 136.575Z'
        fill='#F9F8FA'
      />
      <g opacity='0.7'>
        <path
          d='M91.3807 104.225H76.8711L75.9908 89.7156H90.5004L91.3807 104.225Z'
          fill='#F9F8FA'
        />
        <path
          d='M89.3806 69.7249H74.871L73.9907 55.2153H88.5003L89.3806 69.7249Z'
          fill='#F9F8FA'
        />
        <path
          d='M93.51 138.215H79.0005L78.1202 123.706H92.6297L93.51 138.215Z'
          fill='#F9F8FA'
        />
      </g>
      <path d='M129.868 55.54H96.5103V57.2483H129.868V55.54Z' fill='#4B587A' />
      <path
        d='M126.326 61.9427H96.8877V63.651H126.326V61.9427Z'
        fill='#4B587A'
      />
      <path
        d='M130.623 68.3403H97.2656V70.0486H130.623V68.3403Z'
        fill='#4B587A'
      />
      <path
        d='M131.887 89.657H98.5288V91.3652H131.887V89.657Z'
        fill='#4B587A'
      />
      <path
        d='M118.487 96.0598H98.9053V97.7681H118.487V96.0598Z'
        fill='#4B587A'
      />
      <path
        d='M109.397 102.457H99.2886V104.166H109.397V102.457Z'
        fill='#4B587A'
      />
      <path
        d='M132.646 102.457H115.967V104.166H132.646V102.457Z'
        fill='#4B587A'
      />
      <path
        d='M133.903 123.774H100.545V125.482H133.903V123.774Z'
        fill='#4B587A'
      />
      <path
        d='M134.285 130.178H100.927V131.886H134.285V130.178Z'
        fill='#4B587A'
      />
      <path
        d='M119.756 136.576H101.306V138.285H119.756V136.576Z'
        fill='#4B587A'
      />
      <path
        d='M83.1221 66.6327C82.7596 66.6331 82.4107 66.4943 82.1475 66.2449L78.6 62.8808C78.4644 62.7525 78.3555 62.5987 78.2793 62.4283C78.2032 62.2578 78.1614 62.0741 78.1563 61.8875C78.146 61.5106 78.2858 61.1451 78.545 60.8713C78.8042 60.5975 79.1615 60.4379 79.5384 60.4276C79.9152 60.4173 80.2807 60.5571 80.5545 60.8163L83.0802 63.2005L88.7656 57.1588C89.0241 56.8843 89.381 56.7237 89.7579 56.7124C90.1347 56.7011 90.5007 56.84 90.7751 57.0985C91.0496 57.357 91.2102 57.7139 91.2215 58.0908C91.2328 58.4676 91.0939 58.8336 90.8354 59.108L84.1754 66.1873C84.0477 66.3244 83.8938 66.4345 83.7227 66.511C83.5517 66.5875 83.3671 66.6289 83.1798 66.6327H83.1221Z'
        fill='#CD3724'
      />
      <path
        d='M85.1457 100.751C84.783 100.752 84.4337 100.613 84.1711 100.363L80.6079 96.9989C80.4675 96.8721 80.3539 96.7186 80.2737 96.5473C80.1935 96.376 80.1483 96.1905 80.1408 96.0015C80.1333 95.8125 80.1635 95.6239 80.2299 95.4468C80.2963 95.2697 80.3973 95.1077 80.5272 94.9701C80.657 94.8326 80.813 94.7224 80.986 94.646C81.159 94.5696 81.3455 94.5285 81.5346 94.5252C81.7237 94.5219 81.9116 94.5563 82.0872 94.6266C82.2628 94.6968 82.4226 94.8015 82.5572 94.9343L85.0881 97.3185L90.7683 91.2768C91.0268 91.0023 91.3837 90.8418 91.7606 90.8305C92.1374 90.8192 92.5033 90.958 92.7778 91.2165C93.0523 91.475 93.2128 91.832 93.2241 92.2088C93.2354 92.5857 93.0966 92.9516 92.8381 93.2261L86.178 100.305C86.0512 100.442 85.8981 100.552 85.728 100.628C85.5579 100.705 85.3742 100.747 85.1877 100.751H85.1457Z'
        fill='#CD3724'
      />
      <path
        d='M87.1628 134.872C86.7994 134.872 86.4503 134.731 86.1882 134.479L82.625 131.115C82.4846 130.988 82.371 130.835 82.2908 130.664C82.2106 130.492 82.1654 130.307 82.1579 130.118C82.1503 129.929 82.1806 129.74 82.247 129.563C82.3133 129.386 82.4144 129.224 82.5442 129.086C82.6741 128.949 82.8301 128.839 83.0031 128.762C83.1761 128.686 83.3626 128.645 83.5517 128.641C83.7408 128.638 83.9287 128.673 84.1043 128.743C84.2799 128.813 84.4397 128.918 84.5743 129.051L87.1052 131.435L92.7906 125.393C93.0568 125.164 93.3983 125.042 93.7492 125.051C94.1 125.059 94.4354 125.197 94.6906 125.438C94.9459 125.678 95.1028 126.005 95.1313 126.355C95.1597 126.705 95.0576 127.053 94.8447 127.332L88.1846 134.411C88.0564 134.548 87.9024 134.657 87.7315 134.734C87.5606 134.81 87.3762 134.852 87.189 134.856L87.1628 134.872Z'
        fill='#CD3724'
      />
      <path
        d='M162.312 54.5609L152.135 57.0625L182.506 180.617L192.683 178.115L162.312 54.5609Z'
        fill='#CD3724'
      />
      <path
        opacity='0.2'
        d='M162.306 54.5588L159.208 55.3206L189.578 178.875L192.677 178.113L162.306 54.5588Z'
        fill='#303950'
      />
      <path
        d='M191.914 179.953L184.027 181.892C183.394 182.048 183.008 182.686 183.163 183.319L184.484 188.692C184.64 189.324 185.278 189.711 185.91 189.555L193.798 187.617C194.43 187.461 194.817 186.823 194.661 186.19L193.34 180.817C193.185 180.185 192.546 179.798 191.914 179.953Z'
        fill='#4B587A'
      />
      <path
        opacity='0.7'
        d='M191.914 179.953L184.027 181.892C183.394 182.048 183.008 182.686 183.163 183.319L184.484 188.692C184.64 189.324 185.278 189.711 185.91 189.555L193.798 187.617C194.43 187.461 194.817 186.823 194.661 186.19L193.34 180.817C193.185 180.185 192.546 179.798 191.914 179.953Z'
        fill='#F9F8FA'
      />
      <path
        d='M191.975 176.337L182.302 178.715C181.718 178.858 181.36 179.449 181.504 180.033L182.369 183.555C182.513 184.139 183.103 184.496 183.688 184.353L193.361 181.975C193.946 181.831 194.303 181.241 194.159 180.656L193.294 177.135C193.15 176.551 192.56 176.193 191.975 176.337Z'
        fill='#D3DCF4'
      />
      <path
        d='M162.314 54.5484L152.138 57.0531L153.332 48.5381L154.097 43.078L157.31 47.5582L162.314 54.5484Z'
        fill='#FFBE9D'
      />
      <path
        d='M157.31 47.5582L153.333 48.5381L154.098 43.078L157.31 47.5582Z'
        fill='#4B587A'
      />
      <path
        d='M188.598 46.4679C188.546 49.6119 187.938 55.5383 189.593 57.4719C185.787 58.7236 182.375 60.9491 179.695 63.9276C177.426 60.2177 180.387 58.2212 180.387 58.2212C183.651 57.1732 183.452 54.7157 182.766 52.4572L188.598 46.4679Z'
        fill='#FFBE9D'
      />
      <path
        d='M178.731 60.727C179.596 57.5149 177.159 57.059 181.22 55.487C184.395 54.2294 190.395 54.0512 191.805 55.0625C192.586 55.6285 191.511 57.6092 191.511 57.6092L178.731 60.727Z'
        fill='#263238'
      />
      <path
        d='M196.331 60.0342C196.567 60.4114 196.735 60.6996 196.924 61.0298C197.112 61.3599 197.269 61.6638 197.448 61.9834C197.783 62.6227 198.097 63.262 198.401 63.9118C199.009 65.206 199.538 66.5318 200.026 67.8732C201.004 70.5903 201.707 73.3992 202.122 76.2572L202.258 77.3366C202.284 77.4886 202.305 77.7611 202.321 77.9969C202.337 78.2327 202.321 78.4475 202.321 78.6571C202.309 79.4301 202.251 80.2018 202.148 80.968C201.964 82.4142 201.687 83.7818 201.383 85.16C200.764 87.8478 199.982 90.4955 199.041 93.0881C198.915 93.4396 198.667 93.7335 198.341 93.915C198.015 94.0964 197.634 94.153 197.269 94.074C196.904 93.995 196.581 93.786 196.359 93.4859C196.138 93.1859 196.033 92.8153 196.064 92.4436C196.274 89.7816 196.52 87.0988 196.714 84.4788C196.808 83.1688 196.882 81.8588 196.903 80.6221C196.903 80.009 196.903 79.4012 196.845 78.8929L196.798 78.5418L196.73 78.2012L196.536 77.2895C195.976 74.8338 195.223 72.4264 194.283 70.0897C193.811 68.916 193.303 67.7527 192.768 66.6051C192.503 66.0287 192.225 65.4628 191.935 64.9074L191.495 64.0794L191.081 63.3196L191.008 63.1834C190.626 62.4798 190.533 61.655 190.75 60.8844C190.966 60.1137 191.475 59.4578 192.167 59.056C192.86 58.6542 193.681 58.5382 194.458 58.7326C195.235 58.927 195.905 59.4164 196.326 60.097L196.331 60.0342Z'
        fill='#FFBE9D'
      />
      <path
        d='M194.927 94.1468L195.362 93.4237L196.588 91.3591L198.836 92.6324C199.303 94.7284 197.877 96.5834 197.877 96.5834L197.243 97.1074C197.027 97.2954 196.748 97.3941 196.462 97.3833C196.175 97.3726 195.904 97.2534 195.703 97.0498L195.215 96.5991C194.909 96.2764 194.715 95.8631 194.663 95.421C194.611 94.9788 194.704 94.5318 194.927 94.1468Z'
        fill='#FFBE9D'
      />
      <path
        d='M165.059 93.0773L164.698 91.7673C164.613 91.4591 164.411 91.1965 164.135 91.0359C163.858 90.8752 163.53 90.8293 163.22 90.9079L161.648 91.3114L160.679 91.5525C160.169 91.6855 159.716 91.9809 159.389 92.394C159.062 92.8071 158.878 93.3155 158.866 93.8423V94.6231C158.863 94.9632 158.99 95.2914 159.221 95.5414C159.452 95.7914 159.768 95.9445 160.108 95.9698L161.077 96.0641C162.531 95.8324 163.865 95.1159 164.86 94.031C164.968 93.9 165.043 93.7448 165.077 93.5787C165.112 93.4126 165.106 93.2405 165.059 93.0773Z'
        fill='#FFBE9D'
      />
      <path
        d='M193.711 57.7285C196.462 58.9494 198.983 61.8576 201.047 67.2391C200.387 69.4556 193.57 73.0869 193.57 73.0869C193.57 73.0869 185.065 55.0561 193.711 57.7285Z'
        fill='#4B587A'
      />
      <path
        d='M170.954 64.619C170.98 66.6154 167.674 71.2423 171.478 73.9252C171.808 77.9653 172.254 81.9424 173.118 88.571H193.465C191.531 81.235 193.203 76.3094 196.305 63.419C196.482 62.7263 196.508 62.0036 196.379 61.3002C196.251 60.5968 195.973 59.9294 195.563 59.3437C195.153 58.758 194.621 58.2678 194.004 57.9067C193.387 57.5456 192.699 57.3221 191.987 57.2515C191.175 57.1729 190.316 57.1048 189.441 57.0577C186.364 56.8372 183.275 56.8372 180.197 57.0577C179.197 57.1467 178.164 57.2725 177.174 57.4087C175.443 57.661 173.861 58.5289 172.719 59.8534C171.576 61.1778 170.95 62.8698 170.954 64.619Z'
        fill='#4B587A'
      />
      <path
        d='M177.693 62.6754C177.74 64.1741 177.693 65.6098 177.693 67.077C177.693 68.5442 177.598 69.9957 177.504 71.4629C177.342 74.4374 176.992 77.3986 176.456 80.329C176.393 80.701 176.294 81.0888 176.205 81.4661C176.115 81.8433 176.037 82.2206 175.916 82.6189L175.749 83.2005L175.66 83.4939C175.626 83.6257 175.584 83.7552 175.534 83.8817C175.315 84.4461 175.014 84.9756 174.643 85.4537C174.132 86.1169 173.573 86.7421 172.971 87.3244C172.421 87.8746 171.861 88.3724 171.295 88.8964C169.066 90.8161 166.698 92.5676 164.21 94.1364C163.898 94.3326 163.525 94.4103 163.16 94.3551C162.796 94.3 162.463 94.1158 162.222 93.836C161.982 93.5562 161.85 93.1993 161.85 92.8304C161.85 92.4615 161.983 92.1049 162.224 91.8255C164.095 89.6929 166.023 87.5759 167.821 85.4799C168.261 84.9559 168.69 84.4319 169.099 83.9079C169.483 83.4425 169.833 82.9504 170.147 82.4355C170.273 82.2416 170.351 82.0058 170.283 82.1368C170.215 82.2678 170.283 82.0687 170.283 82.011L170.33 81.7805L170.43 81.3246C170.503 81.0311 170.545 80.701 170.598 80.3814C170.65 80.0617 170.713 79.7421 170.744 79.4067C171.101 76.7605 171.268 73.9729 171.368 71.1852C171.468 68.3975 171.53 65.5574 171.546 62.8012C171.542 62.3972 171.617 61.9964 171.767 61.6215C171.918 61.2466 172.141 60.905 172.423 60.6162C172.993 60.033 173.772 59.7003 174.588 59.6912C175.404 59.6822 176.19 59.9976 176.773 60.5681C177.356 61.1385 177.689 61.9173 177.698 62.7331L177.693 62.6754Z'
        fill='#FFBE9D'
      />
      <path
        d='M175.45 57.8758C172.94 59.1229 170.451 61.5438 169.419 67.0877C170.074 69.2833 179.045 73.512 179.045 73.512C179.045 73.512 184.039 54.6689 175.45 57.8758Z'
        fill='#4B587A'
      />
      <path
        d='M176.001 44.466C176.069 44.859 175.912 45.2258 175.65 45.273C175.388 45.3201 175.126 45.0477 175.058 44.6494C174.989 44.2512 175.152 43.8896 175.409 43.8425C175.665 43.7953 175.938 44.0678 176.001 44.466Z'
        fill='#263238'
      />
      <path
        d='M175.539 43.8375L174.539 43.7537C174.539 43.7537 175.141 44.3982 175.539 43.8375Z'
        fill='#263238'
      />
      <path
        d='M175.906 44.9066C175.7 45.8836 175.364 46.8283 174.905 47.7153C175.135 47.8225 175.385 47.878 175.639 47.878C175.892 47.878 176.143 47.8225 176.372 47.7153L175.906 44.9066Z'
        fill='#ED893E'
      />
      <path
        d='M174.963 41.762C174.903 41.7615 174.845 41.7392 174.8 41.6991C174.778 41.6772 174.761 41.6511 174.749 41.6223C174.737 41.5935 174.73 41.5627 174.73 41.5315C174.73 41.5003 174.737 41.4694 174.749 41.4406C174.761 41.4118 174.778 41.3857 174.8 41.3638C175.056 41.0819 175.376 40.8646 175.732 40.7298C176.088 40.595 176.471 40.5465 176.849 40.5883C176.879 40.5939 176.908 40.6055 176.934 40.6223C176.959 40.6391 176.981 40.6608 176.998 40.6861C177.015 40.7115 177.027 40.74 177.033 40.77C177.039 40.7999 177.039 40.8308 177.033 40.8607C177.02 40.9235 176.983 40.9788 176.931 41.015C176.878 41.0512 176.813 41.0654 176.75 41.0546C176.45 41.0274 176.148 41.0705 175.868 41.1806C175.588 41.2907 175.337 41.4646 175.136 41.6887C175.09 41.735 175.028 41.7614 174.963 41.762Z'
        fill='#263238'
      />
      <path
        d='M188.969 40.563C189.493 45.4886 189.808 47.5636 187.801 50.519C184.777 54.9992 178.699 54.711 176.744 50.0841C174.984 45.8921 174.433 38.5561 178.814 35.695C179.756 35.0524 180.851 34.6686 181.988 34.5823C183.126 34.4959 184.266 34.71 185.294 35.203C186.323 35.6961 187.204 36.4507 187.849 37.3915C188.494 38.3322 188.88 39.4259 188.969 40.563Z'
        fill='#FFBE9D'
      />
      <path
        d='M180.009 44.1623C176.865 43.6068 176.105 39.9965 177.269 38.4245C171.971 38.6603 168.403 32.7338 174.125 29.354C179.847 25.9742 184.898 31.209 184.489 33.1845C191.537 32.5452 195.666 38.3459 191.825 42.6584C196.892 43.4811 204.48 49.1927 198.91 55.1925C206.44 56.2981 207.257 79.8781 186.758 75.6861C165.148 71.2636 183.923 52.4572 183.923 52.4572C183.923 52.4572 178.448 50.9166 180.009 44.1623Z'
        fill='#263238'
      />
      <path
        d='M200.084 56.7286L199.638 56.4561C201.54 53.3645 201.855 50.5506 200.571 48.0721C198.92 44.9281 194.917 43.0731 191.81 42.895L191.841 42.371C195.09 42.5544 199.298 44.467 201.037 47.8258C202.389 50.4458 202.059 53.5164 200.084 56.7286Z'
        fill='#263238'
      />
      <path
        d='M194.126 43.1872C195.934 42.8885 195.08 39.9384 193.749 39.1576C192.418 38.3769 186.041 39.6449 186.743 41.6781C187.445 43.7112 191.7 43.5907 194.126 43.1872Z'
        fill='#F9F8FA'
      />
      <path
        d='M194.451 42.4446C196.253 42.0987 195.53 39.7093 194.178 38.96C192.827 38.2107 187.256 38.7661 188.016 40.773C188.776 42.7799 192.041 42.9214 194.451 42.4446Z'
        fill='#4B587A'
      />
      <path
        d='M175.565 39.0225C174.358 39.0063 173.191 38.5877 172.249 37.833C170.745 36.6645 169.917 34.9144 170.153 33.3738C170.294 32.3939 170.907 31.0472 173.045 30.1931L173.239 30.6804C171.73 31.283 170.844 32.2524 170.671 33.4472C170.483 34.8096 171.232 36.3658 172.589 37.4191C173.219 37.944 173.977 38.2917 174.786 38.4261C175.594 38.5606 176.424 38.4771 177.19 38.1841L177.389 38.6714C176.81 38.9082 176.191 39.0275 175.565 39.0225Z'
        fill='#263238'
      />
      <path
        d='M181.251 44.7389C181.015 46.0279 180.942 46.5781 180.271 47.1807C180.08 47.3939 179.83 47.5446 179.552 47.613C179.274 47.6814 178.982 47.6644 178.714 47.5642C178.447 47.464 178.215 47.2852 178.051 47.0513C177.886 46.8173 177.796 46.5392 177.792 46.2532C177.687 45.069 178.091 43.1616 179.291 42.7634C180.491 42.3651 181.486 43.4551 181.251 44.7389Z'
        fill='#FFBE9D'
      />
      <path
        d='M178.359 48.8419L177.935 48.0821C177.917 48.051 177.907 48.0158 177.907 47.9799C177.907 47.944 177.917 47.9088 177.935 47.8777L178.359 47.1231C178.377 47.0904 178.404 47.0631 178.436 47.0441C178.468 47.025 178.505 47.015 178.542 47.015C178.58 47.015 178.617 47.025 178.649 47.0441C178.681 47.0631 178.708 47.0904 178.726 47.1231L179.15 47.8777C179.168 47.9088 179.178 47.944 179.178 47.9799C179.178 48.0158 179.168 48.051 179.15 48.0821L178.726 48.8419C178.708 48.8746 178.681 48.9019 178.649 48.9209C178.617 48.94 178.58 48.95 178.542 48.95C178.505 48.95 178.468 48.94 178.436 48.9209C178.404 48.9019 178.377 48.8746 178.359 48.8419Z'
        fill='#F9F8FA'
      />
      <path
        d='M173.129 88.5562C173.129 88.5562 172.411 114.83 171.452 132.111C170.436 147.973 169.131 185.066 169.131 185.066L173.139 185.276C173.139 185.276 182.006 154.061 181.634 135.203C192.04 108.824 188.299 89.3474 188.299 89.3474L173.129 88.5562Z'
        fill='#FFBE9D'
      />
      <path
        d='M169.43 183.4C169.341 183.336 169.234 183.301 169.124 183.301C169.014 183.301 168.906 183.336 168.817 183.4C166.893 184.828 164.563 185.603 162.168 185.612C159.506 185.727 160.161 187.184 163.97 187.671C168.45 188.226 169.409 189.149 170.855 189.51C172.302 189.872 173.449 188.771 173.423 185.732C173.421 185.628 173.388 185.528 173.328 185.443C173.268 185.358 173.185 185.293 173.088 185.255C171.8 184.783 170.572 184.16 169.43 183.4Z'
        fill='#263238'
      />
      <path
        d='M178.448 88.5562C178.448 88.5562 185.784 119.65 190.033 134.144C194.503 149.34 198.045 184.018 198.019 185.166H201.488C201.488 185.166 205.968 157.059 200.44 134.128C199.916 96.9244 193.502 88.5824 193.502 88.5824L178.448 88.5562Z'
        fill='#FFBE9D'
      />
      <path
        d='M197.93 183.923C197.839 183.886 197.739 183.879 197.645 183.904C197.55 183.928 197.466 183.983 197.406 184.059C196.51 185.223 195.86 186.512 193.214 187.644C191.023 188.576 192.234 189.855 195.661 189.389C199.69 188.833 199.748 189.488 201.105 189.488C202.462 189.488 203.102 188.136 202.111 185.197C202.08 185.099 202.022 185.013 201.944 184.946C201.867 184.879 201.772 184.836 201.671 184.819C200.392 184.673 199.136 184.372 197.93 183.923Z'
        fill='#263238'
      />
      <path
        d='M173.129 88.556C173.129 88.556 169.671 111.581 170.158 143.052C181.099 142.99 191.97 141.312 202.421 138.074C202.421 138.074 202.578 97.2963 193.476 88.5717C188.221 87.1569 173.25 88.0948 173.129 88.556Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M173.533 138.583C173.499 138.583 173.466 138.57 173.442 138.546C173.417 138.523 173.403 138.491 173.402 138.457C172.422 118.362 174.801 89.9924 174.822 89.7252C174.822 89.7077 174.827 89.6905 174.834 89.6748C174.842 89.659 174.853 89.645 174.866 89.6337C174.879 89.6223 174.895 89.6139 174.911 89.6089C174.928 89.6039 174.946 89.6024 174.963 89.6046C174.98 89.606 174.997 89.6107 175.013 89.6185C175.028 89.6263 175.042 89.6371 175.053 89.6503C175.064 89.6634 175.073 89.6787 175.078 89.6951C175.083 89.7116 175.085 89.7289 175.084 89.7461C175.057 90.0343 172.684 118.383 173.664 138.478C173.665 138.495 173.663 138.513 173.657 138.529C173.652 138.546 173.643 138.561 173.631 138.574C173.619 138.587 173.605 138.597 173.589 138.604C173.573 138.611 173.555 138.614 173.538 138.614L173.533 138.583Z'
        fill='#F9F8FA'
      />
      <path
        opacity='0.2'
        d='M187.225 118.488L190.206 102.694L191.406 106.472L187.225 118.488Z'
        fill='#303950'
      />
    </svg>
  );
};

export default IconRegistrationsNotice;
