import { takeLatest } from 'redux-saga/effects';
import { onList, onListSuccess, onListFailed } from './List';
import { ApplicationTypes } from '~/domain/interfaces/redux/application/types';

const tasks = [
  takeLatest(ApplicationTypes.LIST, onList),
  takeLatest(ApplicationTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(ApplicationTypes.LIST_FAILED, onListFailed),
];

export default tasks;
