import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/notification/actions/list';

import { iActionList } from '~/domain/interfaces/redux/notification/list';

import { ListNotification } from '~/domain/usecases/notification/redux';

export class ReduxListNotification
  implements ListNotification
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (
    params: ListNotification.Params,
  ): iActionList => this.send(listRequest(params));
}
