import React from 'react';

const IconUserRegistered = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#CEEAF8' />
      <path
        d='M31.0909 19C29.6443 19 28.2569 19.5747 27.234 20.5976C26.211 21.6205 25.6364 23.0079 25.6364 24.4545C25.6364 25.9012 26.211 27.2886 27.234 28.3115C28.2569 29.3344 29.6443 29.9091 31.0909 29.9091C32.5375 29.9091 33.9249 29.3344 34.9479 28.3115C35.9708 27.2886 36.5455 25.9012 36.5455 24.4545C36.5455 23.0079 35.9708 21.6205 34.9479 20.5976C33.9249 19.5747 32.5375 19 31.0909 19ZM31.0909 21.5909C31.467 21.5909 31.8393 21.665 32.1868 21.8089C32.5342 21.9528 32.8499 22.1637 33.1158 22.4296C33.3817 22.6956 33.5927 23.0112 33.7366 23.3587C33.8805 23.7061 33.9545 24.0785 33.9545 24.4545C33.9545 24.8306 33.8805 25.203 33.7366 25.5504C33.5927 25.8978 33.3817 26.2135 33.1158 26.4794C32.8499 26.7454 32.5342 26.9563 32.1868 27.1002C31.8393 27.2441 31.467 27.3182 31.0909 27.3182C30.7149 27.3182 30.3425 27.2441 29.995 27.1002C29.6476 26.9563 29.3319 26.7454 29.066 26.4794C28.8001 26.2135 28.5892 25.8978 28.4453 25.5504C28.3013 25.203 28.2273 24.8306 28.2273 24.4545C28.2273 23.6951 28.529 22.9667 29.066 22.4296C29.603 21.8926 30.3314 21.5909 31.0909 21.5909ZM16.0909 23.0909V27.1818H12V29.9091H16.0909V34H18.8182V29.9091H22.9091V27.1818H18.8182V23.0909H16.0909ZM31.0909 31.2727C27.45 31.2727 20.1818 33.0864 20.1818 36.7273V40.8182H42V36.7273C42 33.0864 34.7318 31.2727 31.0909 31.2727ZM31.0909 33.8636C35.1409 33.8636 39.4091 35.8545 39.4091 36.7273V38.2273H22.7727V36.7273C22.7727 35.8545 27 33.8636 31.0909 33.8636Z'
        fill='#1863A8'
      />
    </svg>
  );
};

export default IconUserRegistered;
