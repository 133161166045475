import { Dispatch } from 'redux';
import store from '~/data/store';
import { refreshRequest } from '~/data/store/reducer/auth/actions/refresh';

import { iActionRefresh } from '~/domain/interfaces/redux/auth/refresh';

import { RefreshAuth } from '~/domain/usecases/auth/redux';

export class ReduxRefreshAuth implements RefreshAuth {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  refresh = (params: RefreshAuth.Params): iActionRefresh =>
    this.send(refreshRequest(params));
}
