import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconEventRegistered } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const EditEventSuccess = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'EDIT_EVENT'} //EDIT_STARTUP
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconEventRegistered />}
      title='Evento editado!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5' >
            <Text>O evento foi editado com sucesso!</Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default EditEventSuccess;
