import React, { useContext, useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';
import { makeRemoteCreateNotice } from '~/main/factories/usecases/notice/CreateNoticeFactory';
import { makeReduxListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';
import { schemaRegisterNotice } from '~/validators/RegisterNotice/RegisterNoticeValidator';
import FormRegisterNotice from '~/presentation/components/Form/RegisterNotice/FormRegisterNotice';
import Stage from '~/presentation/components/Stage';
import { iStep, iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { Conflict } from '~/domain/errors/Conflict';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const RegisterNotice = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [stages, setStages] = useState<iStep[]>([]);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const addStage = (stage: iStep) => {
    setStages((prevState) => [...prevState, stage]);
  };

  const updateStage = (index: number, stage: iStep) => {
    setStages((prevState) =>
      prevState.map((el, i) => (i === index ? stage : el))
    );
  };

  const removeStage = (index: number) => {
    setStages((prevState) => prevState.filter((_, i) => i !== index));
  };

  const onCloseModal = () => {
    onClose();
    setStep(1);
    setStages([]);
    setFiles([]);
    formik.resetForm({ values: initial });
    setIsLoading(false);
  };

  const { initial, validators } = schemaRegisterNotice;
  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      setIsLoading(true);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      await axios
        .post(makeApiUrl('/files/upload'), formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          const f = res.data.map((el: any) => el.id);

          makeRemoteCreateNotice()
            .create({
              body: {
                code: values.code,
                title: values.title,
                description: values.description,
                applicationStart: values.start.toISOString(),
                applicationEnd: values.end.toISOString(),
                start: datefns.format(new Date(values.start), 'yyyy-MM-dd'),
                end: datefns.format(new Date(values.end), 'yyyy-MM-dd'),
                status: 'string',
                value: Number(values.value),
                vacancies: Number(values.vacancies),
                files: f,
                steps: stages,
              },
            })
            .then(() => {
              makeReduxListNotice().list({});
              showConfirmModal('SUBMMIT_NOTICE');
            })
            .catch((error) => {
              if (error instanceof Conflict) {
                showConfirmModal('CONFLICT_ERROR');
              } else {
                showConfirmModal('APPLICATION_ERROR');
              }
            })
            .finally(() => {
              onCloseModal();
            });
        })
        .catch(() => {
          showConfirmModal('APPLICATION_ERROR');
        })
        .finally(() => {
          onCloseModal();
        });
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('stages', stages);
  }, [stages]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size='4xl' isCentered>
      <ModalOverlay />
      <ModalContent bg='#F9F8FA' maxH='618px'>
        <ModalHeader>Cadastrar Novo Edital</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH='416px'>
          {step === 1 && (
            <FormRegisterNotice
              formik={formik}
              setFiles={setFiles}
              externalFiles={files}
            />
          )}
          {step === 2 && (
            <Stage
              stages={stages}
              addStage={addStage}
              removeStage={removeStage}
              updateStage={updateStage}
              isEditable
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justify='space-between' w='100%'>
            <Button
              fontWeight='medium'
              variant='outline'
              color='#303950'
              borderColor='#303950'
              onClick={() => {
                step === 1 ? onCloseModal() : setStep(step - 1);
              }}
            >
              {step === 1 ? 'Cancelar' : 'Voltar'}
            </Button>
            {
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                disabled={!formik.isValid || isLoading || files.length === 0}
                loadingText='Concluindo cadastro'
                fontWeight='500'
                fontSize='14px'
                onClick={() =>
                  step === 1 ? setStep(step + 1) : formik.handleSubmit()
                }
              >
                {step === 1 ? 'Avançar' : 'Concluir cadastro'}
              </Button>
            }
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterNotice;
