import { ListNotification } from '~/domain/usecases/notification/redux/ListNotification';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  NotificationTypes,
} from '~/domain/interfaces/redux/notification/list';

export const listRequest = (
  payload: ListNotification.Params,
): iActionList => ({
  type: NotificationTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListNotification.Model,
): iActionListSuccess => ({
  type: NotificationTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: NotificationTypes.LIST_FAILED,
});
