import React from 'react';

const IconFirstPage = (): JSX.Element => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.2822 0.546193C9.23726 0.439029 9.17331 0.341803 9.09034 0.260133C8.74638 -0.0851266 8.17944 -0.0851266 7.84239 0.251059L3.78051 4.31294C3.69928 4.39504 3.6336 4.49227 3.58866 4.59943C3.54544 4.70616 3.52297 4.8211 3.52297 4.93691C3.52297 5.05315 3.54544 5.16766 3.58866 5.27482C3.61458 5.33662 3.64742 5.39538 3.68718 5.44983C3.71483 5.48915 3.74594 5.52632 3.78051 5.56088L7.84239 9.62276C8.00833 9.78826 8.23302 9.8816 8.46637 9.8816C8.70144 9.8816 8.92614 9.78826 9.09034 9.62276C9.25627 9.4577 9.34961 9.233 9.34961 8.99922C9.34961 8.76502 9.25627 8.54075 9.09034 8.37525L5.65762 4.94166L9.09034 1.50765C9.13528 1.464 9.17331 1.41604 9.20788 1.36419C9.23726 1.31925 9.26146 1.27128 9.2822 1.22159C9.32714 1.11442 9.34961 0.999914 9.34961 0.883675C9.34961 0.767868 9.32714 0.653358 9.2822 0.546193Z'
        fill='#CECECE'
      />
      <path
        d='M2.01921 0.268343C2.17478 0.427794 2.26984 0.645147 2.26984 0.884971V9.11503C2.26984 9.60375 1.87402 10 1.38487 10C1.174 10 0.98214 9.92697 0.830035 9.80468C0.629534 9.64264 0.499899 9.39374 0.499899 9.11503V0.884971C0.499899 0.396249 0.895717 0 1.38487 0C1.63377 0 1.85847 0.102843 2.01921 0.268343Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default IconFirstPage;
