import React from 'react';
import * as yup from 'yup';

export const schemaLogin = {
  initial: {
    login: '',
    password: '',
  },
  validators: yup.object({
    login: yup.string().required('Campo obrigatório'),
    password: yup.string().required('Campo obrigatório'),
  }),
};
