/* eslint-disable no-console */
import React, { forwardRef, useEffect, useState } from 'react';

import {
  Box,
  Stack,
  HStack,
  Text,
  Input as ChakraInput,
  Flex,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  Divider,
} from '@chakra-ui/react';
import * as datefns from 'date-fns';
import DatePicker from 'react-datepicker';
import Select from '~/presentation/components/UI/select';
import Input from '~/presentation/components/UI/Input';
import DateInput from '~/presentation/components/UI/DateInput';
import NoticeConfirmationModal from './InformConfirmationModal';
import UploadDocs from '../../UploadDocs';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import IconCalendarGrey from '~/presentation/base/icons/iconCalendarGrey';
import CustomCurrencyInput from '../../UI/CurrencyInput';
import { iOldFile } from '../../Modals/notice/EditNotice';

interface ownProps {
  formik: any;
  setFiles: (files: File[]) => void;
  oldFiles?: iOldFile[];
  externalFiles?: File[];
}

const FormRegisterInform = ({
  formik,
  setFiles,
  oldFiles,
  externalFiles,
}: ownProps): JSX.Element => {
  const [selectedNotice, setSelectedNotice] = React.useState<string>('All');
  const [startDate, setStartDate] = useState(new Date());

  const [notices, setNotices] = React.useState<string[]>([]);

  const { records } = useSelector((store: iStore) => store.application);

  const adm = true;

  return (
    <Stack
      spacing='4'
      w='100%'
      direction='row'
      gap={'32px'}
      justifyContent='flex-start'
    >
      <Stack spacing='4' w='360px'>
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id='code'
            name='code'
            required
            label='Número'
            formik={formik}
          />

          <Select
            id='type'
            name='type'
            required
            formik={formik}
            label={'Categoria'}
            onChange={(event) => {
              // eslint-disable-next-line no-console
              console.log('... event', event.target.value);
              formik.setFieldValue('type', event.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            placeholder='Selecione'
          >
            <option value='INCOME'>Receita</option>
            <option value='EXPENSE'>Despesa</option>
          </Select>
        </HStack>
        <Text
          fontFamily='Inter'
          fontStyle='normal'
          fontWeight='700'
          fontSize='sm'
          color='#303950'
        >
          Período <span style={{ color: 'red' }}>*</span>
        </Text>
        <HStack spacing='2.5'>
          <InputGroup>
            <DateInput
              formik={formik}
              name='begin'
              required
              onChange={(date) => {
                formik.setFieldValue('begin', date);
                if (date && datefns.compareAsc(date, formik.values.end) === 1) {
                  formik.setFieldValue('end', date);
                }
              }}
              selectsStart
              startDate={startDate}
              dateFormat='dd/MM/yyyy'
            />
            <InputRightElement width={'12.6px'} mr='13px'>
              <IconCalendarGrey />
            </InputRightElement>
          </InputGroup>

          <Text>-</Text>
          <InputGroup>
            <DateInput
              formik={formik}
              name='end'
              required
              onChange={(date) => formik.setFieldValue('end', date)}
              selectsEnd
              endDate={formik.values.end}
              minDate={formik.values.begin}
              dateFormat='dd/MM/yyyy'
            />
            <InputRightElement width={'12.6px'} mr='13px'>
              <IconCalendarGrey />
            </InputRightElement>
          </InputGroup>
        </HStack>
        <HStack spacing='5' alignItems='flex-start'>
          <Select
            id='notice'
            name='notice'
            required
            label='Edital associado'
            formik={formik}
            onChange={(event) => {
              // eslint-disable-next-line no-console
              console.log('... event', event.target.value);
              setSelectedNotice(event.target.value);
              formik.setFieldValue('notice', event.target.value);
            }}
          >
            {formik.values.notice === '' ? (
              <>
                <option value=''>Selecione o Edital</option>
                {records
                  // .filter((item) => {
                  //   const check =
                  //     selectedNotice === 'All'
                  //       ? true
                  //       : item.notice.code === selectedNotice;

                  //   return check;
                  // })
                  .map((item, index) => (
                    <option key={index} value={item?.notice_?.id}>
                      {`Nº ${item?.notice_?.code} - ${item?.notice_?.title}`}
                    </option>
                  ))}
              </>
            ) : (
              <>
                <option value={records.at(0)?.notice_?.id}>{`${
                  records.at(0)?.notice_?.code
                } - ${records.at(0)?.notice_?.title}`}</option>
              </>
            )}
          </Select>
        </HStack>
        <Flex w='168px'>
          <CustomCurrencyInput
            id='value'
            name='value'
            label='Valor por projeto'
            type='text'
            required
            formik={formik}
            placeholder='R$'
          ></CustomCurrencyInput>
          {/* <FormControl isRequired>
            <FormLabel
              fontFamily='Inter'
              fontStyle='normal'
              fontWeight='700'
              fontSize='sm'
              color='#303950'
            >
              Valor total
            </FormLabel>
            <Input
              id='value'
              name='value'
              label=''
              required
              placeholder='R$'
              onChange={(event) => formik.setFieldValue(event?.target.value)}
              formik={formik}
            />
          </FormControl> */}
        </Flex>
      </Stack>
      <Stack>
        <FormControl isRequired>
          <FormLabel
            fontFamily='Inter'
            fontStyle='normal'
            fontWeight='700'
            fontSize='sm'
            color='#303950'
            whiteSpace={'nowrap'}
            marginBottom={'0px'}
          >
            Documentos associados
          </FormLabel>
        </FormControl>
        <UploadDocs
          onChange={(files: File[]) => {
            setFiles(files);
          }}
          oldFiles={oldFiles}
          externalFiles={externalFiles}
        />
      </Stack>
    </Stack>
  );
};

export default FormRegisterInform;
