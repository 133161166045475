import React from 'react';

const IconFacebook = (): JSX.Element => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <circle opacity='0.3' cx='20' cy='20' r='19.5' stroke='#EFEFEF' />
        <path
          d='M25.8291 10.59C25.8291 10.4354 25.7677 10.2871 25.6583 10.1777C25.549 10.0683 25.4006 10.0069 25.246 10.0069H22.3303C20.8621 9.93376 19.4246 10.4447 18.3319 11.4281C17.2393 12.4115 16.5802 13.7874 16.4989 15.2552V18.4041H13.5831C13.4285 18.4041 13.2802 18.4656 13.1708 18.5749C13.0614 18.6843 13 18.8326 13 18.9873V22.0196C13 22.1743 13.0614 22.3226 13.1708 22.432C13.2802 22.5413 13.4285 22.6028 13.5831 22.6028H16.4989V30.4169C16.4989 30.5715 16.5603 30.7198 16.6696 30.8292C16.779 30.9386 16.9273 31 17.082 31H20.5808C20.7355 31 20.8838 30.9386 20.9932 30.8292C21.1025 30.7198 21.164 30.5715 21.164 30.4169V22.6028H24.2196C24.3493 22.6046 24.4759 22.5632 24.5794 22.485C24.6829 22.4069 24.7574 22.2965 24.7911 22.1712L25.6308 19.1389C25.6541 19.0527 25.6572 18.9624 25.64 18.8748C25.6228 18.7873 25.5857 18.7048 25.5316 18.6339C25.4775 18.5629 25.4079 18.5053 25.328 18.4654C25.2482 18.4256 25.1603 18.4046 25.071 18.4041H21.164V15.2552C21.193 14.9665 21.3286 14.699 21.5442 14.5049C21.7599 14.3108 22.0401 14.2041 22.3303 14.2055H25.246C25.4006 14.2055 25.549 14.1441 25.6583 14.0347C25.7677 13.9254 25.8291 13.777 25.8291 13.6224V10.59Z'
          fill='#F9F8FA'
        />
      </g>
    </svg>
  );
};

export default IconFacebook;
