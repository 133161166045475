import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconRegisterDeliveryEvent = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#FBEDD1' />
      <path
        d='M22.1993 45C21.3743 45 20.6678 44.706 20.0797 44.118C19.4917 43.53 19.1982 42.824 19.1992 42V18C19.1992 17.175 19.4932 16.4685 20.0812 15.8805C20.6693 15.2925 21.3753 14.999 22.1993 15H35.6997L43.1999 22.5V42C43.1999 42.825 42.9059 43.5315 42.3178 44.1195C41.7298 44.7075 41.0238 45.001 40.1998 45H22.1993ZM22.1993 42H40.1998V24H34.1996V18H22.1993V42ZM31.1995 40.5C32.8746 40.5 34.2936 39.9125 35.4567 38.7375C36.6197 37.5625 37.2007 36.15 37.1997 34.5V28.5H34.1996V34.5C34.1996 35.325 33.9121 36.0315 33.3371 36.6195C32.7621 37.2075 32.0496 37.501 31.1995 37.5C30.3745 37.5 29.668 37.206 29.08 36.618C28.492 36.03 28.1985 35.324 28.1995 34.5V26.25C28.1995 26.025 28.2745 25.8435 28.4245 25.7055C28.5745 25.5675 28.7495 25.499 28.9495 25.5C29.1745 25.5 29.356 25.569 29.494 25.707C29.632 25.845 29.7005 26.026 29.6995 26.25V34.5H32.6996V26.25C32.6996 25.2 32.3371 24.3125 31.6121 23.5875C30.887 22.8625 29.9995 22.5 28.9495 22.5C27.8995 22.5 27.0119 22.8625 26.2869 23.5875C25.5619 24.3125 25.1994 25.2 25.1994 26.25V34.5C25.1994 36.15 25.7869 37.5625 26.9619 38.7375C28.137 39.9125 29.5495 40.5 31.1995 40.5Z'
        fill='#E98C1F'
      />
    </svg>
  );
};

export default IconRegisterDeliveryEvent;
