import React, { useEffect } from 'react';
import Event from '~/presentation/pages/event';
import { makeReduxListEvent } from '../../usecases/event/ListEventFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListUser } from '../../usecases/user/ListUserFactory';

export const makeEventFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListEvent().list({});
    makeReduxListNotice().list({});
    makeReduxListUser().list({});
  }, []);
  return <Event />;
};
