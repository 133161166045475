import { GetDashboard } from '~/domain/usecases/dashboard/redux/GetDashboard';
import {
  iActionGet,
  iActionGetFailed,
  iActionGetSuccess,
  DashboardTypes,
} from '~/domain/interfaces/redux/dashboard/get';

export const getRequest = (payload: GetDashboard.Params): iActionGet => ({
  type: DashboardTypes.GET,
  payload,
});

export const getSuccess = (
  params: GetDashboard.ModelToRedux
): iActionGetSuccess => ({
  type: DashboardTypes.GET_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetFailed => ({
  type: DashboardTypes.GET_FAILED,
});
