import { call, put, select } from 'redux-saga/effects';
import { iActionGet } from '~/domain/interfaces/redux/activity/get';
import { GetActivity } from '~/domain/usecases/activity/remote/GetActivity';
import { makeRemoteGetActivity } from '~/main/factories/usecases/activity/GetActivityFactory';
import {
  getSuccess,
  getFailed,
} from '~/data/store/reducer/activity/actions/get';

export function* onGet(action: iActionGet) {
  const remoteGetActivity = makeRemoteGetActivity();

  try {
    const response: GetActivity.Model = yield call(
      remoteGetActivity.get,
      action.payload
    );
    yield put(
      getSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}
