import React from 'react';
import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';
import WarningModal from '~/presentation/components/Modals/WarningModal';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeactivateStartup = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterMember;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Desligar do programa'
      question='Tem certeza de que quer desvincular a startup?'
      /* question='Tem certeza de que quer desvincular a startup BitTech do
    Edital nº 23/2022 - SEECT-FAPESQ-PB?' */
      description='Não há como reverter esta operação. Todos os
    documentos de entrega associados a esta startup serão
    excluídos permanentemente.'
      action={() => {
        formik.handleSubmit();
      }}
      actionText='Desligar'
    />
  );
};

export default DeactivateStartup;
