import React from 'react';

const IconMenuExit = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8889 3.88889L9.79222 4.98556L11.0211 6.22222H4.66667V7.77778H11.0211L9.79222 9.00667L10.8889 10.1111L14 7L10.8889 3.88889ZM1.55556 1.55556H7V0H1.55556C0.7 0 0 0.7 0 1.55556V12.4444C0 13.3 0.7 14 1.55556 14H7V12.4444H1.55556V1.55556Z'
        fill='#FF4C34'
      />
    </svg>
  );
};

export default IconMenuExit;
