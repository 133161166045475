import * as yup from 'yup';

export const schemaEvaluateAccountabilityInformValidator = {
  initial: {
    status: 'APPROVED' as 'APPROVED' | 'REJECTED' | 'PENDING',
    justification: '',
  },
  validators: yup.object({
    status: yup
      .string()
      .oneOf(['APPROVED', 'REJECTED', 'PENDING'])
      .required('Campo obrigatório'),
    justification: yup.string(),
  }),
};
