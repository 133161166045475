import React, { forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Select,
  Stack,
  Text,
  Input as ChakraInput,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import Input from '../../UI/Input';
import TextArea from '../../UI/textArea';
import { CalendarIcon } from '@chakra-ui/icons';
import IconCalendarGrey from '~/presentation/base/icons/iconCalendarGrey';
import DateInput from '../../UI/DateInput';

interface ownProps {
  formik: any;
}

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <ChakraInput
    autoComplete='off'
    bg='#F9F8FA'
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
  />
);
customDateInput.displayName = 'DateInput';

const CustomInput = forwardRef(customDateInput);

const icon = <CalendarIcon fontSize='sm' />;

const FormRegisterEvent = ({ formik }: ownProps): JSX.Element => {
  const [startDate, setStartDate] = useState(
    formik.values.start ? formik.values.start : new Date()
  );
  const [endDate, setEndDate] = useState(formik.values.end);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    // eslint-disable-next-line no-console
    console.log('<<<', start, end);
    // eslint-disable-next-line no-console
    /* console.log(
      '...',
      new Date(datefns.format(start, 'dd-MM-yyyy')),
      new Date(datefns.format(end, 'yyyy-MM-dd'))
    ); */
    // eslint-disable-next-line no-console
    /* console.log(
      '---',
      datefns.format(start, 'yyyy-MM-dd'),
      datefns.format(end, 'yyyy-MM-dd')
    ); */

    formik.setFieldValue('start', start);
    formik.setFieldValue('end', end);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Stack spacing='4'>
      <Input
        required
        id='name'
        name='name'
        label='Título do evento'
        formik={formik}
      />
      <Flex
        flexDir='row'
        justifyContent='space-between'
        alignItems='center'
        gap='30px'
      >
        <Flex display='flex' flex='50%' flexDirection='column'>
          <FormControl isRequired>
            <FormLabel
              fontFamily='Inter'
              fontStyle='normal'
              fontWeight='700'
              fontSize='sm'
              color='#303950'
            >
              Data Limite
            </FormLabel>
            <DateInput
              formik={formik}
              required
              name='date'
              onChange={(date) => {
                formik.setFieldValue('date', date);
              }}
              dateFormat='dd/MM/yyyy'
              minDate={new Date()}
            />
          </FormControl>
        </Flex>
        <Flex flexDir='column' flex='50%' w='168px'>
          <FormControl isRequired>
            <FormLabel
              fontFamily='Inter'
              fontStyle='normal'
              fontWeight='700'
              fontSize='sm'
              color='#303950'
            >
              Horário Limite
            </FormLabel>
            <DateInput
              formik={formik}
              name='time'
              required
              onChange={(time) => {
                formik.setFieldValue('time', time);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
              dateFormat='HH:mm'
            ></DateInput>
          </FormControl>
        </Flex>
      </Flex>
      <Input
        id='link'
        name='link'
        label='Link'
        placeholder='https://'
        formik={formik}
      />
      <TextArea
        id='description'
        name='description'
        label='Instruções'
        formik={formik}
        marginTop='0px'
      ></TextArea>
    </Stack>
  );
};

export default FormRegisterEvent;
