import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Menu from './';
import { IconMenuMobileButton } from '~/presentation/base/icons';

const MenuMobile = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        position='absolute'
        left='20px'
        display={{ base:'inherit', breakpoint1024: 'none' }}
        bg='#FDFDFD'
        borderWidth='1px'
        borderColor='#CECECE'
        p='0px'
        w='fit-content'
        aria-label='Menu'
        _hover={{ bg: '#EDEDED', borderColor: '#777777' }}
        css={{
          ':active, :focus': {
            boxShadow: '0px 0px 15px 0px #1997F380',
            borderWidth: '2px',
            borderColor: '#1997F3',
          },
        }}
        onClick={onOpen}
      >
        <IconMenuMobileButton />
      </Button>
      <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxW='fit-content'>
          <DrawerBody p='0'>
            <Menu isMobile />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MenuMobile;
