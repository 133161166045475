import { LogoutAuth } from '~/domain/usecases/auth/redux/LogoutAuth';
import {
  iActionLogout,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/logout';

export const logoutRequest = (payload: LogoutAuth.Params): iActionLogout => ({
  type: AuthTypes.LOGOUT,
  payload,
});
