import { Form as FormFormik, FormikContextType, FormikProvider } from 'formik';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

interface ownProps {
  value: FormikContextType<any>;
  children?: React.ReactNode;
}

const Form: React.FC<ownProps> = ({ value, children }) => {
  return (
    <FormikProvider value={value}>
      <FormFormik>{children}</FormFormik>
    </FormikProvider>
  );
};

export default Form;
