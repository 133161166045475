import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteNotice as UsecaseRemoteDeleteNotice } from '~/domain/usecases/notice/remote';
// import { DeleteNotice as UsecaseReduxDeleteNotice } from '~/domain/usecases/notice/redux';

import { RemoteDeleteNotice } from '~/data/repository/notice';
// import { ReduxDeleteNotice } from '~/data/store/reducer/notice/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteNotice = (): UsecaseRemoteDeleteNotice =>
  new RemoteDeleteNotice(makeApiUrl('/notices/{notice}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteNotice =
  (): UsecaseReduxDeleteNotice =>
    new ReduxDeleteNotice(); */
