import { Button, HStack } from '@chakra-ui/react';
import React from 'react';
import {
  IconDownload,
  IconDownloadDocs,
  IconFilter,
  IconRegister,
} from '~/presentation/base/icons';
import IconFilterNew from '~/presentation/base/icons/iconFilterNew';
import { withAccess } from '~/presentation/hooks/acess';

interface ButtonFilterProps {
  action: () => void;
  showDownloadReportButton?: boolean;
}

const ButtonFilter = ({
  action,
}: ButtonFilterProps): JSX.Element => {
  return (
    <Button
      minW={'fit-content'}
      height='36px'
      background='#FDFDFD'
      color='#222222'
      border={'1px'}
      borderColor={'#9C9C9C'}
      _hover={{ background: '#EDEDED' }}
      fontSize='sm'
      fontWeight={'500'}
      onClick={action}
    >
      <HStack>
        <IconFilterNew />
        <span>Filtros</span>
      </HStack>
    </Button>
  );
};

export default withAccess(ButtonFilter);
