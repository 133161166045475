import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateAccountability as UsecaseRemoteCreateAccountability } from '~/domain/usecases/accountability/remote';
// import { CreateAccountability as UsecaseReduxCreateAccountability } from '~/domain/usecases/accountability/redux';

import { RemoteCreateAccountability } from '~/data/repository/accountability';
// import { ReduxCreateAccountability } from '~/data/store/reducer/accountability/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateAccountability =
  (): UsecaseRemoteCreateAccountability =>
    new RemoteCreateAccountability(
      makeApiUrl('/accountabilities/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateAccountability =
  (): UsecaseReduxCreateAccountability =>
    new ReduxCreateAccountability(); */
