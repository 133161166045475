import { ListStartup } from '~/domain/usecases/startup/redux/ListStartup';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  StartupTypes,
} from '~/domain/interfaces/redux/startup/list';

export const listRequest = (payload: ListStartup.Params): iActionList => ({
  type: StartupTypes.LIST,
  payload,
});

export const listSuccess = (params: ListStartup.Model): iActionListSuccess => ({
  type: StartupTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: StartupTypes.LIST_FAILED,
});
