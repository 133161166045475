import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  TextareaProps,
  Textarea as ChakraInput,
} from '@chakra-ui/react';

interface ownProps extends TextareaProps {
  id: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  value?: string;
  label: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}

const TextArea = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  label,
  formik,
  required,
  ...rest
}: ownProps): JSX.Element => {
  return (
    <FormControl
      isRequired={required}
      isInvalid={formik?.errors[name] && formik?.touched[name]}
    >
      <FormLabel fontWeight='bold' fontSize='sm' mb='2'>
        {label}
      </FormLabel>
      <ChakraInput
        id={id}
        name={name}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        value={formik?.values[name]}
        bg='#F9F8FA'
        {...rest}
      />

      <FormErrorMessage>{formik?.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default TextArea;
