import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconRejected = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='11'
      height='10'
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.243989 0.254407C0.400262 0.0981811 0.612185 0.0104182 0.833155 0.0104182C1.05413 0.0104182 1.26605 0.0981811 1.42232 0.254407L4.99982 3.83191L8.57732 0.254407C8.6542 0.174815 8.74615 0.11133 8.84782 0.0676557C8.94949 0.0239815 9.05884 0.000992978 9.16949 3.14642e-05C9.28014 -0.000930049 9.38987 0.0201545 9.49228 0.0620553C9.5947 0.103956 9.68774 0.165834 9.76599 0.244078C9.84423 0.322321 9.90611 0.415365 9.94801 0.517778C9.98991 0.620192 10.011 0.729925 10.01 0.840575C10.0091 0.951224 9.98608 1.06057 9.94241 1.16224C9.89873 1.26391 9.83525 1.35587 9.75566 1.43274L6.17816 5.01024L9.75566 8.58774C9.90745 8.74491 9.99145 8.95541 9.98955 9.17391C9.98765 9.3924 9.90001 9.60142 9.74551 9.75592C9.591 9.91043 9.38199 9.99807 9.16349 9.99997C8.94499 10.0019 8.73449 9.91787 8.57732 9.76607L4.99982 6.18857L1.42232 9.76607C1.26515 9.91787 1.05465 10.0019 0.836154 9.99997C0.617657 9.99807 0.408647 9.91043 0.25414 9.75592C0.0996331 9.60142 0.0119921 9.3924 0.0100935 9.17391C0.00819477 8.95541 0.0921905 8.74491 0.243989 8.58774L3.82149 5.01024L0.243989 1.43274C0.087763 1.27647 0 1.06454 0 0.843574C0 0.622603 0.087763 0.41068 0.243989 0.254407Z'
        fill={isHilighted ? '#C23422' : '#60656D'}
      />
    </svg>
  );
};

export default IconRejected;
