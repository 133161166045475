import * as yup from 'yup';

export const schemaChatPassword = {
  initial: {
    password: '',
  },
  validators: yup.object({
    password: yup.string().required('Campo obrigatório'),
  }),
};
