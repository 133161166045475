import React, { FormHTMLAttributes } from 'react';

type ownProps = FormHTMLAttributes<HTMLFormElement>;

const Form = ({ onSubmit, children, ...rest }: ownProps): JSX.Element => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit(e);
      }}
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;
