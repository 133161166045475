import { call, put, select } from 'redux-saga/effects';
import { iActionListDeliveryFolders } from '~/domain/interfaces/redux/document/listDeliveryFolders';
import { ListDeliveryFoldersDocument } from '~/domain/usecases/document/remote/ListDeliveryFoldersDocument';
import { makeRemoteListDeliveryFoldersDocument } from '~/main/factories/usecases/document/ListDeliveryFoldersDocumentFactory';
import {
  listDeliveryFoldersSuccess,
  listDeliveryFoldersFailed,
} from '~/data/store/reducer/document/actions/listDeliveryFolders';

export function* onListDeliveryFolders(action: iActionListDeliveryFolders) {
  const remoteListDeliveryFoldersDocument =
    makeRemoteListDeliveryFoldersDocument();

  try {
    const response: ListDeliveryFoldersDocument.Model = yield call(
      remoteListDeliveryFoldersDocument.listDeliveryFolders,
      action.payload
    );
    yield put(
      listDeliveryFoldersSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listDeliveryFoldersFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListDeliveryFoldersSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListDeliveryFoldersFailed() {}
