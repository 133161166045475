import React, { useEffect } from 'react';
import Startup from '~/presentation/pages/startup';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';
import { makeReduxGetActivity } from '../../usecases/activity/GetActivityFactory';

export const makeStartupFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListStartup().list({});
    makeReduxGetActivity().get({});
  }, []);

  return <Startup />;
};
