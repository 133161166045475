import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/event/actions/list';

import { iActionList } from '~/domain/interfaces/redux/event/list';

import { ListEvent } from '~/domain/usecases/event/redux';

export class ReduxListEvent implements ListEvent {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListEvent.Params): iActionList =>
    this.send(listRequest(params));
}
