import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/accountability/actions/list';

import { iActionList } from '~/domain/interfaces/redux/accountability/list';

import { ListAccountability } from '~/domain/usecases/accountability/redux';

export class ReduxListAccountability
  implements ListAccountability
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (
    params: ListAccountability.Params,
  ): iActionList => this.send(listRequest(params));
}
