import React from 'react';

const IconSubmitionRejected = (): JSX.Element => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#FFEFEA' />
      <path
        d='M16 43.5V18C16 17.175 16.294 16.4685 16.882 15.8805C17.47 15.2925 18.176 14.999 19 15H40C40.825 15 41.5315 15.294 42.1195 15.882C42.7075 16.47 43.001 17.176 43 18V28.0125C42.525 27.7875 42.0375 27.6 41.5375 27.45C41.0375 27.3 40.525 27.1875 40 27.1125V18H19V39.075H28.1125C28.2375 39.85 28.4315 40.5875 28.6945 41.2875C28.9575 41.9875 29.301 42.65 29.725 43.275L29.5 43.5L27.25 41.25L25 43.5L22.75 41.25L20.5 43.5L18.25 41.25L16 43.5Z'
        fill='#C23422'
      />
      <path d='M38.5 24H20.5V21H38.5V24Z' fill='#C23422' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.193 42.8055C34.656 44.2685 36.425 45 38.5 45C40.574 45.001 42.3425 44.27 43.8055 42.807C45.2685 41.344 46 39.575 46 37.5C46.001 35.426 45.27 33.6575 43.807 32.1945C42.344 30.7315 40.575 30 38.5 30C36.426 29.999 34.6575 30.73 33.1945 32.193C31.7315 33.656 31 35.425 31 37.5C30.999 39.574 31.73 41.3425 33.193 42.8055ZM36.5027 40.4708L35.5313 39.4994L37.5312 37.4994L35.5313 35.4994L36.5027 34.528L38.5027 36.528L40.5027 34.528L41.4741 35.4994L39.4741 37.4994L41.4741 39.4994L40.5027 40.4708L38.5027 38.4708L36.5027 40.4708Z'
        fill='#C23422'
      />
      <path
        d='M28.1125 36H20.5V33H29.0125C28.7875 33.475 28.6 33.9625 28.45 34.4625C28.3 34.9625 28.1875 35.475 28.1125 36Z'
        fill='#C23422'
      />
      <path
        d='M20.5 30H31.15C32.1 29.075 33.2065 28.3435 34.4695 27.8055C35.7278 27.2695 37.066 27.001 38.4842 27H20.5V30Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconSubmitionRejected;
