import React from 'react';

interface ownProps {
  color?: string;
}

const IconEventTypeBullet = ({ color }: ownProps) => {
  return (
    <svg
      width='6'
      height='7'
      viewBox='0 0 6 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='3' cy='3' r='3' fill={color} />
    </svg>
  );
};

export default IconEventTypeBullet;
