import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import IconRegisterStartup from '~/presentation/base/icons/iconRegisterStartup';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconUserRegistered } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const EditUserSuccess = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'EDIT_USER'} //EDIT_STARTUP
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconUserRegistered />}
      title='Usuário(a) editado(a)!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>O(A) usuário(a) foi editada com sucesso!</Text>
            <Text>
              <Text display='inline' color='#E74D39'>
                *
              </Text>
              Lembre-se: O(A) usuário(a) só terá acesso ao sistema quando
              aceitar o convite enviado por e-mail e concluir seu cadastro.
            </Text>
            {/* <Text>
              A startup {name} sob a gestão do responsável {fullname} foi
              editada
              <Text display='inline' color='#E74D39'>
                *
              </Text>{' '}
              com sucesso.
            </Text> */}
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default EditUserSuccess;
