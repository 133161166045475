import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import {
  IconEventRegistered,
  IconInformApproved,
} from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterInformSuccess = ({ name, fullname, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'SUBMMIT_INFORM'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconInformApproved />}
      onOpenRegister={onOpenRegister}
      topButtonText={'Cadastrar outro informe'}
      title='Informe criado!'
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5' alignItems={'center'}>
            <Text>O informe foi criado com sucesso!</Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default RegisterInformSuccess;
