import { takeLatest } from 'redux-saga/effects';
import { onList, onListSuccess, onListFailed } from './List';
import { NotificationTypes } from '~/domain/interfaces/redux/notification/types';

const tasks = [
  takeLatest(NotificationTypes.LIST, onList),
  takeLatest(NotificationTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(NotificationTypes.LIST_FAILED, onListFailed),
];

export default tasks;
