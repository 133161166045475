import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconError } from '~/presentation/base/icons';

interface ownProps {
    name?: string;
    fullname?: string;
    customText?: string;
}

const ConflictError = ({ name, fullname, customText }: ownProps) => {
    const { active } = useContext(ConfirmModalContext);

    return (
        <Confirmation
            isOpen={active === 'CONFLICT_ERROR'}
            showTopButton={false}
            onClose={() => {
                // eslint-disable-next-line no-console
                console.log('close');
            }}
            icon={<IconError />}
            title='Oops! Algo deu errado...!'
            body={
                <Box fontSize='sm' color={'#60656D'}>
                    <Stack spacing='5'>
                        <Text textAlign={'center'}>
                            {customText ? customText : 'Dois editais não podem ter a mesma numeração'}
                        </Text>
                    </Stack>
                </Box>
            }
            buttonText='Voltar ao início'
            errorConfirmation
            returnPage='/inicio'
        ></Confirmation>
    );
};

export default ConflictError;
