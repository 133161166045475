/* eslint-disable no-console */
import React, { forwardRef, useState } from 'react';

import {
  Box,
  Select,
  Stack,
  HStack,
  Text,
  Input as ChakraInput,
  Flex,
} from '@chakra-ui/react';
import * as datefns from 'date-fns';
import DatePicker from 'react-datepicker';
import Input from '~/presentation/components/UI/Input';
import TextArea from '~/presentation/components/UI/textArea';
import { CalendarIcon } from '@chakra-ui/icons';
import { schemaRegisterNotice } from '~/validators/RegisterNotice/RegisterNoticeValidator';
import { useFormik } from 'formik';
import { IconNextMonth, IconPrevMonth } from '~/presentation/base/icons';
import DateInput from '~/presentation/components/UI/DateInput';
import NoticeConfirmationModal from './NoticeConfirmationModal';
import AddStepModal from './AddStepModal';
import UploadDocs from '../../UploadDocs';
import { iOldFile } from '../../Modals/notice/EditNotice';
import getMask from '~/utils/getMask';
import CustomCurrencyInput from '../../UI/CurrencyInput';

interface ownProps {
  formik: any;
  setFiles: (files: File[]) => void;
  oldFiles?: iOldFile[];
  externalFiles?: File[];
}

const FormRegisterNotice = ({
  formik,
  setFiles,
  oldFiles,
  externalFiles,
}: ownProps): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  return (
    <Stack spacing='4' w='100%' direction='row' gap={'16px'}>
      <Stack spacing='4' w='360px'>
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id='code'
            name='code'
            label='Número'
            required
            formik={formik}
            placeholder='ex : 01/2023'
          />
          <Input
            id='title'
            name='title'
            label='Identificação'
            required
            formik={formik}
            placeholder='ex : SEECT-PB'
          />
        </HStack>
        <Text fontWeight='bold' fontSize='sm' mb='1'>
          Período de Inscrições
          <span style={{ color: 'red' }}> *</span>
        </Text>
        <HStack spacing='7'>
          <DateInput
            formik={formik}
            required
            name='start'
            onChange={(date) => {
              date?.setHours(0, 0, 0, 0);
              formik.setFieldValue('start', date);
              if (date && datefns.compareAsc(date, formik.values.end) === 1) {
                date?.setHours(23, 59, 59, 999);
                formik.setFieldValue('end', date);
              }
            }}
            selectsStart
            startDate={startDate}
            minDate={startDate}
            dateFormat='dd/MM/yyyy'
          />
          <DateInput
            formik={formik}
            required
            name='end'
            onChange={(date) => {
              date?.setHours(23, 59, 59, 999);
              formik.setFieldValue('end', date);
            }}
            selectsEnd
            endDate={formik.values.end}
            minDate={formik.values.start}
            dateFormat='dd/MM/yyyy'
          />
        </HStack>
        <HStack spacing='7' alignItems='flex-start'>
          <CustomCurrencyInput
            id='value'
            name='value'
            label='Valor por projeto'
            type='text'
            required
            formik={formik}
            //placeholder='R$ 0,00'
            value='0,00'
          ></CustomCurrencyInput>
          {/* <Input
            id='value'
            name='value'
            label='Valor pro projeto'
            type='number'
            required
            formik={formik}
            placeholder='R$'
          /> */}
          <Input
            id='vacancies'
            name='vacancies'
            label='Vagas'
            type='number'
            required
            formik={formik}
          />
        </HStack>
        <Flex w='full'>
          <TextArea
            marginTop={'10px'}
            id='description'
            name='description'
            label='Objeto do edital'
            required
            formik={formik}
            resize='none'
            placeholder='ex: Edital de financiamento para Startups da área de saúde.'
            minH={'165px'}
          />
        </Flex>
      </Stack>
      <UploadDocs
        onChange={(files: File[]) => {
          setFiles(files);
        }}
        oldFiles={oldFiles}
        externalFiles={externalFiles}
      />
    </Stack>
  );
};

export default FormRegisterNotice;
