import { Dispatch } from 'redux';
import store from '~/data/store';
import { listMentoringFoldersRequest } from '~/data/store/reducer/document/actions/listMentoringFolders';

import { iActionListMentoringFolders } from '~/domain/interfaces/redux/document/listMentoringFolders';

import { ListMentoringFoldersDocument } from '~/domain/usecases/document/redux';

export class ReduxListMentoringFoldersDocument
  implements ListMentoringFoldersDocument
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listMentoringFolders = (
    params: ListMentoringFoldersDocument.Params
  ): iActionListMentoringFolders =>
    this.send(listMentoringFoldersRequest(params));
}
