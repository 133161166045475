import React, { Children, useEffect, useState } from 'react';
import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Select,
  Text,
} from '@chakra-ui/react';

import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';

//As long as it istn integrated, props ID is optional.

interface ownProps {
  id?: number;
  headerTitle: string;
  subtitle?: string;
  modalSize: 'large' | 'small' | 'tiny';
  children?: React.ReactNode;
  data?: [];
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  startupImg?: React.ReactNode;
  verticalLine?: React.ReactNode;
  isVerticalLine?: boolean;
  horizontalLine?: React.ReactNode;
  isPartitioned?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

enum modalSizeOptions {
  largeWidth = '862px',
  largeHeight = '445px',
  smallWidth = '862px',
  smallHeight = '445px',
  tinyWidth = '388px',
  tinyHeight = '574px',
}

const GenericModalLayout = ({
  headerTitle,
  modalSize,
  children,
  startupImg,
  data,
  isPartitioned,
  isVerticalLine,
  centerContent,
  rightContent,
  isOpen,
  onClose,
}: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterMember;
  const [modalSizeHeightLayout, setModalSizeHeightLayout] = useState<string>(
    modalSizeOptions.largeHeight
  );
  const [modalSizeWidthLayout, setModalSizeWidthLayout] = useState<string>(
    modalSizeOptions.largeWidth
  );

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  useEffect(() => {
    if (modalSize == 'large') {
      // eslint-disable-next-line no-console
      console.log(modalSize);
      setModalSizeWidthLayout(modalSizeOptions.largeWidth);
      setModalSizeHeightLayout(modalSizeOptions.largeHeight);
    } else if (modalSize == 'small') {
      // eslint-disable-next-line no-console
      console.log(modalSize);
      setModalSizeWidthLayout(modalSizeOptions.smallWidth);
      setModalSizeHeightLayout(modalSizeOptions.smallHeight);
    } else if (modalSize == 'tiny') {
      // eslint-disable-next-line no-console
      console.log(modalSize);
      setModalSizeWidthLayout(modalSizeOptions.tinyWidth);
      setModalSizeHeightLayout(modalSizeOptions.tinyHeight);
    }
  }, [modalSize]);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW={modalSizeWidthLayout}
          maxH={modalSizeHeightLayout}
          bg='#F9F8FA'
          paddingTop={'24px'}
        >
          <ModalHeader
            fontSize='24px'
            py='3'
            whiteSpace='nowrap'
            padding='0px 0px 0px 24px'
          >
            <Flex
              flexDirection='row'
              justifyContent={'space-between'}
              alignItems='center'
              paddingBottom={'22.5px'}
            >
              <Text
                fontWeight='700'
                fontFamily='Inter'
                fontSize='18px'
                color={'#303950'}
              >
                {headerTitle}
              </Text>
              <ModalCloseButton
                size='md'
                position={'inherit'}
                marginRight='24px'
                color={'#60656D'}
              ></ModalCloseButton>
            </Flex>
          </ModalHeader>
          <ModalBody paddingBottom={'24px'} paddingTop={'0px'}>
            <Flex
              flexDirection={'row'}
              justifyContent='space-between'
              alignItems={'flex-start'}
              gap='33px'
            >
              {isPartitioned && (
                <Flex flexDirection={'row'} gap='33px'>
                  {startupImg}
                  {isVerticalLine && (
                    <Box borderLeft='1px solid #EFEFEF '></Box>
                  )}
                  <Flex flexDirection='column' mt='0px' gap={'20px'}>
                    <Flex flexDir={'row'} gap={'24px'}>
                      {centerContent}
                    </Flex>
                  </Flex>
                  {rightContent}
                </Flex>
              )}
            </Flex>
            {/* <Box borderTop='1px solid #E9ECEE ' marginTop={'20px'}></Box> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GenericModalLayout;
