import { Box, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconSubmitionApproved } from '~/presentation/base/icons';
import { iApplication } from '~/domain/interfaces/models/Application';

interface ownProps {
  application?: iApplication['records'][0];
}

const SubmitionApproved = ({ application }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'APPLICATION_APPROVED'} //EDIT_STARTUP
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconSubmitionApproved />}
      title='Inscrição deferida!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>
              Você optou por{' '}
              <Box as='span' fontWeight={'bold'} color={'#5b7a17'}>
                {' '}
                deferir{' '}
              </Box>{' '}
              a inscrição da startup {`"`}
              <b>{`${application?.startup_?.fullname}`}</b>
              {`"`} para o Edital nº{' '}
              {`${application?.notice_.code} - ${application?.notice_.title}`}.
            </Text>
            <Text>
              Lembre-se: você ainda poderá alterar o resultado desta avaliação
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default SubmitionApproved;
