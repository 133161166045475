import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
  Text,
  HStack,
  Avatar,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { iApplication } from '~/domain/interfaces/models/Application';
import { iStartup } from '~/domain/interfaces/models/Startup';
import { iStore } from '~/domain/interfaces/models/Store';
import { makeReduxListApplication } from '~/main/factories/usecases/application/ListApplicationFactory';
import { makeRemoteUpdateApplication } from '~/main/factories/usecases/application/UpdateApplicationFactory';
import { IconEvalApplication } from '~/presentation/base/icons';
import { IconOpenFolder } from '~/presentation/base/icons';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import SubmitionApproved from '~/presentation/components/Modals/Confirmation/SubmitionApproved';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import SubmitionRejected from '~/presentation/components/Modals/Confirmation/SubmitionRejected';
import EvaluateApplication from '~/presentation/components/Modals/application/EvaluateApplication';
import RevenueEvaluation from '~/presentation/components/RevenueEvaluation';
import Tab from '~/presentation/components/Tab';
import Select from '~/presentation/components/UI/select';
import getDate from '~/utils/getDate';

interface iRecordsByNotice {
  [key: string]: {
    startupInfo: iStartup['records'][0];
    application: iApplication['records'][0];
  }[];
}

const Registrations = (): JSX.Element => {
  const [open, setOpen] = React.useState('');
  const [selectedApplication, setSelectedApplication] = React.useState<
    iApplication['records'][0] | undefined
  >(undefined);
  const [active, setActive] = React.useState('all');
  const [tabs, setTabs] = React.useState<string[]>([]);
  const [recordsByNotice, setRecordsByNotice] =
    React.useState<iRecordsByNotice>({});
  const [selectedNotice, setSelectedNotice] = React.useState<string>('All');
  const [selectedStatus, setSelectedStatus] = React.useState<string>('All');
  const [notices, setNotices] = React.useState<string[]>([]);
  const { records } = useSelector((store: iStore) => store.application);
  const startups = useSelector((store: iStore) => store.startup.records);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');

  useEffect(() => {
    const _notices: string[] = [];

    records.forEach((item) => {
      if (!_notices.includes(item.notice_?.code)) {
        _notices.push(item.notice_?.code);
      }
    });

    setNotices(_notices);
  }, [records]);

  const renderStatus = (status: string) => {
    switch (status) {
      case 'SUBMITTED':
        return (
          <Tag h='40px' bg='#EEF2F5' color='#5B646B'>
            <TagLabel>Em aberto</TagLabel>
          </Tag>
        );
      case 'APPROVED':
        return (
          <Tag h='40px' bg='#EBF3D9' color='#6C9020'>
            <TagLabel>Deferida</TagLabel>
          </Tag>
        );
      case 'REJECTED':
        return (
          <Tag h='40px' bg='#FFEFEA' color='#CD3724'>
            <TagLabel>Indeferida</TagLabel>
          </Tag>
        );
    }
  };

  const updateStatus = (application: number, status: string) => {
    makeRemoteUpdateApplication()
      .update({ application, body: { status } })
      .then(() => {
        makeReduxListApplication().list({});
      });
  };

  const extractCity = (address: string) => {
    let city = '';
    if (address) {
      const addressParts = address.split(',');
      if (addressParts.length >= 3) {
        city = addressParts[2].trim();
      }
    }
    return city;
  };

  return (
    <Box flex='1' my='6' mx='8' h='calc(100% - 136px)' position='relative'>
      <Tab
        selectFilter={
          <HStack>
            <Select
              id='notice'
              name='notice'
              label=''
              maxW='330px'
              onChange={(event) => {
                setSelectedNotice(event.target.value);
              }}
            >
              <option value='All'>Edital: Todos os editais</option>
              {notices.map((item: string, index: number) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Select>
            <Select
              id='status'
              name='status'
              label=''
              maxW='220px'
              onChange={(event) => {
                setSelectedStatus(event.target.value);
              }}
            >
              <option value='All'>Avaliação: Todos</option>
              <option value='SUBMITTED'>Em aberto</option>
              <option value='APPROVED'>Deferida</option>
              <option value='REJECTED'>Indeferida</option>
            </Select>
          </HStack>
        }
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        buttonText='Nova Inscrição'
        action={function (): void {
          throw new Error('Function not implemented.');
        }}
      ></Tab>
      <TableContainer
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        maxHeight='calc(100% - 190px)'
        overflowY='auto'
      >
        <Table
          position='sticky'
          top={0}
          zIndex={5}
          variant='simple'
          colorScheme='white'
        >
          <Thead backgroundColor='white-3' height='50px'>
            <Tr>
              <Th fontSize='sm'>ID</Th>
              <Th fontSize='sm'>Edital Associado</Th>
              <Th fontSize='sm'>Startup</Th>
              <Th fontSize='sm'>Gestor Responsável</Th>
              <Th fontSize='sm'>Realizada em</Th>
              <Th fontSize='sm'>Avaliação</Th>
              <Th fontSize='sm'>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records
              .filter((item) => {
                let check =
                  selectedNotice === 'All'
                    ? true
                    : item.notice_?.code === selectedNotice;

                check =
                  check &&
                  (selectedStatus === 'All'
                    ? true
                    : item.status === selectedStatus);
                if (
                  selectedNotice === 'All' &&
                  selectedStatus === 'All' &&
                  filterByNameValue.trim() === ''
                ) {
                  return true;
                } else if (filterByNameValue.trim() !== '') {
                  return (
                    `${item.notice_?.code + ' ' + item.notice_?.title}`
                      .toLowerCase()
                      .search(filterByNameValue.toLowerCase()) !== -1
                  );
                } else {
                  return check;
                }
              })
              .map((item, index) => (
                <Tr fontSize='sm' fontWeight={400} color='#747C86' key={index}>
                  <Td>
                    <Text>{item.id}</Text>
                  </Td>
                  <Td>
                    <Box>
                      <Text>{`N° ${item.notice_?.code}`}</Text>
                      <Text>{item.notice_?.title}</Text>
                    </Box>
                  </Td>
                  <Td>
                    <HStack spacing='4'>
                      <Avatar
                        name={item.startup_?.fullname ?? ''}
                        src={item.startup_?.logo ?? ''}
                      ></Avatar>
                      <Flex flexDirection={'column'}>
                        <Text fontWeight={'semibold'}>
                          {item.startup_?.name}
                        </Text>
                        <Text>
                          {`${item.startup_?.address.city ?? ''} - ${
                            item.startup_?.address?.province ?? ''
                          } `}
                        </Text>
                      </Flex>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack spacing='4'>
                      <Avatar
                        name={
                          item.startup_?.members
                            .filter((member) => member?.major === true)
                            .at(0)?.user_?.fullname
                        }
                        src={
                          item.startup_?.members
                            .filter((member) => member?.major === true)
                            .at(0)?.user_?.avatar ?? ''
                        }
                      ></Avatar>
                      <Box>
                        <Text fontWeight='semibold'>
                          {
                            item.startup_?.members
                              .filter((member) => member?.major === true)
                              .at(0)?.user_?.fullname
                          }
                        </Text>
                        <Text>
                          {
                            item.startup_?.members
                              .filter((member) => member?.major === true)
                              .at(0)?.user_?.email
                          }
                        </Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td>
                    <Text>{getDate(item.createdAt)}</Text>
                  </Td>
                  <Td>
                    {item.status === 'APPROVED' && (
                      <RevenueEvaluation status='APPROVED' isHilighted>
                        Deferida
                      </RevenueEvaluation>
                    )}
                    {item.status === 'REJECTED' && (
                      <RevenueEvaluation status='REJECTED' isHilighted>
                        Indeferida
                      </RevenueEvaluation>
                    )}
                    {item.status === 'SUBMITTED' && (
                      <RevenueEvaluation status='PENDING' isHilighted>
                        Em aberto
                      </RevenueEvaluation>
                    )}
                  </Td>
                  <Td>
                    <Flex gap='3'>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
                        resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
                      >
                        <Link
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          to={{
                            pathname: '/documentos',
                            state: {
                              path: [
                                { name: 'STARTUPS', id: 0 },
                                {
                                  name: `Inscrição ${item.startup_?.name} no Edital ${item.notice_?.code}`,
                                  id: item?.folder?.id,
                                },
                              ],
                            },
                          }}
                        >
                          <IconOpenFolder />
                        </Link>
                      </ActionButton>
                      <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => {
                          setOpen('EVALUATE'), setSelectedApplication(item);
                        }}
                      >
                        <IconEvalApplication />
                      </ActionButton>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <EvaluateApplication
        isOpen={open === 'EVALUATE'}
        onClose={() => setOpen('')}
        application={selectedApplication}
      />
      <SubmitionApproved application={selectedApplication}></SubmitionApproved>
      <SubmitionRejected application={selectedApplication}></SubmitionRejected>
      <SubmitionApplicationError></SubmitionApplicationError>
    </Box>
  );
};

export default Registrations;

const data = [
  {
    avatar: '',
    name: 'Nome do candidato',
    email: 'email@gmail.com',
    team: {
      name: 'Diretoria',
      color: '#30A2DA',
    },
    position: 'Lorem',
    permission: {
      name: 'Admin',
      color: '#D85A13',
    },
  },
  {
    avatar: '',
    name: 'Nome do candidato',
    email: 'email@gmail.com',
    team: {
      name: 'Desenvolvimento',
      color: '#F28E19',
    },
    position: 'Lorem',
    permission: {
      name: 'Gerente',
      color: '#AC38A8',
    },
  },
  {
    avatar: '',
    name: 'Nome do candidato',
    email: 'email@gmail.com',
    team: {
      name: 'Desenvolvimento',
      color: '#F28E19',
    },
    position: 'Lorem',
    permission: {
      name: 'Membro',
      color: '#1997F3',
    },
  },
  {
    avatar: '',
    name: 'Nome do candidato',
    email: 'email@gmail.com',
    team: {
      name: 'Design',
      color: '#AC38A8',
    },
    position: 'Lorem',
    permission: {
      name: 'Gerente',
      color: '#AC38A8',
    },
  },
  {
    avatar: '',
    name: 'Nome do candidato',
    email: 'email@gmail.com',
    team: {
      name: 'Qualidade',
      color: '#DE4377',
    },
    position: 'Lorem',
    permission: {
      name: 'Admin',
      color: '#D85A13',
    },
  },
];
