import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from '../themes';

export default createGlobalStyle`

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: 0;

    a {
      text-decoration: none;
    }
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${(props) => 'c4c4c4'};
    font-family: 'Inter', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiTooltip-popper[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  .MuiTooltip-popper[data-popper-reference-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }

  /** SCROLLBAR **/
    ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(100, 100, 100, .5);
  }
  ::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
  } 

  .dzu-previewStatusContainer {
    display: none !important;
  }

  .dzu-previewFileName {
    display: none !important;
  }

  .pagination {
    .page-item {
      .page-link {
        color: #004BA5 !important;
        border: 1px solid #004BA5 !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .page-item.active {
      .page-link {
        color: #fff !important;
        background-color: #004BA5 !important;
        border: 1px solid #004BA5 !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .react-datepicker__header {
  background-color: black;
}
  }
`;
