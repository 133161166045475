import { Box, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconDeleteDefault } from '~/presentation/base/icons';
import { iStartup } from '~/domain/interfaces/models';

interface ownProps {
  startup?: iStartup['records'][0];
}

const DeleteStartupSuccess = ({ startup }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'DELETE_STARTUP'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconDeleteDefault />}
      title={'Startup excluída!'}
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text textAlign={'center'}>
              {`A Startup`} <b>{startup?.name}</b> {`foi excluída com sucesso!`}
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default DeleteStartupSuccess;
