import { takeLatest } from 'redux-saga/effects';
import { onGet, onGetSuccess, onGetFailed } from './Get';
import { DashboardTypes } from '~/domain/interfaces/redux/dashboard/types';

const tasks = [
  takeLatest(DashboardTypes.GET, onGet),
  takeLatest(DashboardTypes.GET_SUCCESS, onGetSuccess),
  takeLatest(DashboardTypes.GET_FAILED, onGetFailed),
];

export default tasks;
