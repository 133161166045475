import React from 'react';

const Disconnect = (): JSX.Element => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.13916 2.96357L4.13891 2.96378L1.00005 10.0078H0.850047C0.850047 10.0079 0.850047 10.0079 0.850047 10.008C0.850178 15.0491 4.95486 19.15 10 19.15C15.0453 19.15 19.15 15.049 19.15 10.0078C19.1499 8.65954 18.8506 7.32812 18.2738 6.10954C17.6969 4.891 16.8568 3.81565 15.8141 2.96101C15.8141 2.96098 15.8141 2.96094 15.814 2.96091L15.0787 2.35536L14.9632 2.26026L14.8678 2.3755L13.6563 3.83886L13.5604 3.9547L13.6765 4.05031L14.412 4.65608L14.4123 4.65631C15.2043 5.30527 15.8424 6.12191 16.2806 7.04733C16.7188 7.97275 16.9461 8.98389 16.9462 10.0078C16.9462 13.836 13.8301 16.9509 10 16.9509C6.16999 16.9509 3.05389 13.836 3.05389 10.0078L3.05389 10.0072C3.05003 8.9861 3.27175 7.97673 3.7032 7.05123C4.13462 6.1258 4.76509 5.30707 5.54957 4.65353C5.54963 4.65348 5.5497 4.65343 5.54976 4.65338L6.28069 4.04787L6.39668 3.95178L6.30011 3.83619L5.08122 2.37715L4.98545 2.26251L4.87041 2.3578L4.13916 2.96357ZM9.04816 0.85H8.89816V1V10.6923V10.8423H9.04816H10.952H11.102V10.6923V1V0.85H10.952H9.04816Z'
        fill='#C23422'
        stroke='#C23422'
        strokeWidth='0.3'
      />
    </svg>
  );
};

export default Disconnect;
