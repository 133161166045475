import React, { useEffect } from 'react';
import Dashboard from '~/presentation/pages/dashboard';
import { makeReduxGetDashboard } from '../../usecases/dashboard/GetDashboardFactory';

export const makeDashboardFactory: React.FC = () => {
  useEffect(() => {
    makeReduxGetDashboard().get({});
  }, []);
  return <Dashboard />;
};
