import {
  Box,
  Divider,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import IconConfirmNotice from '~/presentation/base/icons/iconConfirmNotice';

interface ownProps {
  name?: string;
  fullname?: string;
}

const EditNoticeSuccess = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='500px'
      isOpen={active === 'EDIT_NOTICE'} //EDIT_STARTUP
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconConfirmNotice />}
      title='Edital editado!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>O Edital foi editado com sucesso. Lembre-se que:</Text>
            <UnorderedList pl='5' spacing='5'>
              <ListItem>
                <Text>
                  Se precisar modificar as informações deste edital ou de suas
                  etapas, clique no botão “Editar” na lista principal;
                </Text>
              </ListItem>
              <ListItem>
                <Text display={'list-item'}>
                  Para permitir que uma startup passe de uma etapa outra, altere
                  o campo “Etapa associada” no Painel de Startups;
                </Text>
              </ListItem>
            </UnorderedList>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default EditNoticeSuccess;
