import { iAccountability } from '~/domain/interfaces/models/Accountability';
import { AccountabilityTypes } from '~/domain/interfaces/redux/accountability/types';
import { AccountabilityActions } from './actions';

export const initialState: iAccountability = {
  loading: false,
  records: [],
};

const reducer = (
  state = initialState,
  action: AccountabilityActions
): iAccountability => {
  switch (action.type) {
    case AccountabilityTypes.LIST:
      return { ...state, loading: true };
    case AccountabilityTypes.LIST_FAILED:
      return { ...state, loading: false };
    case AccountabilityTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
