import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListApplication as UsecaseRemoteListApplication } from '~/domain/usecases/application/remote';
 import { ListApplication as UsecaseReduxListApplication } from '~/domain/usecases/application/redux';

import { RemoteListApplication } from '~/data/repository/application';
 import { ReduxListApplication } from '~/data/store/reducer/application/usecases';

/**
 * send request via API.
 */
export const makeRemoteListApplication =
  (): UsecaseRemoteListApplication =>
    new RemoteListApplication(
      makeApiUrl('/applications/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListApplication =
  (): UsecaseReduxListApplication =>
    new ReduxListApplication();
