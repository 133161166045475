import { call, put, select } from 'redux-saga/effects';
import { iActionListNoticeFolders } from '~/domain/interfaces/redux/document/listNoticeFolders';
import { ListNoticeFoldersDocument } from '~/domain/usecases/document/remote/ListNoticeFoldersDocument';
import { makeRemoteListNoticeFoldersDocument } from '~/main/factories/usecases/document/ListNoticeFoldersDocumentFactory';
import {
  listNoticeFoldersSuccess,
  listNoticeFoldersFailed,
} from '~/data/store/reducer/document/actions/listNoticeFolders';

export function* onListNoticeFolders(action: iActionListNoticeFolders) {
  const remoteListNoticeFoldersDocument = makeRemoteListNoticeFoldersDocument();

  try {
    const response: ListNoticeFoldersDocument.Model = yield call(
      remoteListNoticeFoldersDocument.listNoticeFolders,
      action.payload
    );
    yield put(
      listNoticeFoldersSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listNoticeFoldersFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListNoticeFoldersSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListNoticeFoldersFailed() {}
