import * as yup from 'yup';
import { validateCPF, validatePhone } from 'validations-br';

export const schemaEditMember = {
  initial: {
    fullname: '',
    login: '',
    password: '',
    datepicker: new Date(),
    rg: '',
    address: '',
    job: '',
    email: '',
    role: '',
    //TO DO: Arrumar a requisição do getUser{id} para assim então solicitar o "gender" no Schema
    //gender: '',
    cpf: '',
    cep: '',
    phone: '',
    workload: '',
    permission: 'ADMIN',
    gender: '',
  },
  validators: yup.object({
    fullname: yup.string().required('Campo obrigatório'),
    datepicker: yup.date().required('Campo obrigatório'),
    rg: yup.string().required('Campo obrigatório') ,
    address: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório').email('Email inválido'),
    role: yup.string().required('Campo obrigatório'),
    job: yup.string().required('Campo obrigatório'),
    gender: yup
      .string()
      .oneOf(
        ['MALE', 'FEMALE', 'OTHER'],
        'Selecione uma opção válida'
      ),
    cpf: yup
      .string()
      .required('Campo obrigatório')
      .test('is-cnpj', 'CNPJ inválido', (cpf) => validateCPF(cpf!)),
    cep: yup.string().required('Campo obrigatório'),
    /* .required('Campo obrigatório')
      .matches(/(?!^0{5}-0{3}$)(^[0-9]{5}-[0-9]{3}$)/, 'CEP inválido') */
    phone: yup.string().required('Campo obrigatório'),
    workload: yup
      .string()
      .oneOf(['20h/semana', '40h/semana'], 'Selecione uma opção válida')
      .required('Campo obrigatório'),
    permission: yup.string().required('Campo obrigatório'),
  }),
};
