import { Button, HStack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { IconDownload, IconDownloadDocs, IconRegister } from '~/presentation/base/icons';
import { withAccess } from '~/presentation/hooks/acess';

interface ButtonDownloadReportProps {
  action: () => void;
}

const ButtonDownloadReport = ({ action }: ButtonDownloadReportProps): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Button
      width={{ base: '11%', md: '37%' }}
      height='36px'
      background='#FDFDFD'
      color='#222222'
      border={'1px'}
      borderColor={'#9C9C9C'}
      _hover={{ background: '#EDEDED' }}
      fontSize='sm'
      fontWeight={'500'}
      onClick={action}
    >
      <HStack>
        <IconDownloadDocs fill='#222222' />
        {!isMobile && <span>Baixar relatório</span>}
      </HStack>
    </Button>
  );
}

export default withAccess(ButtonDownloadReport);
