import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateEvent as UsecaseRemoteCreateEvent } from '~/domain/usecases/event/remote';
// import { CreateEvent as UsecaseReduxCreateEvent } from '~/domain/usecases/event/redux';

import { RemoteCreateEvent } from '~/data/repository/event';
// import { ReduxCreateEvent } from '~/data/store/reducer/event/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateEvent = (): UsecaseRemoteCreateEvent =>
  new RemoteCreateEvent(makeApiUrl('/events/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateEvent =
  (): UsecaseReduxCreateEvent =>
    new ReduxCreateEvent(); */
