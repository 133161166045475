import React from 'react';

const IconMenuMobileButton = () => {
  return (
    <svg
      width='17'
      height='14'
      viewBox='0 0 17 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 8.16667H16.3333V5.83333H0V8.16667ZM0 14H16.3333V11.6667H0V14ZM0 0V2.33333H16.3333V0H0Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuMobileButton;
