import React from 'react';

const IconMenuDocsWhite = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.92321 0.939113L7.76194 3.02544H13.5577C13.9023 3.02544 14.2328 3.16334 14.4765 3.40879C14.7202 3.65424 14.8571 3.98714 14.8571 4.33426V12.2535C14.8568 12.584 14.7263 12.9008 14.4943 13.1345C14.2623 13.3682 13.9477 13.4997 13.6196 13.5H1.25641C0.923351 13.4995 0.604075 13.366 0.368563 13.1288C0.133068 12.8916 0.000533079 12.57 0 12.2346V1.80882C0 1.4617 0.136906 1.1288 0.380601 0.883349C0.624296 0.637898 0.954816 0.500005 1.29945 0.500005L4.94862 0.500005C5.13268 0.499503 5.31474 0.538389 5.48276 0.61409C5.65077 0.68979 5.8009 0.800576 5.92321 0.939113ZM4.86638 1.99522H1.48452V3.02544H5.77587L4.86638 1.99522ZM1.48452 12.0048H13.3726V4.52066H1.48452V12.0048Z'
        fill='#E2E2E2'
      />
    </svg>
  );
};

export default IconMenuDocsWhite;
