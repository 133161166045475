import { ListMentoringFoldersDocument } from '~/domain/usecases/document/redux/ListMentoringFoldersDocument';
import {
  iActionListMentoringFolders,
  iActionListMentoringFoldersFailed,
  iActionListMentoringFoldersSuccess,
  DocumentTypes,
} from '~/domain/interfaces/redux/document/listMentoringFolders';

export const listMentoringFoldersRequest = (
  payload: ListMentoringFoldersDocument.Params
): iActionListMentoringFolders => ({
  type: DocumentTypes.LISTMENTORINGFOLDERS,
  payload,
});

export const listMentoringFoldersSuccess = (
  params: ListMentoringFoldersDocument.Model
): iActionListMentoringFoldersSuccess => ({
  type: DocumentTypes.LISTMENTORINGFOLDERS_SUCCESS,
  payload: params,
});

export const listMentoringFoldersFailed =
  (): iActionListMentoringFoldersFailed => ({
    type: DocumentTypes.LISTMENTORINGFOLDERS_FAILED,
  });
