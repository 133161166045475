const getTextBasedOnEventType = (eventType: string) => {
  switch (eventType) {
    case 'MEETING':
      return 'Responsável';
    case 'MENTORING':
      return 'Mentor Responsável';
    case 'DELIVERY':
      return '';
    default:
      return '';
  }
};

export default getTextBasedOnEventType;
