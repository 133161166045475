import React from 'react';
interface ownProps {
  color: string;
  bgColor: string;
}
const IconHomeButtonEvents = ({ color, bgColor }: ownProps) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill={bgColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.7209 11.5814V10H13.9535V11.5814H10.8837C10.6493 11.5814 10.4246 11.6745 10.2588 11.8402C10.0931 12.006 10 12.2307 10 12.4651V25.1163C10 25.3507 10.0931 25.5754 10.2588 25.7412C10.4246 25.9069 10.6493 26 10.8837 26H25.1163C25.3507 26 25.5754 25.9069 25.7412 25.7412C25.9069 25.5754 26 25.3507 26 25.1163V12.4651C26 12.2307 25.9069 12.006 25.7412 11.8402C25.5754 11.6745 25.3507 11.5814 25.1163 11.5814H22.0465V10H20.2791V11.5814H15.7209ZM20.2791 14.9302H22.0465V13.3488H24.2326V16.3256H11.7674V13.3488H13.9535V14.9302H15.7209V13.3488H20.2791V14.9302ZM24.2326 24.2326H11.7674V18.093H24.2326V24.2326Z'
        fill={color}
      />
    </svg>
  );
};

export default IconHomeButtonEvents;
