import React from 'react';

const IconSubmitionApproved = (): JSX.Element => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#EBF3D9' />
      <path
        d='M37.4125 40.875L42.625 35.7L41.05 34.125L37.4125 37.6875L35.95 36.225L34.375 37.8375L37.4125 40.875ZM20.5 24H38.5V21H20.5V24ZM38.5 45C36.425 45 34.656 44.2685 33.193 42.8055C31.73 41.3425 30.999 39.574 31 37.5C31 35.425 31.7315 33.656 33.1945 32.193C34.6575 30.73 36.426 29.999 38.5 30C40.575 30 42.344 30.7315 43.807 32.1945C45.27 33.6575 46.001 35.426 46 37.5C46 39.575 45.2685 41.344 43.8055 42.807C42.3425 44.27 40.574 45.001 38.5 45ZM16 43.5V18C16 17.175 16.294 16.4685 16.882 15.8805C17.47 15.2925 18.176 14.999 19 15H40C40.825 15 41.5315 15.294 42.1195 15.882C42.7075 16.47 43.001 17.176 43 18V28.0125C42.525 27.7875 42.0375 27.6 41.5375 27.45C41.0375 27.3 40.525 27.1875 40 27.1125V18H19V39.075H28.1125C28.2375 39.85 28.4315 40.5875 28.6945 41.2875C28.9575 41.9875 29.301 42.65 29.725 43.275L29.5 43.5L27.25 41.25L25 43.5L22.75 41.25L20.5 43.5L18.25 41.25L16 43.5ZM20.5 36H28.1125C28.1875 35.475 28.3 34.9625 28.45 34.4625C28.6 33.9625 28.7875 33.475 29.0125 33H20.5V36ZM20.5 30H31.15C32.1 29.075 33.2065 28.3435 34.4695 27.8055C35.7325 27.2675 37.076 26.999 38.5 27H20.5V30Z'
        fill='#5B7A17'
      />
    </svg>
  );
};

export default IconSubmitionApproved;
