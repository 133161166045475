import { all } from 'redux-saga/effects';

import NoticeTasks from './notice';
import StartupTasks from './startup';
import AuthTasks from './auth';
import ApplicationTasks from './application';
import EventTasks from './event';
import AccountabilityTasks from './accountability';
import UserTasks from './user';
import DocumentTasks from './document';
import ActivityTasks from './activity';
import NotificationTasks from './notification';
import DashboardTasks from './dashboard';

export default function* rootSaga(): any {
  return yield all([
    ...NoticeTasks,
    ...StartupTasks,
    ...AuthTasks,
    ...ApplicationTasks,
    ...EventTasks,
    ...AccountabilityTasks,
    ...UserTasks,
    ...DocumentTasks,
    ...ActivityTasks,
    ...NotificationTasks,
    ...DashboardTasks,
  ]);
}
