import { call, put, select } from 'redux-saga/effects';
import { iActionLogin } from '~/domain/interfaces/redux/auth/login';
import { LoginAuth } from '~/domain/usecases/auth/remote/LoginAuth';
import { makeRemoteLoginAuth } from '~/main/factories/usecases/auth/LoginAuthFactory';
import {
  loginSuccess,
  loginFailed,
} from '~/data/store/reducer/auth/actions/login';
import { History } from '~/main/routes';

export function* onLogin(action: iActionLogin) {
  const remoteLoginAuth = makeRemoteLoginAuth();

  try {
    const response: LoginAuth.Model = yield call(
      remoteLoginAuth.login,
      action.payload
    );
    yield put(
      loginSuccess({
        ...response,
      })
    );
    onLoginSuccess();
  } catch (e) {
    yield put(loginFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginSuccess() {
  History.push('/inicio');
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {}
