import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteEvent as UsecaseRemoteDeleteEvent } from '~/domain/usecases/event/remote';
// import { DeleteEvent as UsecaseReduxDeleteEvent } from '~/domain/usecases/event/redux';

import { RemoteDeleteEvent } from '~/data/repository/event';
// import { ReduxDeleteEvent } from '~/data/store/reducer/event/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteEvent = (): UsecaseRemoteDeleteEvent =>
  new RemoteDeleteEvent(makeApiUrl('/events/{event}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteEvent =
  (): UsecaseReduxDeleteEvent =>
    new ReduxDeleteEvent(); */
