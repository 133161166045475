import React from 'react';

const IconMenuBudget = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM5.2 9.6H9.6C9.70609 9.6 9.80783 9.55786 9.88284 9.48284C9.95786 9.40783 10 9.30609 10 9.2C10 9.09391 9.95786 8.99217 9.88284 8.91716C9.80783 8.84214 9.70609 8.8 9.6 8.8H6.4C5.86957 8.8 5.36086 8.58929 4.98579 8.21421C4.61071 7.83914 4.4 7.33043 4.4 6.8C4.4 6.26957 4.61071 5.76086 4.98579 5.38579C5.36086 5.01071 5.86957 4.8 6.4 4.8H7.2V3.2H8.8V4.8H10.8V6.4H6.4C6.29391 6.4 6.19217 6.44214 6.11716 6.51716C6.04214 6.59217 6 6.69391 6 6.8C6 6.90609 6.04214 7.00783 6.11716 7.08284C6.19217 7.15786 6.29391 7.2 6.4 7.2H9.6C10.1304 7.2 10.6391 7.41071 11.0142 7.78579C11.3893 8.16086 11.6 8.66957 11.6 9.2C11.6 9.73043 11.3893 10.2391 11.0142 10.6142C10.6391 10.9893 10.1304 11.2 9.6 11.2H8.8V12.8H7.2V11.2H5.2V9.6Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuBudget;
