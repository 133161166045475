import { iNotice } from '~/domain/interfaces/models/Notice';
import { NoticeTypes } from '~/domain/interfaces/redux/notice/types';
import { NoticeActions } from './actions';

export const initialState: iNotice = {
  loading: false,
  records: [],
};

const reducer = (state = initialState, action: NoticeActions): iNotice => {
  switch (action.type) {
    case NoticeTypes.LIST: {
      return { ...state, loading: true };
    }
    case NoticeTypes.LIST_FAILED:
      return { ...state, loading: false };
    case NoticeTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
