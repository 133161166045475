import { ListDeliveryFoldersDocument } from '~/domain/usecases/document/redux/ListDeliveryFoldersDocument';
import {
  iActionListDeliveryFolders,
  iActionListDeliveryFoldersFailed,
  iActionListDeliveryFoldersSuccess,
  DocumentTypes,
} from '~/domain/interfaces/redux/document/listDeliveryFolders';

export const listDeliveryFoldersRequest = (
  payload: ListDeliveryFoldersDocument.Params
): iActionListDeliveryFolders => ({
  type: DocumentTypes.LISTDELIVERYFOLDERS,
  payload,
});

export const listDeliveryFoldersSuccess = (
  params: ListDeliveryFoldersDocument.Model
): iActionListDeliveryFoldersSuccess => ({
  type: DocumentTypes.LISTDELIVERYFOLDERS_SUCCESS,
  payload: params,
});

export const listDeliveryFoldersFailed =
  (): iActionListDeliveryFoldersFailed => ({
    type: DocumentTypes.LISTDELIVERYFOLDERS_FAILED,
  });
