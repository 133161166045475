import React, { useEffect } from 'react';
import Announcement from '~/presentation/pages/announcement';
import { makeReduxListApplication } from '../../usecases/application/ListApplicationFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';

export const makeAnnouncementFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListNotice().list({});
    makeReduxListStartup().list({});
    makeReduxListApplication().list({});
  }, []);
  return <Announcement />;
};
