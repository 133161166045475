import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateAccountability as UsecaseRemoteUpdateAccountability } from '~/domain/usecases/accountability/remote';
// import { UpdateAccountability as UsecaseReduxUpdateAccountability } from '~/domain/usecases/accountability/redux';

import { RemoteUpdateAccountability } from '~/data/repository/accountability';
// import { ReduxUpdateAccountability } from '~/data/store/reducer/accountability/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateAccountability =
  (): UsecaseRemoteUpdateAccountability =>
    new RemoteUpdateAccountability(
      makeApiUrl('/accountabilities/{accountability}'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateAccountability =
  (): UsecaseReduxUpdateAccountability =>
    new ReduxUpdateAccountability(); */
