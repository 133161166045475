import { RefreshAuth } from '~/domain/usecases/auth/redux/RefreshAuth';
import {
  iActionRefresh,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/refresh';

export const refreshRequest = (
  payload: RefreshAuth.Params
): iActionRefresh => ({
  type: AuthTypes.REFRESH,
  payload,
});
