import React, { useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import Input from '../../UI/Input';
import getMask from '~/utils/getMask';
import cepPromise from 'cep-promise';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';
import UploadImage from '../../UploadImage';

interface ownProps {
  formik: any;
  onChange?: (files: File) => void;
  oldData?: { name?: string; url?: string };
}

const FormEditStartup = ({
  formik,
  onChange,
  oldData,
}: ownProps): JSX.Element => {
  const [cepResponse, setCepResponse] = useState<CepResponse | undefined>(
    undefined
  );

  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'address',
          `${cepResponseData.street + ', ' + cepResponseData.neighborhood}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };
  return (
    <Stack>
      <Stack spacing='4'>
        <Flex flexDirection={'row'} justifyContent='space-between'>
          <UploadImage onChange={onChange} oldData={oldData} />
          <Box borderLeft='1px solid #E9ECEE '></Box>

          <Flex flexDirection='column' mt='0px' gap={'20px'}>
            <Flex gap='6'>
              <Input
                id='name'
                name='name'
                label='Nome da Startup'
                required
                formik={formik}
                width='360px'
              />

              <Input
                id='cnpj'
                name='cnpj'
                label='CNPJ'
                required={false}
                placeholder='__.___.___/____-__'
                mask={getMask('cnpj')}
                formik={formik}
                width='168px'
              />
            </Flex>

            <Flex gap='6'>
              <Input
                id='address'
                name='address'
                label='Endereço da Startup'
                required
                formik={formik}
                w='360px'
              />

              <Input
                width={'168px'}
                id='cep'
                name='cep'
                label='CEP'
                required
                placeholder='_ _ _ _ _ - _ _ _'
                mask={getMask('cep')}
                formik={formik}
                onBlurCapture={handleOnBlur}
              />
            </Flex>
          </Flex>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default FormEditStartup;
