import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Button,
  HStack,
  Link,
  useDisclosure,
  Divider,
  FormControl,
  FormLabel,
  Icon,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { History } from '~/main/routes';
import {
  IconDelete,
  IconFacebook,
  IconInstagram,
  IconLogo,
  IconLogoGovPBWhite,
  IconTwitter,
  IconYoutube,
} from '~/presentation/base/icons';
import { schemaLogin } from '~/validators/Login/LoginValidator';

import back from '../../../presentation/base/images/back.png';
import Gov from '../../../presentation/base/images/gov.png';
import Input from '~/presentation/components/UI/Input';
import Form from '~/presentation/components/UI/form';
import { makeReduxLoginAuth } from '~/main/factories/usecases/auth/LoginAuthFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import ForgotPassword from '~/presentation/components/ForgotPassword';
import EditMember from '~/presentation/components/Modals/user/EditMember';
import FormEditMember from '~/presentation/components/Form/FormEditMember/FormEditMember';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import SignUpFinalization from '~/presentation/components/Modals/user/SignUpFinalization';
import axios from 'axios';
import SignUpUserConfirmationSuccess from '~/presentation/components/Modals/Confirmation/SignUpUserConfirmationSuccess';

interface ownProps {
  token?: string;
}

const Login: React.FC<ownProps> = ({ token }): JSX.Element => {
  const { initial, validators } = schemaLogin;
  const { error } = useSelector((store: iStore) => store.auth);
  const [open, setOpen] = React.useState('');

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,

    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      makeReduxLoginAuth().login({
        body: {
          email: values.login,
          password: values.password,
        },
      });
      // resetForm({ values: initial });
    },
  });

  return (
    <Flex w='100%' h='100%'>
      <Flex w='450px'>
        <Flex justify='space-between' direction='column'>
          <Flex pl={'48px'} pt={'30px'}>
            <IconLogo />
          </Flex>
          <Form onSubmit={() => formik.handleSubmit()}>
            <Stack px='12' w='100%' spacing='28' py='8'>
              <Box w='100%'>
                <Text fontSize='2xl' fontWeight='bold' mb='1'>
                  Login
                </Text>
                <Text mb='8' fontSize='xs'>
                  Faça login para acessar o sistema da incubadora do Parque
                  Tecnológico Horizontes de Inovação
                </Text>
                <Stack spacing='4'>
                  <Input
                    id='login'
                    name='login'
                    label='Nome de usuário ou E-mail'
                    formik={formik}
                  />
                  <Input
                    id='password'
                    name='password'
                    label='Senha'
                    type='password'
                    formik={formik}
                  />
                </Stack>
                <Text fontSize='xs' textAlign='end' mt='2'>
                  <ForgotPassword />
                </Text>
                {error && (
                  <Text
                    fontSize='md'
                    color='red.500'
                    mt='3'
                    mb='-2'
                    textAlign='center'
                  >
                    E-mail ou senha inválidos
                  </Text>
                )}
                <Button
                  mt='5'
                  w='100%'
                  bg='#303950'
                  type='submit'
                  color='#F9F8FA'
                  _hover={{ background: '#4B587A' }}
                  _active={{ background: '#2D3548' }}
                >
                  Entrar
                </Button>
              </Box>
            </Stack>
          </Form>
          <Box py='8' px='12' borderTop='1px solid #CFCFCF'>
            <Text fontSize='small' textAlign='center'>
              © Parque Tecnológico Horizontes de Inovação, 2022 Governo do
              Estado da Paraíba
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flex='1'
        align='center'
        justify='space-between'
        direction='column'
        backgroundImage={back}
        backgroundRepeat='no-repeat'
        backgroundSize='100% 100%'
        color='#F9F8FA'
        fontSize='4xl'
        fontWeight='bold'
        lineHeight='130%'
        position='relative'
      >
        <Box mt={'30px'}>
          {' '}
          <IconLogoGovPBWhite></IconLogoGovPBWhite>
        </Box>
        <Box textAlign='center'>
          <Text fontSize='xl' fontWeight='semibold'>
            BEM-VINDO AO
          </Text>
          <Text>Parque Tecnológico</Text>
          <Text>Horizontes de Inovação</Text>
        </Box>
        <Box
          position='relative'
          bottom='0'
          fontSize='sm'
          fontWeight='semibold'
          mb='8'
          textAlign='center'
        >
          <Text>Siga nossas redes sociais</Text>
          <HStack spacing='15'>
            <IconFacebook />
            <IconTwitter />
            <IconInstagram />
            <IconYoutube />
          </HStack>
        </Box>
      </Flex>
      {token && (
        <SignUpFinalization
          isOpen={true}
          onClose={() => setOpen('')}
          token={token}
        ></SignUpFinalization>
      )}
      <SignUpUserConfirmationSuccess></SignUpUserConfirmationSuccess>
    </Flex>
  );
};

export default Login;
