import React, { useContext } from 'react';
import WarningModal from '~/presentation/components/Modals/WarningModal';
import { makeRemoteDeleteEvent } from '~/main/factories/usecases/event/DeleteEventFactory';
import { iEvent } from '~/domain/interfaces/models/Event';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import getEventTypeString from '~/utils/getEventTypeString';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  event?: iEvent['records'][0];
}

const DeleteEvent = ({ isOpen, onClose, event }: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Excluir evento'
      question={`Tem certeza de que quer excluir a ${getEventTypeString(
        event?.type ?? ''
      )} “${event?.name}”?`}
      description='Não há como reverter esta operação.'
      action={() => {
        event &&
          makeRemoteDeleteEvent()
            .delete({ id: event.id })
            .then(() => {
              makeReduxListEvent().list({ query: { limit: 999 } });
              showConfirmModal('DELETE_EVENT');
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              onClose();
            });
      }}
      actionText='Excluir'
      actionTextLoading='Excluindo'
    />
  );
};

export default DeleteEvent;
