import { call, put, select } from 'redux-saga/effects';
import { iActionListFolderContent } from '~/domain/interfaces/redux/document/listFolderContent';
import { ListFolderContentDocument } from '~/domain/usecases/document/remote/ListFolderContentDocument';
import { makeRemoteListFolderContentDocument } from '~/main/factories/usecases/document/ListFolderContentDocumentFactory';
import {
  listFolderContentSuccess,
  listFolderContentFailed,
} from '~/data/store/reducer/document/actions/listFolderContent';

export function* onListFolderContent(action: iActionListFolderContent) {
  const remoteListFolderContentDocument = makeRemoteListFolderContentDocument();

  try {
    const response: ListFolderContentDocument.Model = yield call(
      remoteListFolderContentDocument.listFolderContent,
      action.payload
    );
    yield put(
      listFolderContentSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listFolderContentFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFolderContentSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFolderContentFailed() {}
