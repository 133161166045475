import React from 'react';

const IconMP4 = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.85 6.675V6.575H10.75H7.925V3.75V3.65H7.825H6.85H6.75V3.75V7.65V7.75H6.85H10.75H10.85V7.65V6.675ZM12.6 7C12.6 9.80477 10.3048 12.1 7.5 12.1C4.69523 12.1 2.4 9.80477 2.4 7C2.4 4.19523 4.69523 1.9 7.5 1.9C10.3048 1.9 12.6 4.19523 12.6 7ZM14.1 7C14.1 3.36977 11.1302 0.4 7.5 0.4C3.86977 0.4 0.9 3.36977 0.9 7C0.9 10.6302 3.86977 13.6 7.5 13.6C11.1302 13.6 14.1 10.6302 14.1 7Z'
        fill='#60656D'
        stroke='#60656D'
        strokeWidth='0.2'
      />
    </svg>
  );
};

export default IconMP4;
