import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
// import { RefreshAuth as UsecaseRemoteRefreshAuth } from '~/domain/usecases/auth/remote';
import { RefreshAuth as UsecaseReduxRefreshAuth } from '~/domain/usecases/auth/redux';

// import { RemoteRefreshAuth } from '~/data/repository/auth';
import { ReduxRefreshAuth } from '~/data/store/reducer/auth/usecases';

/**
 * send request via API.
 */
/* export const makeRemoteRefreshAuth =
  (): UsecaseRemoteRefreshAuth =>
    new RemoteRefreshAuth(
      makeApiUrl('/auth/refresh'),
      makeHttpClient()
    ); */

/**
 * send request via REDUX.
 */
export const makeReduxRefreshAuth = (): UsecaseReduxRefreshAuth =>
  new ReduxRefreshAuth();
