import React, { useEffect } from 'react';
import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Select,
  Text,
  ModalFooter,
} from '@chakra-ui/react';
import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';
import Stage from '../Stage';
import { iStartup } from '~/domain/interfaces/models';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const StageManager = ({ isOpen, onClose, startup }: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterMember;
  const [active, setActive] = React.useState('RECEITA');

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent minW='977px' /* minH=' 601px' */ bg='#F9F8FA'>
          <Box
            flex='1'
            h='calc(100% - 112px)'
            m='8'
            mt={0}
            paddingTop='24px'
            paddingBottom='24px'
            margin='0px'
          >
            <ModalHeader py='3' whiteSpace='nowrap' padding='0px 0px 0px 24px'>
              <Flex
                flexDirection='row'
                justifyContent={'space-between'}
                alignItems='center'
              >
                <Text
                  fontWeight='700'
                  fontFamily='Inter'
                  fontSize='18px'
                  color={'#303950'}
                >
                  Gerenciar Etapas
                </Text>
                <ModalCloseButton
                  size='md'
                  position={'inherit'}
                  marginRight='24px'
                  color={'#60656D'}
                ></ModalCloseButton>
              </Flex>
            </ModalHeader>
            <ModalBody paddingTop='22.5px' paddingBottom='20px'>
              <Stage
                isEvaluable
                stages={
                  startup?.applications?.find((el) => {
                    el.status === 'APPROVED' && el.steps.length > 0;
                  })?.steps
                }
              />
            </ModalBody>
            <ModalFooter
              display={'flex'}
              flexDir='row'
              justifyContent='space-between'
              alignItems={'center'}
            >
              <Button
                width='100px'
                bg='#F9F8FA'
                color='#60656D'
                _hover={{ background: '#E9ECEE', color: '#303950' }}
                _active={{ background: '#CECECE', color: '#303950' }}
                boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                padding='10px, 22px, 10px, 22px'
                border='1px solid #CECECE'
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='500'
                  fontSize='sm'
                >
                  Cancelar
                </Text>
              </Button>
              <Button
                width='160px'
                bg='#303950'
                color='#F9F8FA'
                _hover={{ background: '#4B587A', color: '#F9F8FA' }}
                _active={{ background: '#303950', color: '#788FC9' }}
                boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                padding='10px, 22px, 10px, 22px'
              >
                <Text
                  fontFamily='Inter'
                  fontStyle='normal'
                  fontWeight='500'
                  fontSize='sm'
                >
                  Salvar Alterações
                </Text>
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StageManager;
