import { iEvent } from '~/domain/interfaces/models/Event';
import { EventTypes } from '~/domain/interfaces/redux/event/types';
import { EventActions } from './actions';

export const initialState: iEvent = {
  loading: false,
  metadata: {
    items: 0,
    offset: 0,
    total: 0,
  },
  records: [],
};

const reducer = (state = initialState, action: EventActions): iEvent => {
  switch (action.type) {
    case EventTypes.LIST: {
      // eslint-disable-next-line no-console
      console.log('...action.payload event', action.payload);
      return { ...state, loading: true };
    }
    case EventTypes.LIST_FAILED:
      return { ...state, loading: false };
    case EventTypes.LIST_SUCCESS:
      return { ...action.payload, loading: false };

    default:
      return state;
  }
};

export default reducer;
