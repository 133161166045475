/* eslint-disable no-console */
import * as datefns from 'date-fns';

const getDate = (date?: string) => {
  if (date) {
    /* console.log(
      '>>>',
      datefns.format(new Date(date.replace('.000Z', '')), 'dd/MM/yyyy')
    );
    console.log('>>>', datefns.format(new Date(date), 'dd/MM/yyyy')); */
  }
  return date
    ? datefns.format(new Date(date.replace('.000Z', '')), 'dd/MM/yyyy')
    : '';
};

export const getDateEvent = (date?: string) => {
  return date ? datefns.format(new Date(date), 'dd/MM/yyyy') : '';
};

export const getTime = (date?: string) => {
  return date ? datefns.format(new Date(date), 'HH:mm') : '';
};

export default getDate;