import React from 'react';
import { Route, Redirect, RouterProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import History from '../History';

export interface PrivateRouteType {
  component: React.FC;
  path: string;
  exact?: boolean;
  roles?: string[];
}

export const PrivateRoute: React.FC<PrivateRouteType> = (
  props: PrivateRouteType
) => {
  const { accessToken, user } = useSelector((state: iStore) => state.auth);
  const { component, path, exact, roles } = props;

  const hasAccess = () => {
    const hAcess = roles ? roles.includes(user?.role_.name || '') : true;

    return hAcess ? (
      <Route path={path} exact={exact} component={component} />
    ) : (
      <Redirect to='/' />
    );
  };

  return accessToken ? hasAccess() : <Redirect to='/' />;
};

export default PrivateRoute;
