import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListNotification as UsecaseRemoteListNotification } from '~/domain/usecases/notification/remote';
import { ListNotification as UsecaseReduxListNotification } from '~/domain/usecases/notification/redux';

import { RemoteListNotification } from '~/data/repository/notification';
import { ReduxListNotification } from '~/data/store/reducer/notification/usecases';

/**
 * send request via API.
 */
export const makeRemoteListNotification = (): UsecaseRemoteListNotification =>
  new RemoteListNotification(makeApiUrl('/notifications/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListNotification = (): UsecaseReduxListNotification =>
  new ReduxListNotification();
