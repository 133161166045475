import React from 'react';

const IconEvaluate = () => {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.44708 12.6575L5.61119 10.8217L4.71639 11.7165L7.44708 14.4472L12.2821 9.61217L11.3873 8.71736L7.44708 12.6575Z" fill="#222222" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4147 1.56789L11.4738 1.62956H14.6422C15.0628 1.62956 15.4661 1.79663 15.7635 2.09401C16.0609 2.39139 16.228 2.79473 16.228 3.21529V18.2154C16.228 18.636 16.0609 19.0393 15.7635 19.3367C15.4661 19.6341 15.0628 19.8012 14.6422 19.8012H1.78495C1.36439 19.8012 0.961052 19.6341 0.663669 19.3367C0.366287 19.0393 0.199219 18.636 0.199219 18.2154V3.21529C0.199219 2.79473 0.366287 2.39139 0.663669 2.09401C0.961052 1.79663 1.36439 1.62956 1.78495 1.62956H4.95341L5.01247 1.56789C5.4262 1.13585 5.9231 0.79203 6.47325 0.557146C7.0234 0.322262 7.6154 0.201172 8.21359 0.201172C8.81178 0.201172 9.40378 0.322262 9.95392 0.557146C10.5041 0.79203 11.001 1.13585 11.4147 1.56789ZM5.50835 4.48673H10.9188L10.8856 4.25802C10.7925 3.6158 10.4712 3.02862 9.98045 2.60397C9.48975 2.17931 8.86252 1.94559 8.21359 1.94559C7.56465 1.94559 6.93742 2.17931 6.44672 2.60397C5.95602 3.02862 5.63468 3.6158 5.54153 4.25802L5.50835 4.48673ZM1.9421 18.0583H14.4851V3.37244H12.4775L12.536 3.61864C12.6158 3.95461 12.6562 4.29873 12.6565 4.64404V5.35817C12.6565 5.58929 12.5647 5.81094 12.4012 5.97437C12.2378 6.1378 12.0162 6.22961 11.785 6.22961H4.64212C4.411 6.22961 4.18935 6.1378 4.02592 5.97437C3.86249 5.81094 3.77068 5.58929 3.77068 5.35817V4.64404C3.77096 4.29873 3.8114 3.95461 3.89117 3.61864L3.94962 3.37244H1.9421V18.0583Z" fill="#222222" />
        </svg>
    );
};

export default IconEvaluate;
