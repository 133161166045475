import { Center, Flex, Icon, Text, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { makeReduxLogoutAuth } from '~/main/factories/usecases/auth/LogoutAuthFactory';
import { History } from '~/main/routes';
import { withAccess } from '~/presentation/hooks/acess';

interface ownProps<T> {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconHover?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  selected?: boolean;
  name: string;
  state?: T;
}

const MenuItem = <T,>({
  icon,
  iconHover,
  text,
  selected,
  name,
  state,
}: ownProps<T>): JSX.Element => {
  return (
    <Flex
      w='100%'
      h='40px'
      align='center'
      gap='10px'
      px='8'
      cursor='pointer'
      bg={selected ? '#FDFDFD' : ''}
      color={text === 'Sair' ? '#FF4C34' : selected ? '#222222' : '#E2E2E2'}
      onClick={() => {
        History.getHistory().push(name, state);
        text === 'Sair' ? makeReduxLogoutAuth().logout({}) : '';
      }}
      _hover={{
        bg: selected ? '#EDEDED' : '#484848',
        color: selected ? '#222222' : '#FDFDFD',
      }}
      _focus={{
        boxShadow: '0px 0px 15px 0px #1997F380',
        borderWidth: '2px',
        borderColor: '#1997F3',
      }}
    >
      <Center w='30px' h='30px'>
        <Icon as={selected ? iconHover : icon} />
      </Center>
      <Text fontWeight={500} fontSize='sm'>
        {text}
      </Text>
    </Flex>
  );
};

export default withAccess(MenuItem);
