import React from 'react';

const IconMenuTeam = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.56111 3.43901C3.56111 1.53893 5.10004 0 7.00012 0C8.9002 0 10.4391 1.53893 10.4391 3.43901C10.4391 5.33909 8.9002 6.87802 7.00012 6.87802C5.10004 6.87802 3.56111 5.33909 3.56111 3.43901ZM7.7623 1.59894C7.52066 1.49885 7.26167 1.44734 7.00012 1.44734C6.47189 1.44734 5.9653 1.65717 5.59179 2.03068C5.21828 2.4042 5.00844 2.91079 5.00844 3.43901C5.00844 3.96724 5.21828 4.47383 5.59179 4.84734C5.9653 5.22085 6.47189 5.43069 7.00012 5.43069C7.26167 5.43069 7.52066 5.37917 7.7623 5.27908C8.00394 5.17899 8.2235 5.03228 8.40845 4.84734C8.59339 4.6624 8.7401 4.44284 8.84019 4.20119C8.94028 3.95955 8.9918 3.70056 8.9918 3.43901C8.9918 3.17746 8.94028 2.91847 8.84019 2.67683C8.7401 2.43519 8.59339 2.21563 8.40845 2.03068C8.2235 1.84574 8.00394 1.69903 7.7623 1.59894Z'
        fill='#222222'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 11.4515C0 10.9043 0.27314 10.416 0.739288 9.98574C1.20627 9.55475 1.85877 9.19038 2.59303 8.89624C4.06174 8.30787 5.82686 8.01249 7.00012 8.01249C8.17338 8.01249 9.93849 8.30787 11.4072 8.89624C12.1415 9.19038 12.794 9.55475 13.2609 9.98574C13.7271 10.416 14.0002 10.9043 14.0002 11.4515V14.0002H0V11.4515ZM1.44734 12.5529H12.5529V11.4515C12.5529 11.3338 12.4836 11.2222 12.3965 11.1257C12.3059 11.0254 12.1777 10.9215 12.0219 10.8175C11.7096 10.609 11.2699 10.3893 10.7509 10.1891C9.71296 9.78878 8.34028 9.45983 7.00012 9.45983C5.65996 9.45983 4.28728 9.78878 3.24929 10.1891C2.73033 10.3893 2.29065 10.609 1.97836 10.8175C1.82256 10.9215 1.69438 11.0254 1.60373 11.1257C1.51661 11.2222 1.44734 11.3338 1.44734 11.4515V12.5529Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuTeam;
