import React from 'react';

const IconDeleteDefault = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#FFEFEA' />
      <path
        d='M22.75 23.25H21.5V39.5C21.5 40.163 21.7634 40.7989 22.2322 41.2678C22.7011 41.7366 23.337 42 24 42H36.5C37.163 42 37.7989 41.7366 38.2678 41.2678C38.7366 40.7989 39 40.163 39 39.5V23.25H22.75ZM27.75 38.25H25.25V27H27.75V38.25ZM35.25 38.25H32.75V27H35.25V38.25ZM36.0225 19.5L34 17H26.5L24.4775 19.5H19V22H41.5V19.5H36.0225Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconDeleteDefault;
