import { validateCNPJ } from 'validations-br';
import * as yup from 'yup';

export const schemaEditStartup = {
  initial: {
    name: '',
    cnpj: '',
    address: '',
    cep: '',
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    cnpj: yup
      .string()
      .matches(
        /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
        'CNPJ inválido'
      )
      .test('is-cnpj', 'CNPJ inválido', (cnpj) =>
        cnpj ? validateCNPJ(cnpj) : true
      ),
    address: yup.string().required('Campo obrigatório'),
    cep: yup
      .string()
      .required('Campo obrigatório')
      .matches(/(?!^0{5}-0{3}$)(^[0-9]{5}-[0-9]{3}$)/, 'CEP inválido'),
  }),
};
