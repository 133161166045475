import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateNotice as UsecaseRemoteCreateNotice } from '~/domain/usecases/notice/remote';
// import { CreateNotice as UsecaseReduxCreateNotice } from '~/domain/usecases/notice/redux';

import { RemoteCreateNotice } from '~/data/repository/notice';
// import { ReduxCreateNotice } from '~/data/store/reducer/notice/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateNotice = (): UsecaseRemoteCreateNotice =>
  new RemoteCreateNotice(makeApiUrl('/notices/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateNotice =
  (): UsecaseReduxCreateNotice =>
    new ReduxCreateNotice(); */
