import React from 'react';

const IconPDF = () => {
  return (
    <svg 
        width="24" 
        height="30" 
        viewBox="0 0 24 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path d="M22.125 8.25L15 1.125L14.625 1.5L12.75 3L10.5 7.5L15.75 10.875L22.125 9.75V8.25Z" fill="#9B3457"/>
            <path d="M23.2567 7.52344C23.4576 7.72433 23.5714 7.99553 23.5714 8.28013V28.9286C23.5714 29.5212 23.0926 30 22.5 30H1.07143C0.478795 30 0 29.5212 0 28.9286V1.07143C0 0.478795 0.478795 0 1.07143 0H15.2913C15.5759 0 15.8504 0.113839 16.0513 0.314732L23.2567 7.52344ZM21.1004 8.77232L14.7991 2.47098V8.77232H21.1004Z" fill="#C23422"/>
            <path d="M7.624 19.144C7.624 19.4427 7.552 19.7227 7.408 19.984C7.26933 20.2453 7.048 20.456 6.744 20.616C6.44533 20.776 6.06667 20.856 5.608 20.856H4.672V23H3.552V17.416H5.608C6.04 17.416 6.408 17.4907 6.712 17.64C7.016 17.7893 7.24267 17.9947 7.392 18.256C7.54667 18.5173 7.624 18.8133 7.624 19.144ZM5.56 19.952C5.86933 19.952 6.09867 19.8827 6.248 19.744C6.39733 19.6 6.472 19.4 6.472 19.144C6.472 18.6 6.168 18.328 5.56 18.328H4.672V19.952H5.56ZM10.3712 17.416C10.9579 17.416 11.4725 17.5307 11.9152 17.76C12.3632 17.9893 12.7072 18.3173 12.9472 18.744C13.1925 19.1653 13.3152 19.656 13.3152 20.216C13.3152 20.776 13.1925 21.2667 12.9472 21.688C12.7072 22.104 12.3632 22.4267 11.9152 22.656C11.4725 22.8853 10.9579 23 10.3712 23H8.41919V17.416H10.3712ZM10.3312 22.048C10.9179 22.048 11.3712 21.888 11.6912 21.568C12.0112 21.248 12.1712 20.7973 12.1712 20.216C12.1712 19.6347 12.0112 19.1813 11.6912 18.856C11.3712 18.5253 10.9179 18.36 10.3312 18.36H9.53919V22.048H10.3312ZM17.6016 17.416V18.32H15.2736V19.752H17.0576V20.64H15.2736V23H14.1536V17.416H17.6016Z" fill="#F9F8FA"/>
    </svg>

  );
};

export default IconPDF;