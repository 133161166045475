import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  HStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  IconDelete,
  IconEdit,
  IconOpenFolder,
  IconTickedSearch,
} from '~/presentation/base/icons';
import Tab from '~/presentation/components/Tab';
import getDate from '~/utils/getDate';
import RevenueEvaluation from '~/presentation/components/RevenueEvaluation';
import RegisterInform from '~/presentation/components/Modals/accountability/RegisterInform';
import { useSelector } from 'react-redux';
import { iAccountability, iStore } from '~/domain/interfaces/models';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import EvaluateInform from '~/presentation/components/EvaluateInform';
import EditInform from '~/presentation/components/Modals/accountability/EditInform';
import RegisterInformSuccess from '~/presentation/components/Modals/Confirmation/RegisterInformSuccess ';
import EditInformSuccess from '~/presentation/components/Modals/Confirmation/EditInformSuccess ';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import SubmitionInformRejected from '~/presentation/components/Modals/Confirmation/SubmitionInformRejected';
import SubmitionInformApproved from '~/presentation/components/Modals/Confirmation/SubmitionInformApproved';
import { makeRemoteListFolderContentDocument } from '~/main/factories/usecases/document/ListFolderContentDocumentFactory';
import { History } from '~/main/routes';

interface PartialiAccountability {
  code: string;
  period: string;
  convertedStatus: 'pendente' | 'aprovado' | 'rejeitado';
  value: string;
  noticeTitle: string;
}

const Accountability: React.FC = (): JSX.Element => {
  const [active, setActive] = React.useState('RECEITAS');
  const [open, setOpen] = React.useState('');
  const [canRegister, setCanRegister] = React.useState(false);
  const { records } = useSelector((store: iStore) => store.application);
  const accountabilities = useSelector(
    (store: iStore) => store.accountability.records
  );
  const { startups, user } = useSelector((store: iStore) => store.auth);
  const [, setNotices] = React.useState<string[]>([]);
  const [selectedAccountability, setSelectedAccountability] = React.useState<
    iAccountability['records'][0] | undefined
  >(undefined);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const openRegisterInform = () => {
    setOpen('REGISTER');
  };

  // Map for status filtering
  const statusMap: {
    [key: string]: string;
  } = {
    APPROVED: 'aprovado',
    REJECTED: 'rejeitado',
    PENDING: 'pendente',
  };

  useEffect(() => {
    if (
      user?.role_.name === 'LEADER' &&
      startups &&
      records.find(
        (item) =>
          item.startup_?.id === startups[0].id && item.status === 'APPROVED'
      )
    ) {
      setCanRegister(true);
    }
  }, [startups, records]);

  useEffect(() => {
    const _notices: string[] = [];

    records.forEach((item: any) => {
      if (!_notices.includes(item.description)) {
        _notices.push(item.description);
      }
    });

    setNotices(_notices);
  }, [records]);

  return (
    <Box flex='1' h='calc(100% - 112px)' m='8' mt={0}>
      <Tab
        active={active}
        setActive={setActive}
        tabs={[{ title: 'RECEITAS' }, { title: 'DESPESAS' }]}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        actions={['ADMIN', 'LEADER']}
        resourcers={['ADMIN', 'LEADER']}
        action={() => setOpen('REGISTER')}
        canRegister={canRegister}
        buttonText='Novo Informe'
        searchPlaceholder='Pesquisar informes...'
        showDownloadReportButton
      />
      {active === 'RECEITAS' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          maxHeight='calc(100% - 190px)'
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead
              position='sticky'
              top={0}
              zIndex={5}
              backgroundColor='white-3'
              height='50px'
            >
              <Tr>
                <Th fontSize='sm' color={'#60656D'}>
                  NÚMERO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  PERÍODO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  EDITAL ASSOCIADO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  TOTAL DE RECEITA
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  AVALIAÇÃO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  AÇÕES
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {accountabilities
                ?.filter((element) => element.type === 'INCOME')
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else {
                    const filteredObject: PartialiAccountability = {
                      code: `${item?.code}`.toLowerCase(),
                      period:
                        `${getDate(item?.begin)}` +
                        ' até\n' +
                        `${getDate(item?.end)}`,
                      convertedStatus: `${
                        statusMap[item?.status]
                      }`.toLowerCase() as 'pendente' | 'aprovado' | 'rejeitado',
                      value: item.value.toString(),
                      noticeTitle: `${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.code
                      } - ${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.title
                      }`,
                    };

                    return (
                      filteredObject &&
                      Object.values(filteredObject).some((value) => {
                        return value
                          .toString()
                          .includes(filterByNameValue.toLowerCase());
                      })
                    );
                  }
                })
                .map((item, index) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td>
                      <Text>{item?.code}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {' '}
                        {`${getDate(item?.begin)}` +
                          ' até\n' +
                          `${getDate(item?.end)}`}
                      </Text>
                    </Td>
                    <Td>
                      {`${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.code
                      } - ${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.title
                      }`}
                    </Td>
                    <Td>{`R$ ${item?.value}`}</Td>
                    <Td>
                      <RevenueEvaluation status={item?.status} isHilighted>
                        {item?.status === 'APPROVED'
                          ? 'Aprovado'
                          : item?.status === 'REJECTED'
                          ? 'Reprovado'
                          : 'Em aberto'}
                      </RevenueEvaluation>
                    </Td>
                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            item?.folder_?.parent &&
                              makeRemoteListFolderContentDocument()
                                .listFolderContent({
                                  id: item?.folder_?.parent,
                                })
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                .then((res) => {
                                  History.getHistory().push('/documentos', {
                                    path: [
                                      { name: 'STARTUPS', id: 0 },
                                      {
                                        name: 'Prestação de Contas',
                                        id: item?.folder_?.parent,
                                      },
                                      {
                                        name: item?.folder_?.name,
                                        id: item?.folder_?.id,
                                      },
                                    ],
                                  });
                                });
                          }}
                        >
                          <IconOpenFolder />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'LEADER']}
                          resourcers={['ADMIN', 'LEADER']}
                          onClick={() => {
                            setOpen('EDIT'), setSelectedAccountability(item);
                          }}
                          disabled={
                            !(
                              user?.role_.name === 'MANAGER' &&
                              startups?.some(
                                (startup) => startup.id === item.startup_?.id
                              )
                            )
                          }
                        >
                          <IconEdit />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'SUPERVISOR']}
                          resourcers={['ADMIN', 'SUPERVISOR']}
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedAccountability(item);
                          }}
                        >
                          <IconTickedSearch />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'LEADER']}
                          resourcers={['ADMIN', 'LEADER']}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {active === 'DESPESAS' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead backgroundColor='white-3' height='50px'>
              <Tr>
                <Th fontSize='sm' color={'#60656D'}>
                  NÚMERO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  PERÍODO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  EDITAL ASSOCIADO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  TOTAL DE GASTOS
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  AVALIAÇÃO
                </Th>
                <Th fontSize='sm' color={'#60656D'}>
                  AÇÕES
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {accountabilities
                ?.filter((element) => element.type === 'EXPENSE')
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else {
                    const filteredObject: PartialiAccountability = {
                      code: `${item?.code}`.toLowerCase(),
                      period:
                        `${getDate(item?.begin)}` +
                        ' até\n' +
                        `${getDate(item?.end)}`,
                      convertedStatus: `${
                        statusMap[item?.status]
                      }`.toLowerCase() as 'pendente' | 'aprovado' | 'rejeitado',
                      value: item.value.toString(),
                      noticeTitle: `${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.code
                      } - ${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.title
                      }`,
                    };

                    return (
                      filteredObject &&
                      Object.values(filteredObject).some((value) => {
                        return value
                          .toString()
                          .includes(filterByNameValue.toLowerCase());
                      })
                    );
                  }
                })
                .map((item: any, index: any) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td>
                      <Text>{item?.code}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {' '}
                        {`${getDate(item?.begin)}` +
                          ' até\n' +
                          `${getDate(item?.end)}`}
                      </Text>
                    </Td>
                    <Td>
                      {`${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.code
                      } - ${
                        records?.find(
                          (element) => element.notice_?.id === item.notice
                        )?.notice_?.title
                      }`}
                    </Td>
                    <Td>{`R$ ${item?.value}`}</Td>
                    <Td>
                      <RevenueEvaluation status={item?.status} isHilighted>
                        {item?.status === 'APPROVED'
                          ? 'Aprovado'
                          : item?.status === 'REJECTED'
                          ? 'Reprovado'
                          : 'Em aberto'}
                      </RevenueEvaluation>
                    </Td>
                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            setOpen('DOCUMENTS'),
                              setSelectedAccountability(item);
                          }}
                        >
                          <IconOpenFolder />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'LEADER']}
                          resourcers={['ADMIN', 'LEADER']}
                          onClick={() => {
                            setOpen('EDIT'), setSelectedAccountability(item);
                          }}
                        >
                          <IconEdit />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'SUPERVISOR']}
                          resourcers={['ADMIN', 'SUPERVISOR']}
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedAccountability(item);
                          }}
                        >
                          <IconTickedSearch />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'LEADER']}
                          resourcers={['ADMIN', 'LEADER']}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <RegisterInform
        isOpen={open === 'REGISTER'}
        onClose={() => setOpen('')}
      />
      <EvaluateInform
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        accountability={selectedAccountability}
      />
      <EditInform
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        accountability={selectedAccountability}
      ></EditInform>
      <RegisterInformSuccess onOpenRegister={openRegisterInform} />
      <EditInformSuccess></EditInformSuccess>
      <SubmitionInformApproved></SubmitionInformApproved>
      <SubmitionInformRejected></SubmitionInformRejected>
      <SubmitionApplicationError></SubmitionApplicationError>
    </Box>
  );
};

export default Accountability;
