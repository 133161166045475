import React from 'react';

const IconRegister = (): JSX.Element => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.08811 11.9001L7.11033 11.9001L7.11033 7.01121H11.9992V4.98899H7.11033L7.11033 0.100098H5.08811V4.98899H0.199219V7.01121H5.08811V11.9001Z" fill="#FDFDFD" />
    </svg>
  );
};

export default IconRegister;
