import React from 'react';

const IconMenuDocs = (): JSX.Element => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.92435 0.937733L7.76343 3.02446H13.5603C13.905 3.02446 14.2356 3.16238 14.4793 3.40788C14.7231 3.65338 14.86 3.98635 14.86 4.33353V12.2543C14.8597 12.5849 14.7291 12.9018 14.4971 13.1355C14.265 13.3692 13.9504 13.5007 13.6222 13.501H1.25666C0.923528 13.5005 0.604192 13.367 0.368634 13.1297C0.133094 12.8925 0.000533181 12.5709 0 12.2354V1.80761C0 1.46042 0.136933 1.12746 0.380674 0.881958C0.624416 0.63646 0.955 0.49854 1.2997 0.49854L4.94957 0.49854C5.13367 0.498038 5.31577 0.536932 5.48381 0.612647C5.65186 0.688361 5.80202 0.799169 5.92435 0.937733ZM4.86732 1.99405H1.48481V3.02446H5.77698L4.86732 1.99405ZM1.48481 12.0055H13.3752V4.51997H1.48481V12.0055Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuDocs;
