import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetActivity as UsecaseRemoteGetActivity } from '~/domain/usecases/activity/remote';
import { GetActivity as UsecaseReduxGetActivity } from '~/domain/usecases/activity/redux';

import { RemoteGetActivity } from '~/data/repository/activity';
import { ReduxGetActivity } from '~/data/store/reducer/activity/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetActivity = (): UsecaseRemoteGetActivity =>
  new RemoteGetActivity(makeApiUrl('/activities/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetActivity = (): UsecaseReduxGetActivity =>
  new ReduxGetActivity();
