import React from 'react';
import * as yup from 'yup';

export const schemaResetPassword = {
  initial: {
    email: '',
  },
  validators: yup.object({
    email: yup.string().required('Campo obrigatório').email('Email inválido'),
  }),
};
