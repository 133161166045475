import { DeleteNotice } from '~/domain/usecases/notice/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDeleteNotice implements DeleteNotice {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteNotice.Model>;

  constructor(url: string, httClient: HttpClient<DeleteNotice.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (params: DeleteNotice.Params): Promise<DeleteNotice.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{notice}', params.id.toString()),
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
