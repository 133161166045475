import { Flex, Text, Link, Box } from '@chakra-ui/react';
import { clamp } from 'date-fns';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { withAccess } from '~/presentation/hooks/acess';

interface ownProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  to: string;
}

const HomeButton = ({ title, description, icon, to }: ownProps) => {
  return (
    <Link
      border=' 1px solid #CECECE'
      borderRadius='5px'
      background='#FDFDFD'
      _hover={{ background: '#EDEDED', borderColor: '#9C9C9C' }}
      _focus={{
        border: '2px',
        borderColor: '#1997F3',
        boxShadow: '0px 0px 15px 0px #1997F380',
      }}
      h='105px'
      p='20px'
      as={ReachLink}
      to={to}
      display='flex'
      justifyContent='center'
    >
      <Flex gap={{base:'16px', sm:'20px'}}>
        <Flex maxW='36px' maxH='36'>
          {icon}
        </Flex>
        <Box fontSize='14px' minW='234px' maxW='254' maxH='65px'>
          <Text color='#222222' fontWeight={600} mb='1'>
            {title}
          </Text>
          <Text color='#484848'>{description}</Text>
        </Box>
      </Flex>
    </Link>
  );
};

export default withAccess(HomeButton);
