import React, { useContext, useEffect } from 'react';
import {
  Button,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  ModalFooter,
} from '@chakra-ui/react';
import StringMask from '~/utils/getStringMask';
import { useFormik } from 'formik';
import { schemaEditStartup } from '~/validators/EditStartup/EditStartupValidator';
import { iStartup, iStore } from '~/domain/interfaces/models';
import FormEditStartup from '~/presentation/components/Form/FormEditStartup/FormEditStartup';
import { makeRemoteUpdateStartup } from '~/main/factories/usecases/startup/UpdateStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const EditStartup = ({ isOpen, onClose, startup }: ownProps): JSX.Element => {
  const { initial, validators } = schemaEditStartup;

  useEffect(() => {
    if (startup) {
      const { street } = startup.address;

      formik.setValues({
        ...formik.values,
        name: startup.name,
        cnpj: new StringMask('00.000.000/0000-00').apply(startup.cnpj),
        address: street,
        cep: startup.address.zipcode,
      });
    }
  }, [startup, isOpen]);

  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<File | null>(null);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      startup &&
        makeRemoteUpdateStartup()
          .update({
            id: startup?.id,
            body: {
              name: values.name,
              fullname: values.name,
              description: 'string',
              supervisor: startup.supervisor, // startup.supervisor.id
              cnpj: values.cnpj.replace(/[./-]/g, ''),
              address: {
                type: 'APT',
                zipcode: values.cep,
                province: 'PB',
                city: 'João Pessoa',
                country: 'BRA',
                neighborhood: 'Mangabeira',
                street: values.address,
                number: '120',
                complement: 'CI',
              },
            },
          })
          .then(async () => {
            if (image !== null) {
              const formData = new FormData();
              formData.append('file', image);

              await axios.post(
                makeApiUrl(`/startups/${startup?.id}/logo`),
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
            }
            makeReduxListStartup().list({});
            showConfirmModal('EDIT_STARTUP');
          })
          .catch(() => {
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            onCloseModal();
            setIsLoading(false);
          });
    },
  });

  const onCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onCloseModal();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW='786px' bg='#F9F8FA' paddingTop={'24px'}>
          <ModalHeader
            fontSize='24px'
            py='3'
            whiteSpace='nowrap'
            padding='0px 0px 0px 24px'
          >
            <Flex
              flexDirection='row'
              justifyContent={'space-between'}
              alignItems='center'
              paddingBottom={'22.5px'}
            >
              <Text fontWeight='700' fontSize='18px' color={'#303950'}>
                Editar Startup
              </Text>
              <ModalCloseButton
                size='md'
                position={'inherit'}
                marginRight='24px'
                color={'#60656D'}
              ></ModalCloseButton>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex flexDir={'column'} justify='space-between' gap={15}>
              <FormEditStartup
                formik={formik}
                onChange={setImage}
                oldData={{ name: startup?.fullname, url: startup?.logo }}
              />
            </Flex>
            <Box borderTop='1px solid #E9ECEE ' marginTop={'20px'}></Box>
          </ModalBody>

          <ModalFooter
            display={'flex'}
            flexDir='row'
            justifyContent='space-between'
            alignItems={'center'}
            paddingTop='12px'
            paddingBottom='24px'
          >
            <Button
              width='100px'
              bg='#F9F8FA'
              color='#60656D'
              _hover={{ background: '#E9ECEE', color: '#303950' }}
              _active={{ background: '#CECECE', color: '#303950' }}
              boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
              padding='10px, 22px, 10px, 22px'
              border='1px solid #CECECE'
              onClick={() => {
                onCloseModal();
              }}
              fontWeight='500'
              fontSize='sm'
            >
              Cancelar
            </Button>
            {
              <Button
                bg='#303950'
                color='#F9F8FA'
                _hover={{ background: '#4B587A', color: '#F9F8FA' }}
                _active={{ background: '#303950', color: '#788FC9' }}
                boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                padding='10px, 22px, 10px, 22px'
                isLoading={isLoading}
                loadingText='Salvando alterações'
                fontWeight='500'
                fontSize='14px'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Salvar Alterações
              </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditStartup;
