import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListDeliveryFoldersDocument as UsecaseRemoteListDeliveryFoldersDocument } from '~/domain/usecases/document/remote';
import { ListDeliveryFoldersDocument as UsecaseReduxListDeliveryFoldersDocument } from '~/domain/usecases/document/redux';

import { RemoteListDeliveryFoldersDocument } from '~/data/repository/document';
import { ReduxListDeliveryFoldersDocument } from '~/data/store/reducer/document/usecases';

/**
 * send request via API.
 */
export const makeRemoteListDeliveryFoldersDocument =
  (): UsecaseRemoteListDeliveryFoldersDocument =>
    new RemoteListDeliveryFoldersDocument(
      makeApiUrl('/documents/deliveries/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListDeliveryFoldersDocument =
  (): UsecaseReduxListDeliveryFoldersDocument =>
    new ReduxListDeliveryFoldersDocument();
