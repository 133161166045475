import React from 'react';

const IconCamera = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.88889 12.8889C10 12.8889 10.9446 12.5001 11.7227 11.7227C12.5001 10.9446 12.8889 10 12.8889 8.88889C12.8889 7.77778 12.5001 6.83319 11.7227 6.05511C10.9446 5.27763 10 4.88889 8.88889 4.88889C7.77778 4.88889 6.83319 5.27763 6.05511 6.05511C5.27763 6.83319 4.88889 7.77778 4.88889 8.88889C4.88889 10 5.27763 10.9446 6.05511 11.7227C6.83319 12.5001 7.77778 12.8889 8.88889 12.8889ZM1.77778 16C1.28889 16 0.870519 15.8261 0.522667 15.4782C0.174222 15.1298 0 14.7111 0 14.2222V3.55556C0 3.06667 0.174222 2.6483 0.522667 2.30044C0.870519 1.952 1.28889 1.77778 1.77778 1.77778H4.57778L5.68889 0.577778C5.85185 0.385185 6.048 0.240593 6.27733 0.144C6.50726 0.0479999 6.74815 0 7 0H10.7778C11.0296 0 11.2705 0.0479999 11.5004 0.144C11.7298 0.240593 11.9259 0.385185 12.0889 0.577778L13.2 1.77778H16C16.4889 1.77778 16.9076 1.952 17.256 2.30044C17.6039 2.6483 17.7778 3.06667 17.7778 3.55556V14.2222C17.7778 14.7111 17.6039 15.1298 17.256 15.4782C16.9076 15.8261 16.4889 16 16 16H1.77778Z" fill="#60656D" />
    </svg>

  );
};

export default IconCamera;
