import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Avatar,
  Box,
  Text,
  Flex,
  useBreakpointValue,
  extendTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  VStack,
  Stack,
  Grid,
} from '@chakra-ui/react';

import { IconDelete, IconEdit, IconView } from '~/presentation/base/icons';
import { useSelector } from 'react-redux';
import { iStartup, iStore } from '~/domain/interfaces/models';
import StageManager from '~/presentation/components/StageManager';
import DeleteStartup from '~/presentation/components/Modals/startup/DeleteStartup';
import DeactivateStartup from '~/presentation/components/Modals/startup/DeactivateStartup';
import Tab from '~/presentation/components/Tab';
import RegisterStartup from '~/presentation/components/Modals/startup/RegisterStartup';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import DetailsStartup from '~/presentation/components/Modals/startup/DetailsStartup';
import RegisterStartupSucess from '~/presentation/components/Modals/Confirmation/RegisterStartupSucess';
import EditStartupSucess from '~/presentation/components/Modals/Confirmation/EditStartupSucess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeleteStartupSuccess from '~/presentation/components/Modals/Confirmation/DeleteStartupSuccess';
import IconEvaluate from '~/presentation/base/icons/iconEvaluate';
import ConflictError from '~/presentation/components/Modals/Confirmation/ConflictError';
import EditStartup from '~/presentation/components/Modals/startup/EditStartup';
import IconMoreOptions from '~/presentation/base/icons/iconMoreOptions';

const Startup: React.FC = (): JSX.Element => {
  const [open, setOpen] = React.useState('');
  const openRegisterStartup = () => {
    setOpen('REGISTER')
  };
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: true,
    xl: false,
  });
  const isTablet = useBreakpointValue({
    base: false,
    md: true,
    lg: true,
    xl: false,
  });
  const isLargeScreen = useBreakpointValue({ base: false, xxl: true });
  const [selectedStartup, setSelectedStartup] = React.useState<
    iStartup['records'][0] | undefined
  >(undefined);
  const [notices, setNotices] = React.useState<string[]>([]);
  const [selectedNotice, setSelectedNotice] = React.useState<string>('All');
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const { records } = useSelector((store: iStore) => store.startup);
  const adm = true;
  // eslint-disable-next-line no-console
  console.log('... path', window.location);

  useEffect(() => {
    const _notices: string[] = [];

    records.forEach((item: iStartup['records'][0]) => {
      const n = item.applications.find(
        (el) => el.status === 'APPROVED'
      )?.notice_;
      if (n && !_notices.includes(n.code)) {
        _notices.push(n.code);
      }
    });

    setNotices(_notices);
  }, [records]);

  const hasStep = (item: iStartup['records'][0]) => {
    return !(
      item?.applications?.find((el) => {
        el.status === 'APPROVED' && el.steps.length > 0;
      }) !== undefined
    );
  };

  return (
    <Box
      flex='1'
      my='6'
      mx={{ base: 'auto', md: '8' }}
      h='calc(100% - 136px)'
      position='relative'
    >
      <Tab
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        setOpen={setOpen}
        buttonText={'Nova Startup'}
        resourcers={['ADMIN', 'SUPERVISOR']}
        actions={['ADMIN', 'SUPERVISOR']}
        action={() => setOpen('REGISTER')}
        searchPlaceholder='Pesquisar startups...'
      ></Tab>
      <TableContainer
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        maxHeight={{ base: 'calc(100vh - 15px)', md: 'calc(100% - 190px)' }}
        overflowY='auto'
      >
        <Table variant='simple' colorScheme='white'>
          <Thead
            position='sticky'
            top={0}
            zIndex={5}
            backgroundColor='white-3'
            height='50px'
          >
            <Tr>
              <Th fontSize='sm' textTransform={'capitalize'}>
                Nome da Startup
              </Th>
              <Th fontSize='sm' textTransform={'capitalize'}>
                Edital Associado
              </Th>
              <Th fontSize='sm' textTransform={'capitalize'}>
                Etapa Atual
              </Th>
              {!isMobile && (
                <Th fontSize='sm' textTransform='capitalize'>
                  Situação
                </Th>
              )}
              {isLargeScreen && (
                <Th fontSize='sm' textTransform='capitalize'>
                  Criado em
                </Th>
              )}
              <Th
                fontSize='sm'
                textTransform={'capitalize'}
                w={{ lg: '0px', xl: '0px' }}
              >
                Ações
              </Th>
            </Tr>
          </Thead>

          <Tbody overflowX='scroll' maxH={200}>
            {records
              .filter((item) => {
                const n = item.applications.find(
                  (el) => el.status === 'APPROVED'
                )?.notice_?.code;

                if (
                  selectedNotice === 'All' &&
                  filterByNameValue.trim() === ''
                ) {
                  return true;
                } else if (filterByNameValue.trim() !== '') {
                  return (
                    item.name
                      .toLowerCase()
                      .search(filterByNameValue.toLowerCase()) !== -1
                  );
                } else {
                  return n === selectedNotice;
                }
              })
              .map((item, index: any) => (
                <Tr fontSize='sm' color='#747C86' key={index}>
                  <Td>
                    <HStack>
                      <Avatar
                        name={item?.name}
                        src={item?.logo}
                        style={{ borderRadius: '5px' }}
                      />
                      <Box fontSize='sm'>
                        <Text fontWeight='semibold'>{item?.name}</Text>
                        <Text>
                          {item?.address.city} - {item?.address.province}
                        </Text>
                      </Box>
                    </HStack>
                  </Td>
                  {/* <Td>
                    <HStack spacing='4'>
                      <Avatar name={item?.fullname} />
                      <Box fontSize='sm'>
                        <Text fontWeight='semibold'>{item?.fullname}</Text>
                        <Text fontSize='xs'>e-mail@gmail.com</Text>
                      </Box>
                    </HStack>
                  </Td> */}
                  <Td>
                    <Text
                      style={{
                        color: item.applications.find(
                          (el) => el.status === 'APPROVED'
                        )?.notice_?.code
                          ? 'inherit'
                          : '#9C9C9C',
                      }}
                    >
                      {item.applications.find((el) => el.status === 'APPROVED')
                        ?.notice_?.code || 'Não está associado'}
                    </Text>
                  </Td>
                  <Td>
                    <Text style={{ color: '#9C9C9C' }}>
                      {item.applications.find((el) => el.status === 'APPROVED')
                        ?.steps[0]?.name || 'Não foi incubado'}
                    </Text>
                  </Td>
                  {!isMobile && <Td>Situação</Td>}
                  <Td>
                    {!isTablet && (
                      <HStack spacing='4'>
                        <ActionButton
                          tooltip='Ver detalhes'
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedStartup(item);
                          }}
                        >
                          <IconView />
                        </ActionButton>
                        <ActionButton tooltip='Avaliar desempenho' disabled>
                          <IconEvaluate />
                        </ActionButton>
                        {/* <ActionButton
                        onClick={() => {
                          setOpen('STAGE'), setSelectedStartup(item);
                        }}
                        disabled={hasStep(item)}
                      >
                        <IconStage />
                      </ActionButton> */}
                        <ActionButton
                          tooltip='Editar'
                          actions={['ADMIN', 'SUPERVISOR']}
                          resourcers={['ADMIN', 'SUPERVISOR']}
                          onClick={() => {
                            setOpen('EDIT'), setSelectedStartup(item);
                          }}
                        >
                          <IconEdit />
                        </ActionButton>
                        {/* <ActionButton
                        actions={['ADMIN', 'SUPERVISOR']}
                        resourcers={['ADMIN', 'SUPERVISOR']}
                        onClick={() => setOpen('DEACTIVATE')}
                      >
                        <IconDisconnect />
                      </ActionButton> */}
                        <ActionButton
                          tooltip='Remover'
                          actions={['ADMIN', 'SUPERVISOR']}
                          resourcers={['ADMIN', 'SUPERVISOR']}
                          onClick={() => {
                            setOpen('DELETE'), setSelectedStartup(item);
                          }}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    )}
                    {isTablet && (
                      <Popover placement='bottom-end'>
                        <PopoverTrigger>
                          <Flex
                            h='100%'
                            px='4'
                            py='3'
                            gap='4'
                            alignItems='center'
                          >
                            <Box
                              w='calc(100% - 40px)'
                              color='#60656D'
                              fontSize='md'
                            >
                              <ActionButton tooltip='Mais Opções'>
                                <IconMoreOptions />
                              </ActionButton>
                            </Box>
                          </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                          bg='white'
                          w='160px'
                          fontSize='sm'
                          lineHeight='140%'
                        >
                          <PopoverBody
                            p='0'
                            border='1px solid #CECECE'
                            borderRadius='5px'
                          >
                            <Grid>
                              <ActionButton
                                w={'160px'}
                                borderRadius={'0px'}
                                borderTopRadius={'5px'}
                                p={'15px'}
                                justifyContent='flex-start'
                                onClick={() => {
                                  setOpen('DETAILS'), setSelectedStartup(item);
                                }}
                              >
                                <IconView />{' '}
                                <Text
                                  ml='10px'
                                  color='#222222'
                                  fontWeight={'500'}
                                >
                                  Ver detalhes
                                </Text>
                              </ActionButton>
                              <ActionButton
                                w={'160px'}
                                borderRadius={'0px'}
                                justifyContent='flex-start'
                                p={'15px'}
                                disabled
                              >
                                <IconEvaluate />{' '}
                                <Text
                                  ml='10px'
                                  color='#222222'
                                  fontWeight={'500'}
                                >
                                  Avaliar
                                </Text>
                              </ActionButton>
                              <ActionButton
                                w={'160px'}
                                borderRadius={'0px'}
                                justifyContent='flex-start'
                                p={'15px'}
                                actions={['ADMIN', 'SUPERVISOR']}
                                resourcers={['ADMIN', 'SUPERVISOR']}
                                onClick={() => {
                                  setOpen('EDIT'), setSelectedStartup(item);
                                }}
                              >
                                <IconEdit />{' '}
                                <Text
                                  ml='10px'
                                  color='#222222'
                                  fontWeight={'500'}
                                >
                                  Editar
                                </Text>
                              </ActionButton>
                              <ActionButton
                                w={'160px'}
                                borderRadius={'0px'}
                                borderBottomRadius={'5px'}
                                justifyContent='flex-start'
                                p={'15px'}
                                actions={['ADMIN', 'SUPERVISOR']}
                                resourcers={['ADMIN', 'SUPERVISOR']}
                                onClick={() => {
                                  setOpen('DELETE'), setSelectedStartup(item);
                                }}
                              >
                                <IconDelete />{' '}
                                <Text
                                  ml='10px'
                                  color='#C23422'
                                  fontWeight={'500'}
                                >
                                  Excluir
                                </Text>
                              </ActionButton>
                            </Grid>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <RegisterStartup
        isOpen={open === 'REGISTER'}
        onClose={() => setOpen('')}
      />
      <StageManager
        isOpen={open === 'STAGE'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></StageManager>

      <DeleteStartup
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
        id={selectedStartup?.id}
      ></DeleteStartup>

      <DeactivateStartup
        isOpen={open === 'DEACTIVATE'}
        onClose={() => setOpen('')}
      ></DeactivateStartup>

      <EditStartup
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></EditStartup>

      <DetailsStartup
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        startup={selectedStartup}
      ></DetailsStartup>

      <RegisterStartupSucess
        onOpenRegister={openRegisterStartup}
      />
      <EditStartupSucess></EditStartupSucess>
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteStartupSuccess startup={selectedStartup}></DeleteStartupSuccess>
      <ConflictError customText='Já existe uma startup associada a este e-mail'></ConflictError>
      {/* <RegisterNoticeSucess />  Usando para teste de editais e outros.*/}
    </Box>
  );
};

export default Startup;
