import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteFile as UsecaseRemoteDeleteFile } from '~/domain/usecases/file/remote';
// import { DeleteFile as UsecaseReduxDeleteFile } from '~/domain/usecases/file/redux';

import { RemoteDeleteFile } from '~/data/repository/file';
// import { ReduxDeleteFile } from '~/data/store/reducer/file/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteFile = (): UsecaseRemoteDeleteFile =>
  new RemoteDeleteFile(makeApiUrl('/files/{file}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteFile =
  (): UsecaseReduxDeleteFile =>
    new ReduxDeleteFile(); */
