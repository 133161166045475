import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import IconRegisterStartup from '~/presentation/base/icons/iconRegisterStartup';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconUserRegistered } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const SignUpUserConfirmationSuccess = ({ name, fullname }: ownProps) => {
  const { active, data } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'SIGN_UP_USER_CONFIRMATION'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconUserRegistered />}
      showTopButton={false}
      buttonText='Entendi'
      title='Usuário(a) cadastrado!'
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5' text-align={'center'}>
            <Text>
              O(A) usuário(a) {data?.fullname} foi cadastrado{' '}
              <Text display='inline' color='#E74D39'>
                *
              </Text>{' '}
              com sucesso.
            </Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default SignUpUserConfirmationSuccess;
