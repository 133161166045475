import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateStartup as UsecaseRemoteCreateStartup } from '~/domain/usecases/startup/remote';
// import { CreateStartup as UsecaseReduxCreateStartup } from '~/domain/usecases/startup/redux';

import { RemoteCreateStartup } from '~/data/repository/startup';
// import { ReduxCreateStartup } from '~/data/store/reducer/startup/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateStartup = (): UsecaseRemoteCreateStartup =>
  new RemoteCreateStartup(makeApiUrl('/startups/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateStartup =
  (): UsecaseReduxCreateStartup =>
    new ReduxCreateStartup(); */
