const getDashboardActivitiesColors = (short: string) => {
  switch (short) {
    case 'EDUCATION':
      return '#0873F1';
    case 'FOOD_AND_DRINKS':
      return '#FCBD5F';
    case 'INFORMATION_TECHNOLOGY':
      return '#1997F3';
    case 'HEALTH_AND_WELLNESS':
      return '#FB6FA1';
    case 'MANAGEMENT':
      return '#AC38A8';
    case 'EVENTS_AND_TOURISM':
      return '#D53520';
    case 'AGRIBUSINESS':
      return '#0152B0';
    case 'MARKETING':
      return '#DE6D04';
    case 'COMMERCE_AND_RETAIL':
      return '#E981D8';
    case 'ENVIRONMENT':
      return '#8BC314';
    case 'CREATIVE_ECONOMY':
      return '#FF4C34';
    default:
      return '#000';
  }
};

export default getDashboardActivitiesColors;
