import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ConfirmUser as UsecaseRemoteConfirmUser } from '~/domain/usecases/user/remote';
// import { ConfirmUser as UsecaseReduxConfirmUser } from '~/domain/usecases/user/redux';

import { RemoteConfirmUser } from '~/data/repository/user';
// import { ReduxConfirmUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteConfirmUser = (): UsecaseRemoteConfirmUser =>
  new RemoteConfirmUser(makeApiUrl('/users/confirmation/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxConfirmUser =
  (): UsecaseReduxConfirmUser =>
    new ReduxConfirmUser(); */
