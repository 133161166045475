import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Text,
  ModalFooter,
  HStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FormRegisterEvent from '../../../Form/RegisterEvent/FormRegisterEvent';
import {
  mainSchema,
  schemaRegisterEvent,
} from '~/validators/RegisterEvent/RegisterEventValidator';
import { makeRemoteCreateEvent } from '~/main/factories/usecases/event/CreateEventFactory';
import getTypeEvent from '~/utils/getTypeEvent';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxListEvent } from '~/main/factories/usecases/event/ListEventFactory';
import TypeEvent from './TypeEvent';
import { withAccess } from '~/presentation/hooks/acess';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { CreateEvent } from '~/domain/usecases/event/remote/CreateEvent';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const InfoEvent = withAccess(() => {
  return (
    <Stack maxW='322px'>
      <Text fontWeight='semibold' fontSize='sm' mb='1'>
        É importante lembrar que:
      </Text>
      <UnorderedList pl='4' color='#747C86' fontSize='sm' spacing={15}>
        <ListItem>
          Não há como convidar admins, mentores ou supervisores específicos. O
          convite sempre se estenderá a todos;
        </ListItem>
        <ListItem>
          Se a opção “Startups” for selecionada, todos os gestores e membros
          serão convidados;
        </ListItem>
      </UnorderedList>
    </Stack>
  );
});

const RegisterEvent = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableTabs, setDisableTabs] = useState(false);
  const { user, startups } = useSelector((store: iStore) => store.auth);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('...user', user?.role_.name);
    if (user?.role_.name === 'MENTOR') {
      setSelectedTab(1);
    }
  }, []);

  const handleSelect =
    (fieldName: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = event.target.value;
      formik.setFieldValue(fieldName, selectedOption);
    };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      formik.setFieldValue('guests', [...selectedOptions, option]);
      setSelectedOptions([...selectedOptions, option]);
    } else {
      formik.setFieldValue(
        'guests',
        selectedOptions.filter((o) => o !== option)
      );
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    }
  };

  const setSchema = useCallback(
    (tab: number) => {
      if (user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER') {
        return mainSchema.validators;
      } else return schemaRegisterEvent[tab];
    },
    [user]
  );

  const formik = useFormik({
    initialValues: mainSchema.initial,
    validationSchema: setSchema(selectedTab),
    validateOnMount: true,
    onSubmit: async (values, { resetForm }) => {
      const isFormValid = await formik.validateForm();
      if (isFormValid) {
        setIsLoading(true);
        const date = new Date(values.date);
        const time = new Date(values.time);
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());

        const requestBody: CreateEvent.Params = {
          body: {
            date: date.toString(),
            name: values.name,
            type: getTypeEvent(selectedTab),
            startup: startups?.length ? startups[0].id : undefined,
            guests: values.guests,
            responsable:
              selectedTab === 1 ? Number(values.responsable) : user?.id,
            ...(values.link && { link: values.link }),
            ...(values.description && { description: values.description }),
            ...(values.notice && { notice: Number(values.notice) }),
            ...(values.noticeStep && { noticeStep: Number(values.noticeStep) }),
          },
        };

        user &&
          makeRemoteCreateEvent()
            .create({
              body: requestBody.body,
            })
            .then(() => {
              makeReduxListEvent().list({});

              if (selectedTab === 0) {
                showConfirmModal('SUBMMIT_MEETING_EVENT');
              } else if (selectedTab === 1) {
                showConfirmModal('SUBMMIT_MENTORING_EVENT');
              } else if (selectedTab === 2) {
                showConfirmModal('SUBMMIT_DELIVERY_EVENT');
              }
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              resetForm({ values: mainSchema.initial });
              setSelectedOptions([]);
              setIsLoading(false);
              onClose();
            });
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW='800px' bg='#F8F8F8'>
        <ModalHeader fontSize='18px' py='3'>
          Criar evento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify='space-between' gap={'32px'}>
            <FormRegisterEvent formik={formik} />
            <TypeEvent
              formik={formik}
              setSelectedTab={setSelectedTab}
              setSelectedOptions={setSelectedOptions}
              selectedTab={selectedTab}
              selectedOptions={selectedOptions}
              handleCheckboxChange={handleCheckboxChange}
              handleSelect={handleSelect}
              disableTabs={disableTabs}
              actions={['ADMIN', 'SUPERVISOR', 'MENTOR']}
              resourcers={['ADMIN', 'SUPERVISOR', 'MENTOR']}
            />
            <InfoEvent
              actions={['LEADER', 'MEMBER']}
              resourcers={['LEADER', 'MEMBER']}
            />
          </Flex>
        </ModalBody>
        <ModalFooter pt='8'>
          <HStack justifyContent='space-between' w='100%' spacing='4'>
            <Button
              w='100px'
              variant='outline'
              color='#60656D'
              borderColor='#CECECE'
              onClick={() => {
                onClose();
                formik.resetForm();
              }}
            >
              <Text fontWeight='500' fontSize='14px'>
                Cancelar
              </Text>
            </Button>
            {
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                isLoading={isLoading}
                fontWeight='500'
                fontSize='14px'
                loadingText='Agendando evento'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Agendar evento
              </Button>
            }
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterEvent;
