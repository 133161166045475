import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import React, { cloneElement } from 'react';
import { ButtonProps } from './types';

type ButtonVariants = 'primary' | 'secondary' | 'destructive';
type ButtonSizes = 'sm' | 'md';
type ButtonStyleOptions = ChakraButtonProps;

const buttonSizes: Record<ButtonSizes, ButtonStyleOptions> = {
  sm: {
    paddingInline: 4,
    paddingBlock: 2,
    height: 9,
  },
  md: {
    paddingInline: 6,
    paddingBlock: 3,
    height: 10,
  },
};

const buttonVariants: Record<ButtonVariants, ButtonStyleOptions> = {
  primary: {
    color: 'white.1',
    bgColor: 'black.1',
    fontSize: '14px',
    _disabled: {
      bgColor: 'black.5',
      color: 'white.1',
      pointerEvents: 'none',
    },
    _hover: {
      bgColor: 'black.2',
    },
  },
  secondary: {
    color: 'black.2',
    bgColor: 'white.1',
    fontSize: '14px',
    border: '1px solid',
    borderColor: 'black.5',
    _hover: {
      bgColor: 'white.2',
      borderColor: 'black.4',
    },
    _disabled: {
      bgColor: 'black.6',
      color: 'black.5',
      pointerEvents: 'none',
    },
  },
  destructive: {
    color: 'white.1',
    bgColor: 'red.3',
    fontSize: '14px',
    _focusVisible: {
      outlineColor: 'white.1',
      outline: '2px solid',
      outlineOffset: 0,
      boxShadow: '0px 0px 15px 0px #1997F380',
    },
    _hover: {
      bgColor: 'red.4',
    },
    _disabled: {
      bgColor: 'black.5',
      color: 'white.1',
      pointerEvents: 'none',
    },
  },
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'primary', icon, size = 'md', ...rest }, ref) => {
    return (
      <ChakraButton
        ref={ref}
        fontFamily='Inter'
        fontWeight='medium'
        width='fit-content'
        gap={2.5}
        _focusVisible={{
          outline: '2px solid',
          outlineColor: 'blue.1',
          outlineOffset: 0,
          boxShadow: '0px 0px 15px 0px #1997F380',
        }}
        sx={{
          '&>svg path': {
            fill: 'currentColor',
          },
        }}
        {...buttonVariants[variant || 'primary']}
        {...buttonSizes[size || 'md']}
        {...rest}
      >
        {icon && cloneElement(icon)}
        {rest.children}
      </ChakraButton>
    );
  }
);
Button.displayName = 'Button';
