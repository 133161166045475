import React from 'react';

const IconRegistrationsNoticeStartups = (): JSX.Element => {
  return (
    <svg
      width='212'
      height='204'
      viewBox='0 0 212 204'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 102C2 128.522 12.5357 153.957 31.2893 172.711C50.043 191.464 75.4783 202 102 202C128.522 202 153.957 191.464 172.711 172.711C191.464 153.957 202 128.522 202 102C202 75.4783 191.464 50.043 172.711 31.2893C153.957 12.5357 128.522 2 102 2C75.4783 2 50.043 12.5357 31.2893 31.2893C12.5357 50.043 2 75.4783 2 102Z'
        fill='#D3DCF4'
        fillOpacity='0.3'
        stroke='#D3DCF4'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.19 165.11H55.1902C53.5246 165.11 51.9273 164.448 50.7495 163.27C49.5718 162.093 48.9102 160.495 48.9102 158.83V38.4073C48.9108 36.6712 49.6009 35.0065 50.8288 33.7791C52.0566 32.5518 53.7216 31.8623 55.4577 31.8623H147.92C148.78 31.862 149.631 32.031 150.426 32.3598C151.22 32.6886 151.942 33.1706 152.55 33.7784C153.158 34.3862 153.64 35.1078 153.969 35.9021C154.298 36.6963 154.468 37.5476 154.468 38.4073V158.83C154.468 160.495 153.806 162.092 152.629 163.27C151.452 164.447 149.855 165.109 148.19 165.11Z'
        fill='#4B587A'
      />
      <path
        d='M147.92 31.8623H55.4547C53.7187 31.8623 52.0537 32.5518 50.8258 33.7791C49.598 35.0065 48.9079 36.6712 48.9072 38.4073V47.4373C48.9072 45.6013 49.6366 43.8406 50.9348 42.5424C52.233 41.2441 53.9938 40.5148 55.8297 40.5148H147.545C149.381 40.5148 151.141 41.2441 152.44 42.5424C153.738 43.8406 154.467 45.6013 154.467 47.4373V38.4073C154.467 37.5476 154.298 36.6963 153.969 35.9021C153.64 35.1078 153.157 34.3862 152.549 33.7784C151.941 33.1706 151.22 32.6886 150.425 32.3598C149.631 32.031 148.779 31.862 147.92 31.8623Z'
        fill='#788FC9'
      />
      <path
        d='M148.19 165.11H55.1902C53.5246 165.11 51.9273 164.448 50.7495 163.27C49.5718 162.093 48.9102 160.495 48.9102 158.83V38.4073C48.9108 36.6712 49.6009 35.0065 50.8288 33.7791C52.0566 32.5518 53.7216 31.8623 55.4577 31.8623H147.92C148.78 31.862 149.631 32.031 150.426 32.3598C151.22 32.6886 151.942 33.1706 152.55 33.7784C153.158 34.3862 153.64 35.1078 153.969 35.9021C154.298 36.6963 154.468 37.5476 154.468 38.4073V158.83C154.468 160.495 153.806 162.092 152.629 163.27C151.452 164.447 149.855 165.109 148.19 165.11Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M59.29 42.2443H144.083V154.724H59.29V42.2443Z'
        fill='white'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M71.3799 69.9326H124.252'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M71.3799 85.5078H124.252'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M71.3799 102.813H108.23'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.0055 26.6699H125.37C126.203 26.6699 127.002 27.0007 127.591 27.5896C128.18 28.1784 128.51 28.9771 128.51 29.8099V46.0249C128.51 46.8583 128.179 47.6576 127.59 48.247C127.001 48.8363 126.201 49.1674 125.368 49.1674H78.008C77.5945 49.1687 77.1847 49.0884 76.8023 48.9311C76.4199 48.7737 76.0723 48.5425 75.7794 48.2505C75.4865 47.9586 75.2542 47.6117 75.0956 47.2298C74.9371 46.8479 74.8555 46.4384 74.8555 46.0249V29.8099C74.8555 29.3967 74.937 28.9876 75.0954 28.606C75.2539 28.2243 75.486 27.8777 75.7787 27.586C76.0713 27.2943 76.4187 27.0632 76.8008 26.906C77.1829 26.7488 77.5923 26.6686 78.0055 26.6699Z'
        fill='#E8F4FA'
      />
      <path
        d='M125.368 26.6699H78.008C77.5945 26.6686 77.1847 26.7489 76.8023 26.9062C76.4199 27.0636 76.0723 27.2948 75.7794 27.5868C75.4865 27.8787 75.2542 28.2256 75.0956 28.6075C74.9371 28.9894 74.8555 29.3989 74.8555 29.8124V37.0624C74.8555 36.6077 74.945 36.1574 75.119 35.7374C75.293 35.3173 75.5481 34.9356 75.8696 34.614C76.1911 34.2925 76.5728 34.0375 76.9929 33.8635C77.413 33.6895 77.8633 33.5999 78.318 33.5999H125.05C125.968 33.6006 126.848 33.9656 127.497 34.6149C128.146 35.2642 128.51 36.1445 128.51 37.0624V29.8124C128.51 29.3997 128.429 28.9911 128.271 28.6098C128.113 28.2285 127.882 27.8821 127.59 27.5903C127.298 27.2985 126.952 27.067 126.571 26.9091C126.189 26.7512 125.781 26.6699 125.368 26.6699Z'
        fill='white'
      />
      <path
        d='M78.0055 26.6699H125.37C126.203 26.6699 127.002 27.0007 127.591 27.5896C128.18 28.1784 128.51 28.9771 128.51 29.8099V46.0249C128.51 46.8583 128.179 47.6576 127.59 48.247C127.001 48.8363 126.201 49.1674 125.368 49.1674H78.008C77.5945 49.1687 77.1847 49.0884 76.8023 48.9311C76.4199 48.7737 76.0723 48.5425 75.7794 48.2505C75.4865 47.9586 75.2542 47.6117 75.0956 47.2298C74.9371 46.8479 74.8555 46.4384 74.8555 46.0249V29.8099C74.8555 29.3967 74.937 28.9876 75.0954 28.606C75.2539 28.2243 75.486 27.8777 75.7787 27.586C76.0713 27.2943 76.4187 27.0632 76.8008 26.906C77.1829 26.7488 77.5923 26.6686 78.0055 26.6699Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M86.9775 42.2443H116.398'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.9004 24.9396C93.9004 26.0759 94.1242 27.201 94.559 28.2508C94.9939 29.3005 95.6312 30.2544 96.4346 31.0579C97.2381 31.8613 98.192 32.4986 99.2417 32.9335C100.291 33.3683 101.417 33.5921 102.553 33.5921C103.689 33.5921 104.814 33.3683 105.864 32.9335C106.914 32.4986 107.868 31.8613 108.671 31.0579C109.475 30.2544 110.112 29.3005 110.547 28.2508C110.982 27.201 111.205 26.0759 111.205 24.9396C111.205 23.8033 110.982 22.6782 110.547 21.6284C110.112 20.5787 109.475 19.6248 108.671 18.8214C107.868 18.0179 106.914 17.3806 105.864 16.9457C104.814 16.5109 103.689 16.2871 102.553 16.2871C101.417 16.2871 100.291 16.5109 99.2417 16.9457C98.192 17.3806 97.2381 18.0179 96.4346 18.8214C95.6312 19.6248 94.9939 20.5787 94.559 21.6284C94.1242 22.6782 93.9004 23.8033 93.9004 24.9396Z'
        fill='#E8F4FA'
      />
      <path
        d='M102.553 23.21C104.235 23.2109 105.881 23.7026 107.288 24.6249C108.696 25.5473 109.803 26.8601 110.475 28.4025C111.054 27.0855 111.294 25.6452 111.176 24.2118C111.058 22.7783 110.584 21.397 109.797 20.1928C109.011 18.9886 107.937 17.9994 106.672 17.3147C105.407 16.63 103.991 16.2715 102.553 16.2715C101.115 16.2715 99.699 16.63 98.4341 17.3147C97.1692 17.9994 96.0949 18.9886 95.3085 20.1928C94.522 21.397 94.0482 22.7783 93.9298 24.2118C93.8114 25.6452 94.0522 27.0855 94.6304 28.4025C95.3026 26.8601 96.4102 25.5473 97.8174 24.6249C99.2247 23.7026 100.87 23.2109 102.553 23.21Z'
        fill='white'
      />
      <path
        d='M93.9004 24.9396C93.9004 26.0759 94.1242 27.201 94.559 28.2508C94.9939 29.3005 95.6312 30.2544 96.4346 31.0579C97.2381 31.8613 98.192 32.4986 99.2417 32.9335C100.291 33.3683 101.417 33.5921 102.553 33.5921C103.689 33.5921 104.814 33.3683 105.864 32.9335C106.914 32.4986 107.868 31.8613 108.671 31.0579C109.475 30.2544 110.112 29.3005 110.547 28.2508C110.982 27.201 111.205 26.0759 111.205 24.9396C111.205 23.8033 110.982 22.6782 110.547 21.6284C110.112 20.5787 109.475 19.6248 108.671 18.8214C107.868 18.0179 106.914 17.3806 105.864 16.9457C104.814 16.5109 103.689 16.2871 102.553 16.2871C101.417 16.2871 100.291 16.5109 99.2417 16.9457C98.192 17.3806 97.2381 18.0179 96.4346 18.8214C95.6312 19.6248 94.9939 20.5787 94.559 21.6284C94.1242 22.6782 93.9004 23.8033 93.9004 24.9396V24.9396Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        opacity='0.15'
        d='M144.085 80.7793L119.46 107.454C117.907 109.142 117.087 111.378 117.18 113.67C117.274 115.962 118.274 118.124 119.96 119.679C121.015 120.65 122.299 121.338 123.692 121.679L115.692 129.679L116.917 130.904L106.105 141.714C105.621 142.197 105.291 142.811 105.155 143.48C105.019 144.149 105.084 144.844 105.342 145.476C105.599 146.109 106.038 146.651 106.602 147.036C107.167 147.42 107.832 147.629 108.515 147.637C108.767 147.636 109.018 147.61 109.265 147.559C118.74 145.544 123.56 142.634 123.615 138.899L124.27 138.244L126.717 140.692L132.77 134.642C133.196 134.985 133.654 135.287 134.137 135.544C135.73 136.391 137.548 136.718 139.336 136.482C141.124 136.246 142.794 135.458 144.112 134.227L144.085 80.7793Z'
        fill='#303950'
      />
      <path
        d='M138.12 101.735L168.433 113.042L181.183 92.5924L148.223 83.6924L138.12 101.735Z'
        fill='#D1B07E'
      />
      <path
        d='M186.445 136.25L209.92 112.775C210.011 112.685 210.108 112.602 210.21 112.525C202.352 105.705 200.412 91.2752 185.302 83.3702C165.407 72.9827 140.802 75.4602 119.192 102.448C118.292 103.644 117.892 105.144 118.076 106.63C118.26 108.116 119.015 109.473 120.18 110.414C121.345 111.354 122.831 111.805 124.322 111.672C125.814 111.538 127.195 110.83 128.175 109.698C129.605 107.925 131.042 106.28 132.482 104.738C132.99 105.28 133.598 105.719 134.272 106.03C156.885 123.15 161.442 125.325 166.987 127.48C175.54 130.805 179.317 132.41 185.605 138.103C185.65 137.403 185.949 136.745 186.445 136.25ZM170.945 99.7077C169.039 102.011 166.387 103.576 163.449 104.132C160.511 104.687 157.471 104.199 154.855 102.75L141.49 96.6252C149.292 90.9127 157.165 88.5302 165.007 89.5527C171.677 90.4202 173.222 96.3202 170.952 99.6977L170.945 99.7077Z'
        fill='#FFDA8F'
      />
      <path
        d='M205.632 117.05L209.92 112.765C210.01 112.675 210.107 112.592 210.21 112.515C202.352 105.695 200.412 91.2655 185.302 83.3605C165.407 72.973 140.802 75.4505 119.192 102.438C118.363 103.47 117.913 104.756 117.916 106.08C117.919 107.404 118.376 108.687 119.21 109.715C119.376 109.398 119.572 109.097 119.795 108.815C141.407 81.8155 163.952 80.103 184.025 90.1405C190.697 93.473 199.65 109.193 205.632 117.05Z'
        fill='#FFE9BD'
      />
      <path
        opacity='0.15'
        d='M63.6025 185.143C63.6025 185.711 64.5733 186.274 66.4593 186.8C68.3454 187.325 71.1098 187.802 74.5948 188.204C78.0798 188.606 82.2171 188.925 86.7704 189.143C91.3238 189.361 96.204 189.472 101.133 189.472C106.061 189.472 110.941 189.361 115.495 189.143C120.048 188.925 124.185 188.606 127.67 188.204C131.155 187.802 133.92 187.325 135.806 186.8C137.692 186.274 138.663 185.711 138.663 185.143C138.663 184.574 137.692 184.011 135.806 183.485C133.92 182.96 131.155 182.483 127.67 182.081C124.185 181.679 120.048 181.36 115.495 181.142C110.941 180.924 106.061 180.812 101.133 180.812C96.204 180.812 91.3238 180.924 86.7704 181.142C82.2171 181.36 78.0798 181.679 74.5948 182.081C71.1098 182.483 68.3454 182.96 66.4593 183.485C64.5733 184.011 63.6025 184.574 63.6025 185.143Z'
        fill='#303950'
      />
      <path
        d='M76.6603 120.795C75.7652 121.689 75.1047 122.791 74.7371 124.001C74.3695 125.212 74.3062 126.495 74.5529 127.736C74.7996 128.977 75.3486 130.138 76.1512 131.116C76.9539 132.094 77.9855 132.859 79.1545 133.343C80.3235 133.828 81.594 134.016 82.8532 133.892C84.1124 133.767 85.3215 133.334 86.3733 132.631C87.4252 131.928 88.2873 130.976 88.8834 129.86C89.4794 128.744 89.7909 127.498 89.7903 126.232V127.772C89.7903 129.404 90.4385 130.969 91.5923 132.123C92.7461 133.277 94.311 133.925 95.9428 133.925C97.5745 133.925 99.1394 133.277 100.293 132.123C101.447 130.969 102.095 129.404 102.095 127.772C102.095 129.404 102.743 130.969 103.897 132.123C105.051 133.277 106.616 133.925 108.248 133.925H112.855'
        stroke='#DE4377'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M152.967 104.095C153.724 104.136 154.466 104.326 155.15 104.653C155.834 104.981 156.446 105.44 156.952 106.004C157.459 106.569 157.849 107.227 158.101 107.943C158.352 108.658 158.461 109.416 158.42 110.173L157.405 128.923C157.322 130.452 156.635 131.885 155.495 132.908C154.355 133.93 152.856 134.458 151.327 134.375C149.798 134.292 148.365 133.605 147.342 132.466C146.32 131.326 145.792 129.827 145.875 128.298L146.89 109.548C146.973 108.019 147.66 106.586 148.8 105.563C149.939 104.541 151.438 104.013 152.967 104.095Z'
        fill='#D1B07E'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M147.003 102.295C148.354 103.015 149.364 104.242 149.811 105.707C150.258 107.171 150.104 108.753 149.385 110.105L142.493 123.515C141.755 124.834 140.53 125.81 139.08 126.236C137.631 126.662 136.072 126.503 134.738 125.793C133.405 125.083 132.403 123.878 131.947 122.438C131.491 120.997 131.618 119.436 132.3 118.088L139.193 104.678C139.549 104.008 140.033 103.416 140.619 102.934C141.204 102.452 141.879 102.09 142.604 101.868C143.329 101.647 144.091 101.571 144.846 101.644C145.6 101.717 146.333 101.939 147.003 102.295Z'
        fill='#D1B07E'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M209.92 112.765C210.011 112.675 210.108 112.592 210.21 112.515C202.352 105.695 200.412 91.2655 185.302 83.3605C165.407 72.973 140.802 75.4505 119.192 102.438C118.292 103.635 117.892 105.134 118.076 106.621C118.26 108.107 119.015 109.463 120.18 110.404C121.345 111.344 122.831 111.795 124.322 111.662C125.814 111.528 127.195 110.821 128.175 109.688C129.605 107.915 131.042 106.27 132.482 104.728C132.99 105.27 133.598 105.709 134.272 106.02C156.885 123.14 161.442 125.315 166.987 127.47C175.54 130.795 179.317 132.4 185.605 138.093C185.649 137.392 185.947 136.731 186.445 136.235L209.92 112.765ZM170.945 99.693C169.041 101.999 166.39 103.567 163.452 104.125C160.514 104.684 157.473 104.197 154.855 102.75L141.49 96.6255C149.292 90.913 157.165 88.5305 165.007 89.553C171.677 90.4205 173.222 96.3205 170.952 99.698L170.945 99.693Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M165.952 100.844L158.43 90.2495L181.867 73.5995C182.498 73.1511 183.211 72.8315 183.965 72.6587C184.719 72.4859 185.5 72.4634 186.263 72.5925C187.026 72.7216 187.756 72.9997 188.411 73.411C189.066 73.8223 189.634 74.3586 190.082 74.9895L190.782 75.9745C191.23 76.6052 191.55 77.3181 191.722 78.0724C191.895 78.8267 191.917 79.6076 191.788 80.3705C191.659 81.1334 191.38 81.8633 190.969 82.5186C190.557 83.1739 190.021 83.7416 189.39 84.1895L165.952 100.844Z'
        fill='#DE4377'
      />
      <path
        d='M190.475 75.5621C190.457 75.5371 190.45 75.5121 190.432 75.4871C189.991 74.7296 189.389 74.0782 188.669 73.5791C187.948 73.08 187.127 72.7454 186.263 72.5989C185.399 72.4525 184.513 72.4977 183.668 72.7316C182.823 72.9654 182.041 73.3821 181.375 73.9521L158.43 90.2496L162.065 95.3646L190.475 75.5621Z'
        fill='#FA5A90'
      />
      <path
        d='M165.952 100.844L158.43 90.2495L181.867 73.5995C182.498 73.1511 183.211 72.8315 183.965 72.6587C184.719 72.4859 185.5 72.4634 186.263 72.5925C187.026 72.7216 187.756 72.9997 188.411 73.411C189.066 73.8223 189.634 74.3586 190.082 74.9895L190.782 75.9745C191.23 76.6052 191.55 77.3181 191.722 78.0724C191.895 78.8267 191.917 79.6076 191.788 80.3705C191.659 81.1334 191.38 81.8633 190.969 82.5186C190.557 83.1739 190.021 83.7416 189.39 84.1895L165.952 100.844Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M159.452 84.216L175.925 72.5135C176.384 72.1732 176.908 71.9292 177.465 71.7962C178.021 71.6632 178.598 71.6438 179.162 71.7393C179.726 71.8348 180.265 72.0431 180.747 72.3518C181.228 72.6606 181.642 73.0633 181.965 73.536'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M121.573 119.976L109.998 128.199C109.743 128.377 109.553 128.633 109.456 128.929C109.359 129.224 109.36 129.543 109.458 129.837C109.557 130.132 109.748 130.387 110.004 130.564C110.259 130.742 110.565 130.832 110.875 130.821C115.728 130.621 122.663 129.741 122.563 126.356L124.915 124.684L121.573 119.976Z'
        fill='#D3DCF4'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.753 125.86L121.573 119.978C121.355 119.667 121.268 119.282 121.332 118.908C121.396 118.534 121.604 118.199 121.913 117.978L127.798 113.798L133.648 122.048L127.765 126.228C127.609 126.336 127.433 126.412 127.247 126.453C127.062 126.493 126.87 126.496 126.683 126.462C126.496 126.427 126.318 126.357 126.158 126.254C125.998 126.15 125.861 126.017 125.753 125.86Z'
        fill='#DAEDF7'
      />
      <path
        d='M130.398 117.448L127.795 113.785L121.913 117.965C121.604 118.187 121.396 118.521 121.332 118.895C121.268 119.27 121.355 119.654 121.573 119.965L123.295 122.39L130.398 117.448Z'
        fill='#E8F4FA'
      />
      <path
        d='M125.753 125.86L121.573 119.978C121.355 119.667 121.268 119.282 121.332 118.908C121.396 118.534 121.604 118.199 121.913 117.978L127.798 113.798L133.648 122.048L127.765 126.228C127.609 126.336 127.433 126.412 127.247 126.453C127.062 126.493 126.87 126.496 126.683 126.462C126.496 126.427 126.318 126.357 126.158 126.254C125.998 126.15 125.861 126.017 125.753 125.86V125.86Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132.812 120.846L128.632 114.963C128.189 114.339 128.012 113.564 128.14 112.81C128.268 112.055 128.691 111.382 129.315 110.938L158.43 90.251L165.952 100.841L136.837 121.528C136.213 121.972 135.438 122.149 134.684 122.021C133.929 121.893 133.256 121.47 132.812 120.846Z'
        fill='#DE4377'
      />
      <path
        d='M130.397 117.448L162.065 95.371L158.43 90.251L129.315 110.938C129.006 111.158 128.743 111.436 128.541 111.757C128.339 112.078 128.203 112.436 128.139 112.81C128.076 113.183 128.087 113.566 128.171 113.936C128.256 114.305 128.413 114.654 128.632 114.963L130.397 117.448Z'
        fill='#FA5A90'
      />
      <path
        d='M132.812 120.846L128.632 114.963C128.189 114.339 128.012 113.564 128.14 112.81C128.268 112.055 128.691 111.382 129.315 110.938L158.43 90.251L165.952 100.841L136.837 121.528C136.213 121.972 135.438 122.149 134.684 122.021C133.929 121.893 133.256 121.47 132.812 120.846V120.846Z'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M170.953 99.6969C169.047 102.004 166.395 103.572 163.455 104.13C160.515 104.687 157.473 104.199 154.855 102.749L139.07 95.5319C138.378 95.1966 137.626 95.0029 136.858 94.9622C136.09 94.9215 135.322 95.0346 134.598 95.2948C133.874 95.5551 133.21 95.9573 132.644 96.4778C132.078 96.9983 131.621 97.6267 131.301 98.326C130.981 99.0253 130.804 99.7815 130.78 100.55C130.756 101.319 130.886 102.085 131.162 102.802C131.438 103.52 131.855 104.176 132.388 104.73C132.921 105.285 133.559 105.727 134.265 106.032C156.878 123.152 161.435 125.327 166.98 127.482'
        fill='#FFCEBF'
      />
      <path
        d='M140.88 103.097L156.665 110.314C158.034 110.985 159.543 111.322 161.067 111.299C162.592 111.275 164.089 110.89 165.436 110.177C166.784 109.463 167.943 108.441 168.82 107.194C169.696 105.946 170.265 104.509 170.48 102.999L170.952 99.6916C169.048 102 166.396 103.569 163.456 104.127C160.516 104.685 157.473 104.198 154.855 102.749L139.07 95.5316C137.753 94.9305 136.258 94.8465 134.882 95.2964C133.506 95.7462 132.35 96.697 131.643 97.96C130.935 99.223 130.729 100.706 131.064 102.114C131.399 103.522 132.252 104.753 133.452 105.562C134.151 104.289 135.304 103.328 136.682 102.871C138.06 102.413 139.559 102.494 140.88 103.097Z'
        fill='#FFDCD1'
      />
      <path
        d='M170.953 99.6969C169.047 102.004 166.395 103.572 163.455 104.13C160.515 104.687 157.473 104.199 154.855 102.749L139.07 95.5319C138.378 95.1966 137.626 95.0029 136.858 94.9622C136.09 94.9215 135.322 95.0346 134.598 95.2948C133.874 95.5551 133.21 95.9573 132.644 96.4778C132.078 96.9983 131.621 97.6267 131.301 98.326C130.981 99.0253 130.804 99.7815 130.78 100.55C130.756 101.319 130.886 102.085 131.162 102.802C131.438 103.52 131.855 104.176 132.388 104.73C132.921 105.285 133.559 105.727 134.265 106.032C156.878 123.152 161.435 125.327 166.98 127.482'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M170.953 99.6969C169.047 102.004 166.395 103.572 163.455 104.13C160.515 104.687 157.473 104.199 154.855 102.749L139.07 95.5319C138.378 95.1966 137.626 95.0029 136.858 94.9622C136.09 94.9215 135.322 95.0346 134.598 95.2948C133.874 95.5551 133.21 95.9573 132.644 96.4778C132.078 96.9983 131.621 97.6267 131.301 98.326C130.981 99.0253 130.804 99.7815 130.78 100.55C130.756 101.319 130.886 102.085 131.162 102.802C131.438 103.52 131.855 104.176 132.388 104.73C132.921 105.285 133.559 105.727 134.265 106.032C156.878 123.152 161.435 125.327 166.98 127.482'
        fill='#FFDA8F'
      />
      <path
        d='M140.88 103.097L156.665 110.314C158.034 110.985 159.543 111.322 161.067 111.299C162.592 111.275 164.089 110.89 165.436 110.177C166.784 109.463 167.943 108.441 168.82 107.194C169.696 105.946 170.265 104.509 170.48 102.999L170.952 99.6916C169.048 102 166.396 103.569 163.456 104.127C160.516 104.685 157.473 104.198 154.855 102.749L139.07 95.5316C137.753 94.9305 136.258 94.8465 134.882 95.2964C133.506 95.7462 132.35 96.697 131.643 97.96C130.935 99.223 130.729 100.706 131.064 102.114C131.399 103.522 132.252 104.753 133.452 105.562C134.151 104.289 135.304 103.328 136.682 102.871C138.06 102.413 139.559 102.494 140.88 103.097Z'
        fill='#FFE9BD'
      />
      <path
        d='M170.953 99.6969C169.047 102.004 166.395 103.572 163.455 104.13C160.515 104.687 157.473 104.199 154.855 102.749L139.07 95.5319C138.378 95.1966 137.626 95.0029 136.858 94.9622C136.09 94.9215 135.322 95.0346 134.598 95.2948C133.874 95.5551 133.21 95.9573 132.644 96.4778C132.078 96.9983 131.621 97.6267 131.301 98.326C130.981 99.0253 130.804 99.7815 130.78 100.55C130.756 101.319 130.886 102.085 131.162 102.802C131.438 103.52 131.855 104.176 132.388 104.73C132.921 105.285 133.559 105.727 134.265 106.032C156.878 123.152 161.435 125.327 166.98 127.482'
        stroke='#303950'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconRegistrationsNoticeStartups;
