import React from 'react';

const IconMenuListAnnounWhite = () => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.25 2H1.5V14H10.5V4.25H8.25V2ZM1.5 0.5H9L12 3.5V14C12 14.3978 11.842 14.7794 11.5607 15.0607C11.2794 15.342 10.8978 15.5 10.5 15.5H1.5C1.10218 15.5 0.720644 15.342 0.43934 15.0607C0.158035 14.7794 0 14.3978 0 14V2C0 1.60218 0.158035 1.22064 0.43934 0.93934C0.720644 0.658035 1.10218 0.5 1.5 0.5ZM3 7.25H9V8.75H3V7.25ZM3 10.25H9V11.75H3V10.25Z'
        fill='#E2E2E2'
      />
    </svg>
  );
};

export default IconMenuListAnnounWhite;
