import React, { useEffect } from 'react';
import Documents from '~/presentation/pages/documents';
import { makeReduxListStartupFoldersDocument } from '../../usecases/document/ListStartupFoldersDocumentFactory';
import { makeReduxListNoticeFoldersDocument } from '../../usecases/document/ListNoticeFoldersDocumentFactory';
import { makeReduxListMentoringFoldersDocument } from '../../usecases/document/ListMentoringFoldersDocumentFactory';
import { makeReduxListDeliveryFoldersDocument } from '../../usecases/document/ListDeliveryFoldersDocumentFactory';

export const makeDocumentsFactory: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('>>> makeDocumentsFactory');
    makeReduxListStartupFoldersDocument().listStartupFolders({});
    makeReduxListNoticeFoldersDocument().listNoticeFolders({});
    makeReduxListMentoringFoldersDocument().listMentoringFolders({});
    makeReduxListDeliveryFoldersDocument().listDeliveryFolders({});
  }, []);

  return <Documents />;
};
