export enum DocumentTypes {
  LISTSTARTUPFOLDERS = '@document/LISTSTARTUPFOLDERS',
  LISTSTARTUPFOLDERS_SUCCESS = '@document/LISTSTARTUPFOLDERS_SUCCESS',
  LISTSTARTUPFOLDERS_FAILED = '@document/LISTSTARTUPFOLDERS_FAILED',
  LISTNOTICEFOLDERS = '@document/LISTNOTICEFOLDERS',
  LISTNOTICEFOLDERS_SUCCESS = '@document/LISTNOTICEFOLDERS_SUCCESS',
  LISTNOTICEFOLDERS_FAILED = '@document/LISTNOTICEFOLDERS_FAILED',
  LISTFOLDERCONTENT = '@document/LISTFOLDERCONTENT',
  LISTFOLDERCONTENT_SUCCESS = '@document/LISTFOLDERCONTENT_SUCCESS',
  LISTFOLDERCONTENT_FAILED = '@document/LISTFOLDERCONTENT_FAILED',
  LISTMENTORINGFOLDERS = '@document/LISTMENTORINGFOLDERS',
  LISTMENTORINGFOLDERS_SUCCESS = '@document/LISTMENTORINGFOLDERS_SUCCESS',
  LISTMENTORINGFOLDERS_FAILED = '@document/LISTMENTORINGFOLDERS_FAILED',
  LISTDELIVERYFOLDERS = '@document/LISTDELIVERYFOLDERS',
  LISTDELIVERYFOLDERS_SUCCESS = '@document/LISTDELIVERYFOLDERS_SUCCESS',
  LISTDELIVERYFOLDERS_FAILED = '@document/LISTDELIVERYFOLDERS_FAILED',
}
