import { takeLatest } from 'redux-saga/effects';
import { onGet, onGetSuccess, onGetFailed } from './Get';
import { ActivityTypes } from '~/domain/interfaces/redux/activity/types';

const tasks = [
  takeLatest(ActivityTypes.GET, onGet),
  takeLatest(ActivityTypes.GET_SUCCESS, onGetSuccess),
  takeLatest(ActivityTypes.GET_FAILED, onGetFailed),
];

export default tasks;
