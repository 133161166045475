import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconInformApproved } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const SubmitionInformApproved = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'INFORM_APPROVED'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconInformApproved />}
      title='Informe aprovado!'
      showTopButton={false}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>
              Você optou por
              <Box as='span' fontWeight={'bold'} color={'#5b7a17'}>
                {' '}
                aprovar{' '}
              </Box>
              a prestação de contas da startup, referente ao período cadastrado.
            </Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default SubmitionInformApproved;
