import * as yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';

export const schemaRegisterStartup = {
  initial: {
    name: '',
    cnpj: '',
    fullname: '',
    description: '',
    activity: undefined,
    city: '',
    address: '',
    cep: '',
    nameManager: '',
    gender: '',
    emailManager: '',
    phoneManager: '',
    cpf: '',
    job: '',
    workload: '',
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    cnpj: yup
      .string()
      .matches(
        /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
        'CNPJ inválido'
      )
      .test('is-cnpj', 'CNPJ inválido', (cnpj) =>
        cnpj ? validateCNPJ(cnpj) : true
      ),
    activity: yup.number().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    address: yup.string(),
    cep: yup
      .string()
      .matches(/^(58\d{3})-\d{3}$/, 'CEP inválido'),
    nameManager: yup.string().required('Campo obrigatório'),
    gender: yup
      .string()
      .required('Campo obrigatório')
      .oneOf(['MALE', 'FEMALE', 'OTHER'], 'Selecione uma opção válida'),
    emailManager: yup
      .string()
      .required('Campo obrigatório')
      .email('Email inválido'),
    phoneManager: yup.string(),
  }),
};
