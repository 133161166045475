import { call, put, select } from 'redux-saga/effects';
import { iActionListStartupFolders } from '~/domain/interfaces/redux/document/listStartupFolders';
import { ListStartupFoldersDocument } from '~/domain/usecases/document/remote/ListStartupFoldersDocument';
import { makeRemoteListStartupFoldersDocument } from '~/main/factories/usecases/document/ListStartupFoldersDocumentFactory';
import {
  listStartupFoldersSuccess,
  listStartupFoldersFailed,
} from '~/data/store/reducer/document/actions/listStartupFolders';

export function* onListStartupFolders(action: iActionListStartupFolders) {
  // eslint-disable-next-line no-console
  console.log('>>> onListStartupFolders');
  const remoteListStartupFoldersDocument =
    makeRemoteListStartupFoldersDocument();

  try {
    const response: ListStartupFoldersDocument.Model = yield call(
      remoteListStartupFoldersDocument.listStartupFolders,
      action.payload
    );
    yield put(
      listStartupFoldersSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listStartupFoldersFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListStartupFoldersSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListStartupFoldersFailed() {}
