import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  defineStyleConfig,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const CheckboxStyle = defineStyleConfig({
  baseStyle: {
    control: {
      _disabled: {
        bg: 'white.2',
        borderColor: 'black.5',
        cursor: 'default',
        pointerEvents: 'none',
        color: 'black.5',
      },
      _indeterminate: {
        bg: 'blue.2',
        borderColor: 'blue.2',
        _hover: {
          bg: 'blue.1',
          borderColor: 'blue.1',
        },
        _focus: {
          outlineColor: 'white.1',
        },
      },
      _checked: {
        bg: 'blue.2',
        borderColor: 'blue.2',
        _hover: {
          bg: 'blue.1',
          borderColor: 'blue.1',
        },
        _focus: {
          outlineColor: 'white.1',
        },
        _disabled: {
          bg: 'white.2',
          borderColor: 'black.5',
          cursor: 'default',
          pointerEvents: 'none',
          color: 'black.5',
        },
      },
      _hover: {
        bg: 'white.2',
      },
      _focusVisible: {
        outlineColor: 'blue.1',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: 0,
        boxShadow: '0px 0px 15px 0px rgba(25, 151, 243, 0.5)',
      },
      transition: 'all 0.2s',
      width: '18px',
      height: '18px',
      borderRadius: 'sm',
      borderColor: 'black.5',
      bgColor: 'white.1',
      borderWidth: '1px',
    },
  },
});

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    return <ChakraCheckbox ref={ref} {...props} />;
  }
);
Checkbox.displayName = 'Checkbox';
