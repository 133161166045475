import React from 'react';

const iconArrowRight = () => {
  return (
    <svg
      width='7'
      height='10'
      viewBox='0 0 7 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.761911 0.263092C0.678886 0.345946 0.613018 0.444362 0.568076 0.552705C0.523133 0.661048 0.5 0.77719 0.5 0.894485C0.5 1.01178 0.523133 1.12792 0.568076 1.23626C0.613018 1.34461 0.678886 1.44302 0.761911 1.52588L4.23681 5.00078L0.761911 8.47568C0.594455 8.64314 0.500379 8.87026 0.500379 9.10707C0.500379 9.34389 0.594455 9.57101 0.761911 9.73847C0.929367 9.90593 1.15649 10 1.3933 10C1.63012 10 1.85724 9.90593 2.0247 9.73847L6.13547 5.6277C6.2185 5.54484 6.28436 5.44642 6.32931 5.33808C6.37425 5.22974 6.39738 5.1136 6.39738 4.9963C6.39738 4.87901 6.37425 4.76287 6.32931 4.65452C6.28436 4.54618 6.2185 4.44776 6.13547 4.36491L2.0247 0.254135C1.68437 -0.08619 1.11119 -0.0861898 0.761911 0.263092Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default iconArrowRight;
