import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/startup/actions/list';

import { iActionList } from '~/domain/interfaces/redux/startup/list';

import { ListStartup } from '~/domain/usecases/startup/redux';

export class ReduxListStartup implements ListStartup {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListStartup.Params): iActionList =>
    this.send(listRequest(params));
}
