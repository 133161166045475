import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconInformApproved } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const EditInformSuccess = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'EDIT_INFORM'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconInformApproved />}
      showTopButton={false}
      title='Informe editado!'
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5' alignItems={'center'}>
            <Text>O informe foi editado com sucesso!</Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default EditInformSuccess;
