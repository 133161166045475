import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListFolderContentDocument as UsecaseRemoteListFolderContentDocument } from '~/domain/usecases/document/remote';
import { ListFolderContentDocument as UsecaseReduxListFolderContentDocument } from '~/domain/usecases/document/redux';

import { RemoteListFolderContentDocument } from '~/data/repository/document';
import { ReduxListFolderContentDocument } from '~/data/store/reducer/document/usecases';

/**
 * send request via API.
 */
export const makeRemoteListFolderContentDocument =
  (): UsecaseRemoteListFolderContentDocument =>
    new RemoteListFolderContentDocument(
      makeApiUrl('/documents/folders/{folder}'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListFolderContentDocument =
  (): UsecaseReduxListFolderContentDocument =>
    new ReduxListFolderContentDocument();
