import React, { useContext } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Button,
  HStack,
  Link,
  useDisclosure,
  Divider,
  FormControl,
  FormLabel,
  ButtonProps,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Input from '~/presentation/components/UI/Input';
import EmailEnviado from './EmailEnviado';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { schemaResetPassword } from '~/validators/Login/ResetPasswordValidator';

const EsqueceuSenha: React.FC = (props: ButtonProps): JSX.Element => {
  const { active, showConfirmModal } = useContext(ConfirmModalContext);
  const { initial, validators } = schemaResetPassword;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      resetForm({ values: initial });
    },
  });

  return (
    <>
      <Link color='#1997F3' onClick={() => showConfirmModal('FORGOT_PASSWORD')}>
        Esqueceu sua senha?
      </Link>

      <Modal
        isOpen={active === 'FORGOT_PASSWORD'}
        onClose={() => {
          showConfirmModal('');
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent w='350px'>
          <ModalHeader
            fontSize='2xl'
            fontWeight='bold'
            paddingBottom='1px'
            paddingTop='10px'
          >
            Esqueceu sua senha?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing='4'>
              <Text fontSize='xs'>
                Insira seu e-mail no campo abaixo e receba as instruções para
                definir uma nova senha.
              </Text>
              <Input id='email' name='email' label='E-mail' formik={formik} />
            </Stack>
          </ModalBody>

          <ModalFooter paddingBottom='50px'>
            <EmailEnviado
              email={formik?.values.email}
              disabled={
                formik.errors.email || !formik?.values.email ? true : false
              }
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EsqueceuSenha;
