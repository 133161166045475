import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  HStack,
  Box,
  Text,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import getDate from '~/utils/getDate';
import { useFormik } from 'formik';
import { makeRemoteEvaluateAccountability } from '~/main/factories/usecases/accountability/EvaluateAccountabilityFactory';
import { schemaEvaluateAccountabilityInformValidator } from '~/validators/RegisterInform/EvaluateAccountabilityInformValidator';
import { makeReduxListAccountability } from '~/main/factories/usecases/accountability/ListAccountabilityFactory';
import RevenueEvaluation from '../RevenueEvaluation';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import TextArea from '../UI/textArea';
import { iAccountability } from '~/domain/interfaces/models';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  accountability?: iAccountability['records'][0];
}

const EvaluateInform = ({
  isOpen,
  onClose,
  accountability,
}: ownProps): JSX.Element => {
  const [status, setStatus] = React.useState<
    'APPROVED' | 'REJECTED' | 'PENDING'
  >(accountability?.status || 'APPROVED' || 'REJECTED' || 'PENDING');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { records } = useSelector((store: iStore) => store.application);

  const { showConfirmModal } = useContext(ConfirmModalContext);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('...accountability', accountability);

    if (accountability) {
      //const { street } = startup.address;
      // eslint-disable-next-line no-console
      console.log('...accountability def values');

      formik.setValues({
        ...formik.values,
        status: accountability.status,
        justification: accountability.justification,
      });

      setStatus(accountability.status);

      // formik.setFieldValue('cnpj', new StringMask('00.000.000/0000-00').apply(startup.cnpj));
    }
  }, [accountability, isOpen]);

  const { initial, validators } = schemaEvaluateAccountabilityInformValidator;
  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      setIsLoading(true);

      accountability &&
        makeRemoteEvaluateAccountability()
          .evaluate({
            id: accountability?.id,
            body: {
              status: status,
              justification: values.justification || '',
            },
          })
          .then(() => {
            makeReduxListAccountability().list({});
            if (status === 'APPROVED') showConfirmModal('INFORM_APPROVED');
            else showConfirmModal('INFORM_REJECTED');
          })
          .catch(() => {
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            setIsLoading(false);
            onClose();
            resetForm({ values: initial });
          });
    },
  });

  return (
    <>
      <Modal size='4xl' isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent width='740px' borderRadius='10px' padding='24px'>
          <ModalHeader
            fontSize='2xl'
            fontWeight='bold'
            padding='0px'
            gap='20px'
            alignItems={'center'}
          >
            Avaliar informe
            <Divider paddingTop='20px' marginBottom='20px' />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody padding='0px'>
            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
              <Box width='300px'>
                <Grid templateColumns='repeat(1, 1fr)' gap={6}>
                  <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Número
                    </GridItem>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Categoria
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {accountability?.code}
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {accountability?.type === 'INCOME'
                        ? 'Receita'
                        : 'Despesa'}
                    </GridItem>
                  </Grid>
                  <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Período
                    </GridItem>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Edital associado
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {`${getDate(accountability?.begin)}` +
                        ' até\n' +
                        `${getDate(accountability?.end)}`}
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {`${
                        records?.find(
                          (element) =>
                            element.notice_?.id === accountability?.notice
                        )?.notice_?.code
                      } - ${
                        records?.find(
                          (element) =>
                            element.notice_?.id === accountability?.notice
                        )?.notice_?.title
                      }`}
                    </GridItem>
                  </Grid>
                  <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Total de gastos
                    </GridItem>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Documentos
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {`R$ ${accountability?.value}`}
                    </GridItem>
                    <GridItem padding='0px'>Ver documentos</GridItem>
                  </Grid>
                  <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Startup
                    </GridItem>
                    <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                      Inserido em
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {`${
                        records?.find(
                          (element) =>
                            element.notice_?.id === accountability?.notice
                        )?.startup_?.name
                      } `}
                    </GridItem>
                    <GridItem padding='0px' color='#60656D'>
                      {/*ALTERAR O CREATEDAT QUANDO O REDUX ESTIVER PRONTO*/}
                      {`${getDate(
                        records?.find(
                          (element) =>
                            element.notice_?.id === accountability?.notice
                        )?.createdAt
                      )} `}
                    </GridItem>
                  </Grid>
                </Grid>
              </Box>
              <Grid templateColumns='repeat(1, 1fr)' gap={2}>
                <Box>
                  <GridItem padding='0px' fontWeight='bold' color={'#303950'}>
                    Resultado da avaliação
                  </GridItem>
                  <GridItem padding='0px' marginTop='10px'>
                    <HStack spacing='3'>
                      <RevenueEvaluation
                        status='APPROVED'
                        isHilighted={status === 'APPROVED'}
                        onClick={() => {
                          // eslint-disable-next-line no-console
                          console.log('...status', status, '-> APPROVED');
                          setStatus('APPROVED');
                        }}
                      >
                        Aprovado
                      </RevenueEvaluation>
                      <RevenueEvaluation
                        status='REJECTED'
                        isHilighted={status === 'REJECTED'}
                        onClick={() => {
                          // eslint-disable-next-line no-console
                          console.log('...status', status, '-> REJECTED');
                          setStatus('REJECTED');
                        }}
                      >
                        Reprovado
                      </RevenueEvaluation>
                    </HStack>
                  </GridItem>
                </Box>
                <Box>
                  <Text as={Flex} fontWeight='bold' fontSize='sm' mb='3'>
                    Justificativa{' '}
                    <Text fontWeight='normal' ml='1'>{` (opcional)`}</Text>
                  </Text>
                  <TextArea
                    id='justification'
                    name='justification'
                    label=''
                    value={formik.values.justification}
                    formik={formik}
                    marginTop='0px'
                    resize='none'
                    minH={'186px'}
                  />
                </Box>
              </Grid>
            </Grid>
          </ModalBody>
          <Divider paddingTop='20px' marginBottom='20px' />
          <ModalFooter justifyContent='space-between' padding='0px'>
            <Button
              width='100px'
              bg='#F9F8FA'
              color='#60656D'
              _hover={{ background: '#E9ECEE', color: '#303950' }}
              _active={{ background: '#CECECE', color: '#303950' }}
              boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
              padding='10px, 22px, 10px, 22px'
              border='1px solid #60656D'
              onClick={() => {
                onClose();
                formik.resetForm();
              }}
            >
              <Text
                fontFamily='Inter'
                fontStyle='normal'
                fontWeight='500'
                fontSize='sm'
              >
                Cancelar
              </Text>
            </Button>
            {
              <Button
                width='200px'
                bg='#303950'
                color='#F9F8FA'
                _hover={{ background: '#4B587A', color: '#F9F8FA' }}
                _active={{ background: '#303950', color: '#788FC9' }}
                boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                padding='10px, 22px, 10px, 22px'
                disabled={accountability?.status === status && isLoading}
                isLoading={isLoading}
                loadingText=' Submetendo avaliação'
                onClick={() => {
                  formik.handleSubmit();
                }}
                fontWeight='500'
                fontSize='sm'
              >
                Submeter avaliação
              </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EvaluateInform;
