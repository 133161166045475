import { AnyAction, combineReducers, Reducer } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';
import notice from './notice';
import startup from './startup';
import auth from './auth';
import application from './application';
import event from './event';
import accountability from './accountability';
import user from './user';
import document from './document';
import activity from './activity';
import notification from './notification';
import dashboard from './dashboard';

const reducer = combineReducers({
  notice,
  startup,
  auth,
  application,
  event,
  accountability,
  user,
  document,
  activity,
  notification,
  dashboard,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === '@auth/LOGOUT') {
    storage.removeItem('persist:phi_reducers');
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key: any) => {
    const blacklistPathsForKey = blacklistPaths
      .filter((path) => path.startsWith(`${String(key)}.`))
      .map((path) => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: 'phi_reducers',
  storage,
  blacklist: [''],
  transforms: [blacklistProperty(['auth.loading'])],
};

export default persistReducer(authPersistConfig, rootReducer);
export type RootState = ReturnType<typeof reducer>;
