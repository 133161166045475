import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import React from 'react';
import { TooltipProps } from './types';

export const Tooltip: React.FC<TooltipProps> = ({
  label,
  isDisabled,
  children,
  placement = 'auto',
  ...props
}) => {
  return (
    <ChakraTooltip
      hasArrow
      bg='black.1'
      p='8.5px 12px'
      borderRadius='5px'
      isDisabled={isDisabled || !label}
      label={label}
      placement={placement}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};
