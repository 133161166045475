import React from 'react';

const IconConfirmNotice = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="30" fill="#CEEAF8" />
      <path d="M42.1875 17H17.8125C16.2592 17 15 18.2592 15 19.8125V40.4375C15 41.9908 16.2592 43.25 17.8125 43.25H42.1875C43.7408 43.25 45 41.9908 45 40.4375V19.8125C45 18.2592 43.7408 17 42.1875 17ZM41.8359 40.4375H18.1641C18.0708 40.4375 17.9814 40.4005 17.9155 40.3345C17.8495 40.2686 17.8125 40.1792 17.8125 40.0859V20.1641C17.8125 20.0708 17.8495 19.9814 17.9155 19.9155C17.9814 19.8495 18.0708 19.8125 18.1641 19.8125H41.8359C41.9292 19.8125 42.0186 19.8495 42.0845 19.9155C42.1505 19.9814 42.1875 20.0708 42.1875 20.1641V40.0859C42.1875 40.1792 42.1505 40.2686 42.0845 40.3345C42.0186 40.4005 41.9292 40.4375 41.8359 40.4375ZM39.375 35.0469V36.4531C39.375 36.8414 39.0602 37.1562 38.6719 37.1562H26.9531C26.5648 37.1562 26.25 36.8414 26.25 36.4531V35.0469C26.25 34.6586 26.5648 34.3438 26.9531 34.3438H38.6719C39.0602 34.3438 39.375 34.6586 39.375 35.0469ZM39.375 29.4219V30.8281C39.375 31.2164 39.0602 31.5312 38.6719 31.5312H26.9531C26.5648 31.5312 26.25 31.2164 26.25 30.8281V29.4219C26.25 29.0336 26.5648 28.7188 26.9531 28.7188H38.6719C39.0602 28.7188 39.375 29.0336 39.375 29.4219ZM39.375 23.7969V25.2031C39.375 25.5914 39.0602 25.9062 38.6719 25.9062H26.9531C26.5648 25.9062 26.25 25.5914 26.25 25.2031V23.7969C26.25 23.4086 26.5648 23.0938 26.9531 23.0938H38.6719C39.0602 23.0938 39.375 23.4086 39.375 23.7969ZM24.6094 24.5C24.6094 25.665 23.665 26.6094 22.5 26.6094C21.335 26.6094 20.3906 25.665 20.3906 24.5C20.3906 23.335 21.335 22.3906 22.5 22.3906C23.665 22.3906 24.6094 23.335 24.6094 24.5ZM24.6094 30.125C24.6094 31.29 23.665 32.2344 22.5 32.2344C21.335 32.2344 20.3906 31.29 20.3906 30.125C20.3906 28.96 21.335 28.0156 22.5 28.0156C23.665 28.0156 24.6094 28.96 24.6094 30.125ZM24.6094 35.75C24.6094 36.915 23.665 37.8594 22.5 37.8594C21.335 37.8594 20.3906 36.915 20.3906 35.75C20.3906 34.585 21.335 33.6406 22.5 33.6406C23.665 33.6406 24.6094 34.585 24.6094 35.75Z" fill="#1997F3" />
    </svg>

  );
};

export default IconConfirmNotice;
