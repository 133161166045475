import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListStartupFoldersDocument as UsecaseRemoteListStartupFoldersDocument } from '~/domain/usecases/document/remote';
import { ListStartupFoldersDocument as UsecaseReduxListStartupFoldersDocument } from '~/domain/usecases/document/redux';

import { RemoteListStartupFoldersDocument } from '~/data/repository/document';
import { ReduxListStartupFoldersDocument } from '~/data/store/reducer/document/usecases';

/**
 * send request via API.
 */
export const makeRemoteListStartupFoldersDocument =
  (): UsecaseRemoteListStartupFoldersDocument =>
    new RemoteListStartupFoldersDocument(
      makeApiUrl('/documents/startups/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListStartupFoldersDocument =
  (): UsecaseReduxListStartupFoldersDocument =>
    new ReduxListStartupFoldersDocument();
