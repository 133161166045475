import {
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import IconConfirmNotice from '~/presentation/base/icons/iconConfirmNotice';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterNoticeSuccess = ({ name, fullname, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
      <Confirmation
        isOpen={active === 'SUBMMIT_NOTICE'}
        onClose={() => {
          // eslint-disable-next-line no-console
          console.log('close');
        }}
        topButtonText={'Cadastrar novo edital'}
        onOpenRegister={onOpenRegister}
        icon={<IconConfirmNotice />}
        title='Edital cadastrado!'
        body={
          <Box fontSize='sm' color={'#60656D'}>
            <Stack spacing='5'>
              <Text>O Edital foi cadastrado com sucesso. Lembre-se que:</Text>
              <UnorderedList pl='5' spacing='5'>
                <ListItem>
                  <Text>
                    Se precisar modificar as informações deste edital ou de suas
                    etapas, clique no botão “Editar” na lista principal;
                  </Text>
                </ListItem>
                <ListItem>
                  <Text display={'list-item'}>
                    Para permitir que uma startup passe de uma etapa outra,
                    altere o campo “Etapa associada” no Painel de Startups;
                  </Text>
                </ListItem>
              </UnorderedList>
            </Stack>
          </Box>
        }
      />
  );
};

export default RegisterNoticeSuccess;
