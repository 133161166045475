import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/notice/actions/list';

import { iActionList } from '~/domain/interfaces/redux/notice/list';

import { ListNotice } from '~/domain/usecases/notice/redux';

export class ReduxListNotice implements ListNotice {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListNotice.Params): iActionList =>
    this.send(listRequest(params));
}
