import * as yup from 'yup';

export const schemaRegisterStage = {
  initial: {
    name: '',
    description: '',
    start: new Date(),
    end: new Date(),
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    start: yup.date().required('Campo obrigatório'),
    end: yup.date().required('Campo obrigatório'),
  }),
};
