import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateStartup as UsecaseRemoteUpdateStartup } from '~/domain/usecases/startup/remote';
// import { UpdateStartup as UsecaseReduxUpdateStartup } from '~/domain/usecases/startup/redux';

import { RemoteUpdateStartup } from '~/data/repository/startup';
// import { ReduxUpdateStartup } from '~/data/store/reducer/startup/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateStartup =
  (): UsecaseRemoteUpdateStartup =>
    new RemoteUpdateStartup(
      makeApiUrl('/startups/{startup}'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateStartup =
  (): UsecaseReduxUpdateStartup =>
    new ReduxUpdateStartup(); */
