import { takeLatest } from 'redux-saga/effects';
import {
  onListStartupFolders,
  onListStartupFoldersSuccess,
  onListStartupFoldersFailed,
} from './ListStartupFolders';
import {
  onListNoticeFolders,
  onListNoticeFoldersSuccess,
  onListNoticeFoldersFailed,
} from './ListNoticeFolders';
import {
  onListFolderContent,
  onListFolderContentSuccess,
  onListFolderContentFailed,
} from './ListFolderContent';
import {
  onListMentoringFolders,
  onListMentoringFoldersSuccess,
  onListMentoringFoldersFailed,
} from './ListMentoringFolders';
import {
  onListDeliveryFolders,
  onListDeliveryFoldersSuccess,
  onListDeliveryFoldersFailed,
} from './ListDeliveryFolders';

import { DocumentTypes } from '~/domain/interfaces/redux/document/types';

const tasks = [
  takeLatest(DocumentTypes.LISTSTARTUPFOLDERS, onListStartupFolders),
  takeLatest(
    DocumentTypes.LISTSTARTUPFOLDERS_SUCCESS,
    onListStartupFoldersSuccess
  ),
  takeLatest(
    DocumentTypes.LISTSTARTUPFOLDERS_FAILED,
    onListStartupFoldersFailed
  ),
  takeLatest(DocumentTypes.LISTNOTICEFOLDERS, onListNoticeFolders),
  takeLatest(
    DocumentTypes.LISTNOTICEFOLDERS_SUCCESS,
    onListNoticeFoldersSuccess
  ),
  takeLatest(DocumentTypes.LISTNOTICEFOLDERS_FAILED, onListNoticeFoldersFailed),
  takeLatest(DocumentTypes.LISTFOLDERCONTENT, onListFolderContent),
  takeLatest(
    DocumentTypes.LISTFOLDERCONTENT_SUCCESS,
    onListFolderContentSuccess
  ),
  takeLatest(DocumentTypes.LISTFOLDERCONTENT_FAILED, onListFolderContentFailed),
  takeLatest(DocumentTypes.LISTMENTORINGFOLDERS, onListMentoringFolders),
  takeLatest(
    DocumentTypes.LISTMENTORINGFOLDERS_SUCCESS,
    onListMentoringFoldersSuccess
  ),
  takeLatest(
    DocumentTypes.LISTMENTORINGFOLDERS_FAILED,
    onListMentoringFoldersFailed
  ),
  takeLatest(DocumentTypes.LISTDELIVERYFOLDERS, onListDeliveryFolders),
  takeLatest(
    DocumentTypes.LISTDELIVERYFOLDERS_SUCCESS,
    onListDeliveryFoldersSuccess
  ),
  takeLatest(
    DocumentTypes.LISTDELIVERYFOLDERS_FAILED,
    onListDeliveryFoldersFailed
  ),
];

export default tasks;
