import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  ModalFooter,
  HStack,
  Divider,
} from '@chakra-ui/react';
import * as datefns from 'date-fns';
import { schemaEditMember } from '~/validators/RegisterMember/EditMemberValidator';
import { useFormik } from 'formik';
import FormEditMember from '~/presentation/components/Form/FormEditMember/FormEditMember';
import { makeRemoteUpdateUser } from '~/main/factories/usecases/user/UpdateUserFactory';
import { makeRemoteGetUser } from '~/main/factories/usecases/user/GetUserFactory';
import StringMask from '~/utils/getStringMask';
import { makeReduxListUser } from '~/main/factories/usecases/user/ListUserFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import UploadImage from '~/presentation/components/UploadImage';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxRefreshAuth } from '~/main/factories/usecases/auth/RefreshAuthFactory';
import { Conflict } from '~/domain/errors/Conflict';

interface ownProps {
  id?: number;
  isOpen: boolean;
  isLoggedUser?: boolean;
  onClose: () => void;
}

const EditMember = ({
  id,
  isOpen,
  isLoggedUser,
  onClose,
}: ownProps): JSX.Element => {
  const { initial, validators } = schemaEditMember;
  const [selectedTab, setSelectedTab] = useState('Supervisor');
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<File | null>(null);
  const [user, setUser] = React.useState<{
    fullname: string;
    avatar: string;
  }>();
  const { accessToken, refreshToken } = useSelector(
    (store: iStore) => store.auth
  );

  useEffect(() => {
    id &&
      isOpen &&
      makeRemoteGetUser()
        .get({ id })
        .then((response) => {
          formik.setValues({
            ...formik.values,
            fullname: response.fullname,
            address: response.address.street,
            email: response.email,
            job: response.job,
            workload: response.workload,
            role: response.role.toString(),
            cpf: new StringMask('000.000.000-00').apply(response.cpf),
            phone: new StringMask('(00) 00000-0000').apply(response.phone),
            cep: response.address.zipcode,
            rg: response.rg,
            datepicker: new Date(response?.birthdate + 'T00:00:00'),
            gender: response.gender,
            //TO DO: Arrumar a requisição do getUser{id} para assim então solicitar o "gender" no Schema
            //gender: response.gender,
          });
          setUser(response);
        });
  }, [id, isOpen]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      setIsLoading(true);
      id &&
        makeRemoteUpdateUser()
          .update({
            id: id,
            body: {
              fullname: values.fullname,
              email: values.email,
              address: values.address,
              cpf: values.cpf.replace(/\D/g, ''),
              phone: values.phone.replace(/\D/g, ''),
              job: values.job,
              workload: values.workload,
              cep: values.cep,
              rg: values.rg,
              birthdate: datefns.format(
                new Date(values.datepicker),
                'yyyy-MM-dd'
              ),
              gender: values.gender,
            },
          })
          .then(async () => {
            if (image !== null) {
              const formData = new FormData();
              formData.append('file', image);

              await axios.post(makeApiUrl(`/users/${id}/avatar`), formData, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              isLoggedUser &&
                (await axios
                  .post(makeApiUrl('/auth/refresh'), {
                    token: refreshToken,
                  })
                  .then((response) => {
                    makeReduxRefreshAuth().refresh({
                      ...response.data,
                    });
                  }));
            }

            makeReduxListUser().list({});
            !isLoggedUser && showConfirmModal('EDIT_USER');
          })
          .catch((error) => {
            if (error instanceof Conflict) {
              !isLoggedUser && showConfirmModal('CONFLICT_ERROR');
            } else {
              !isLoggedUser && showConfirmModal('APPLICATION_ERROR');
            }
          })
          .finally(() => {
            setIsLoading(false);
            onCloseModal();
          });
    },
  });

  const onCloseModal = () => {
    onClose();
    formik.resetForm({ values: initial });
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW='800px' bg='#F8F8F8'>
          <ModalHeader fontSize='18px' py='3'>
            Editar Informações
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify='space-between' gap={'32px'}>
              <UploadImage
                onChange={setImage}
                oldData={{ name: user?.fullname, url: user?.avatar }}
              />
              <Divider
                orientation='vertical'
                display='inline-flex'
                height='532px'
                position='relative'
                left='15px'
              />
              <Stack rowGap={'12px'}>
                {/* <Box w='50%'>
                  <Text fontWeight='bold' fontSize='sm' mb='2'>
                    Papel do usuário<span style={{ color: 'red' }}>*</span>
                  </Text>
                  <Tabs variant='unstyled' defaultIndex={0}>
                    <Flex direction='column' rowGap='12px'>
                      <TabList gap={3} h='40px' color='#303950'>
                        <Tab
                          w='83px'
                          bg='#F9F8FA'
                          border='1px solid rgba(91, 100, 107, 0.5)'
                          borderRadius='5px'
                          fontWeight='500'
                          _selected={{
                            bg: '#FFF2EE',
                            border: '1px solid #E74D39',
                          }}
                          onClick={() => {
                            formik.setFieldValue('permission', 'ADMIN');
                            setSelectedTab('Admin');
                          }}
                        >
                          Admin
                        </Tab>
                        <Tab
                          w='113px'
                          bg='#F9F8FA'
                          border='1px solid rgba(91, 100, 107, 0.5)'
                          borderRadius='5px'
                          fontWeight='500'
                          _selected={{
                            bg: '#F3F9E7',
                            border: '1px solid #5B7A17',
                          }}
                          onClick={() => {
                            formik.setFieldValue('permission', 'SUPERVISOR');
                            setSelectedTab('Supervisor');
                          }}
                        >
                          Supervisor
                        </Tab>
                        <Tab
                          w='89px'
                          bg='#F9F8FA'
                          border='1px solid rgba(91, 100, 107, 0.5)'
                          borderRadius='5px'
                          fontWeight='500'
                          _selected={{
                            bg: '#F6E1FA',
                            border: '1px solid #AC38A8',
                          }}
                          onClick={() => {
                            formik.setFieldValue('permission', 'MENTOR');
                            setSelectedTab('Mentor');
                          }}
                        >
                          Mentor
                        </Tab>
                        <Tab
                          w='86px'
                          bg='#F9F8FA'
                          border='1px solid rgba(91, 100, 107, 0.5)'
                          borderRadius='5px'
                          fontWeight='500'
                          _selected={{
                            bg: '#E4F0F7',
                            border: '1px solid #1863A8',
                          }}
                          onClick={() => {
                            formik.setFieldValue('permission', 'MANAGER');
                            setSelectedTab('Gestor');
                          }}
                        >
                          Gestor
                        </Tab>
                        <Tab
                          w='96px'
                          bg='#F9F8FA'
                          border='1px solid rgba(91, 100, 107, 0.5)'
                          borderRadius='5px'
                          fontWeight='500'
                          _selected={{
                            bg: '#FFF5E0',
                            border: '1px solid #BD5114',
                          }}
                          onClick={() => {
                            formik.setFieldValue('permission', 'MEMBER');
                            setSelectedTab('Membro');
                          }}
                        >
                          Membro
                        </Tab>
                      </TabList>
                    </Flex>
                  </Tabs>
                </Box> */}
                <FormEditMember formik={formik} />
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter pt='20px'>
            <Stack>
              <Divider
                orientation='horizontal'
                display='inline-flex'
                width='738px'
                position='relative'
                mb={'12px'}
              />
              <HStack justifyContent='space-between' w='100%' spacing='4'>
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'
                >
                  <Button
                    w='100px'
                    variant='outline'
                    color='#60656D'
                    borderColor='#CECECE'
                    onClick={onCloseModal}
                    fontWeight='500'
                    fontSize='14px'
                  >
                    Cancelar
                  </Button>
                  {
                    <Button
                      id='submit'
                      bg='#303950'
                      _hover={{ background: '#788FC9' }}
                      _active={{ background: '#303950' }}
                      color='white'
                      isLoading={isLoading}
                      loadingText='Salvando alterações'
                      fontWeight='500'
                      fontSize='14px'
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Salvar alterações
                    </Button>
                  }
                </Flex>
              </HStack>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditMember;
