import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconError } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
}

const SubmitionApplicationError = ({ name, fullname }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'APPLICATION_ERROR'}
      showTopButton={false}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconError />}
      title='Oops! Algo deu errado...!'
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text textAlign={'center'}>
              Não foi possível realizar esta operação. Clique no botão abaixo
              para voltar à página inicial.
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Voltar ao início'
      returnPage='/inicio'
      errorConfirmation
    ></Confirmation>
  );
};

export default SubmitionApplicationError;
