import { iUser } from '~/domain/interfaces/models/User';
import { UserTypes } from '~/domain/interfaces/redux/user/types';
import { UserActions } from './actions';

export const initialState: iUser = {
  loading: false,
  records: [],
};

const reducer = (state = initialState, action: UserActions): iUser => {
  switch (action.type) {
    case UserTypes.LIST:
      return { ...state, loading: true };
    case UserTypes.LIST_FAILED:
      return { ...state, loading: false };
    case UserTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
