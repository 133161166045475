import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  Box,
  Stack,
  Text,
  Tag,
  TagLabel,
  useDisclosure,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import * as mime from 'mime';
import {
  IconDelete,
  IconDOCX,
  IconDownload,
  IconEdit,
  IconMP4,
  IconPDF,
  IconPlus,
  IconPNG,
  IconPPT,
  IconShared,
  IconView,
  IconXLS,
} from '~/presentation/base/icons';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import { iNotice } from '~/domain/interfaces/models';

const Arquivos: React.FC = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error } = useSelector((store: iStore) => store.auth);
  const [da, setDa] = React.useState<iNotice['records']>([]);
  const { records } = useSelector((store: iStore) => store.notice);
  const userStartup = useSelector((store: iStore) =>
    store.startup.records.find(
      (el) => el.fullname === store.auth.user?.fullname
    )
  );
  const applications = useSelector((store: iStore) =>
    store.application.records?.filter((el) => el.startup_?.id === userStartup?.id)
  );

  return (
    <>
      <Button
        onClick={onOpen}
        width='40px'
        bg='#F9F8FA'
        color='#1C294B'
        _hover={{ background: '#1C294B', color: 'white' }}
        boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
        padding='10px 12px 11px 12px'
      >
        <IconDownload />
      </Button>

      <Modal size='2xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize='2xl' fontWeight='bold'>
            Arquivos do edital
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer
              borderTop='1px solid #cfcfcf'
              borderBottom='1px solid #cfcfcf'
              borderLeft='1px solid #cfcfcf'
              borderRight='1px solid #cfcfcf'
              borderRadius='0.375rem'
              marginLeft={30}
              marginRight={30}
            >
              <Table variant='simple' colorScheme='white'>
                <Tbody>
                  {records?.map((item: any, index: any) => (
                    <Tr
                      fontSize={14}
                      fontWeight={400}
                      color='#747C86'
                      key={index}
                    >
                      <Td>
                        <HStack spacing='4'>
                          <IconPDF />
                          <Box fontSize='sm'>
                            <Text fontWeight='semibold'>
                              {`N° ${item.code}`} {item.title}
                            </Text>
                            <Text fontSize='xs'>{item.size}</Text>
                          </Box>
                        </HStack>
                      </Td>
                      <Td gap={15}>
                        <Button
                          onClick={onOpen}
                          width='40px'
                          bg='#F9F8FA'
                          color='#1C294B'
                          _hover={{ background: '#1C294B', color: 'white' }}
                          boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
                          padding='10px 12px 11px 12px'
                        >
                          <IconDownload />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const renderIcons = (type: string) => {
  // eslint-disable-next-line no-console
  console.log('>>> type', type);
  switch (type) {
    case '.pdf':
      return <IconPDF />;
    case '.docx':
      return <IconDOCX />;
    case '.xlsx':
      return <IconXLS />;
    case '.pptx':
      return <IconPPT />;
    case '.mp4':
      return <IconMP4 />;
    case '.png':
      return <IconPNG />;
    default:
      return 'Type not found';
  }
};

export default Arquivos;
