import React from 'react';

const IconMenuEvent = () => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.64826 1.78488V0.5H3.21221V1.78488H0.718023C0.527592 1.78488 0.34496 1.86053 0.210304 1.99519C0.0756486 2.12984 0 2.31248 0 2.50291V12.782C0 12.9724 0.0756486 13.155 0.210304 13.2897C0.34496 13.4244 0.527592 13.5 0.718023 13.5H12.282C12.4724 13.5 12.655 13.4244 12.7897 13.2897C12.9244 13.155 13 12.9724 13 12.782V2.50291C13 2.31248 12.9244 2.12984 12.7897 1.99519C12.655 1.86053 12.4724 1.78488 12.282 1.78488H9.78779V0.5H8.35174V1.78488H4.64826ZM8.35174 4.50581H9.78779V3.22093H11.564V5.63953H1.43605V3.22093H3.21221V4.50581H4.64826V3.22093H8.35174V4.50581ZM11.564 12.064H1.43605V7.07558H11.564V12.064Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuEvent;
