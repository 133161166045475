import React from 'react';

const IconUploadDocs = (): JSX.Element => {
  return (
    <svg
      width='30'
      height='24'
      viewBox='0 0 30 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 19.5H16.5V13.2L18.9 15.6L21 13.5L15 7.5L9 13.5L11.1 15.6L13.5 13.2V19.5ZM3 24C2.175 24 1.4685 23.706 0.880502 23.118C0.292502 22.53 -0.000997453 21.824 2.54669e-06 21V3C2.54669e-06 2.175 0.294003 1.4685 0.882002 0.880502C1.47 0.292502 2.176 -0.000997453 3 2.54669e-06H12L15 3H27C27.825 3 28.5315 3.294 29.1195 3.882C29.7075 4.47 30.001 5.176 30 6V21C30 21.825 29.706 22.5315 29.118 23.1195C28.53 23.7075 27.824 24.001 27 24H3ZM3 3V21H27V6H13.7625L10.7625 3H3Z'
        fill='#60656D'
      />
    </svg>
  );
};

export default IconUploadDocs;
