import { ListApplication } from '~/domain/usecases/application/redux/ListApplication';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  ApplicationTypes,
} from '~/domain/interfaces/redux/application/list';

export const listRequest = (payload: ListApplication.Params): iActionList => ({
  type: ApplicationTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListApplication.Model
): iActionListSuccess => ({
  type: ApplicationTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: ApplicationTypes.LIST_FAILED,
});
