import * as yup from 'yup';

export const schemaEvaluateApplication = {
  initial: {
    status: '' as 'APPROVED' | 'REJECTED' | 'SUBMITTED' | 'REVIEW',
    justify: '',
  },
  validators: yup.object({
    status: yup
      .string()
      .oneOf(['APPROVED', 'REJECTED'])
      .required('Campo obrigatório'),
      justify: yup.string(),
  }),
};