import React from 'react';

const IconHomeButtonRegistrations = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#FFF5E0' />
      <path
        d='M22.9213 9C23.4179 9 23.8313 9.16903 24.1692 9.50756L25.492 10.8304C25.8323 11.1707 26.0085 11.5784 26.0233 12.0595C26.0381 12.5381 25.8776 12.9425 25.5393 13.2802L24.2785 14.5411L20.4361 10.7438L21.6723 9.50764C22.0109 9.16904 22.4246 9 22.9213 9Z'
        fill='#DE6D04'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.7368 16.1064L18.8935 12.2629L9 22.1565V26H12.8434L22.7368 16.1064ZM10.6462 22.8588V24.3537H12.1411L20.412 16.0827L18.9171 14.5878L10.6462 22.8588Z'
        fill='#DE6D04'
      />
    </svg>
  );
};

export default IconHomeButtonRegistrations;
