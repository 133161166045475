import React from 'react';

const IconDeleteDocs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.1 3.5H1.4V12.6C1.4 12.9713 1.5475 13.3274 1.81005 13.5899C2.0726 13.8525 2.4287 14 2.8 14H9.8C10.1713 14 10.5274 13.8525 10.79 13.5899C11.0525 13.3274 11.2 12.9713 11.2 12.6V3.5H2.1ZM4.9 11.9H3.5V5.6H4.9V11.9ZM9.1 11.9H7.7V5.6H9.1V11.9ZM9.5326 1.4L8.4 0H4.2L3.0674 1.4H0V2.8H12.6V1.4H9.5326Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconDeleteDocs;
