import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

const isUserRole = (role: string[]): boolean => {
  const { user } = useSelector((store: iStore) => store.auth);

  return user ? role.includes(user.role_.name) : false;
};

export default isUserRole;
