import React from 'react';

const IconFilterNew = (): JSX.Element => {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5312 10.1111H0.644531V11.6667H8.5312C8.8812 13.0044 10.0868 14 11.5334 14C12.9801 14 14.1856 13.0044 14.5356 11.6667H16.2001V10.1111H14.5356C14.1856 8.77333 12.9801 7.77778 11.5334 7.77778C10.0868 7.77778 8.8812 8.77333 8.5312 10.1111ZM9.97787 10.8889C9.97787 10.0333 10.6779 9.33333 11.5334 9.33333C12.389 9.33333 13.089 10.0333 13.089 10.8889C13.089 11.7444 12.389 12.4444 11.5334 12.4444C10.6779 12.4444 9.97787 11.7444 9.97787 10.8889ZM0.644531 2.33333V3.88889H2.30898C2.65898 5.22667 3.86453 6.22222 5.3112 6.22222C6.75786 6.22222 7.96342 5.22667 8.31342 3.88889H16.2001V2.33333H8.31342C7.96342 0.995556 6.75786 0 5.3112 0C3.86453 0 2.65898 0.995556 2.30898 2.33333H0.644531ZM3.75564 3.11111C3.75564 2.25556 4.45564 1.55556 5.3112 1.55556C6.16675 1.55556 6.86675 2.25556 6.86675 3.11111C6.86675 3.96667 6.16675 4.66667 5.3112 4.66667C4.45564 4.66667 3.75564 3.96667 3.75564 3.11111Z" fill="#222222" />
        </svg>
    );
};

export default IconFilterNew;
