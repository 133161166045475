import * as yup from 'yup';

export const schemaRegisterNotice = {
  initial: {
    code: '',
    title: '',
    description: '',
    vacancies: '',
    value: '0',
    start: new Date(),
    end: new Date(new Date().setHours(23, 59, 59, 999)),
  },
  validators: yup.object({
    code: yup.string().required('Campo obrigatório'),
    title: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    vacancies: yup.string().required('Campo obrigatório'),
    value: yup
      .string()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Campo obrigatório'),
    start: yup.date(),
    end: yup.date().required('Campo obrigatório'),
  }),
};
