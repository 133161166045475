import {
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IconAttention } from '~/presentation/base/icons';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  question: string;
  description: string;
  action: () => void;
  actionText: string;
  actionTextLoading?: string;
}

const WarningModal = ({
  isOpen,
  onClose,
  title,
  question,
  description,
  action,
  actionText,
  actionTextLoading,
}: ownProps): JSX.Element => {
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (!isOpen) setLoading(false);
  }, [isOpen]);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p='6'>
          <ModalHeader p='0' whiteSpace='nowrap'>
            <Flex
              flexDirection='row'
              justifyContent={'space-between'}
              alignItems='center'
              paddingBottom={'22.5px'}
            >
              <Text fontWeight='700' fontSize='18px' color={'#303950'}>
                {title}
              </Text>
              <ModalCloseButton
                size='md'
                position={'inherit'}
                color={'#60656D'}
              ></ModalCloseButton>
            </Flex>
          </ModalHeader>
          <ModalBody p='0'>
            <Flex flexDir={'column'} justify='space-between'>
              <Flex flexDir='column' gap='20px'>
                <Text fontSize='sm' color='#676D75'>
                  {question}
                </Text>
                <Flex
                  backgroundColor='#FFEFEA'
                  borderRadius='5px'
                  padding='16px 12px'
                  flexDir={'row'}
                  gap='12px'
                >
                  <Box>
                    <IconAttention></IconAttention>
                  </Box>
                  <Flex flexDir={'column'} gap='12px'>
                    <Text
                      fontStyle='bold'
                      fontWeight='700'
                      fontSize='sm'
                      color='#C23422'
                    >
                      Atenção{' '}
                      <Text
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize='sm'
                        color='#C23422'
                      >
                        {description}
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
                <Box borderTop='1px solid #E9ECEE '></Box>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            p='0'
            mt='20px'
            justifyContent='space-between'
            alignItems='center'
            gap='10px'
          >
            <Button
              width='100px'
              bg='#F9F8FA'
              color='#60656D'
              _hover={{ background: '#E9ECEE', color: '#303950' }}
              _active={{ background: '#CECECE', color: '#303950' }}
              boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
              padding='10px, 22px, 10px, 22px'
              border='1px solid #CECECE'
              onClick={() => onClose()}
              fontWeight='500'
              fontSize='sm'
            >
              Cancelar
            </Button>
            <Button
              width={loading ? '120px' : '100px'}
              bg='#C23422'
              color='#F9F8FA'
              _hover={{ background: '#E74D39', color: '' }}
              _active={{ background: '#9B3457', color: '#F9F8FA' }}
              boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
              padding='10px, 22px, 10px, 22px'
              onClick={() => {
                action(), setLoading(true);
              }}
              isLoading={loading}
              loadingText={actionTextLoading}
              fontWeight='500'
              fontSize='sm'
            >
              {actionText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WarningModal;
