import React from 'react';
import { Box, FormLabel, Stack, Text } from '@chakra-ui/react';
import Input from '../../UI/Input';
import Select from '../../UI/select';

interface ownProps {
  formik: any;
}

const FormRegisterMember = ({ formik }: ownProps): JSX.Element => {
  return (
    <Stack spacing='4'>
      <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
        Nome completo<span style={{ color: 'red' }}>*</span>
      </FormLabel>
      <Input id='name' w='360px' name='name' formik={formik} isRequired />
      <FormLabel
        htmlFor='name'
        display='block'
        fontWeight={'700'}
        fontSize='14px'
        mb='0px'
      >
        E-mail<span style={{ color: 'red' }}>*</span>
      </FormLabel>
      <Input id='email' w='360px' name='email' formik={formik} />
      <FormLabel
        htmlFor='name'
        display='block'
        fontWeight={'700'}
        fontSize='14px'
        mb='0px'
      >
        Cargo<span style={{ color: 'red' }}>*</span>
      </FormLabel>
      <Input id='role' w='360px' name='job' formik={formik} />
      <Select
        id='workload'
        name='workload'
        required
        isRequired
        placeholder='Selecione'
        bg='#F9F8FA'
        onChange={formik.handleChange}
        value={formik.values.workload}
        label={'Dedicação'}
        formik={formik}
      >
        <option value='20h/semana'>20h/semana</option>
        <option value='40h/semana'>40h/semana</option>
      </Select>
    </Stack>
  );
};

export default FormRegisterMember;
