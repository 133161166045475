import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListEvent as UsecaseRemoteListEvent } from '~/domain/usecases/event/remote';
import { ListEvent as UsecaseReduxListEvent } from '~/domain/usecases/event/redux';

import { RemoteListEvent } from '~/data/repository/event';
import { ReduxListEvent } from '~/data/store/reducer/event/usecases';

/**
 * send request via API.
 */
export const makeRemoteListEvent = (): UsecaseRemoteListEvent =>
  new RemoteListEvent(makeApiUrl('/events/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListEvent = (): UsecaseReduxListEvent =>
  new ReduxListEvent();
