import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateEvent as UsecaseRemoteUpdateEvent } from '~/domain/usecases/event/remote';
// import { UpdateEvent as UsecaseReduxUpdateEvent } from '~/domain/usecases/event/redux';

import { RemoteUpdateEvent } from '~/data/repository/event';
// import { ReduxUpdateEvent } from '~/data/store/reducer/event/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateEvent = (): UsecaseRemoteUpdateEvent =>
  new RemoteUpdateEvent(makeApiUrl('/events/{event}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateEvent =
  (): UsecaseReduxUpdateEvent =>
    new ReduxUpdateEvent(); */
