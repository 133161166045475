import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import EsqueceuSenha from './EsqueceuSenha';

const ForgotPassword = (): JSX.Element => {
  const [path, setPath] = React.useState('');
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <EsqueceuSenha></EsqueceuSenha>
  );
};

export default ForgotPassword;
