import jwtDecode from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import storeDev from '~/data/store';
/* import { iStore } from '~/domain/interfaces/models';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage'; */
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';
import { iStore } from '~/domain/interfaces/models';
import { makeApiUrl } from '~/main/factories/http';
import { makeReduxRefreshAuth } from '~/main/factories/usecases/auth/RefreshAuthFactory';
import { History } from '~/main/routes';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache
);

class AxiosHttpClient implements HttpClient {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;
    const store: iStore = storeDev.getState();

    const checkToken = async (_token: string, _refresh?: string) => {
      const decodedToken: any = jwtDecode(_token);
      // eslint-disable-next-line no-console
      console.log('Decoded Token', decodedToken);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        // eslint-disable-next-line no-console
        console.log('Token expired.');
        /* // eslint-disable-next-line no-console
        console.log(
          await makeReduxRefreshAuth().refresh({ body: { token: _token } })
        ); */
        await axios
          .post(makeApiUrl('/auth/refresh'), { token: _refresh })
          .then((response) => {
            // eslint-disable-next-line no-console
            console.log('... res', response);
            makeReduxRefreshAuth().refresh({
              ...response.data,
            });
            _token = response.data.accessToken;
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('>>> error refresh token', error);
            History.push('/login');
          });

        return _token;
      } else {
        // eslint-disable-next-line no-console
        console.log('Valid token');
        return _token;
      }
    };

    //to do
    const token = store.auth.accessToken;
    const refreshToken = store.auth.refreshToken;

    let authorizationHeader = data.headers;

    if (
      token &&
      (!data.headers ||
        (typeof data.headers === 'object' && Object.keys(data.headers).length))
    ) {
      authorizationHeader = {
        ...(!data.url.includes('dashboard') && {
          Authorization: `Bearer ${await checkToken(token, refreshToken)}`,
        }),
        ...data.headers,
      };
    }

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: authorizationHeader,
        responseType: data.responseType,
        params: data.params,
      });
    } catch (error: any) {
      if (error.response?.status === 502 || error.response?.status === 503) {
        let found = false;

        document.querySelectorAll('.notification__message').forEach((node) => {
          if (
            node.innerHTML.includes('Falha interna no servidor') ||
            node.innerHTML.includes('Internal server failure') ||
            node.innerHTML.includes('Fallo interno del servidor')
          ) {
            found = true;
          }
        });

        if (!found) {
          /* AlertMessage({
            message: intl.formatMessage({
              id: 'Falha interna no servidor, tente novamente em minutos.',
            }),
            type: 'danger',
          }); */
        }
      }
      axiosResponse = error.response;
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
    };
  };
}

export default AxiosHttpClient;
