import React, { useEffect } from 'react';
import Home from '~/presentation/pages/home';
import { makeReduxListNotification } from '../../usecases/notification/ListNotificationFactory';

export const makeHomeFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListNotification().list({ query: { limit: 999 } });
  }, []);

  return <Home />;
};
