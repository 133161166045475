import { Dispatch } from 'redux';
import store from '~/data/store';
import { listDeliveryFoldersRequest } from '~/data/store/reducer/document/actions/listDeliveryFolders';

import { iActionListDeliveryFolders } from '~/domain/interfaces/redux/document/listDeliveryFolders';

import { ListDeliveryFoldersDocument } from '~/domain/usecases/document/redux';

export class ReduxListDeliveryFoldersDocument
  implements ListDeliveryFoldersDocument
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listDeliveryFolders = (
    params: ListDeliveryFoldersDocument.Params
  ): iActionListDeliveryFolders =>
    this.send(listDeliveryFoldersRequest(params));
}
