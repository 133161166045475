enum EventType {
  Meeting = 'MEETING',
  Mentoring = 'MENTORING',
  Delivery = 'DELIVERY',
}

const getColorByEventType = (eventType: string): string => {
  switch (eventType) {
    case EventType.Meeting:
      return '#1997F3';
    case EventType.Mentoring:
      return '#AC38A8';
    case EventType.Delivery:
      return '#ed7a07';
    default:
      return '#1997F3';
  }
};

export { EventType, getColorByEventType };
