import { ListNoticeFoldersDocument } from '~/domain/usecases/document/redux/ListNoticeFoldersDocument';
import {
  iActionListNoticeFolders,
  iActionListNoticeFoldersFailed,
  iActionListNoticeFoldersSuccess,
  DocumentTypes,
} from '~/domain/interfaces/redux/document/listNoticeFolders';

export const listNoticeFoldersRequest = (
  payload: ListNoticeFoldersDocument.Params
): iActionListNoticeFolders => ({
  type: DocumentTypes.LISTNOTICEFOLDERS,
  payload,
});

export const listNoticeFoldersSuccess = (
  params: ListNoticeFoldersDocument.Model
): iActionListNoticeFoldersSuccess => ({
  type: DocumentTypes.LISTNOTICEFOLDERS_SUCCESS,
  payload: params,
});

export const listNoticeFoldersFailed = (): iActionListNoticeFoldersFailed => ({
  type: DocumentTypes.LISTNOTICEFOLDERS_FAILED,
});
