import React from 'react';

const IconHomeButtonAccountability = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#EBF3D9' />
      <path
        d='M18 27.5C12.7531 27.5 8.5 23.2469 8.5 18C8.5 12.7531 12.7531 8.5 18 8.5C23.2469 8.5 27.5 12.7531 27.5 18C27.5 23.2469 23.2469 27.5 18 27.5ZM18 25.6C20.0156 25.6 21.9487 24.7993 23.374 23.374C24.7993 21.9487 25.6 20.0156 25.6 18C25.6 15.9844 24.7993 14.0513 23.374 12.626C21.9487 11.2007 20.0156 10.4 18 10.4C15.9844 10.4 14.0513 11.2007 12.626 12.626C11.2007 14.0513 10.4 15.9844 10.4 18C10.4 20.0156 11.2007 21.9487 12.626 23.374C14.0513 24.7993 15.9844 25.6 18 25.6ZM14.675 19.9H19.9C20.026 19.9 20.1468 19.85 20.2359 19.7609C20.325 19.6718 20.375 19.551 20.375 19.425C20.375 19.299 20.325 19.1782 20.2359 19.0891C20.1468 19 20.026 18.95 19.9 18.95H16.1C15.4701 18.95 14.866 18.6998 14.4206 18.2544C13.9752 17.809 13.725 17.2049 13.725 16.575C13.725 15.9451 13.9752 15.341 14.4206 14.8956C14.866 14.4502 15.4701 14.2 16.1 14.2H17.05V12.3H18.95V14.2H21.325V16.1H16.1C15.974 16.1 15.8532 16.15 15.7641 16.2391C15.675 16.3282 15.625 16.449 15.625 16.575C15.625 16.701 15.675 16.8218 15.7641 16.9109C15.8532 17 15.974 17.05 16.1 17.05H19.9C20.5299 17.05 21.134 17.3002 21.5794 17.7456C22.0248 18.191 22.275 18.7951 22.275 19.425C22.275 20.0549 22.0248 20.659 21.5794 21.1044C21.134 21.5498 20.5299 21.8 19.9 21.8H18.95V23.7H17.05V21.8H14.675V19.9Z'
        fill='#5B7A17'
      />
    </svg>
  );
};

export default IconHomeButtonAccountability;
