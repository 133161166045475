import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  IconAnnoun,
  IconAnnounHover,
  IconCalendar,
  IconCalendarBlue,
  IconCapac,
  IconForum,
  IconLogoWhite,
  IconMenuHomeWhite,
  IconMenuStartupsWhite,
  IconMenuStartups,
  IconMenuTeamWhite,
  IconMenuTeam,
  IconMenuListAnnounWhite,
  IconMenuListAnnoun,
  IconMenuRegistrationsWhite,
  IconMenuRegistrations,
  IconMenuDocsWhite,
  IconMenuDocs,
  IconMenuBudgetWhite,
  IconMenuBudget,
  IconMenuHome,
  IconMenuEvent,
  IconMenuEventWhite,
  IconMenuExit,
  IconMenuDashboardWhite,
  IconMenuGearWhite,
} from '~/presentation/base/icons';
import MenuItem from './MenuItem';
import MenuDropdown from './MenuDropdown';
import { useLocation } from 'react-router';
import { withAccess } from '~/presentation/hooks/acess';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

const texto = () => {
  return <Text>Joia</Text>;
};
interface ownProps {
  isMobile?: boolean;
}

const Menu = ({ isMobile }: ownProps): JSX.Element => {
  const [path, setPath] = React.useState('');
  const location = useLocation();
  const { user } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <Flex
      w='270px'
      minW='270px'
      h='100%'
      bg='#222222'
      color='#E2E2E2'
      boxShadow='0px 2px 6px rgba(0, 0, 0, 0.1)'
      direction='column'
      borderRight='1px'
      borderColor='#777777'
      display={isMobile ? 'block' : { base: 'none', breakpoint1024: 'block' }}
    >
      <Box p='32px' mb='24px'>
        <IconLogoWhite />
      </Box>
      <MenuItem
        icon={IconMenuHomeWhite}
        iconHover={IconMenuHome}
        text='Início'
        name='inicio'
        selected={path === '/inicio'}
      />
      <MenuItem
        actions={['MENTOR', 'LEADER', 'MEMBER']}
        resourcers={['MENTOR', 'LEADER', 'MEMBER']}
        icon={IconMenuListAnnounWhite}
        iconHover={IconMenuListAnnoun}
        text='Editais'
        selected={path === '/editais'}
        name='/editais'
      />
      <Text
        fontSize='12px'
        fontWeight='600'
        lineHeight='base'
        my='5'
        mx='8'
        color='#9C9C9C'
      >
        {user?.role_.name === 'ADMIN' || user?.role_.name === 'SUPERVISOR'
          ? 'GESTÃO'
          : user?.role_.name === 'MENTOR'
          ? 'MENTORIA'
          : 'MINHA STARTUP'}
      </Text>
      <MenuItem
        actions={['SUPERVISOR', 'ADMIN', 'MENTOR']}
        resourcers={['SUPERVISOR', 'ADMIN', 'MENTOR']}
        icon={IconMenuStartupsWhite}
        iconHover={IconMenuStartups}
        text='Startups'
        selected={path === '/startups'}
        name='/startups'
      />
      <MenuItem
        actions={['SUPERVISOR', 'ADMIN', 'LEADER', 'MEMBER']}
        resourcers={['SUPERVISOR', 'ADMIN', 'LEADER', 'MEMBER']}
        icon={IconMenuTeamWhite}
        iconHover={IconMenuTeam}
        text={
          user?.role_.name === 'LEADER' || user?.role_.name === 'MEMBER'
            ? 'Equipe'
            : 'Usuários'
        }
        selected={path === '/usuarios'}
        name='/usuarios'
      />
      <MenuItem
        actions={['SUPERVISOR', 'ADMIN']}
        resourcers={['SUPERVISOR', 'ADMIN']}
        icon={IconMenuListAnnounWhite}
        iconHover={IconMenuListAnnoun}
        text='Editais'
        selected={path === '/editais'}
        name='/editais'
      />

      <Text
        fontSize='12px'
        fontWeight='600'
        lineHeight='base'
        my='5'
        mx='8'
        color='#9C9C9C'
        display={
          user?.role_.name === 'ADMIN' || user?.role_.name === 'SUPERVISOR'
            ? 'inherit'
            : 'none'
        }
      >
        ACOMPANHAMENTO
      </Text>

      <MenuItem
        actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
        resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
        icon={IconMenuRegistrationsWhite}
        iconHover={IconMenuRegistrations}
        text='Inscrições'
        selected={path === '/inscricoes'}
        name='/inscricoes'
      />

      <MenuItem
        icon={IconMenuEventWhite}
        iconHover={IconMenuEvent}
        text='Eventos'
        selected={path === '/eventos'}
        name='/eventos'
      />
      <MenuItem
        icon={IconMenuDocsWhite}
        iconHover={IconMenuDocs}
        text='Documentos'
        selected={path === '/documentos'}
        name='/documentos'
      />

      <MenuItem
        actions={['ADMIN', 'SUPERVISOR', 'LEADER']}
        resourcers={['ADMIN', 'SUPERVISOR', 'LEADER']}
        icon={IconMenuBudgetWhite}
        iconHover={IconMenuBudget}
        text='Finanças'
        name='/orcamento'
        selected={path === '/orcamento'}
      />
      <Text
        fontSize='12px'
        fontWeight='600'
        lineHeight='base'
        my='5'
        mx='8'
        color='#9C9C9C'
      >
        SISTEMA
      </Text>

      <MenuItem
        icon={IconMenuDashboardWhite}
        text='Estatísticas'
        name='/dashboard'
        selected={path === '/dashboard'}
        state={{ view: true }}
      />
      {/*
      <MenuItem
        icon={IconMenuGearWhite}
        text='Configurações'
        name='/'
      />
 */}
      <MenuItem icon={IconMenuExit} text='Sair' name='/' />
    </Flex>
  );
};

export default Menu;
