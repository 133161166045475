import React from 'react';

const IconLastPage = (): JSX.Element => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.56741 0.546193C0.61235 0.439029 0.676303 0.341803 0.759269 0.260133C1.10323 -0.0851266 1.67017 -0.0851266 2.00722 0.251059L6.0691 4.31294C6.15033 4.39504 6.21601 4.49227 6.26095 4.59943C6.30417 4.70616 6.32664 4.8211 6.32664 4.93691C6.32664 5.05315 6.30417 5.16766 6.26095 5.27482C6.23503 5.33662 6.20219 5.39538 6.16243 5.44983C6.13478 5.48915 6.10366 5.52632 6.0691 5.56088L2.00722 9.62276C1.84128 9.78826 1.61658 9.8816 1.38324 9.8816C1.14817 9.8816 0.923472 9.78826 0.759269 9.62276C0.593337 9.4577 0.5 9.233 0.5 8.99922C0.5 8.76502 0.593337 8.54075 0.759269 8.37525L4.19199 4.94166L0.759269 1.50765C0.714329 1.464 0.676303 1.41604 0.641734 1.36419C0.61235 1.31925 0.588151 1.27128 0.56741 1.22159C0.52247 1.11442 0.5 0.999914 0.5 0.883675C0.5 0.767868 0.52247 0.653358 0.56741 0.546193Z'
        fill='#CECECE'
      />
      <path
        d='M7.83039 0.268343C7.67483 0.427794 7.57977 0.645147 7.57977 0.884971V9.11503C7.57977 9.60375 7.97559 10 8.46474 10C8.67561 10 8.86747 9.92697 9.01957 9.80468C9.22008 9.64264 9.34971 9.39374 9.34971 9.11503V0.884971C9.34971 0.396249 8.95389 0 8.46474 0C8.21584 0 7.99114 0.102843 7.83039 0.268343Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default IconLastPage;
