// import namor from 'namor'

const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = () => {
  const statusChance = Math.random();
  return {
    firstName: 'Folder',
    lastName: '01',
    docs: [
      {
        name: 'Planilha.xlsx',
        type: 'xlsx',
        size: '1.2MB',
      },
      {
        name: 'Planilha2.xlsx',
        type: 'xlsx',
        size: '1.2MB',
      },
      {
        name: 'Infográfico2.png',
        type: 'png',
        size: '1.2MB',
      },
      {
        name: 'Apresentação2.pptx',
        type: 'pptx',
        size: '1.2MB',
      },
      {
        name: 'Instruções3.pdf',
        type: 'pdf',
        size: '1.2MB',
      },
    ],
    age: Math.floor(Math.random() * 30),
    visits: Math.floor(Math.random() * 100),
    progress: Math.floor(Math.random() * 100),
    status:
      statusChance > 0.66
        ? 'relationship'
        : statusChance > 0.33
        ? 'complicated'
        : 'single',
  };
};

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}
