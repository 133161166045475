import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/user/list';
import { ListUser } from '~/domain/usecases/user/remote/ListUser';
import { makeRemoteListUser } from '~/main/factories/usecases/user/ListUserFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/user/actions/list';

export function* onList(action: iActionList) {
  const remoteListUser = makeRemoteListUser();

  try {
    const response: ListUser.Model = yield call(
      remoteListUser.list,
      action.payload
    );

    const records = response.records.map((item) => {
      if (item.members.length > 0) {
        const startup = item.members[0].startup_;
        return {
          ...item,
          startup: startup,
        };
      } else {
        return item;
      }
    });

    yield put(
      listSuccess({
        ...response,
        records,
      })
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
