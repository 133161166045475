import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Cell,
  Text,
} from 'recharts';
import { maxWordWidth } from '~/utils/measureText';

interface ownProps {
  width: number;
  height: number;
  datakey: string;
  data: { name: string; value: number; valueAndPerc: string; color: string }[];
}

let ctx: CanvasRenderingContext2D | null;

const BarChart = ({ width, height, data, datakey }: ownProps): JSX.Element => {
  const maxTextWidth = useMemo(
    () => maxWordWidth(data, 'valueAndPerc', ctx),
    [data]
  );

  const YAxisLeftTick = ({ y, payload: { value } }: any) => {
    return (
      <Text
        y={y}
        alignmentBaseline='before-edge'
        textAnchor='start'
        verticalAnchor='middle'
        style={{ marginRight: '20px', textAlign: 'right', width: '200px' }}
      >
        {value}
      </Text>
    );
  };

  const YAxisRightTick = ({ x, y, payload: { value } }: any) => {
    return (
      <Text
        x={x}
        y={y}
        alignmentBaseline='before-edge'
        textAnchor='start'
        verticalAnchor='middle'
        style={{ marginRight: '0px', textAlign: 'right', width: '200px' }}
      >
        {value}
      </Text>
    );
  };

  return (
    <ResponsiveContainer width={'100%'} height={40 * data.length} debounce={0}>
      <RechartsBarChart
        data={data}
        layout='vertical'
        margin={{ left: 150, right: maxTextWidth - 50 }}
      >
        <XAxis hide axisLine={false} type='number' domain={[0, 100]} />
        <YAxis
          yAxisId={0}
          dataKey={'name'}
          type='category'
          axisLine={false}
          tickLine={false}
          tickSize={100}
          tick={(x) => <YAxisLeftTick {...x} />}
        />
        <YAxis
          orientation='right'
          yAxisId={1}
          dataKey={'valueAndPerc'}
          type='category'
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => value.toLocaleString()}
          tick={YAxisRightTick}
          /* tick={{
              transform: `translate(${maxTextWidth + 10}, 0)`,
            }} */
        />

        <Bar
          dataKey={'value'}
          minPointSize={0}
          barSize={10}
          background={{ fill: '#E2E2E2' }}
        >
          {data.map((d, idx) => {
            //eslint-disable-next-line
            console.log(d['name']);
            return <Cell key={d['name'] + idx} fill={d.color} />;
          })}
        </Bar>
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
