import React from 'react';

const IconMP4 = () => {
  return (
    <svg 
        width="28" 
        height="25" 
        viewBox="0 0 28 25" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16667 0C3.0616 0 2.00179 0.438987 1.22039 1.22039C0.438987 2.00179 0 3.0616 0 4.16667V15.7051C0 16.8102 0.438987 17.87 1.22039 18.6514C2.00179 19.4328 3.0616 19.8718 4.16667 19.8718H17.6282C18.7333 19.8718 19.7931 19.4328 20.5745 18.6514C21.3559 17.87 21.7949 16.8102 21.7949 15.7051V4.16667C21.7949 3.0616 21.3559 2.00179 20.5745 1.22039C19.7931 0.438987 18.7333 0 17.6282 0H4.16667ZM7.69231 13.141V6.09103C7.69236 5.91845 7.73887 5.74907 7.82694 5.60065C7.91501 5.45224 8.0414 5.33026 8.19285 5.24752C8.3443 5.16478 8.51523 5.12433 8.6877 5.1304C8.86017 5.13648 9.02782 5.18886 9.17308 5.28205L15.0936 9.07564C15.1841 9.13364 15.2586 9.21347 15.3102 9.30779C15.3618 9.4021 15.3888 9.50788 15.3888 9.61538C15.3888 9.72289 15.3618 9.82866 15.3102 9.92298C15.2586 10.0173 15.1841 10.0971 15.0936 10.1551L9.17308 13.9487C9.02782 14.0419 8.86017 14.0943 8.6877 14.1004C8.51523 14.1064 8.3443 14.066 8.19285 13.9832C8.0414 13.9005 7.91501 13.7785 7.82694 13.6301C7.73887 13.4817 7.69236 13.3123 7.69231 13.1397V13.141ZM7.37179 23.0769C6.6718 23.0776 5.98294 22.9017 5.36893 22.5656C4.75493 22.2294 4.2356 21.7439 3.85897 21.1538H18.2692C19.5443 21.1538 20.7672 20.6473 21.6688 19.7457C22.5704 18.8441 23.0769 17.6212 23.0769 16.3462V3.85897C23.667 4.2356 24.1525 4.75493 24.4886 5.36893C24.8248 5.98294 25.0007 6.6718 25 7.37179V16.3462C25 20.0641 21.9872 23.0769 18.2692 23.0769H7.37179Z" fill="#DE4377"/>
    </svg>
  );
};

export default IconMP4;