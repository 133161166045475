import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateApplication as UsecaseRemoteCreateApplication } from '~/domain/usecases/application/remote';
// import { CreateApplication as UsecaseReduxCreateApplication } from '~/domain/usecases/application/redux';

import { RemoteCreateApplication } from '~/data/repository/application';
// import { ReduxCreateApplication } from '~/data/store/reducer/application/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateApplication = (): UsecaseRemoteCreateApplication =>
  new RemoteCreateApplication(makeApiUrl('/applications/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateApplication =
  (): UsecaseReduxCreateApplication =>
    new ReduxCreateApplication(); */
