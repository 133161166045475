import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconError = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#FFEFEA' />
      <path
        d='M30 15C21.705 15 15 21.705 15 30C15 38.295 21.705 45 30 45C33.9782 45 37.7936 43.4196 40.6066 40.6066C43.4196 37.7936 45 33.9782 45 30C45 21.705 38.25 15 30 15ZM30 42C26.8174 42 23.7652 40.7357 21.5147 38.4853C19.2643 36.2348 18 33.1826 18 30C18 26.8174 19.2643 23.7652 21.5147 21.5147C23.7652 19.2643 26.8174 18 30 18C33.1826 18 36.2348 19.2643 38.4853 21.5147C40.7357 23.7652 42 26.8174 42 30C42 33.1826 40.7357 36.2348 38.4853 38.4853C36.2348 40.7357 33.1826 42 30 42ZM36.27 23.64L34.68 25.23L33.09 23.64L31.5 25.23L33.09 26.82L31.5 28.41L33.09 30L34.68 28.41L36.27 30L37.86 28.41L36.27 26.82L37.86 25.23L36.27 23.64ZM23.73 30L25.32 28.41L26.91 30L28.5 28.41L26.91 26.82L28.5 25.23L26.91 23.64L25.32 25.23L23.73 23.64L22.14 25.23L23.73 26.82L22.14 28.41L23.73 30ZM30 33C26.505 33 23.535 33.94 22.335 37H37.665C36.465 33.94 33.495 33 30 33Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconError;
