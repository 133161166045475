import { Dispatch } from 'redux';
import store from '~/data/store';
import { loginRequest } from '~/data/store/reducer/auth/actions/login';

import { iActionLogin } from '~/domain/interfaces/redux/auth/login';

import { LoginAuth } from '~/domain/usecases/auth/redux';

export class ReduxLoginAuth
  implements LoginAuth
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  login = (
    params: LoginAuth.Params,
  ): iActionLogin => this.send(loginRequest(params));
}
