import React, { useCallback } from 'react';
import {
  background,
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import {
  IconApproved,
  IconOngoing,
  IconRejected,
} from '~/presentation/base/icons';

interface ownProps extends ButtonProps {
  status: 'APPROVED' | 'REJECTED' | 'PENDING';
  isHilighted?: boolean;
}

const RevenueEvaluation = ({
  status,
  isHilighted,
  onClick,
  children,
}: ownProps): JSX.Element => {
  const getParams = (_status: string) => {
    const getIcon = () => {
      switch (status) {
        case 'APPROVED': {
          return <IconApproved isHilighted={isHilighted} />;
        }
        case 'REJECTED': {
          return <IconRejected isHilighted={isHilighted} />;
        }
        case 'PENDING':
        default:
          return <IconOngoing />;
      }
    };
    switch (_status) {
      case 'APPROVED': {
        return {
          backgroundColor: '#F3F9E7',
          color: '#5B7A17',
          icon: getIcon(),
        };
      }
      case 'REJECTED': {
        return {
          backgroundColor: '#FFF2EE',
          color: '#C23422',
          icon: getIcon(),
        };
      }
      case 'PENDING':
      default:
        return {
          backgroundColor: '#F9F8FA',
          color: '#60656D',
          icon: getIcon(),
        };
    }
  };

  const renderButton = useCallback(() => {
    const params = isHilighted ? getParams(status) : getParams('');

    return (
      <Button
        h='40px'
        paddingY={'10px'}
        paddingX={'20px'}
        bg={params.backgroundColor}
        color={params.color}
        border='1px solid #CECECE'
        leftIcon={params.icon}
        fontSize='sm'
        onClick={(event) => {
          !isHilighted && onClick && onClick(event);
        }}
      >
        {children}
      </Button>
    );
  }, [isHilighted, status, children]);

  return <>{renderButton()}</>;
};

export default RevenueEvaluation;
