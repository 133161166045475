import React from 'react';

const IconAttention = () => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.3833 4.87258C7.2578 4.61123 7.06102 4.39067 6.81563 4.23629C6.57024 4.08191 6.28623 4 5.99632 4C5.70641 4 5.4224 4.08191 5.17701 4.23629C4.93162 4.39067 4.73484 4.61123 4.60935 4.87258L0.182812 13.58C-0.34678 14.6196 0.322436 16 1.56924 16H10.4229C11.6702 16 12.3383 14.6201 11.8098 13.58L7.3833 4.87258ZM5.99632 7.81887C6.14097 7.81887 6.2797 7.87633 6.38198 7.97862C6.48427 8.0809 6.54173 8.21963 6.54173 8.36428V11.0913C6.54173 11.236 6.48427 11.3747 6.38198 11.477C6.2797 11.5793 6.14097 11.6367 5.99632 11.6367C5.85167 11.6367 5.71294 11.5793 5.61066 11.477C5.50838 11.3747 5.45091 11.236 5.45091 11.0913V8.36428C5.45091 8.21963 5.50838 8.0809 5.61066 7.97862C5.71294 7.87633 5.85167 7.81887 5.99632 7.81887ZM5.99632 12.4548C6.14097 12.4548 6.2797 12.5123 6.38198 12.6146C6.48427 12.7169 6.54173 12.8556 6.54173 13.0003V13.273C6.54173 13.4176 6.48427 13.5563 6.38198 13.6586C6.2797 13.7609 6.14097 13.8184 5.99632 13.8184C5.85167 13.8184 5.71294 13.7609 5.61066 13.6586C5.50838 13.5563 5.45091 13.4176 5.45091 13.273V13.0003C5.45091 12.8556 5.50838 12.7169 5.61066 12.6146C5.71294 12.5123 5.85167 12.4548 5.99632 12.4548Z'
        fill='#C23422'
      />
    </svg>
  );
};

export default IconAttention;
