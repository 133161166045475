import React, { useState } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import Input from '../../UI/Input';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'react-datepicker';
import cepPromise from 'cep-promise';
import IconCalendarGrey from '~/presentation/base/icons/iconCalendarGrey';
import DateInput from '../../UI/DateInput';
import getMask from '~/utils/getMask';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';
import Select from '../../UI/select';

interface ownProps {
  formik: any;
}

const FormUserSignUpFinalization = ({ formik }: ownProps): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date());

  const [cepResponse, setCepResponse] = React.useState<CepResponse | undefined>(
    undefined
  );

  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'adress',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  return (
    <Flex
      direction='column'
      alignItems='flex-end'
      display='inline-flex'
      float='right'
    >
      <Stack
        maxW='700px'
        spacing='4'
        alignItems='flex-start'
        paddingTop={'0px'}
      >
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id={'name'}
            name={'name'}
            label='Nome completo'
            formik={formik}
            minW={'360px'}
            required
          ></Input>
          <Select
            id='gender'
            name='gender'
            minW={'168px'}
            required
            onChange={(event) => {
              // eslint-disable-next-line no-console
              console.log('... event', event.target.value);
              formik.setFieldValue('gender', event.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
            placeholder='Selecione'
            label={'Gênero'}
            formik={formik}
          >
            <option value='MALE'>Masculino</option>
            <option value='FEMALE'>Feminino</option>
            <option value='OTHER'>Outro</option>
          </Select>
        </HStack>
        <HStack spacing='7' alignItems='flex-start'>
          <FormControl isRequired>
            <FormLabel
              fontFamily='Inter'
              fontStyle='normal'
              fontWeight='700'
              fontSize='sm'
              color='#303950'
            >
              Data de nascimento
            </FormLabel>
            <InputGroup>
              <DateInput
                formik={formik}
                name='datepicker'
                required
                onChange={(date) => formik.setFieldValue('datepicker', date)}
                dateFormat='dd/MM/yyyy'
                placeholderText='__ /__ /____'
              />
              <InputRightElement width={'12.6px'} mr='13px'>
                <IconCalendarGrey />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Input
            id={'rg'}
            name={'rg'}
            label='RG'
            placeholder='_ _._ _ _._ _ _-_'
            mask={getMask(
              'rg',
              formik.values.rg.replace(/[/_./-]/g, '').length
            )}
            guide={false}
            formik={formik}
            required
          ></Input>
          <Input
            id={'cpf'}
            name={'cpf'}
            label='CPF'
            placeholder='_ _ _._ _ _._ _ _ - _ _'
            mask={getMask('cpf')}
            formik={formik}
            required
          ></Input>
        </HStack>
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id={'adress'}
            name={'adress'}
            label='Endereço'
            formik={formik}
            minW={'360px'}
            required
          ></Input>
          <Input
            width={'168px'}
            id='cep'
            name='cep'
            label='CEP'
            required
            placeholder='_ _ _ _ _ - _ _ _'
            mask={getMask('cep')}
            formik={formik}
            onBlurCapture={handleOnBlur}
          />
        </HStack>
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id={'email'}
            name={'email'}
            label='Email'
            formik={formik}
            minW={'360px'}
            required
          ></Input>
          <Input
            id={'phone'}
            name={'phone'}
            label='Telefone'
            placeholder='(_ _) _ _ _ _ _ - _ _ _ _'
            mask={getMask('phone')}
            formik={formik}
            required
          ></Input>
        </HStack>
        <HStack spacing={'7'} alignItems='flex-start' maxW={'360px'}>
          <Input
            id={'password'}
            name={'password'}
            label='Senha'
            formik={formik}
            type='password'
            w={'168px'}
            required
          ></Input>
          <Input
            id={'confirm_password'}
            name={'confirm_password'}
            label='Confirmar senha'
            formik={formik}
            type='password'
            w={'168px'}
            required
          ></Input>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default FormUserSignUpFinalization;
