const getTypeEvent = (tabId: number): 'MEETING' | 'MENTORING' | 'DELIVERY' => {
  switch (tabId) {
    case 0:
      return 'MEETING';
    case 1:
      return 'MENTORING';
    case 2:
      return 'DELIVERY';
    default:
      return 'MEETING';
  }
};

export default getTypeEvent;
