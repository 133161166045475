import React from 'react';

const IconEventRegistered = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='60' height='60' rx='30' fill='#CEEAF8' />
      <path
        d='M22 28.5H25V31.5H22V28.5ZM22 34.5H25V37.5H22V34.5ZM28 28.5H31V31.5H28V28.5ZM28 34.5H31V37.5H28V34.5ZM34 28.5H37V31.5H34V28.5ZM34 34.5H37V37.5H34V34.5Z'
        fill='#1997F3'
      />
      <path
        d='M19 45H40C41.6545 45 43 43.6545 43 42V21C43 19.3455 41.6545 18 40 18H37V15H34V18H25V15H22V18H19C17.3455 18 16 19.3455 16 21V42C16 43.6545 17.3455 45 19 45ZM40 24L40.0015 42H19V24H40Z'
        fill='#1997F3'
      />
    </svg>
  );
};

export default IconEventRegistered;
