import { createStore, Store, compose, applyMiddleware } from 'redux';
import createSaga from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './saga';

import { MessageMiddleware } from './middleware/message';

// Devtools.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSaga();

// Middleware
const middleware = [MessageMiddleware(), sagaMiddleware];

// Store
const storeDev: Store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

// if (window.config.devTools) {
//   store = createStore(
//     rootReducer,
//     composeEnhancers(applyMiddleware(...middleware)),
//   );
// } else {
//   store = createStore(rootReducer);
// }

// Start Sagas.
sagaMiddleware.run(rootSaga);

export default storeDev;
