import {
  Button,
  Flex,
  Icon,
  Img,
  Link,
  Stack,
  Text,
  createIcon,
} from '@chakra-ui/react';

import IconNotificationNotices from '~/presentation/base/icons/iconNotificationNotices.svg';
import IconNotificationRegistrations from '~/presentation/base/icons/iconNotificationRegistrations.svg';
import IconNotificationEvents from '~/presentation/base/icons/iconNotificationEvents.svg';
import IconNotificationDocs from '~/presentation/base/icons/iconNotificationDocs.svg';
import IconNotificationAccountability from '~/presentation/base/icons/iconNotificationAccountability.svg';
import IconNotificationStartups from '~/presentation/base/icons/iconNotificationStartups.svg';
import React from 'react';

interface notification {
  id?: string;
  type: string;
  user?: string;
  title: string;
  tag: string;
  color: string;
  image: string;
  message: string;
  link?: string;
  seenAt?: string;
  createdAt: string;
  updatedAt?: string;
}

type ownProps = {
  item: notification;
};

function TimeElapsed(date: Date) {
  const timeDif = Date.now() - date.getTime();
  let time = 0;

  if (timeDif > 86400000) {
    time = Math.floor(timeDif / 86400000);
    if (time > 1) {
      return time.toString() + ' dias';
    } else {
      return time.toString() + ' dia';
    }
  }
  if (timeDif > 3600000) {
    time = Math.floor(timeDif / 3600000);
    return time.toString() + 'h';
  }

  if (timeDif > 60000) {
    time = Math.floor(timeDif / 60000);
    return time.toString() + 'm';
  }

  time = Math.floor(timeDif / 1000);
  return time.toString() + 's';
}

const Notification = ({ item }: ownProps): JSX.Element => {
  let link, linkLabel;

  if (item.link) {
    if (
      item.tag === 'Eventos' &&
      (item.type.substring(6, 13) === 'MEETING' ||
        item.type.substring(6, 15) === 'MENTORING')
    ) {
      link = (
        <Text pt='8px'>
          Para participar da reunião, acesse o link:{' '}
          <Link
            textDecoration={'underline'}
            color='#0873F1'
            href={item.link}
            isExternal
          >
            {item.link}.
          </Link>
        </Text>
      );
    } else {
      if (item.tag === 'Eventos') {
        linkLabel = 'Ver documentos necessários';
      }
      if (item.type === 'NOTICE_REGISTRATION_FINISHING') {
        linkLabel = 'Inscreva-se';
      }
      if (item.type === 'APPLICATION_REJECTED') {
        linkLabel = 'Saber mais';
      }
      if (item.tag === 'Documentos') {
        linkLabel = 'Ver documento';
      }
      if (item.type === 'ACCOUNTABILITY_REJECTED') {
        linkLabel = 'Ver motivos para a reprovação';
      }
      if (item.type === 'APPLICATION_STEP_REJECTED') {
        linkLabel = 'Ver lista de pendências';
      }
      if (item.type === '') {
        linkLabel = 'Ver motivos para o desligamento';
      }

      link = (
        <Link href={item.link}>
          <Button
            fontSize='14px'
            fontWeight='500'
            w='fit-content'
            color='#0873F1'
            variant='link'
            display={item.link ? 'normal' : 'none'}
          >
            {linkLabel}
          </Button>
        </Link>
      );
    }
  }

  let image;
  switch (item.tag) {
    case 'Editais':
      image = IconNotificationNotices;
      break;
    case 'Inscrições':
      image = IconNotificationRegistrations;
      break;
    case 'Eventos':
      image = IconNotificationEvents;
      break;
    case 'Documentos':
      image = IconNotificationDocs;
      break;
    case 'Finanças':
      image = IconNotificationAccountability;
      break;
    case 'Startups':
      image = IconNotificationStartups;
      break;
  }

  return (
    <Stack
      height='fit-content'
      border='1px'
      borderColor='#CECECE'
      px='20px'
      py='16px'
      width='400px'
      fontSize='14px'
      textAlign='initial'
      spacing='8px'
    >
      <Flex
        color={item.color}
        gap='7px'
        alignItems='center'
        flexDirection='row'
      >
        <Img src={image} />
        <Text fontWeight={500}>{item.tag}</Text>
        <Text color='#777777'>
          {' '}
          • {TimeElapsed(new Date(item.createdAt))} atrás
        </Text>
      </Flex>

      <Text color='#222222' fontWeight={600}>
        {item.title}
      </Text>

      <Text>{item.message}</Text>
      {link}
    </Stack>
  );
};

export default Notification;
