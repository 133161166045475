import React from 'react';

const IconNextMonth = (): JSX.Element => {
  return (
    <svg
      width='6'
      height='10'
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.261911 0.263092C0.178886 0.345946 0.113018 0.444362 0.0680755 0.552705C0.0231334 0.661048 0 0.77719 0 0.894485C0 1.01178 0.0231334 1.12792 0.0680755 1.23626C0.113018 1.34461 0.178886 1.44302 0.261911 1.52588L3.73681 5.00078L0.261911 8.47568C0.0944552 8.64314 0.000379222 8.87026 0.000379222 9.10707C0.000379222 9.34389 0.0944552 9.57101 0.261911 9.73847C0.429367 9.90593 0.656486 10 0.893304 10C1.13012 10 1.35724 9.90593 1.5247 9.73847L5.63547 5.6277C5.7185 5.54484 5.78436 5.44642 5.82931 5.33808C5.87425 5.22974 5.89738 5.1136 5.89738 4.9963C5.89738 4.87901 5.87425 4.76287 5.82931 4.65452C5.78436 4.54618 5.7185 4.44776 5.63547 4.36491L1.5247 0.254135C1.18437 -0.08619 0.611193 -0.0861898 0.261911 0.263092Z'
        fill='#60656D'
      />
    </svg>
  );
};

export default IconNextMonth;
