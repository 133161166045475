import React, { useContext } from 'react';
import { WarningModal } from '~/presentation/components/Modals';
import { makeRemoteDeleteNotice } from '~/main/factories/usecases/notice/DeleteNoticeFactory';
import { makeReduxListNotice } from '~/main/factories/usecases/notice/ListNoticeFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { iNotice } from '~/domain/interfaces/models';
interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  notice?: iNotice['records'][0];
  id?: number;
}

const DeleteNotice = ({
  isOpen,
  onClose,
  notice,
  id,
}: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Excluir Edital'
      question={`Tem certeza de que quer excluir o Edital ${notice?.code} - ${notice?.title}  ?`}
      /*TODO question='Tem certeza de que quer excluir o Edital nº 23/2022 - SEECT-FAPESQ-PB?' */
      description='Não há como reverter esta operação.'
      action={() => {
        id &&
          makeRemoteDeleteNotice()
            .delete({ id })
            .then(() => {
              makeReduxListNotice().list({ query: { limit: 999 } });
              showConfirmModal('DELETE_NOTICE');
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              onClose();
            });
      }}
      actionText='Excluir'
      actionTextLoading='Excluindo'
    />
  );
};

export default DeleteNotice;
