import React from 'react';
import WarningModal from '../../WarningModal';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeactivateUser = ({ isOpen, onClose }: ownProps): JSX.Element => {
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Suspender usuário'
      question='Tem certeza de que quer suspender o usuário?'
      description='Enquanto estiver suspenso, o usuário não poderá fazer login no sistema.'
      action={() => {
        //eslint-disable-next-line no-console
        console.log('Excluído');
      }}
      actionText='Suspender'
    />
  );
};

export default DeactivateUser;
