import React from 'react';

interface ownProps {
  isHilighted?: boolean;
}

const IconApproved = ({ isHilighted }: ownProps) => {
  return (
    <svg
      width='13'
      height='10'
      viewBox='0 0 13 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.99614 10L0 6.00386L1.82111 4.18275L3.99614 6.36422L10.3539 0L12.175 1.82111L3.99614 10Z'
        fill={isHilighted ? '#5B7A17' : '#60656D'}
      />
    </svg>
  );
};

export default IconApproved;
