import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Cell,
  Text,
} from 'recharts';
import { maxWordWidth, measureText } from '~/utils/measureText';

interface ownProps {
  width: number;
  height: number;
  datakey: string;
  data: {
    name: string;
    males: number;
    females: number;
    otherGenders: number;
    percentage: string;
  }[];
}

let ctx: CanvasRenderingContext2D | null;

const BarChartGroup = ({
  width,
  height,
  data,
  datakey,
}: ownProps): JSX.Element => {
  const maxTextWidth = useMemo(
    () => maxWordWidth(data, 'percentage', ctx),
    [data]
  );
  const YAxisLeftTick = ({ x, y, payload: { value } }: any) => {
    return (
      <Text
        x={x}
        y={y}
        alignmentBaseline='before-edge'
        textAnchor='start'
        verticalAnchor='middle'
        style={{ marginRight: '0px', textAlign: 'right', width: '200px' }}
        transform='translate(20,0)'
        fontSize={16}
      >
        {value}
      </Text>
    );
  };

  return (
    <ResponsiveContainer
      width={'100%'}
      height={36.4 * data.length}
      debounce={0}
    >
      <RechartsBarChart
        data={data}
        layout='vertical'
        margin={{
          left: 10,
          right: maxTextWidth + 11,
        }}
        /* margin={{ left: 10, right: 100 }} */
        maxBarSize={50}
        barSize={20}
      >
        <XAxis hide axisLine={false} type='number' domain={[0, 100]} />
        <YAxis
          yAxisId={0}
          dataKey={'name'}
          type='category'
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          orientation='right'
          yAxisId={1}
          dataKey={'percentage'}
          type='category'
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => value.toLocaleString()}
          tick={YAxisLeftTick}
          mirror
        />
        <Bar
          dataKey='males'
          minPointSize={0}
          barSize={10}
          fill='#1997F3'
          stackId='a'
          background={{ fill: '#E2E2E2' }}
        />
        <Bar
          dataKey='females'
          minPointSize={0}
          barSize={10}
          fill='#FB6FA1'
          stackId='a'
        />
        <Bar
          dataKey='otherGenders'
          minPointSize={0}
          barSize={10}
          fill='#F49404'
          stackId='a'
        />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChartGroup;
