import { iDocument } from '~/domain/interfaces/models/Document';
import { DocumentTypes } from '~/domain/interfaces/redux/document/types';
import { DocumentActions } from './actions';

export const initialState: iDocument = {
  loading: false,
};

const reducer = (state = initialState, action: DocumentActions): iDocument => {
  switch (action.type) {
    case DocumentTypes.LISTSTARTUPFOLDERS:
      return { ...state, loading: true };
    case DocumentTypes.LISTSTARTUPFOLDERS_FAILED:
      return { ...state, loading: false };
    case DocumentTypes.LISTSTARTUPFOLDERS_SUCCESS: {
      return { ...state, loading: false, startupFolders: action.payload };
    }
    case DocumentTypes.LISTNOTICEFOLDERS:
      return { ...state, loading: true };
    case DocumentTypes.LISTNOTICEFOLDERS_FAILED:
      return { ...state, loading: false };
    case DocumentTypes.LISTNOTICEFOLDERS_SUCCESS: {
      return { ...state, loading: false, noticeFolders: action.payload };
    }
    case DocumentTypes.LISTFOLDERCONTENT:
      return { ...state, loading: true };
    case DocumentTypes.LISTFOLDERCONTENT_FAILED:
      return { ...state, loading: false };
    case DocumentTypes.LISTFOLDERCONTENT_SUCCESS: {
      return { ...state, loading: false, folderContent: action.payload };
    }
    case DocumentTypes.LISTMENTORINGFOLDERS:
      return { ...state, loading: true };
    case DocumentTypes.LISTMENTORINGFOLDERS_FAILED:
      return { ...state, loading: false };
    case DocumentTypes.LISTMENTORINGFOLDERS_SUCCESS: {
      return { ...state, loading: false, mentoringFolders: action.payload };
    }
    case DocumentTypes.LISTDELIVERYFOLDERS:
      return { ...state, loading: true };
    case DocumentTypes.LISTDELIVERYFOLDERS_FAILED:
      return { ...state, loading: false };
    case DocumentTypes.LISTDELIVERYFOLDERS_SUCCESS: {
      return { ...state, loading: false, deliveryFolders: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
