import React from 'react';

const IconTickedSearch = (): JSX.Element => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.25 19.8586L12.9457 14.5543L12.8824 14.491L12.8125 14.5469C12.1968 15.0395 11.4879 15.4301 10.685 15.7184C9.88396 16.0059 9.03087 16.15 8.125 16.15C5.88008 16.15 3.98404 15.3734 2.43073 13.8193C0.877388 12.2651 0.100824 10.3691 0.1 8.12496C0.1 5.88008 0.876558 3.98404 2.43069 2.43073C3.98485 0.877397 5.88091 0.100832 8.125 0.1L18.25 19.8586ZM18.25 19.8586L19.8586 18.25L14.5543 12.9457L14.491 12.8824L14.5469 12.8125C15.0395 12.1968 15.4301 11.4879 15.7184 10.685C16.0059 9.88397 16.15 9.03089 16.15 8.12504L18.25 19.8586ZM12.1807 12.1795C11.0676 13.2926 9.71376 13.85 8.12505 13.85C8.12504 13.85 8.12502 13.85 8.125 13.85V13.75C6.56333 13.7508 5.23542 13.2042 4.14125 12.11L12.1807 12.1795ZM12.1807 12.1795C13.2938 11.0663 13.8508 9.71288 13.85 8.12495M12.1807 12.1795L13.85 8.12495M13.85 8.12495C13.85 8.12496 13.85 8.12498 13.85 8.125H13.75L13.85 8.12495ZM7.47696 8.44571L10.5312 5.39142L11.7026 6.56275L7.43688 10.8587L4.54804 7.99963L5.71875 6.82892L7.33554 8.44571L7.40625 8.51642L7.47696 8.44571ZM8.12504 0.1C10.3699 0.100007 12.266 0.876565 13.8193 2.43069C15.3726 3.98485 16.1492 5.88091 16.15 8.125L8.12504 0.1ZM4.06929 4.07054C2.95616 5.18367 2.39915 6.53712 2.4 8.12505L8.125 2.5V2.4C8.12498 2.4 8.12496 2.4 8.12495 2.4C6.53624 2.40001 5.1824 2.95743 4.06929 4.07054Z'
        fill='#60656D'
        stroke='#F9F8FA'
        strokeWidth='0.2'
      />
    </svg>
  );
};

export default IconTickedSearch;
