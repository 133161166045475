import React from 'react';

const IconXLS = () => {
  return (
    <svg 
        width="24" 
        height="30" 
        viewBox="0 0 24 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path d="M22.125 8.25L15 1.125L14.625 1.5L12.75 3L10.5 7.5L15.75 10.875L22.125 9.75V8.25Z" fill="#6A8B23"/>
            <path d="M23.2567 7.52344C23.4576 7.72433 23.5714 7.99554 23.5714 8.28013V28.9286C23.5714 29.5212 23.0926 30 22.5 30H1.07143C0.478795 30 0 29.5212 0 28.9286V1.07143C0 0.478795 0.478795 0 1.07143 0H15.2913C15.5759 0 15.8504 0.113839 16.0513 0.314732L23.2567 7.52344V7.52344ZM21.1004 8.77232L14.7991 2.47098V8.77232H21.1004Z" fill="#5B7A17"/>
            <path d="M6.896 23L5.688 21.12L4.592 23H3.328L5.088 20.184L3.304 17.416H4.592L5.8 19.288L6.888 17.416H8.152L6.4 20.224L8.184 23H6.896ZM10.1564 22.112H11.9964V23H9.03638V17.416H10.1564V22.112ZM14.6363 23.056C14.2469 23.056 13.8949 22.9893 13.5803 22.856C13.2709 22.7227 13.0256 22.5307 12.8443 22.28C12.6629 22.0293 12.5696 21.7333 12.5643 21.392H13.7643C13.7803 21.6213 13.8603 21.8027 14.0043 21.936C14.1536 22.0693 14.3563 22.136 14.6123 22.136C14.8736 22.136 15.0789 22.0747 15.2283 21.952C15.3776 21.824 15.4523 21.6587 15.4523 21.456C15.4523 21.2907 15.4016 21.1547 15.3003 21.048C15.1989 20.9413 15.0709 20.8587 14.9163 20.8C14.7669 20.736 14.5589 20.6667 14.2923 20.592C13.9296 20.4853 13.6336 20.3813 13.4043 20.28C13.1803 20.1733 12.9856 20.016 12.8203 19.808C12.6603 19.5947 12.5803 19.312 12.5803 18.96C12.5803 18.6293 12.6629 18.3413 12.8283 18.096C12.9936 17.8507 13.2256 17.664 13.5243 17.536C13.8229 17.4027 14.1643 17.336 14.5483 17.336C15.1243 17.336 15.5909 17.4773 15.9483 17.76C16.3109 18.0373 16.5109 18.4267 16.5483 18.928H15.3163C15.3056 18.736 15.2229 18.5787 15.0683 18.456C14.9189 18.328 14.7189 18.264 14.4683 18.264C14.2496 18.264 14.0736 18.32 13.9403 18.432C13.8123 18.544 13.7483 18.7067 13.7483 18.92C13.7483 19.0693 13.7963 19.1947 13.8923 19.296C13.9936 19.392 14.1163 19.472 14.2603 19.536C14.4096 19.5947 14.6176 19.664 14.8843 19.744C15.2469 19.8507 15.5429 19.9573 15.7723 20.064C16.0016 20.1707 16.1989 20.3307 16.3643 20.544C16.5296 20.7573 16.6123 21.0373 16.6123 21.384C16.6123 21.6827 16.5349 21.96 16.3802 22.216C16.2256 22.472 15.9989 22.6773 15.7003 22.832C15.4016 22.9813 15.0469 23.056 14.6363 23.056Z" fill="#F9F8FA"/>
    </svg>


  );
};

export default IconXLS;