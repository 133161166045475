import React from 'react';

const IconCalendarGrey = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2 1.4H10.5V0H9.1V1.4H3.5V0H2.1V1.4H1.4C0.623 1.4 0.00699999 2.03 0.00699999 2.8L0 12.6C0 12.9713 0.1475 13.3274 0.41005 13.5899C0.672601 13.8525 1.0287 14 1.4 14H11.2C11.97 14 12.6 13.37 12.6 12.6V2.8C12.6 2.03 11.97 1.4 11.2 1.4ZM11.2 12.6H1.4V5.6H11.2V12.6ZM4.2 8.4H2.8V7H4.2V8.4ZM7 8.4H5.6V7H7V8.4ZM9.8 8.4H8.4V7H9.8V8.4ZM4.2 11.2H2.8V9.8H4.2V11.2ZM7 11.2H5.6V9.8H7V11.2ZM9.8 11.2H8.4V9.8H9.8V11.2Z" fill="#60656D" />
    </svg>

  );
};

export default IconCalendarGrey;
