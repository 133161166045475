import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import IconRegisterStartup from '~/presentation/base/icons/iconRegisterStartup';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterStartupSuccess = ({ name, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      title='Startup cadastrada!'
      returnPage='/startups'
      isOpen={active === 'SUBMMIT_STARTUP'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      onOpenRegister={onOpenRegister}
      icon={<IconRegisterStartup />}
      topButtonText='Cadastrar outra startup'
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>
              A startup <strong>{name}</strong> foi criada com sucesso.
              Agora, o gestor responsável deve acessar o link enviado por e-mail e concluir seu cadastro.
            </Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default RegisterStartupSuccess;
