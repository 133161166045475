import { LoginAuth } from '~/domain/usecases/auth/redux/LoginAuth';
import {
  iActionLogin,
  iActionLoginFailed,
  iActionLoginSuccess,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/login';

export const loginRequest = (
  payload: LoginAuth.Params,
): iActionLogin => ({
  type: AuthTypes.LOGIN,
  payload,
});

export const loginSuccess = (
  params: LoginAuth.Model,
): iActionLoginSuccess => ({
  type: AuthTypes.LOGIN_SUCCESS,
  payload: params,
});

export const loginFailed = (): iActionLoginFailed => ({
  type: AuthTypes.LOGIN_FAILED,
});
