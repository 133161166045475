import { Text, Input as ChakraInput, Flex } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IconPrevMonth, IconNextMonth } from '~/presentation/base/icons';
import MaskedInput from 'react-text-mask';
import getMask from '~/utils/getMask';

interface ownProps extends ReactDatePickerProps {
  formik: any;
  name: string;
  label?: string;
  required?: boolean;
}

const CustomInput = forwardRef(
  (
    { value, onClick, onChange, ...rest }: any,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const withDateMask = rest.dateFormat === 'dd/MM/yyyy';
    return (
      <ChakraInput
        autoComplete='off'
        bg='#F9F8FA'
        value={value}
        ref={ref}
        onClick={onClick}
        onChange={onChange}
        as={withDateMask ? MaskedInput : undefined}
        mask={getMask('date')}
      />
    );
  }
);
CustomInput.displayName = 'DateInput';

const DateInput = ({ formik, name, ...rest }: ownProps): JSX.Element => {
  const customHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <Flex p='2.5' justify='center' w='100%'>
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <IconPrevMonth />
      </button>
      <Text
        fontWeight='bold'
        fontSize='sm'
        color='#303950'
        w='calc(100% - 64px)'
      >
        {monthDate.toLocaleString('pt-Br', {
          month: 'long',
          year: 'numeric',
        })}
      </Text>
      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <IconNextMonth />
      </button>
    </Flex>
  );

  return (
    <DatePicker
      selected={formik.values[name]}
      /* onChange={(date) => formik.setFieldValue(name, date)} */
      customInput={<CustomInput {...rest} />}
      renderCustomHeader={customHeader}
      {...rest}
    />
  );
};

export default DateInput;
