import React from 'react';

const IconView = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1995 0C15.2833 0 19.5128 3.58461 20.4 8.31482C19.5137 13.045 15.2833 16.6296 10.1995 16.6296C5.11579 16.6296 0.88626 13.045 0 8.31482C0.88626 3.58461 5.11579 0 10.1995 0ZM10.1995 14.7819C12.1226 14.7817 13.9886 14.1418 15.4921 12.9669C16.9956 11.7921 18.0475 10.1519 18.4757 8.31482C18.0464 6.47893 16.994 4.84011 15.4907 3.66641C13.9874 2.49272 12.1221 1.8536 10.2 1.8536C8.27787 1.8536 6.41261 2.49272 4.90931 3.66641C3.40601 4.84011 2.35364 6.47893 1.92431 8.31482C2.35245 10.1517 3.40425 11.7918 4.90756 12.9667C6.41088 14.1415 8.27666 14.7815 10.1995 14.7819ZM10.1995 12.4722C9.07429 12.4722 7.99513 12.0342 7.19946 11.2546C6.4038 10.4749 5.9568 9.41744 5.9568 8.31482C5.9568 7.21221 6.4038 6.15476 7.19946 5.37509C7.99513 4.59542 9.07429 4.15741 10.1995 4.15741C11.3248 4.15741 12.4039 4.59542 13.1996 5.37509C13.9953 6.15476 14.4423 7.21221 14.4423 8.31482C14.4423 9.41744 13.9953 10.4749 13.1996 11.2546C12.4039 12.0342 11.3248 12.4722 10.1995 12.4722ZM10.1995 10.6245C10.8247 10.6245 11.4242 10.3812 11.8662 9.94801C12.3083 9.51486 12.5566 8.92739 12.5566 8.31482C12.5566 7.70226 12.3083 7.11479 11.8662 6.68164C11.4242 6.24849 10.8247 6.00515 10.1995 6.00515C9.57439 6.00515 8.97486 6.24849 8.53283 6.68164C8.09079 7.11479 7.84245 7.70226 7.84245 8.31482C7.84245 8.92739 8.09079 9.51486 8.53283 9.94801C8.97486 10.3812 9.57439 10.6245 10.1995 10.6245Z" fill="#222222" />
    </svg>
  );
};

export default IconView;
