import { Text } from '@chakra-ui/react';
import React from 'react';
import { TypographyProps } from './types';

const typographyVariants = {
  h1_Inter_Bold_36px: {
    fontSize: '36px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  h2_Inter_Bold_20px: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  h3_Inter_Bold_18px: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  h4_Inter_Bold_16px: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  h5_Inter_Bold_14px: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  h6_Inter_SemiBold_14px: {
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  h7_Inter_Medium_14px: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Inter',
  },
  b1_Inter_Regular_14px: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Inter',
  },
  b2_Inter_Regular_14px: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Inter',
  },
  b3_Inter_SemiBold_12px: {
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  b4_Inter_Regular_12px: {
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Inter',
  },
  b3_Inter_Medium_12px: {
    fontSize: '12px',
    fontWeight: '500',
    fontFamily: 'Inter',
  },
  b4_Inter_Medium_11px: {
    fontSize: '11px',
    fontWeight: '500',
    fontFamily: 'Inter',
  },
  b5_Inter_Regular_12px: {
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Inter',
  },
};

export const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'b4_Inter_Medium_11px',
  color,
  ...props
}) => {
  return <Text color={color} {...typographyVariants[variant]}>{children}</Text>;
};
