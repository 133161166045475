import React from 'react';

const IconPPT = () => {
  return (
    <svg 
        width="24" 
        height="30" 
        viewBox="0 0 24 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path d="M22.125 8.25L15 1.125L14.625 1.5L12.75 3L10.5 7.5L15.75 10.875L22.125 9.75V8.25Z" fill="#BD5114"/>
            <path d="M23.2567 7.52344C23.4576 7.72433 23.5714 7.99553 23.5714 8.28013V28.9286C23.5714 29.5212 23.0926 30 22.5 30H1.07143C0.478795 30 0 29.5212 0 28.9286V1.07143C0 0.478795 0.478795 0 1.07143 0H15.2913C15.5759 0 15.8504 0.113839 16.0513 0.314732L23.2567 7.52344ZM21.1004 8.77232L14.7991 2.47098V8.77232H21.1004Z" fill="#EA8B1B"/>
            <path d="M8.624 20.644C8.624 20.9427 8.552 21.2227 8.408 21.484C8.26933 21.7453 8.048 21.956 7.744 22.116C7.44533 22.276 7.06667 22.356 6.608 22.356H5.672V24.5H4.552V18.916H6.608C7.04 18.916 7.408 18.9907 7.712 19.14C8.016 19.2893 8.24267 19.4947 8.392 19.756C8.54667 20.0173 8.624 20.3133 8.624 20.644ZM6.56 21.452C6.86933 21.452 7.09867 21.3827 7.248 21.244C7.39733 21.1 7.472 20.9 7.472 20.644C7.472 20.1 7.168 19.828 6.56 19.828H5.672V21.452H6.56ZM13.4912 20.644C13.4912 20.9427 13.4192 21.2227 13.2752 21.484C13.1365 21.7453 12.9152 21.956 12.6112 22.116C12.3125 22.276 11.9339 22.356 11.4752 22.356H10.5392V24.5H9.41919V18.916H11.4752C11.9072 18.916 12.2752 18.9907 12.5792 19.14C12.8832 19.2893 13.1099 19.4947 13.2592 19.756C13.4139 20.0173 13.4912 20.3133 13.4912 20.644ZM11.4272 21.452C11.7365 21.452 11.9659 21.3827 12.1152 21.244C12.2645 21.1 12.3392 20.9 12.3392 20.644C12.3392 20.1 12.0352 19.828 11.4272 19.828H10.5392V21.452H11.4272ZM18.0864 18.916V19.82H16.5984V24.5H15.4784V19.82H13.9904V18.916H18.0864Z" fill="#F9F8FA"/>
    </svg>
  );
};

export default IconPPT;