import React from 'react';

const IconHomeButtonUsers = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#EBF3D9' />
      <path
        d='M14.3154 13.6846C14.3154 11.6488 15.9642 10 18 10C20.0358 10 21.6846 11.6488 21.6846 13.6846C21.6846 15.7204 20.0358 17.3692 18 17.3692C15.9642 17.3692 14.3154 15.7204 14.3154 13.6846ZM18.8166 11.7131C18.5577 11.6059 18.2802 11.5507 18 11.5507C17.4341 11.5507 16.8913 11.7755 16.4911 12.1757C16.0909 12.5759 15.8661 13.1186 15.8661 13.6846C15.8661 14.2505 16.0909 14.7933 16.4911 15.1935C16.8913 15.5937 17.4341 15.8185 18 15.8185C18.2802 15.8185 18.5577 15.7633 18.8166 15.6561C19.0755 15.5488 19.3107 15.3916 19.5089 15.1935C19.707 14.9953 19.8642 14.7601 19.9715 14.5012C20.0787 14.2423 20.1339 13.9648 20.1339 13.6846C20.1339 13.4044 20.0787 13.1269 19.9715 12.868C19.8642 12.6091 19.707 12.3738 19.5089 12.1757C19.3107 11.9775 19.0755 11.8204 18.8166 11.7131Z'
        fill='#5B7A17'
      />
      <path
        d='M10.5 22.2693C10.5 21.683 10.7926 21.1598 11.2921 20.6988C11.7924 20.2371 12.4915 19.8467 13.2782 19.5315C14.8518 18.9011 16.743 18.5847 18 18.5847C19.257 18.5847 21.1482 18.9011 22.7218 19.5315C23.5085 19.8467 24.2076 20.2371 24.7079 20.6988C25.2074 21.1598 25.5 21.683 25.5 22.2693V25H10.5V22.2693ZM12.0507 23.4493H23.9493V22.2693C23.9493 22.1431 23.8751 22.0236 23.7818 21.9202C23.6846 21.8127 23.5473 21.7014 23.3804 21.5899C23.0458 21.3666 22.5747 21.1312 22.0187 20.9167C20.9066 20.4878 19.4359 20.1354 18 20.1354C16.5641 20.1354 15.0934 20.4878 13.9813 20.9167C13.4253 21.1312 12.9542 21.3666 12.6196 21.5899C12.4527 21.7014 12.3154 21.8127 12.2182 21.9202C12.1249 22.0236 12.0507 22.1431 12.0507 22.2693V23.4493Z'
        fill='#5B7A17'
      />
    </svg>
  );
};

export default IconHomeButtonUsers;
