import React from 'react';
import {
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
} from '@chakra-ui/react';
import Stage from '~/presentation/components/Stage';
import { iNotice, iStartup } from '~/domain/interfaces/models';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  notice?: iNotice['records'][0];
}

const StageNotice = ({ isOpen, onClose, notice }: ownProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW='688px' bg='#F9F8FA'>
        <ModalHeader>
          <Text
            fontWeight='700'
            fontFamily='Inter'
            fontSize='18px'
            color={'#303950'}
          >
            Etapas do Edital
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stage stages={notice?.steps} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StageNotice;
