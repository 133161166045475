import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SelectProps,
  Select as ChakraSelect,
} from '@chakra-ui/react';

interface ownProps extends SelectProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  formik?: any;
}

const Select = ({
  id,
  name,
  label,
  required,
  formik,
  children,
  ...rest
}: ownProps): JSX.Element => {
  return (
    <FormControl isRequired={required} isInvalid={formik?.errors[id] && formik?.touched[name]}>
      <FormLabel fontWeight='bold' fontSize='sm' mb='2'>
        {label}
      </FormLabel>
      <ChakraSelect
        id={id}
        name={name}
        onBlur={() => formik?.setFieldTouched(name)}
        {...rest}
      >
        {children}
      </ChakraSelect>
      <FormErrorMessage>{formik?.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default Select;
