import React from 'react';

const IconMoreOptions = (): JSX.Element => {
    return (
        <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7C0 7.46413 0.184375 7.90925 0.512563 8.23744C0.840752 8.56563 1.28587 8.75 1.75 8.75C2.21413 8.75 2.65925 8.56563 2.98744 8.23744C3.31563 7.90925 3.5 7.46413 3.5 7C3.5 6.53587 3.31563 6.09075 2.98744 5.76256C2.65925 5.43437 2.21413 5.25 1.75 5.25C1.28587 5.25 0.840752 5.43437 0.512563 5.76256C0.184375 6.09075 0 6.53587 0 7ZM0 1.75C0 2.21413 0.184375 2.65925 0.512563 2.98744C0.840752 3.31563 1.28587 3.5 1.75 3.5C2.21413 3.5 2.65925 3.31563 2.98744 2.98744C3.31563 2.65925 3.5 2.21413 3.5 1.75C3.5 1.28587 3.31563 0.840752 2.98744 0.512563C2.65925 0.184374 2.21413 0 1.75 0C1.28587 0 0.840752 0.184374 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75ZM0 12.25C0 12.7141 0.184375 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14C2.21413 14 2.65925 13.8156 2.98744 13.4874C3.31563 13.1592 3.5 12.7141 3.5 12.25C3.5 11.7859 3.31563 11.3408 2.98744 11.0126C2.65925 10.6844 2.21413 10.5 1.75 10.5C1.28587 10.5 0.840752 10.6844 0.512563 11.0126C0.184375 11.3408 0 11.7859 0 12.25Z" fill="#222222" />
        </svg>

    );
};

export default IconMoreOptions;