import { ListStartupFoldersDocument } from '~/domain/usecases/document/redux/ListStartupFoldersDocument';
import {
  iActionListStartupFolders,
  iActionListStartupFoldersFailed,
  iActionListStartupFoldersSuccess,
  DocumentTypes,
} from '~/domain/interfaces/redux/document/listStartupFolders';

export const listStartupFoldersRequest = (
  payload: ListStartupFoldersDocument.Params
): iActionListStartupFolders => ({
  type: DocumentTypes.LISTSTARTUPFOLDERS,
  payload,
});

export const listStartupFoldersSuccess = (
  params: ListStartupFoldersDocument.Model
): iActionListStartupFoldersSuccess => ({
  type: DocumentTypes.LISTSTARTUPFOLDERS_SUCCESS,
  payload: params,
});

export const listStartupFoldersFailed =
  (): iActionListStartupFoldersFailed => ({
    type: DocumentTypes.LISTSTARTUPFOLDERS_FAILED,
  });
