import { GetActivity } from '~/domain/usecases/activity/redux/GetActivity';
import {
  iActionGet,
  iActionGetFailed,
  iActionGetSuccess,
  ActivityTypes,
} from '~/domain/interfaces/redux/activity/get';

export const getRequest = (
  payload: GetActivity.Params,
): iActionGet => ({
  type: ActivityTypes.GET,
  payload,
});

export const getSuccess = (
  params: GetActivity.Model,
): iActionGetSuccess => ({
  type: ActivityTypes.GET_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetFailed => ({
  type: ActivityTypes.GET_FAILED,
});
