import React from 'react';

interface ownProps {
  color: string;
  bgColor: string;
}

const IconHomeButtonNotice = ({ color, bgColor }: ownProps) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill={bgColor} />
      <path
        d='M20.8 11.6H13.6V24.4H23.2V14H20.8V11.6ZM13.6 10H21.6L24.8 13.2V24.4C24.8 24.8243 24.6314 25.2313 24.3314 25.5314C24.0313 25.8314 23.6243 26 23.2 26H13.6C13.1757 26 12.7687 25.8314 12.4686 25.5314C12.1686 25.2313 12 24.8243 12 24.4V11.6C12 11.1757 12.1686 10.7687 12.4686 10.4686C12.7687 10.1686 13.1757 10 13.6 10ZM15.2 17.2H21.6V18.8H15.2V17.2ZM15.2 20.4H21.6V22H15.2V20.4Z'
        fill={color}
      />
    </svg>
  );
};

export default IconHomeButtonNotice;
