import { call, put, select } from 'redux-saga/effects';
import { iActionGet } from '~/domain/interfaces/redux/dashboard/get';
import { GetDashboard } from '~/domain/usecases/dashboard/remote/GetDashboard';
import { makeRemoteGetDashboard } from '~/main/factories/usecases/dashboard/GetDashboardFactory';
import {
  getSuccess,
  getFailed,
} from '~/data/store/reducer/dashboard/actions/get';
import calculePercentage from '~/utils/calculePercentage';

export function* onGet(action: iActionGet) {
  const remoteGetDashboard = makeRemoteGetDashboard();

  try {
    const response: GetDashboard.Model = yield call(
      remoteGetDashboard.get,
      action.payload
    );
    const { notice, startup, application, events, users } = response;

    const usersAge: { [key: string]: { value: number; percentage: number } } =
      {};

    yield put(
      getSuccess({
        notice: [
          {
            label: 'Editais cadastrados',
            value: notice.total.toString(),
            footer: 'Total',
          },
          {
            label: 'Com inscrições abertas',
            value: notice.opened.toString(),
            footer: `(${notice.openedPercentage}%)`,
          },
          {
            label: 'Em execução',
            value: notice.inProgress.toString(),
            footer: `(${notice.inProgressPercentage}%)`,
          },
        ],
        startup: [
          {
            label: 'Startups criadas',
            value: startup.total.toString(),
            footer: 'Total',
          },
          {
            label: 'Em incubação',
            value: startup.incubation.toString(),
            footer: `(${startup.incubationPercentage}%)`,
          },
          {
            label: 'Em situação regular',
            value: startup.regular.toString(),
            footer: `(${startup.regularPercentage}%)`,
          },
        ],
        application: [
          {
            label: 'Inscrições realizadas',
            value: application.total.toString(),
            footer: 'Total',
          },
          {
            label: 'Propostas selecionadas',
            value: application.approved.toString(),
            footer: `(${application.approvedPercentage}%)`,
          },
          {
            label: 'Recursos oferecidos',
            value: `R$ ${application.resourceValeu.toString()}`,
            footer: 'Total',
          },
        ],
        events: [
          {
            label: 'Eventos realizados',
            value: events.total.toString(),
            footer: 'Total',
          },
          {
            label: 'Reuniões',
            value: events.meetings.toString(),
            footer: `(${events.meetingsPercentage}%)`,
          },
          {
            label: 'Mentorias',
            value: events.mentorings.toString(),
            footer: `(${events.mentoringsPercentage}%)`,
          },
          {
            label: 'Entregas',
            value: events.deliveries.toString(),
            footer: `(${events.deliveriesPercentage}%)`,
          },
        ],
        users: [
          {
            label: 'Usuários',
            value: users.total.toString(),
            footer: 'Total',
          },
          {
            label: 'Supervisores & Admins',
            value: users.admins.toString(),
            footer: `(${users.adminsPercentage}%)`,
          },
          {
            label: 'Mentores',
            value: users.mentors.toString(),
            footer: `(${users.mentorsPercentage}%)`,
          },
          {
            label: 'Gestores & membros',
            value: users.members.toString(),
            footer: `(${users.membersPercentage}%)`,
          },
        ],
        usersAge: {
          ageList: [
            {
              name: '18 - 24',
              males: calculePercentage(
                users.ageGroup1Genders.males,
                users.ageGroup1,
                users.ageGroup1Percentage
              ),
              females: calculePercentage(
                users.ageGroup1Genders.females,
                users.ageGroup1,
                users.ageGroup1Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup1Genders.otherGenders,
                users.ageGroup1,
                users.ageGroup1Percentage
              ),
              percentage: `${
                users.ageGroup1
              } (${users.ageGroup1Percentage.toFixed(0)}%)`,
            },
            {
              name: '25 - 30',
              males: calculePercentage(
                users.ageGroup2Genders.males,
                users.ageGroup2,
                users.ageGroup2Percentage
              ),
              females: calculePercentage(
                users.ageGroup2Genders.females,
                users.ageGroup2,
                users.ageGroup2Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup2Genders.otherGenders,
                users.ageGroup2,
                users.ageGroup2Percentage
              ),
              percentage: `${
                users.ageGroup2
              } (${users.ageGroup2Percentage.toFixed(0)}%)`,
            },
            {
              name: '31 - 40',
              males: calculePercentage(
                users.ageGroup3Genders.males,
                users.ageGroup3,
                users.ageGroup3Percentage
              ),
              females: calculePercentage(
                users.ageGroup3Genders.females,
                users.ageGroup3,
                users.ageGroup3Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup3Genders.otherGenders,
                users.ageGroup3,
                users.ageGroup3Percentage
              ),
              percentage: `${
                users.ageGroup3
              } (${users.ageGroup3Percentage.toFixed(0)}%)`,
            },
            {
              name: '41 - 50',
              males: calculePercentage(
                users.ageGroup4Genders.males,
                users.ageGroup4,
                users.ageGroup4Percentage
              ),
              females: calculePercentage(
                users.ageGroup4Genders.females,
                users.ageGroup4,
                users.ageGroup4Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup4Genders.otherGenders,
                users.ageGroup4,
                users.ageGroup4Percentage
              ),
              percentage: `${
                users.ageGroup4
              } (${users.ageGroup4Percentage.toFixed(0)}%)`,
            },
            {
              name: '51 - 60',
              males: calculePercentage(
                users.ageGroup5Genders.males,
                users.ageGroup5,
                users.ageGroup5Percentage
              ),
              females: calculePercentage(
                users.ageGroup5Genders.females,
                users.ageGroup5,
                users.ageGroup5Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup5Genders.otherGenders,
                users.ageGroup5,
                users.ageGroup5Percentage
              ),
              percentage: `${
                users.ageGroup5
              } (${users.ageGroup5Percentage.toFixed(0)}%)`,
            },
            {
              name: '60+',
              males: calculePercentage(
                users.ageGroup6Genders.males,
                users.ageGroup6,
                users.ageGroup6Percentage
              ),
              females: calculePercentage(
                users.ageGroup6Genders.females,
                users.ageGroup6,
                users.ageGroup6Percentage
              ),
              otherGenders: calculePercentage(
                users.ageGroup6Genders.otherGenders,
                users.ageGroup6,
                users.ageGroup6Percentage
              ),
              percentage: `${
                users.ageGroup6
              } (${users.ageGroup6Percentage.toFixed(0)}%)`,
            },
          ],
          male: `${users.genderMale.toString()} homens (${
            users.genderMalePercentage
          }%)`,
          female: `${users.genderFemale.toString()} mulheres (${
            users.genderFemalePercentage
          }%)`,
          other: `${users.genderOther.toString()} outros (${
            users.genderOtherPercentage
          }%)`,
        },
        activity: startup.activity,
      })
    );
  } catch (e) {
    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}
