import { iAuth } from '~/domain/interfaces/models/Auth';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  error: false,
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false, error: true };
    case AuthTypes.LOGIN_SUCCESS: {
      return { ...action.payload, loading: false, error: false };
    }
    case AuthTypes.REFRESH: {
      // eslint-disable-next-line no-console
      console.log('>>> REFRESH');
      const { accessToken, refreshToken, user } = action.payload;
      user.role = state.user ? state.user.role : user.role;

      return { ...state, accessToken, refreshToken, user, loading: true };
    }
    case AuthTypes.LOGOUT:
      return state;
    default:
      return state;
  }
};

export default reducer;
