import React from 'react';

const IconFolder = (): JSX.Element => {
  return (
    <svg
      width='20'
      height='15'
      viewBox='0 0 20 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.875 15C1.35938 15 0.917814 14.8162 0.550314 14.4487C0.182814 14.0812 -0.000623408 13.64 1.59168e-06 13.125V1.875C1.59168e-06 1.35938 0.183752 0.917814 0.551252 0.550314C0.918751 0.182814 1.36 -0.000623408 1.875 1.59168e-06H6.72656C6.97656 1.59168e-06 7.215 0.0468767 7.44187 0.140627C7.66875 0.234377 7.86781 0.367189 8.03906 0.539064L9.375 1.875H16.875C17.3906 1.875 17.8322 2.05875 18.1997 2.42625C18.5672 2.79375 18.7506 3.235 18.75 3.75H3.75C3.23438 3.75 2.79281 3.93375 2.42531 4.30125C2.05781 4.66875 1.87438 5.11 1.875 5.625V13.125L3.72656 6.96094C3.85156 6.55469 4.08219 6.23031 4.41844 5.98781C4.75469 5.74531 5.12563 5.62438 5.53125 5.625H17.625C18.2656 5.625 18.7697 5.87906 19.1372 6.38719C19.5047 6.89531 19.6022 7.44594 19.4297 8.03906L17.7422 13.6641C17.6172 14.0703 17.3866 14.3947 17.0503 14.6372C16.7141 14.8797 16.3431 15.0006 15.9375 15H1.875Z'
        fill='#5B646B'
      />
    </svg>
  );
};

export default IconFolder;
