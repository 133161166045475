import React from 'react';

const IconPrevMonth = (): JSX.Element => {
  return (
    <svg
      width='6'
      height='10'
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.63555 0.263092C5.71857 0.345946 5.78444 0.444362 5.82939 0.552705C5.87433 0.661048 5.89746 0.77719 5.89746 0.894485C5.89746 1.01178 5.87433 1.12792 5.82939 1.23626C5.78444 1.34461 5.71857 1.44302 5.63555 1.52588L2.16065 5.00078L5.63555 8.47568C5.80301 8.64314 5.89708 8.87026 5.89708 9.10707C5.89708 9.34389 5.80301 9.57101 5.63555 9.73847C5.46809 9.90593 5.24098 10 5.00416 10C4.76734 10 4.54022 9.90593 4.37276 9.73847L0.26199 5.6277C0.178965 5.54484 0.113096 5.44642 0.0681543 5.33808C0.023212 5.22974 7.96318e-05 5.1136 7.96318e-05 4.9963C7.96318e-05 4.87901 0.023212 4.76287 0.0681543 4.65452C0.113096 4.54618 0.178965 4.44776 0.26199 4.36491L4.37276 0.254135C4.71309 -0.08619 5.28627 -0.0861898 5.63555 0.263092Z'
        fill='#60656D'
      />
    </svg>
  );
};

export default IconPrevMonth;
