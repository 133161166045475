import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { EvaluateApplication as UsecaseRemoteEvaluateApplication } from '~/domain/usecases/application/remote';
// import { EvaluateApplication as UsecaseReduxEvaluateApplication } from '~/domain/usecases/application/redux';

import { RemoteEvaluateApplication } from '~/data/repository/application';
// import { ReduxEvaluateApplication } from '~/data/store/reducer/application/usecases';

/**
 * send request via API.
 */
export const makeRemoteEvaluateApplication =
  (): UsecaseRemoteEvaluateApplication =>
    new RemoteEvaluateApplication(
      makeApiUrl('/applications/{application}/evaluate'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxEvaluateApplication =
  (): UsecaseReduxEvaluateApplication =>
    new ReduxEvaluateApplication(); */
