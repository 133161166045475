import React from 'react';

const IconPicture = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M30 12.8576L12.8576 30L30 47.1424L47.1424 30L30 12.8576ZM0 30L30 60L60 30L30 0L0 30Z" fill="url(#paint0_linear_2105_39473)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.9092 30.0012L30.0001 39.0921L39.091 30.0012L30.0001 20.9103L20.9092 30.0012Z" fill="url(#paint1_linear_2105_39473)" />
      <defs>
        <linearGradient id="paint0_linear_2105_39473" x1="30" y1="0" x2="30" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E11A5D" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_2105_39473" x1="30" y1="0" x2="30" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E11A5D" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>


  );
};

export default IconPicture;
