import React from 'react';
import { Login } from '~/presentation/pages';
import { useLocation } from 'react-router-dom';

export const makeConfirmUserFactory: React.FC = () => {
  const token = useLocation().search.split('?token=');
  // eslint-disable-next-line
  console.log(token);
  return <Login token={token[1]} />;
};
