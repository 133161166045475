import React from 'react';
interface ownProps {
  color: string;
  bgColor: string;
}

const IconHomeButtonDocs = ({ color, bgColor }: ownProps) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill={bgColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8345 10.5067L18.9561 12.914H25.6435C26.0411 12.914 26.4225 13.0731 26.7037 13.3563C26.9849 13.6395 27.1429 14.0236 27.1429 14.4241V23.5618C27.1425 23.9431 26.9919 24.3087 26.7242 24.5783C26.4565 24.8479 26.0935 24.9996 25.7149 25H11.4497C11.0654 24.9994 10.697 24.8454 10.4253 24.5717C10.1535 24.298 10.0006 23.927 10 23.5399V11.5102C10 11.1097 10.158 10.7255 10.4392 10.4423C10.7203 10.1591 11.1017 10 11.4994 10L15.7099 10C15.9223 9.99943 16.1324 10.0443 16.3263 10.1316C16.5201 10.219 16.6934 10.3468 16.8345 10.5067ZM15.6151 11.7253H11.7129V12.914H16.6645L15.6151 11.7253ZM11.7129 23.2747H25.4299V14.6392H11.7129V23.2747Z'
        fill={color}
      />
    </svg>
  );
};

export default IconHomeButtonDocs;
