import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListNotice as UsecaseRemoteListNotice } from '~/domain/usecases/notice/remote';
import { ListNotice as UsecaseReduxListNotice } from '~/domain/usecases/notice/redux';

import { RemoteListNotice } from '~/data/repository/notice';
import { ReduxListNotice } from '~/data/store/reducer/notice/usecases';

/**
 * send request via API.
 */
export const makeRemoteListNotice = (): UsecaseRemoteListNotice =>
  new RemoteListNotice(makeApiUrl('/notices'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListNotice = (): UsecaseReduxListNotice =>
  new ReduxListNotice();
