import { Box, Stack, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconDeleteDefault } from '~/presentation/base/icons';
import { iNotice } from '~/domain/interfaces/models';

interface ownProps {
  notice?: iNotice['records'][0];
}

const DeleteNoticeSuccess = ({ notice }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      isOpen={active === 'DELETE_NOTICE'}
      showTopButton={false}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconDeleteDefault />}
      title={'Edital excluído!'}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text textAlign={'center'}>
              {`O Edital`} <b>{notice?.title}</b> {`foi excluído com sucesso!`}
            </Text>
          </Stack>
        </Box>
      }
      buttonText='Entendi'
    ></Confirmation>
  );
};

export default DeleteNoticeSuccess;
