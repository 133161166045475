import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListNoticeFoldersDocument as UsecaseRemoteListNoticeFoldersDocument } from '~/domain/usecases/document/remote';
import { ListNoticeFoldersDocument as UsecaseReduxListNoticeFoldersDocument } from '~/domain/usecases/document/redux';

import { RemoteListNoticeFoldersDocument } from '~/data/repository/document';
import { ReduxListNoticeFoldersDocument } from '~/data/store/reducer/document/usecases';

/**
 * send request via API.
 */
export const makeRemoteListNoticeFoldersDocument =
  (): UsecaseRemoteListNoticeFoldersDocument =>
    new RemoteListNoticeFoldersDocument(
      makeApiUrl('/documents/notices/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListNoticeFoldersDocument =
  (): UsecaseReduxListNoticeFoldersDocument =>
    new ReduxListNoticeFoldersDocument();
