import React, { useContext, useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import {
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import { useFormik } from 'formik';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';

import { schemaRegisterInform } from '~/validators/RegisterInform/RegisterInformValidator';
import FormRegisterInform from '~/presentation/components/Form/RegisterInform/FormRegisterInform';
import { iOldFile } from '../../notice/EditNotice';
import { makeRemoteUpdateAccountability } from '~/main/factories/usecases/accountability/UpdateAccountabilityFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { iAccountability } from '~/domain/interfaces/models';
import { makeReduxListAccountability } from '~/main/factories/usecases/accountability/ListAccountabilityFactory';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  accountability?: iAccountability['records'][0];
}

const EditInform = ({
  isOpen,
  onClose,
  accountability,
}: ownProps): JSX.Element => {
  const [justificationText, setJustificationText] = React.useState<string>('');
  const { startups } = useSelector((store: iStore) => store.auth);
  const [files, setFiles] = useState<File[]>([]);
  const [oldFiles, setOldFiles] = useState<iOldFile[]>([]);
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (accountability) {
      formik.setValues({
        ...formik.values,
        code: accountability.code,
        notice: accountability.notice.toString(),
        type: accountability.type,
        value: accountability.value.toString(),
        begin: new Date(accountability.begin),
        end: new Date(accountability.end),
      });

      const _oldFiles = accountability.folder_?.files.map((el) => ({
        id: el.id,
        name: el.filename,
        type: el.mimetype,
        size: el.size,
      }));
      setOldFiles(_oldFiles);
      // formik.setFieldValue('cnpj', new StringMask('00.000.000/0000-00').apply(startup.cnpj));
    }
  }, [accountability, isOpen]);

  const onCloseModal = () => {
    onClose();
    setFiles([]);
    formik.resetForm({ values: initial });
    setIsLoading(false);
  };

  const { initial, validators } = schemaRegisterInform;

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);
      // eslint-disable-next-line no-console
      console.log('...startups', startups, !startups);
      if (!startups || !startups.length) {
        onCloseModal();
        throw new Error('Startup não encontrada');
      }

      setIsLoading(true);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      let _files = oldFiles?.map((el) => el.id);

      try {
        if (files.length > 0) {
          const response = await axios.post(
            makeApiUrl('/files/upload'),
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          _files = [
            ...oldFiles.map((el) => el.id),
            ...response.data.map((el: any) => el.id),
          ];
        }
      } catch (error) {
        showConfirmModal('APPLICATION_ERROR');
        onCloseModal();
      }

      accountability &&
        makeRemoteUpdateAccountability()
          .update({
            id: accountability?.id,
            body: {
              code: values.code,
              startup: startups[0].id,
              notice: Number(values.notice),
              type: values.type,
              value: Number(values.value),
              begin: datefns.format(new Date(values.begin), 'yyyy-MM-dd'),
              end: datefns.format(new Date(values.end), 'yyyy-MM-dd'),
              files: _files,
            },
          })
          .then(() => {
            showConfirmModal('EDIT_INFORM');
            makeReduxListAccountability().list({});
          })
          .catch(() => {
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            onCloseModal();
          });
    },
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onCloseModal();
        }}
        size='3xl'
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg='#F9F8FA'>
          <ModalHeader>Editar Informe</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH='416px' paddingBottom={'4px'} paddingTop={'6.5px'}>
            <Divider w={'100%'} marginBottom={'20px'}></Divider>
            <FormRegisterInform
              formik={formik}
              setFiles={setFiles}
              oldFiles={oldFiles}
              externalFiles={files}
            />
            <Divider w={'100%'}></Divider>
          </ModalBody>
          <ModalFooter>
            <Flex justify='space-between' w='100%'>
              <Button
                fontWeight='medium'
                variant='outline'
                color='#303950'
                borderColor='#E9ECEE'
                onClick={() => {
                  onCloseModal();
                }}
              >
                Cancelar
              </Button>
              {
                <Button
                  id='submit'
                  bg='#303950'
                  _hover={{ background: '#788FC9' }}
                  _active={{ background: '#303950' }}
                  color='white'
                  isLoading={isLoading}
                  loadingText=' Editando informe'
                  fontWeight='500'
                  fontSize='14px'
                  onClick={() => formik.handleSubmit()}
                >
                  Editar informe
                </Button>
              }
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditInform;
