import React from 'react';

type prop = {
  unread?: boolean;
  disabled?: boolean;
};

export const IconBellNotifications = ({
  unread,
  disabled,
}: prop): JSX.Element => {
  const color = disabled ? '#9C9C9C' : '#222222';
  if (unread) {
    return (
      <svg
        width='20'
        height='22'
        viewBox='-1 -0.6 20 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        overflow='visible'
      >
        <path
          d='M8.35374 0.0770484C8.23259 0.117414 8.01913 0.232744 7.88067 0.330775C7.34991 0.694064 7.09029 1.16115 7.04414 1.83583L7.00953 2.26255L6.65184 2.38942C4.6211 3.116 3.04612 4.71332 2.37112 6.72583C2.03074 7.74073 2.01921 7.91949 2.01921 11.6043V14.9546L1.0096 15.9638L0 16.9729V17.4746V17.982H5.76916H11.5325L11.5672 17.7629C11.6422 17.3247 11.7922 16.846 11.9768 16.4366L12.1672 16.0214H8.07105H3.97495L3.99226 12.0541L4.00956 8.09249L4.1711 7.5735C4.70763 5.86085 5.98262 4.62105 7.70183 4.1309C8.30759 3.96367 9.62295 3.94637 10.2114 4.10207C12.0172 4.58645 13.2748 5.79742 13.8287 7.58504L13.9844 8.09249L14.0075 11.1026C14.0191 12.7634 14.0479 14.1185 14.071 14.1185C14.0941 14.1185 14.2383 14.0551 14.3998 13.9801C14.769 13.8013 15.3806 13.611 15.7556 13.5649L16.0498 13.5245L16.0209 10.8085C15.9979 8.34045 15.9863 8.04636 15.8825 7.5735C15.4786 5.80895 14.5729 4.39039 13.1825 3.34666C12.7671 3.03526 11.6883 2.47015 11.2729 2.35482L11.0364 2.28562L11.0018 1.88773C10.9383 1.13809 10.5172 0.498003 9.85949 0.157779C9.53642 -0.0094491 8.68835 -0.0555811 8.35374 0.0770484Z'
          fill={color}
        />
        <path
          d='M16.0556 15.5601C14.596 15.8196 13.5575 17.0536 13.5575 18.5299C13.5575 19.3429 13.8402 20.0061 14.446 20.6116C14.8844 21.0498 15.2594 21.2632 15.8363 21.4131C17.3652 21.8052 19.0094 20.8307 19.419 19.2853C19.5401 18.8412 19.5228 18.0628 19.3844 17.6245C19.1709 16.9268 18.5651 16.1944 17.9421 15.8773C17.3767 15.5947 16.6152 15.4621 16.0556 15.5601Z'
          fill='#F3412A'
        />
        <path
          d='M7.03837 19.2218C7.03837 19.81 7.52875 20.502 8.16912 20.8134C8.48643 20.9691 8.60181 20.9979 8.98834 20.9979C9.37488 21.0037 9.49603 20.9749 9.8191 20.8249C10.4537 20.5251 10.9037 19.9426 10.996 19.291L11.0306 19.02H9.0345H7.03837V19.2218Z'
          fill={color}
        />
      </svg>
    );
  }
  return (
    <svg
      width='18'
      height='21'
      viewBox='0 0 18 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 19H11C11 20.1 10.1 21 9 21C7.9 21 7 20.1 7 19ZM18 17V18H0V17L2 15V9C2 5.9 4 3.2 7 2.3V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.3C14 3.2 16 5.9 16 9V15L18 17ZM14 9C14 6.2 11.8 4 9 4C6.2 4 4 6.2 4 9V16H14V9Z'
        fill={color}
      />
    </svg>
  );
};

export default IconBellNotifications;
