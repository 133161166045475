import React from 'react';

interface IconDownloadDocsProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const IconDownloadDocs = ({ fill, ...props }: IconDownloadDocsProps) => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.5 11.75L2.8125 7.0625L4.125 5.70312L6.5625 8.14062V0.5H8.4375V8.14062L10.875 5.70312L12.1875 7.0625L7.5 11.75ZM0 15.5V10.8125H1.875V13.625H13.125V10.8125H15V15.5H0Z'
        fill={fill || '#1997F3'}
      />
    </svg>
  );
};

export default IconDownloadDocs;
