import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/dashboard/actions/get';

import { iActionGet } from '~/domain/interfaces/redux/dashboard/get';

import { GetDashboard } from '~/domain/usecases/dashboard/redux';

export class ReduxGetDashboard implements GetDashboard {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetDashboard.Params): iActionGet =>
    this.send(getRequest(params));
}
