import React, { useContext } from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Button,
  HStack,
  Link,
  useDisclosure,
  Divider,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import RedefinirSenha from './RedefinirSenha';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';

interface ownProps {
  email: string;
  disabled: boolean;
}

const EmailEnviado: React.FC<ownProps> = ({ email, disabled }): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        w='100%'
        bg='#303950'
        disabled={disabled}
        color='#F9F8FA'
        _hover={{ background: '#4B587A' }}
        _active={{ background: '#2D3548' }}
        onClick={() => {
          onOpen();
        }}
      >
        Enviar E-mail
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          // close
        }}
      >
        <ModalOverlay />
        <ModalContent w='350px'>
          <ModalHeader fontSize='2xl' fontWeight='bold'>
            Email enviado!
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              showConfirmModal('');
              onClose();
            }}
          />
          <ModalBody>
            <Stack spacing='4'>
              <Text fontSize='xs'>
                Você receberá uma mensagem em{' '}
                <Text as='u' color='#1997F3'>
                  {email}
                </Text>{' '}
                contendo as instruções para definir uma nova senha.
              </Text>
              <Divider />
              <Text fontSize='xs'>
                Não recebeu a mensagem? Verifique se o endereço de e-mail está
                correto e solicite o reenvio das instruções.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent='start' paddingBottom='22px'>
            <Text fontSize='xs' mt='2'>
              <Link color='#1997F3' onClick={onClose}>
                Reenviar e-mail
              </Link>
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailEnviado;
