import { call, put, select } from 'redux-saga/effects';
import { iActionListMentoringFolders } from '~/domain/interfaces/redux/document/listMentoringFolders';
import { ListMentoringFoldersDocument } from '~/domain/usecases/document/remote/ListMentoringFoldersDocument';
import { makeRemoteListMentoringFoldersDocument } from '~/main/factories/usecases/document/ListMentoringFoldersDocumentFactory';
import {
  listMentoringFoldersSuccess,
  listMentoringFoldersFailed,
} from '~/data/store/reducer/document/actions/listMentoringFolders';

export function* onListMentoringFolders(action: iActionListMentoringFolders) {
  const remoteListMentoringFoldersDocument =
    makeRemoteListMentoringFoldersDocument();

  try {
    const response: ListMentoringFoldersDocument.Model = yield call(
      remoteListMentoringFoldersDocument.listMentoringFolders,
      action.payload
    );
    yield put(
      listMentoringFoldersSuccess({
        ...response,
      })
    );
  } catch (e) {
    yield put(listMentoringFoldersFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListMentoringFoldersSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListMentoringFoldersFailed() {}
