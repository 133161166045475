import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListMentoringFoldersDocument as UsecaseRemoteListMentoringFoldersDocument } from '~/domain/usecases/document/remote';
import { ListMentoringFoldersDocument as UsecaseReduxListMentoringFoldersDocument } from '~/domain/usecases/document/redux';

import { RemoteListMentoringFoldersDocument } from '~/data/repository/document';
import { ReduxListMentoringFoldersDocument } from '~/data/store/reducer/document/usecases';

/**
 * send request via API.
 */
export const makeRemoteListMentoringFoldersDocument =
  (): UsecaseRemoteListMentoringFoldersDocument =>
    new RemoteListMentoringFoldersDocument(
      makeApiUrl('/documents/mentors/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
export const makeReduxListMentoringFoldersDocument =
  (): UsecaseReduxListMentoringFoldersDocument =>
    new ReduxListMentoringFoldersDocument();
