import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Text,
  HStack,
  Button,
  Divider,
  Stack,
} from '@chakra-ui/react';
import Input from '~/presentation/components/UI/Input';
import { History } from '~/main/routes';
import { makeRemoteCreateStartup } from '~/main/factories/usecases/startup/CreateStartupFactory';
import { useFormik } from 'formik';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { schemaRegisterStartup } from '~/validators/RegisterStartup/RegisterStartupValidator';
import { makeRemoteCreateUser } from '~/main/factories/usecases/user/CreateUserFactory';
import FormRegisterStartup from '~/presentation/components/Form/RegisterStartup/FormRegisterStartup';
import { IconRegistrationsNoticeStartups } from '~/presentation/base/icons';
import cepPromise from 'cep-promise';

interface CepResponse {
  street: string;
  city: string;
  neighborhood: string;
  state: string;
}

const RegistrationStartup = (): JSX.Element => {
  const { initial, validators } = schemaRegisterStartup;
  const [step, setStep] = useState(1);

  const [cepResponse, setCepResponse] = useState<CepResponse | undefined>(
    undefined
  );

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values, { resetForm }) => {
      // eslint-disable-next-line no-console
      console.log('...enviado', values);

      /* makeRemoteCreateStartup()
        .create({
          body: {
            cnpj: values.cnpj.replace(/[./-]/g, ''),
            name: values.name,
            fullname: values.nameManager,
            description: values.notice,
            supervisor: 1,
            address: {
              type: 'APT',
              zipcode: values.cep,
              province: 'PB',
              city: 'string',
              country: 'BRA',
              neighborhood: 'string',
              street: values.address,
              number: values.numberManager,
              complement: 'string',
            },
            manager: {
              fullname: values.nameManager,
              email: values.emailManager,
            },
          },
        })
        .then(() => { */
          /* makeRemoteCreateUser()
            .create({
              body: {
                fullname: values.nameManager,
                email: values.emailManager,
                login: values.emailManager,
                phone: '83999999999',
                avatar: '',
                role: 'MEMBER',
                password: 'senha123',
              },
            })
            .then(() => { */
       /*    makeReduxListStartup().list({});
          resetForm({
            values: initial,
          }); */
          // History.push('/startups');
          /* }); */
      /*   }); */
    },
  });

  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log(formik.values.cep.length);
  //   async function fetchCEP() {
  //     try {
  //       const cepResponseData = await cepPromise(formik.values.cep);
  //       setCepResponse(cepResponseData);
  //       setCepResponseStreet(cepResponseData.street);
  //     } catch {
  //       setCepResponse(undefined);
  //     }
  //   }

  //   if (formik.values?.cep?.length === 9) {
  //     fetchCEP();
  //   } else {
  //     setCepResponse(undefined);
  //   }
  // }, [setCepResponse, setCepResponseStreet, formik.values.cep]);

  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue('address', cepResponseData.street);
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  // eslint-disable-next-line no-console
  console.log(formik.isValid, formik.values, formik.errors, formik.touched);

  const handleFieldsErrors = () => {
    formik.setTouched({
      name: true,
      cnpj: true,
      cep: true,
      address: true,
      nameManager: true,
      emailManager: true,
    });
  };

  return (
    <Box flex='1'>
      {step === 1 ? (
        <>
          <Flex justify='space-between' mx='8'>
            <Text color='#1c294b' fontWeight='bold' fontSize='2xl'>
              Nova Startup
            </Text>
            <HStack spacing='4'>
              <Button
                variant='outline'
                color='#303950'
                borderColor='#303950'
                onClick={() => History.push('/startups')}
              >
                Cancelar
              </Button>
              <Button
                id='submit'
                bg='#303950'
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                fontSize={14}
                color='white'
                onClick={() =>
                  step === 1 && formik.isValid
                    ? (formik.handleSubmit, setStep(step + 1))
                    : // eslint-disable-next-line no-console
                      (console.log('disparo de erro',formik.values, formik.errors),
                      handleFieldsErrors())
                }
              >
                Concluir cadastro
              </Button>
            </HStack>
          </Flex>
          <Flex direction='column'>
            <Stack maxW='700px' spacing='4' pl='8' py='8'>
              <Text fontWeight='bold' fontSize='md' mb='1'>
                Dados Gerais
              </Text>
              <HStack spacing='7' alignItems='flex-start'>
                <Input
                  id='name'
                  name='name'
                  label='Nome da Startup'
                  required
                  formik={formik}
                />
                <Input
                  id='cnpj'
                  name='cnpj'
                  label={'CNPJ'}
                  required
                  placeholder='__.___.___/____-__'
                  /* mask='99.999.999/9999-99' */
                  mask={[
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  formik={formik}
                />
              </HStack>
              <HStack spacing='7' alignItems='flex-start'>
                <Box maxW='175px'>
                  <Input
                    id='cep'
                    name='cep'
                    label='CEP'
                    required
                    placeholder='_____-___'
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    formik={formik}
                    value={formik?.values.cep}
                    onBlurCapture={handleOnBlur}
                  />
                </Box>
                <Input
                  id='address'
                  name='address'
                  label='Endereço'
                  required
                  formik={formik}
                  value={formik?.values.address}
                />
                <Box maxW='175px'>
                  <Input
                    id='number'
                    name='number'
                    label='Número'
                    required
                    formik={formik}
                  />
                </Box>
              </HStack>
              {/* <HStack spacing='7'>
      <Box w='50%'>
        <Text fontWeight='bold' fontSize='sm' mb='1'>
          Edital associado
        </Text>
        <Select
          id='notice'
          name='notice'
          placeholder='Selecione'
          bg='#F9F8FA'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.notice}
        >
          <option value='Edital nº 000/2022'>Edital nº 000/2022</option>
          <option value='Edital nº 090/2022'>Edital nº 090/2023</option>
          <option value='Edital nº 900/2022'>Edital nº 900/2024</option>
        </Select>
      </Box>
      <Box w='50%'>
        <Text fontWeight='bold' fontSize='sm' mb='1'>
          Supervisor
        </Text>
        <Select
          id='supervisor'
          name='supervisor'
          placeholder='Selecione'
          bg='#F9F8FA'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.supervisor}
        >
          <option value='option1'>Nome do Supervisor</option>
          <option value='option1'>Nome do Supervisor</option>
          <option value='option1'>Nome do Supervisor</option>
        </Select>
      </Box>
    </HStack> */}
            </Stack>
            <Divider />
            <Stack maxW='700px' spacing='4' pl='8' py='8'>
              <Text fontWeight='bold' fontSize='md' mb='1'>
                Informações do Gestor
              </Text>
              <HStack spacing='7' alignItems='flex-start'>
                <Input
                  id='nameManager'
                  name='nameManager'
                  label='Nome do Gestor'
                  required
                  formik={formik}
                />
                <Input
                  id='emailManager'
                  name='emailManager'
                  label='E-mail do Gestor'
                  required
                  formik={formik}
                />
              </HStack>
            </Stack>
          </Flex>
        </>
      ) : (
        <Box>
          <Flex
            flexDirection={'column'}
            align='center'
            justify={'space-between'}
            gap='30px'
            paddingTop='168px'
          >
            <IconRegistrationsNoticeStartups></IconRegistrationsNoticeStartups>
            <Flex
              flexDirection={'column'}
              align='center'
              justify={'center'}
              gap='5px'
            >
              <Text color='#1c294b' fontWeight='bold' fontSize='3xl'>
                Cadastro concluído!
              </Text>
              <Text
                color='#303950'
                fontWeight='normal'
                fontSize='1xl'
                fontFamily='Poppins'
                textAlign='center'
                width='380px'
              >
                A Startup “Lorem Ipsum Dolor Sit Amet” foi cadastrada com
                sucesso.
              </Text>
            </Flex>
            <Button
              bg='transparent'
              _hover={{ background: '#D3DCF4' }}
              _active={{ background: '#B3C5F4' }}
              border='1.5px solid #303950'
              border-radius='5px'
              color='#303950'
              fontSize={12}
              onClick={() => History.push('/startups')}
              width='254px'
              height='40px'
              padding='10px 20px 10px 20px'
            >
              <Text
                color='#303950'
                fontWeight='500'
                fontSize='1xl'
                textAlign='center'
              >
                Voltar para a Lista de Startups
              </Text>
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default RegistrationStartup;
