import React from 'react';

const IconEdit = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7401 0C15.266 0 15.7037 0.178977 16.0615 0.537417L17.4622 1.93809C17.8224 2.29838 18.009 2.73007 18.0247 3.23951C18.0403 3.74621 17.8704 4.17436 17.5122 4.53201L16.1772 5.86702L12.1088 1.84642L13.4177 0.537496C13.7762 0.178982 14.2143 0 14.7401 0Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5449 7.52437L10.4754 3.45483L0 13.9305V18H4.06946L14.5449 7.52437ZM1.74305 14.6741V16.2569H3.32588L12.0833 7.49936L10.5004 5.9165L1.74305 14.6741Z" fill="#222222" />
    </svg>

  );
};

export default IconEdit;
