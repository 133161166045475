import React, { useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { Field, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Form from '~/presentation/components/UI/FormFormik';
import { Button } from '~/presentation/components/UI';
import { Typography } from '~/presentation/components/UI/Typography';
import FormItem from '~/presentation/components/UI/FormFormik/formItem';
import { Input } from '~/presentation/components/UI/NewInput';
import { schemaChatPassword } from '~/validators/ChatPassword/ChatPasswordValidator';
import { makeRemoteConfirmMattermostUser } from '~/main/factories/usecases/user/ConfirmMattermostUserFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxRefreshAuth } from '~/main/factories/usecases/auth/RefreshAuthFactory';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';

interface ownProps {
  isOpen: boolean;
  onClose?: () => void;
}

const ChatPassword = ({ isOpen, onClose }: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { initial, validators } = schemaChatPassword;

  const { user, refreshToken } = useSelector((store: iStore) => store.auth);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    isInitialValid: false,
    onSubmit: async (values) => {
      user &&
        makeRemoteConfirmMattermostUser()
          .confirmMattermost({
            user: user.id,
            body: { password: values.password },
          })
          .then(() => {
            axios
              .post(makeApiUrl('/auth/refresh'), { token: refreshToken })
              .then((response) => {
                makeReduxRefreshAuth().refresh({
                  ...response.data,
                });
              });
            window.open(`${window.config.connection.mattermostUrl}`, '_blank');
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.log('>>> error');
            window.open(`${window.config.connection.mattermostUrl}`, '_blank');
            showConfirmModal('APPLICATION_ERROR');
          })
          .finally(() => {
            window.open(`${window.config.connection.mattermostUrl}`, '_blank');
            onCloseModal();
          });
    },
  });

  const history = useHistory();

  const onCloseModal = () => {
    if (onClose) {
      formik.resetForm({ values: initial });
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg='#FDFDFD'
          w={{ base: '100%', md: '400px' }}
          position={{ base: 'absolute', md: 'inherit' }}
          bottom={{ base: '0', md: 'inherit' }}
          top={{ base: '5px', md: 'auto' }}
          margin={{ base: '100px 0px 0px 0px ', md: 'auto' }}
          minHeight={{ base: 'fit-content', md: '290px' }}
          borderTopRadius='10px'
          borderBottomRadius={{ base: '0px', md: '10px' }}
        >
          <ModalHeader
            paddingTop={{ base: '27px', md: '20px' }}
            paddingX={{ base: '20px', md: '20px' }}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h3_Inter_Bold_18px'>
              Acessar bate-papo
            </Typography>
          </ModalHeader>
          <ModalBody
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            paddingX={{ base: '20px' }}
            paddingTop={{ base: '0px' }}
            paddingBottom={{ base: '20px' }}
          >
            <Typography color='#484848' variant='b1_Inter_Regular_14px'>
              As mensagens são enviadas e recebidas através da plataforma{' '}
              <Text
                display={'inline'}
                decoration={'underline'}
                color={'blue.2'}
              >
                {`Mattermost.`}
              </Text>{' '}
              Para acessá-la, digite sua senha atual no campo abaixo.
            </Typography>
            <Form value={formik}>
              <FormItem name='password'>
                <Field
                  id='password'
                  as={Input}
                  isRequired
                  label='Senha'
                  placeholder='Digite sua senha'
                  type='password'
                />
              </FormItem>
            </Form>
          </ModalBody>
          <ModalFooter
            padding='20px'
            display={'flex'}
            flexDirection={'column'}
            borderTop={'1px solid #CECECE'}
          >
            <Button
              w={'100%'}
              disabled={!formik.isValid || formik.isSubmitting}
              isLoading={formik.isSubmitting}
              /* loadingText='Confirmando...' */
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChatPassword;
