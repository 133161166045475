import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import IconRegisterDeliveryEvent from '~/presentation/base/icons/iconRegisterDeliveryEvent';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterDeliveryEventSuccess = ({ name, fullname, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='fit-content'
      isOpen={active === 'SUBMMIT_DELIVERY_EVENT'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconRegisterDeliveryEvent />}
      title='Entrega agendada!'
      topButtonText='Agendar outro evento'
      onOpenRegister={onOpenRegister}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>A entrega foi agendada com sucesso!</Text>
            <Text>
              Os documentos devem ser enviados até a data acordada, como
              requisito para cumprir a etapa de Acompanhamento do Edital
              selecionado.
            </Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default RegisterDeliveryEventSuccess;
