import React, { useContext } from 'react';
import WarningModal from '../../WarningModal';
import { makeRemoteDeleteUser } from '~/main/factories/usecases/user/DeleteUserFactory';
import { makeReduxListUser } from '~/main/factories/usecases/user/ListUserFactory';
import { iUser } from '~/domain/interfaces/models/User';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  user?: iUser['records'][0];
  id?: number;
}

const DeleteUser = ({ isOpen, onClose, user, id }: ownProps): JSX.Element => {
  const { showConfirmModal } = useContext(ConfirmModalContext);

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onClose}
      title='Excluir Usuário(a)'
      question={`Tem certeza de que quer excluir o(a) usuário(a) ${user?.fullname}?`}
      description='Não há como reverter esta operação. 
        Todos os documentos cadastrados por este usuário serão perdidos.'
      action={() => {
        id &&
          makeRemoteDeleteUser()
            .delete({ id })
            .then(() => {
              makeReduxListUser().list({ query: { limit: 999 } });
              showConfirmModal('DELETE_USER');
            })
            .catch(() => {
              showConfirmModal('APPLICATION_ERROR');
            })
            .finally(() => {
              onClose();
            });
      }}
      actionText='Excluir'
      actionTextLoading='Excluindo'
    />
  );
};

export default DeleteUser;
