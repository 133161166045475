import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  IconFooter,
  IconHomeButtonEvents,
  IconHomeButtonNotice,
  IconHomeButtonRegistrations,
  IconHomeButtonStartups,
  IconHomeButtonUsers,
  IconLogo,
  IconLogoTextWhite,
  IconSocialFooter,
} from '~/presentation/base/icons';
import DashboardCard from '~/presentation/components/DashboardCard';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import BarChart from '~/presentation/components/BarChart';
import BarChartGroup from '~/presentation/components/BarChartGroup';
import { History } from '~/main/routes';
import getDashboardActivitiesColors from '~/utils/getDashboardActivitiesColors';
import { useLocation } from 'react-router';

interface LocationState {
  view: boolean;
}

const Dashboard = (): JSX.Element => {
  const { notice, startup, application, events, users, usersAge, activity } =
    useSelector((store: iStore) => store.dashboard);
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const { state } = useLocation<LocationState>();

  const redirect = () => {
    if (accessToken) History.push('/inicio');
    else History.push('/login');
  };

  return (
    <Box w='100%' h='100%'>
      <Flex bg='black' color='white' py='5' px='12'>
        <Flex w='20%'>
          <Box>
            <IconLogoTextWhite />
          </Box>
        </Flex>
        <Center w='60%'>{state?.view && 'Painel de Monitoramento'}</Center>
        <Flex w='20%' justify='end'>
          <Button onClick={redirect} color='#222222'>
            {'Entrar no sistema '}
          </Button>
        </Flex>
      </Flex>
      <Flex w='100%' h='calc(100% - 208px)' py='8' px='12' overflow='auto'>
        <Wrap w='100%' spacing='3' overflow='unset'>
          <WrapItem w={{ '2xl': '49%', xl: '100%' }}>
            <Flex w='100%' direction='column' gap='3'>
              <DashboardCard
                icon={
                  <IconHomeButtonNotice color={'#AC38A8'} bgColor={'#F6E1FA'} />
                }
                data={notice}
                separator={'ALL'}
                title='Editais de financiamento'
              />
              <DashboardCard
                icon={<IconHomeButtonStartups />}
                data={startup}
                separator={'ALL'}
                title='Startups'
              />
              <Flex
                w='100%'
                h='100%'
                border='1px solid #CECECE'
                borderRadius='5px'
                direction='column'
                bg='#FDFDFD'
              >
                <Box
                  w='100%'
                  py='4'
                  px='5'
                  fontWeight='bold'
                  fontSize='md'
                  borderBottom='1px solid #CECECE'
                >
                  Áreas de atuação das startups
                </Box>
                <Box w='100%' py='4' px='5'>
                  <BarChart
                    width={360}
                    height={20}
                    data={activity
                      ?.sort((a, b) => b.count - a.count)
                      .map((item) => ({
                        name: item.name,
                        value: item.percentage,
                        valueAndPerc: `${item.count} (${item.percentage}%)`,
                        color: getDashboardActivitiesColors(item.short),
                      }))}
                    datakey='value'
                  />
                </Box>
              </Flex>
            </Flex>
          </WrapItem>
          <WrapItem w={{ '2xl': '49%', xl: '100%' }}>
            <Flex w='100%' direction='column' gap='3'>
              <DashboardCard
                icon={<IconHomeButtonRegistrations />}
                data={application}
                separator={'ALL'}
                title='Inscrições'
              />
              <DashboardCard
                icon={
                  <IconHomeButtonEvents color={'#C23422'} bgColor={'#FFEFEA'} />
                }
                data={events}
                separator='FIRST'
                title='Eventos'
              />
              <DashboardCard
                icon={<IconHomeButtonUsers />}
                data={users}
                separator='FIRST'
                title='Usuários'
              />
              <Flex
                w='100%'
                h='100%'
                border='1px solid #CECECE'
                borderRadius='5px'
                direction='column'
                bg='#FDFDFD'
              >
                <Flex
                  w='100%'
                  py='4'
                  px='5'
                  fontWeight='bold'
                  fontSize='md'
                  borderBottom='1px solid #CECECE'
                  justify='space-between'
                >
                  Idade e gênero dos usuários
                  <Flex alignItems='baseline' fontWeight='normal' fontSize='sm'>
                    <Box
                      bg='#1997F3'
                      w='10px'
                      h='10px'
                      mr='2'
                      borderRadius='2px'
                    />
                    Homens
                    <Box
                      bg='#FB6FA1'
                      w='10px'
                      h='10px'
                      ml='3'
                      mr='2'
                      borderRadius='2px'
                    />
                    Mulheres
                    <Box
                      bg='#F49404'
                      w='10px'
                      h='10px'
                      ml='3'
                      mr='2'
                      borderRadius='2px'
                    />
                    Outros
                  </Flex>
                </Flex>
                <Box w='100%' py='4' px='5'>
                  <BarChartGroup
                    width={360}
                    height={20}
                    data={usersAge?.ageList}
                    datakey='value'
                  />
                </Box>
                <Flex px='5' mb='6' justify='end' fontSize='sm'>
                  <Text>
                    Total: <strong>{users[0]?.value}</strong>
                  </Text>
                  <Text color='#CECECE' mx='2'>
                    |
                  </Text>
                  <Text>{usersAge?.male}</Text>
                  <Text color='#CECECE' mx='2'>
                    |
                  </Text>
                  <Text>{usersAge?.female}</Text>
                  <Text color='#CECECE' mx='2'>
                    |
                  </Text>
                  <Text>{usersAge?.other}</Text>
                </Flex>
              </Flex>
            </Flex>
          </WrapItem>
        </Wrap>
      </Flex>
      <Flex
        h='128px'
        bg='black'
        py='6'
        px='12'
        justify='space-between'
        align='start'
      >
        <IconFooter />
        <IconSocialFooter />
      </Flex>
    </Box>
  );
};

export default Dashboard;

const data1 = [
  { name: 'Tecnologia da informação', pv: 77, color: '#1997F3' },
  { name: 'Saúde e bem-estar', pv: 63, color: '#FB6FA1' },
  { name: 'Alimentos e bebidas', pv: 50, color: '#FCBD5F' },
  { name: 'Comércio e varejo', pv: 48, color: '#E981D8' },
  { name: 'Meio ambiente', pv: 45, color: '#8BC314' },
  { name: 'Agronegócio', pv: 21, color: '#0152B0' },
  { name: 'Eventos e turismo', pv: 10, color: '#D53520' },
  { name: 'Marketing e vendas', pv: 7, color: '#DE6D04' },
  { name: 'Educação', pv: 7, color: '#0873F1' },
  { name: 'Economia criativa', pv: 5, color: '#FF4C34' },
  { name: 'Gestão', pv: 3, color: '#AC38A8' },
];

const data2 = [
  { name: '18 - 24', pv: 77, uv: 10, result: '50 (50%)' },
  { name: '25 - 30', pv: 63, uv: 10, result: '50 (50%)' },
  { name: '31 - 40', pv: 50, uv: 10, result: '50 (50%)' },
  { name: '41 - 50', pv: 48, uv: 10, result: '50 (50%)' },
  { name: '51 - 60', pv: 45, uv: 10, result: '50 (50%)' },
  { name: '60+', pv: 21, uv: 10, result: '50 (50%)' },
];
