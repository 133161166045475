import { Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { IconRegister } from '~/presentation/base/icons';
import { withAccess } from '~/presentation/hooks/acess';

interface ButtonRegisterProps {
  action: () => void;
  text?: string;
  disabled?: boolean;
  showDownloadReportButton?: boolean;
}

const ButtonRegister = ({
  action,
  text,
  disabled,
  showDownloadReportButton,
}: ButtonRegisterProps): JSX.Element => {
  return (
    <Button
      width={
        showDownloadReportButton
          ? { base: '50%', md: '33%' }
          : { base: '86%', md: '57%' }
      }
      height='36px'
      background='#222222'
      color='#F9F8FA'
      _hover={{ background: '#4B587A' }}
      fontSize='sm'
      fontWeight={'500'}
      onClick={action}
      disabled={disabled}
    >
      <HStack>
        <IconRegister />
        {text && <span>{text}</span>}
      </HStack>
    </Button>
  );
};

export default withAccess(ButtonRegister);
