export const measureText = (
  text: any,
  font: string,
  ctx: CanvasRenderingContext2D | null
) => {
  if (!ctx) {
    ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) {
      return 0;
    }
    ctx.font = font;
  }

  return ctx.measureText(text).width;
};

export const maxWordWidth = (
  data: any,
  key: string,
  ctx: CanvasRenderingContext2D | null
) => {
  return data.reduce((acc: number, cur: { [x: string]: any }) => {
    const value = cur[key];
    const width = measureText(value.toLocaleString(), "16px 'Inter'", ctx);
    // eslint-disable-next-line no-console
    console.log('>>>width', width, value, acc);
    if (width > acc) {
      return width;
    }
    return acc;
  }, 0);
};
