import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteStartup as UsecaseRemoteDeleteStartup } from '~/domain/usecases/startup/remote';
// import { DeleteStartup as UsecaseReduxDeleteStartup } from '~/domain/usecases/startup/redux';

import { RemoteDeleteStartup } from '~/data/repository/startup';
// import { ReduxDeleteStartup } from '~/data/store/reducer/startup/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteStartup = (): UsecaseRemoteDeleteStartup =>
  new RemoteDeleteStartup(makeApiUrl('/startups/{id}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteStartup =
  (): UsecaseReduxDeleteStartup =>
    new ReduxDeleteStartup(); */