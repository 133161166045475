// import { active as activeMessage } from '~/data/store/reducer/message/actions/active';

export const MessageMiddleware = () => {
  // Socket param is the client. We'll show how to set this up later.
  return ({ dispatch, getState }: any) =>
    (next: any) =>
    (action: any) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      /*  const closeNotification = activeMessage({
        active: 'none',
      }); */

      const { type } = action;

      return next(action);
    };
};
