import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateNotice as UsecaseRemoteUpdateNotice } from '~/domain/usecases/notice/remote';
// import { UpdateNotice as UsecaseReduxUpdateNotice } from '~/domain/usecases/notice/redux';

import { RemoteUpdateNotice } from '~/data/repository/notice';
// import { ReduxUpdateNotice } from '~/data/store/reducer/notice/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateNotice =
  (): UsecaseRemoteUpdateNotice =>
    new RemoteUpdateNotice(
      makeApiUrl('/notices/{notice}'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateNotice =
  (): UsecaseReduxUpdateNotice =>
    new ReduxUpdateNotice(); */
