import { Dispatch } from 'redux';
import store from '~/data/store';
import { listStartupFoldersRequest } from '~/data/store/reducer/document/actions/listStartupFolders';

import { iActionListStartupFolders } from '~/domain/interfaces/redux/document/listStartupFolders';

import { ListStartupFoldersDocument } from '~/domain/usecases/document/redux';

export class ReduxListStartupFoldersDocument
  implements ListStartupFoldersDocument
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listStartupFolders = (
    params: ListStartupFoldersDocument.Params
  ): iActionListStartupFolders => this.send(listStartupFoldersRequest(params));
}
