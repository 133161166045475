import React from 'react';

const IconMenuRegistrationsWhite = (): JSX.Element => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4646 0C11.8736 0 12.214 0.139204 12.4923 0.417991L13.5817 1.5074C13.8619 1.78763 14.007 2.12338 14.0192 2.51962C14.0313 2.91372 13.8992 3.24673 13.6206 3.5249L12.5823 4.56324L9.41796 1.4361L10.436 0.418052C10.7148 0.139209 11.0555 0 11.4646 0Z'
        fill='#E2E2E2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3127 5.85229L8.14755 2.68709L0 10.8348V14H3.16514L11.3127 5.85229ZM1.35571 11.4131V12.6443H2.5868L9.39809 5.83284L8.167 4.60173L1.35571 11.4131Z'
        fill='#E2E2E2'
      />
    </svg>
  );
};

export default IconMenuRegistrationsWhite;
