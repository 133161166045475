import React from 'react';
import {
  Box,
  Flex,
  Text,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
  Avatar,
} from '@chakra-ui/react';
import {
  IconDelete,
  IconEdit,
  IconPlus,
  IconView,
} from '~/presentation/base/icons';
import Tab from '~/presentation/components/Tab';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import ButtonRegister from '~/presentation/components/ButtonRegister';
import RegisterEvent from '~/presentation/components/Modals/event/RegisterEvent';
import EditEvent from '~/presentation/components/Modals/event/EditEvent';
import { iEvent } from '~/domain/interfaces/models/Event';
import getDate, { getDateEvent, getTime } from '~/utils/getDate';
import RegisterEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterEventSuccess';
import EditEventSuccess from '~/presentation/components/Modals/Confirmation/EditEventSuccess ';
import RegisterMeetingEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterMeetingEventSuccess';
import RegisterMentoringEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterMentoringEventSuccess';
import RegisterDeliveryEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterDeliveryEventSuccess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeleteEvent from '~/presentation/components/Modals/event/DeleteEvent';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import DetailsEvent from '~/presentation/components/Modals/event/DetailsEvent';
import DeleteEventSuccess from '~/presentation/components/Modals/Confirmation/DeleteEventSuccess';
import { iAuth } from '~/domain/interfaces/models/Auth';

const Event = (): JSX.Element => {
  const [active, setActive] = React.useState('REUNIÕES');
  const [open, setOpen] = React.useState('');
  const openRegisterEvent = () => {
    setOpen('REGISTER')
  };
  const [selectedEvent, setSelectedEvent] = React.useState<
    iEvent['records'][0] | undefined
  >(undefined);
  const { records } = useSelector((store: iStore) => store.event);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const { user, startups } = useSelector((store: iStore) => store.auth);

  const isEventEditable = (item: iEvent['records'][0]) => {
    switch (user?.role_.name) {
      case 'ADMIN':
        return true;
      case 'SUPERVISOR':
        return item.responsable_?.role_.name !== 'ADMIN';
      case 'MENTOR':
        return item.responsable_?.id === user?.id;
      case 'LEADER':
        return (
          (item.responsable_?.role_.name === 'LEADER' ||
            item.responsable_?.role_.name === 'MEMBER') &&
          startups &&
          item.startup_?.id === startups[0]?.id
        );
      case 'MEMBER':
        return item.responsable_?.id === user?.id;
      default:
        return false;
    }
  };

  return (
    <Box flex='1' h='calc(100% - 112px)' m='8' mt={0} position='relative'>
      <Tab
        active={active}
        setActive={setActive}
        tabs={[
          { title: 'REUNIÕES' },
          { title: 'MENTORIAS' },
          { title: 'ENTREGAS' },
        ]}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        action={() => setOpen('REGISTER')}
        buttonText='Novo Evento'
        searchPlaceholder='Pesquisar eventos...'
      />
      {active === 'REUNIÕES' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          maxHeight='calc(100% - 190px)'
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead
              position='sticky'
              top={0}
              zIndex={5}
              backgroundColor='white-3'
              height='50px'
            >
              <Tr>
                <Th align='center'>Nome do Evento</Th>
                <Th>Data</Th>
                <Th>Horário</Th>
                <Th>Link Associado</Th>
                <Th>Responsável</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {records
                .filter((item) => item.type === 'MEETING')
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return (
                      `${item.name}`
                        .toLowerCase()
                        .search(filterByNameValue.toLowerCase()) !== -1
                    );
                  }
                })
                .map((item, index) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td align='center'>
                      <Text align='center'>{item.name}</Text>
                    </Td>
                    <Td>
                      <Text>{getDate(item.date)}</Text>
                    </Td>
                    <Td>
                      <Text>{getTime(item.date)}</Text>
                    </Td>
                    <Td>
                      <Link color='#1997F3'>{item.link}</Link>
                    </Td>
                    <Td>
                      <HStack spacing='4'>
                        {item.responsable_ && (
                          <Flex flexDirection={'row'} gap={'12px'}>
                            <Text
                              fontFamily='Inter'
                              fontStyle='normal'
                              fontWeight='400'
                              fontSize='sm'
                              color='#60656D'
                            >
                              {
                                <Avatar
                                  name={item?.responsable_?.fullname}
                                  src={item?.responsable_?.avatar}
                                />
                              }
                            </Text>
                            <Flex flexDirection={'column'} maxHeight={'40px'}>
                              <Text
                                fontFamily='Inter'
                                fontStyle='normal'
                                fontWeight='700'
                                fontSize='sm'
                                color='#60656D'
                              >
                                {item?.responsable_?.fullname}
                              </Text>

                              <Text
                                fontFamily='Inter'
                                fontStyle='normal'
                                fontWeight='400'
                                fontSize='sm'
                                color='#60656D'
                              >
                                {item?.responsable_?.email}
                              </Text>
                            </Flex>
                          </Flex>
                        )}
                      </HStack>
                    </Td>

                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedEvent(item);
                          }}
                        >
                          <IconView />
                        </ActionButton>

                        <ActionButton
                          onClick={() => {
                            setOpen('EDIT'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconEdit />
                        </ActionButton>
                        <ActionButton
                          actions={['ADMIN', 'SUPERVISOR']}
                          resourcers={['ADMIN', 'SUPERVISOR']}
                          onClick={() => {
                            // eslint-disable-next-line no-console
                            setOpen('DELETE'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {active === 'MENTORIAS' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          maxHeight='calc(100% - 190px)'
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead
              position='sticky'
              top={0}
              zIndex={5}
              backgroundColor='white-3'
              height='50px'
            >
              <Tr>
                <Th align='center'>Nome do Evento</Th>
                <Th>Data</Th>
                <Th>Horário</Th>
                <Th>Link Associado</Th>
                <Th>Mentor Responsável</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {records
                .filter((item) => item.type === 'MENTORING')
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return `${item.name}`
                      .toLowerCase()
                      .startsWith(filterByNameValue.toLowerCase());
                  }
                })
                .map((item, index) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td align='center'>
                      <Text align='center'>{item.name}</Text>
                    </Td>
                    <Td>
                      <Text>{getDate(item.date)}</Text>
                    </Td>
                    <Td>
                      <Text>{getTime(item.date)}</Text>
                    </Td>
                    <Td>
                      <Link color='#1997F3'>{item.link}</Link>
                    </Td>
                    <Td>
                      <HStack spacing='4'>
                        {item.responsable_ && (
                          <Flex flexDirection={'row'} gap={'12px'}>
                            <Text
                              fontFamily='Inter'
                              fontStyle='normal'
                              fontWeight='400'
                              fontSize='sm'
                              color='#60656D'
                            >
                              {
                                <Avatar
                                  name={item?.responsable_?.fullname}
                                  src={item?.responsable_?.avatar}
                                />
                              }
                            </Text>
                            <Flex flexDirection={'column'} maxHeight={'40px'}>
                              <Text
                                fontFamily='Inter'
                                fontStyle='normal'
                                fontWeight='700'
                                fontSize='sm'
                                color='#60656D'
                              >
                                {item?.responsable_?.fullname}
                              </Text>

                              <Text
                                fontFamily='Inter'
                                fontStyle='normal'
                                fontWeight='400'
                                fontSize='sm'
                                color='#60656D'
                              >
                                {item?.responsable_?.email}
                              </Text>
                            </Flex>
                          </Flex>
                        )}
                      </HStack>
                    </Td>

                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedEvent(item);
                          }}
                        >
                          <IconView />
                        </ActionButton>

                        <ActionButton
                          onClick={() => {
                            setOpen('EDIT'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconEdit />
                        </ActionButton>

                        <ActionButton
                          onClick={() => {
                            setOpen('DELETE'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {active === 'ENTREGAS' && (
        <TableContainer
          borderLeft='1px solid #cfcfcf'
          borderRight='1px solid #cfcfcf'
          maxHeight='calc(100% - 190px)'
          overflowY='auto'
        >
          <Table variant='simple' colorScheme='white'>
            <Thead
              position='sticky'
              top={0}
              zIndex={5}
              backgroundColor='white-3'
              height='50px'
            >
              <Tr>
                <Th align='center'>Nome do Evento</Th>
                <Th>Data Limite</Th>
                <Th>Edital Associado</Th>
                <Th>Etapa Associada</Th>
                <Th>Link Associado</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {records
                .filter((item) => item.type === 'DELIVERY')
                .filter((item) => {
                  if (filterByNameValue.trim() === '') {
                    return true;
                  } else if (filterByNameValue.trim() !== '') {
                    return (
                      `${item.name}`
                        .toLowerCase()
                        .search(filterByNameValue.toLowerCase()) !== -1
                    );
                  }
                })
                .map((item, index) => (
                  <Tr
                    fontSize={14}
                    fontWeight={400}
                    color='#747C86'
                    key={index}
                  >
                    <Td align='center'>
                      <Text align='center'>{item.name}</Text>
                    </Td>
                    <Td>
                      <Text>{getDate(item.date)}</Text>
                    </Td>
                    <Td>
                      <Text>{item.notice_.code}</Text>
                    </Td>
                    <Td>
                      <Text>{item.noticeStep_.name}</Text>
                    </Td>
                    <Td>
                      <Link color='#1997F3'>{item.link}</Link>
                    </Td>
                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            setOpen('DETAILS'), setSelectedEvent(item);
                          }}
                        >
                          <IconView />
                        </ActionButton>
                        <ActionButton
                          onClick={() => {
                            setOpen('EDIT'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconEdit />
                        </ActionButton>
                        <ActionButton
                          onClick={() => {
                            setOpen('DELETE'), setSelectedEvent(item);
                          }}
                          isDisabled={!isEventEditable(item)}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <DetailsEvent
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      ></DetailsEvent>
      <RegisterEvent isOpen={open === 'REGISTER'} onClose={() => setOpen('')} />
      <EditEvent
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      />
      <DeleteEvent
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      />
      <RegisterEventSuccess onOpenRegister={openRegisterEvent}></RegisterEventSuccess>
      <EditEventSuccess></EditEventSuccess>
      <RegisterMeetingEventSuccess onOpenRegister={openRegisterEvent}></RegisterMeetingEventSuccess>
      <RegisterMentoringEventSuccess onOpenRegister={openRegisterEvent}></RegisterMentoringEventSuccess>
      <RegisterDeliveryEventSuccess onOpenRegister={openRegisterEvent}></RegisterDeliveryEventSuccess>
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteEventSuccess event={selectedEvent}></DeleteEventSuccess>
    </Box>
  );
};

export default Event;
