import React from 'react';

const IconFolder = (): JSX.Element => {
  return (
    <svg
      width='31'
      height='26'
      viewBox='0 0 31 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.15 14.5625C21.0025 14.5625 21.7325 14.2562 22.3401 13.6437C22.9467 13.0323 23.25 12.2969 23.25 11.4375C23.25 10.5781 22.9467 9.84219 22.3401 9.22969C21.7325 8.61823 21.0025 8.3125 20.15 8.3125C19.2975 8.3125 18.568 8.61823 17.9614 9.22969C17.3538 9.84219 17.05 10.5781 17.05 11.4375C17.05 12.2969 17.3538 13.0323 17.9614 13.6437C18.568 14.2562 19.2975 14.5625 20.15 14.5625ZM13.95 20.8125H26.35V19.9531C26.35 18.7813 25.7817 17.85 24.645 17.1594C23.5083 16.4698 22.01 16.125 20.15 16.125C18.29 16.125 16.7917 16.4698 15.655 17.1594C14.5183 17.85 13.95 18.7813 13.95 19.9531V20.8125ZM3.1 25.5C2.2475 25.5 1.51797 25.1943 0.9114 24.5828C0.3038 23.9703 0 23.2344 0 22.375V3.625C0 2.76562 0.3038 2.03021 0.9114 1.41875C1.51797 0.80625 2.2475 0.5 3.1 0.5H12.4L15.5 3.625H27.9C28.7525 3.625 29.4825 3.93125 30.0901 4.54375C30.6967 5.15521 31 5.89062 31 6.75V22.375C31 23.2344 30.6967 23.9703 30.0901 24.5828C29.4825 25.1943 28.7525 25.5 27.9 25.5H3.1Z'
        fill='#60656D'
      />
    </svg>
  );
};

export default IconFolder;
