import { ListEvent } from '~/domain/usecases/event/redux/ListEvent';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  EventTypes,
} from '~/domain/interfaces/redux/event/list';

export const listRequest = (payload: ListEvent.Params): iActionList => ({
  type: EventTypes.LIST,
  payload,
});

export const listSuccess = (params: ListEvent.Model): iActionListSuccess => ({
  type: EventTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: EventTypes.LIST_FAILED,
});
