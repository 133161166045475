import React from 'react';
import {
  Flex,
  Stack,
  HStack,
  Box,
  Divider,
  Text,
  FormControl,
  FormLabel,
  useBreakpointValue,
  VStack,
  Spacer,
} from '@chakra-ui/react';
import Input from '../../UI/Input';
import cepPromise from 'cep-promise';
import getMask from '~/utils/getMask';
import Select from '../../UI/select';
import { useSelector } from 'react-redux';
import { iActivity } from '~/domain/interfaces/models/Activity';
import { iStore } from '~/domain/interfaces/models';
import cidadesPB from '~/utils/cidadesPB';

interface ownProps {
  formik: any;
}

export interface CepResponse {
  street: string;
  city: string;
  neighborhood: string;
  state: string;
}

const FormRegisterStartup = ({ formik }: ownProps): JSX.Element => {
  const [cepResponse, setCepResponse] = React.useState<CepResponse | undefined>(
    undefined
  );
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { records } = useSelector((store: iStore) => store.activity)
  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue('address', `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`);
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  return (
    <Flex
      direction='column'
      alignItems='center'
      justifyContent='center'
      display='inline-flex'
      float='right'
      w={{ base: '100%', md: 'auto' }}
    >
      <Stack
        maxW='700px'
        width={'100%'}
        spacing='4'
        py='4'
        alignItems='stretch'
        paddingTop={'0px'}
      >
        {!isMobile ? (
          <>
            <HStack spacing='4' alignItems='flex-start'>
              <Input
                width={'280px'}
                id='name'
                name='name'
                label='Nome da Startup'
                required
                formik={formik}
                background='#FFF' />
              <Input
                optional
                maxW={'180px'}
                id='cnpj'
                name='cnpj'
                label='CNPJ'
                placeholder='__.___.___/____-__'
                /* mask='99.999.999/9999-99' */
                mask={getMask('cnpj')}
                formik={formik}
                background='#FFF' />
            </HStack>
            <HStack spacing='4' alignItems='flex-start'>
              <Select
                width={'280px'}
                id='activity'
                name='activity'
                required
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                onChange={formik.handleChange}
                value={formik.values.activity}
                label={'Área de atuação'}
                formik={formik}
              >
                {records.map((activity) => (
                  <option key={activity.id} value={activity.id}>
                    {activity.name}
                  </option>
                ))}
              </Select>
              <Select
                maxW={'180px'}
                id='city'
                name='city'
                required
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                onChange={formik.handleChange}
                value={formik?.values.city}
                label={'Cidade'}
                formik={formik}
              >
                {cidadesPB.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </Select>
            </HStack>
            <HStack spacing='4' alignItems='flex-start'>
              <Input
                width={'280px'}
                id='address'
                name='address'
                label='Endereço'
                optional
                formik={formik}
                value={formik?.values.address}
                background='#FFF' />
              <Input
                maxW={'180px'}
                id='cep'
                name='cep'
                label='CEP'
                optional
                placeholder='_____-___'
                mask={getMask('cep')}
                formik={formik}
                onBlurCapture={handleOnBlur}
                background='#FFF' />
            </HStack>
            <HStack spacing='4' alignItems='flex-start'>
              <Input
                width={'280px'}
                id='nameManager'
                name='nameManager'
                label='Nome do Gestor'
                required
                formik={formik}
                background='#FFF' />
              <Select
                id='gender'
                name='gender'
                maxW={'180px'}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                label={'Gênero'}
                formik={formik}
              >
                <option value='MALE'>Masculino</option>
                <option value='FEMALE'>Feminino</option>
                <option value='OTHER'>Outro</option>
              </Select>
            </HStack>
            <HStack spacing='4' alignItems='flex-start'>
              <Input
                width={'280px'}
                id='emailManager'
                name='emailManager'
                label='E-mail'
                required
                formik={formik}
                background='#FFF' />
              <Input
                maxW={'180px'}
                id='phoneManager'
                name='phoneManager'
                label='Telefone'
                mask={getMask('phone')}
                placeholder='(_ _) _ _ _ _ _ - _ _ _ _'
                optional
                formik={formik}
                background='#FFF' />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing='4' alignItems='flex-start'>
              <Input
                width={'100%'}
                id='name'
                name='name'
                label='Nome da Startup'
                required
                formik={formik}
                background='#FFF'
              />
              <Input
                optional
                width={'100%'}
                id='cnpj'
                name='cnpj'
                label='CNPJ'
                placeholder='__.___.___/____-__'
                /* mask='99.999.999/9999-99' */
                mask={getMask('cnpj')}
                formik={formik}
                background='#FFF'
              />
              <Select
                width={'100%'}
                id='field'
                name='field'
                required
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                onChange={formik.handleChange}
                value={formik.values.field}
                label={'Área de atuação'}
                formik={formik}
              >
                {records.map((activity) => (
                  <option key={activity.id} value={activity.id}>
                    {activity.name}
                  </option>
                ))}
              </Select>
              <Select
                width={'100%'}
                id='city'
                name='city'
                required
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                onChange={formik.handleChange}
                value={formik?.values.city}
                label={'Cidade'}
                formik={formik}
              >
                {cidadesPB.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </Select>
              <Input
                width={'100%'}
                id='address'
                name='address'
                label='Endereço'
                optional
                formik={formik}
                value={formik?.values.address}
                background='#FFF'
              />
              <Input
                width={'100%'}
                id='cep'
                name='cep'
                label='CEP'
                optional
                placeholder='_____-___'
                mask={getMask('cep')}
                formik={formik}
                onBlurCapture={handleOnBlur}
                background='#FFF'
              />
              <Input
                width={'100%'}
                id='nameManager'
                name='nameManager'
                label='Nome do Gestor'
                required
                formik={formik}
                background='#FFF'
              />
              <Select
                id='gender'
                name='gender'
                width={'100%'}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
                placeholder='Escolha uma opção'
                bg='#F9F8FA'
                label={'Gênero'}
                formik={formik}
              >
                <option value='MALE'>Masculino</option>
                <option value='FEMALE'>Feminino</option>
                <option value='OTHER'>Outro</option>
              </Select>
              <Input
                width={'100%'}
                id='emailManager'
                name='emailManager'
                label='E-mail'
                required
                formik={formik}
                background='#FFF'
              />
              <Input
                width={'100%'}
                id='phoneManager'
                name='phoneManager'
                label='Telefone'
                mask={getMask('phone')}
                placeholder='(_ _) _ _ _ _ _ - _ _ _ _'
                optional
                formik={formik}
                background='#FFF'
              />
            </VStack>
          </>
        )}
      </Stack>
    </Flex>
  );
};

export default FormRegisterStartup;
