/* eslint-disable no-console */
import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  Input,
  Editable,
  EditableInput,
  EditablePreview,
} from '@chakra-ui/react';

interface ownProps extends InputProps {
  id: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  required?: boolean;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}

const CustomCurrencyInput = ({
  id,
  name,
  onChange,
  onBlur,
  value,
  label,
  required,
  formik,
  rightIcon,
  disabled = false,
  ...rest
}: ownProps): JSX.Element => {
  const internalOnChange = (valueString: any) => {
    formik.setFieldValue(name, valueString);
    console.log('...internalOnCHange', valueString);
  };
  return (
    <FormControl
      isRequired={required}
      isInvalid={formik?.errors[name] && formik?.touched[name]}
    >
      {label && (
        <FormLabel fontWeight='bold' fontSize='sm' mb='2'>
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <ChakraInput
          id={id}
          px='2.5'
          fontSize='sm'
          name={name}
          onValueChange={internalOnChange}
          onBlur={formik ? formik?.handleBlur : onBlur}
          value={formik ? formik?.values[name] : value}
          textAlign='left'
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          as={CurrencyInput}
          decimalSeparator=','
          groupSeparator='.'
          bg='#F9F8FA'
          disabled={disabled}
          {...rest}
        />
        {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
      </InputGroup>

      <FormErrorMessage>{formik?.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default CustomCurrencyInput;

// import React, { useState, useRef, useEffect, ChangeEvent } from "react";
// import CurrencyInput from "react-currency-input-field";
// import InputMask from "react-input-mask";

// import {
//   Container,
//   Center,
//   FormControl,
//   Stack,
//   FormLabel,
//   Input,
//   Editable,
//   EditableInput,
//   EditablePreview
// } from "@chakra-ui/react";

// export default function App() {
//   const internalOnChange = (valueString) => {
//     console.log(valueString);
//   };

//   return (
//     <Container>
//       <Center>
//         <Stack direction="column">
//           <FormControl>
//             <FormLabel>React Currency Input Field</FormLabel>
//             <Input
//               textAlign="right"
//               intlConfig={{ locale: "pt-BR", currency: "BRL" }}
//               as={CurrencyInput}
//               decimalSeparator=","
//               groupSeparator="."
//               onValueChange={internalOnChange}
//             />
//           </FormControl>
//         </Stack>
//       </Center>
//     </Container>
//   );
// }
