import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface ownProps {
  icon?: React.ReactNode;
  title: string;
  data: { value: string; label: string; footer: string }[];
  separator: 'ALL' | 'FIRST';
}

const DashboardCard = ({
  data,
  separator,
  icon,
  title,
}: ownProps): JSX.Element => {
  return (
    <Flex
      w='100%'
      h='100%'
      border='1px solid #CECECE'
      borderRadius='5px'
      direction='column'
      bg='#FDFDFD'
    >
      <Box
        w='100%'
        py='4'
        px='5'
        fontWeight='bold'
        fontSize='md'
        borderBottom='1px solid #CECECE'
      >
        {title}
      </Box>
      <Flex
        py='4'
        px='5'
        gap='5'
        minW='660px'
        borderBottomRadius='5px'
        bg='#FDFDFD'
      >
        {icon}
        {data.map((item, idx) => {
          return (
            <>
              <Flex
                key={item.label}
                direction='column'
                gap='1'
                ml={separator === 'FIRST' && idx > 1 ? 5 : 0}
              >
                <Box fontSize='lg' fontWeight='bold' color='#222222'>
                  {item.value}
                </Box>
                <Box fontSize='sm' color='#484848'>
                  {item.label}
                </Box>
                <Box fontSize='xs' color='#777777'>
                  {item.footer}
                </Box>
              </Flex>
              {separator === 'ALL' && idx !== data.length - 1 && (
                <Box h='60px' w='1px' bg='#CECECE' />
              )}
              {separator === 'FIRST' && idx === 0 && (
                <Box h='60px' w='1px' bg='#CECECE' />
              )}
            </>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DashboardCard;
