import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import Confirmation from '..';
import { IconEventRegistered } from '~/presentation/base/icons';

interface ownProps {
  name?: string;
  fullname?: string;
  onOpenRegister?: any;
}

const RegisterEventSuccess = ({ name, fullname, onOpenRegister }: ownProps) => {
  const { active } = useContext(ConfirmModalContext);

  return (
    <Confirmation
      height='392px'
      isOpen={active === 'SUBMMIT_EVENT'}
      onClose={() => {
        // eslint-disable-next-line no-console
        console.log('close');
      }}
      icon={<IconEventRegistered />}
      title='Evento criado!'
      topButtonText='Agendar outro evento'
      onOpenRegister={onOpenRegister}
      body={
        <Box fontSize='sm' color={'#60656D'}>
          <Stack spacing='5'>
            <Text>O evento foi criado com sucesso!</Text>
          </Stack>
        </Box>
      }
    ></Confirmation>
  );
};

export default RegisterEventSuccess;
