import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetDashboard as UsecaseRemoteGetDashboard } from '~/domain/usecases/dashboard/remote';
import { GetDashboard as UsecaseReduxGetDashboard } from '~/domain/usecases/dashboard/redux';

import { RemoteGetDashboard } from '~/data/repository/dashboard';
import { ReduxGetDashboard } from '~/data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetDashboard = (): UsecaseRemoteGetDashboard =>
  new RemoteGetDashboard(makeApiUrl('/dashboard'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetDashboard = (): UsecaseReduxGetDashboard =>
  new ReduxGetDashboard();
