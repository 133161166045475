import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/activity/actions/get';

import { iActionGet } from '~/domain/interfaces/redux/activity/get';

import { GetActivity } from '~/domain/usecases/activity/redux';

export class ReduxGetActivity
  implements GetActivity
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (
    params: GetActivity.Params,
  ): iActionGet => this.send(getRequest(params));
}
