import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteUser as UsecaseRemoteDeleteUser } from '~/domain/usecases/user/remote';
// import { DeleteUser as UsecaseReduxDeleteUser } from '~/domain/usecases/user/redux';

import { RemoteDeleteUser } from '~/data/repository/user';
// import { ReduxDeleteUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteUser = (): UsecaseRemoteDeleteUser =>
  new RemoteDeleteUser(makeApiUrl('/users/{user}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteUser =
  (): UsecaseReduxDeleteUser =>
    new ReduxDeleteUser(); */
