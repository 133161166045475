import { ErrorMessage } from 'formik';
import React, { cloneElement } from 'react';

interface IProps {
  name: string;
  children: React.ReactElement;
}

const FormItem = ({ children, name }: IProps) => {
  return (
    <div>
      {cloneElement(children, { name })}
      <ErrorMessage name={name} />
    </div>
  );
};

export default FormItem;
