import React, { useEffect } from 'react';
import Registrations from '~/presentation/pages/registrations';
import { makeReduxListApplication } from '../../usecases/application/ListApplicationFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

export const makeRegistrationsFactory: React.FC = () => {
  const { user, startups } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    makeReduxListApplication().list({
      query: {
        ...(user?.role_.name === 'LEADER' && startups?.length
          ? { startup: startups[0].id }
          : {}),
      },
    });
    makeReduxListNotice().list({});
    makeReduxListStartup().list({});
  }, []);
  return <Registrations />;
};
