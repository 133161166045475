import React from 'react';

const IconMenuHome = () => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.55556 12.4444H3.88889V7.77778H8.55556V12.4444H10.8889V5.44444L6.22222 1.94444L1.55556 5.44444V12.4444ZM0 14V4.66667L6.22222 0L12.4444 4.66667V14H7V9.33333H5.44444V14H0Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconMenuHome;
