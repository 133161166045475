import { Dispatch } from 'redux';
import store from '~/data/store';
import { listFolderContentRequest } from '~/data/store/reducer/document/actions/listFolderContent';

import { iActionListFolderContent } from '~/domain/interfaces/redux/document/listFolderContent';

import { ListFolderContentDocument } from '~/domain/usecases/document/redux';

export class ReduxListFolderContentDocument
  implements ListFolderContentDocument
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listFolderContent = (
    params: ListFolderContentDocument.Params
  ): iActionListFolderContent => this.send(listFolderContentRequest(params));
}
