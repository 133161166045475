import { ListFolderContentDocument } from '~/domain/usecases/document/redux/ListFolderContentDocument';
import {
  iActionListFolderContent,
  iActionListFolderContentFailed,
  iActionListFolderContentSuccess,
  DocumentTypes,
} from '~/domain/interfaces/redux/document/listFolderContent';

export const listFolderContentRequest = (
  payload: ListFolderContentDocument.Params
): iActionListFolderContent => ({
  type: DocumentTypes.LISTFOLDERCONTENT,
  payload,
});

export const listFolderContentSuccess = (
  params: ListFolderContentDocument.Model
): iActionListFolderContentSuccess => ({
  type: DocumentTypes.LISTFOLDERCONTENT_SUCCESS,
  payload: params,
});

export const listFolderContentFailed = (): iActionListFolderContentFailed => ({
  type: DocumentTypes.LISTFOLDERCONTENT_FAILED,
});
