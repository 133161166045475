import * as yup from 'yup';

export const schemaRegisterInform = {
  initial: {
    begin: new Date(),
    end: new Date(),
    code: '',
    files: [],
    notice: '',
    startup: '',
    type: '' as 'INCOME' | 'EXPENSE',
    value: '',
  },
  validators: yup.object({
    begin: yup.date().required('Campo obrigatório'),
    end: yup.date().required('Campo obrigatório'),
    code: yup.string().required('Campo obrigatório'),
    files: yup.array().required('Campo obrigatório'),
    notice: yup.string().required('Campo obrigatório'),
    startup: yup.string(),
    type: yup
      .string()
      .oneOf(['INCOME', 'EXPENSE'])
      .required('Campo obrigatório'),
    value: yup.string().required('Campo Obrigatório'),
  }),
};
