import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { LoginAuth as UsecaseRemoteLoginAuth } from '~/domain/usecases/auth/remote';
import { LoginAuth as UsecaseReduxLoginAuth } from '~/domain/usecases/auth/redux';

import { RemoteLoginAuth } from '~/data/repository/auth';
import { ReduxLoginAuth } from '~/data/store/reducer/auth/usecases';

/**
 * send request via API.
 */
export const makeRemoteLoginAuth = (): UsecaseRemoteLoginAuth =>
  new RemoteLoginAuth(makeApiUrl('/auth/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxLoginAuth = (): UsecaseReduxLoginAuth =>
  new ReduxLoginAuth();
