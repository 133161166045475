import { Dispatch } from 'redux';
import store from '~/data/store';
import { listNoticeFoldersRequest } from '~/data/store/reducer/document/actions/listNoticeFolders';

import { iActionListNoticeFolders } from '~/domain/interfaces/redux/document/listNoticeFolders';

import { ListNoticeFoldersDocument } from '~/domain/usecases/document/redux';

export class ReduxListNoticeFoldersDocument
  implements ListNoticeFoldersDocument
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listNoticeFolders = (
    params: ListNoticeFoldersDocument.Params
  ): iActionListNoticeFolders => this.send(listNoticeFoldersRequest(params));
}
