import React from 'react';

const iconHomeButtonStartups = () => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#E4F6FF' />
      <path
        d='M10.0163 25.8816C10.0108 25.9208 10.0054 25.9603 10 26C10.0884 25.988 10.1756 25.9759 10.2617 25.9636C10.2143 25.9704 10.1665 25.9771 10.1184 25.9837L10 26L10.0163 25.8816Z'
        fill='#0873F1'
      />
      <path
        d='M19.6405 16.3389C19.3469 16.0453 19.2004 15.6868 19.2004 15.2559C19.2004 14.8256 19.3469 14.4673 19.6405 14.1737C19.9337 13.8805 20.2954 13.7336 20.7334 13.7336C21.1713 13.7336 21.533 13.8805 21.8263 14.1737C22.1199 14.4673 22.2664 14.8256 22.2664 15.2559C22.2664 15.6868 22.1198 16.0453 21.8263 16.3389C21.533 16.6322 21.1713 16.779 20.7334 16.779C20.2954 16.779 19.9337 16.6322 19.6405 16.3389Z'
        fill='#0873F1'
      />
      <path
        d='M25.9752 10.8281C26.0831 12.3192 25.8391 13.7729 25.242 15.1906C24.6444 16.6101 23.6992 17.9682 22.4031 19.2643L22.3594 19.308L22.7837 21.408C22.8349 21.664 22.822 21.9121 22.7456 22.1541C22.6687 22.3975 22.541 22.6081 22.3619 22.7872L19.7661 25.383C19.5865 25.5626 19.3766 25.6295 19.1247 25.5873C18.8765 25.5462 18.7105 25.4122 18.6153 25.1744L17.3489 22.2055L13.7945 18.6511L10.828 17.3857L10.8256 17.3847C10.587 17.2893 10.449 17.1221 10.4015 16.8726C10.3536 16.6213 10.4176 16.4127 10.5964 16.2339L13.1922 13.6381C13.3708 13.4595 13.5845 13.3318 13.8353 13.2547C14.0845 13.1779 14.3362 13.1652 14.5922 13.2163L16.692 13.6406L16.7357 13.5969C18.0318 12.3008 19.3899 11.3556 20.8094 10.758C22.2271 10.1609 23.6808 9.91687 25.1719 10.0248C25.269 10.0373 25.3672 10.0647 25.4668 10.108C25.5639 10.1508 25.648 10.2081 25.7199 10.2801C25.7919 10.352 25.8491 10.4364 25.8913 10.5334C25.9351 10.633 25.9628 10.7311 25.9752 10.8281ZM12.3524 16.5009L14.2161 17.2895L14.2645 17.1928C14.4558 16.8102 14.6538 16.4414 14.8586 16.0864C15.063 15.7322 15.2879 15.3778 15.5334 15.0231L15.6335 14.8786L14.2471 14.6062L12.3524 16.5009ZM24.0881 11.3609C23.1065 11.3889 22.0638 11.6762 20.9612 12.2173C19.8573 12.7587 18.8545 13.4804 17.9532 14.3818C17.3715 14.9635 16.8519 15.5869 16.3946 16.2521C15.9376 16.9168 15.5962 17.543 15.3724 18.1305L15.3458 18.2005L17.8002 20.6334L17.8695 20.607C18.457 20.3832 19.0832 20.0418 19.7479 19.5848C20.4131 19.1275 21.0365 18.6079 21.6182 18.0262C22.5194 17.125 23.2412 16.1259 23.7826 15.0292C24.3237 13.933 24.6111 12.8935 24.6391 11.9119V11.9086C24.6391 11.8391 24.6259 11.7698 24.6012 11.7029C24.5746 11.6329 24.5346 11.571 24.4818 11.5182C24.429 11.4654 24.3662 11.4251 24.2962 11.3985C24.2293 11.3738 24.1609 11.3609 24.0914 11.3609V11.476L24.0881 11.3609ZM18.8072 21.7149L18.711 21.763L19.4985 23.6482L21.3938 21.7529L21.1214 20.3665L20.9769 20.4666C20.6227 20.7118 20.2686 20.9329 19.915 21.1298C19.5595 21.3284 19.1902 21.5234 18.8072 21.7149Z'
        fill='#0873F1'
      />
      <path
        d='M14.9866 21.0134C15.4449 21.4718 15.67 22.0288 15.6632 22.6919C15.6569 23.3563 15.4249 23.9146 14.966 24.3735C14.6423 24.6972 14.0903 24.9855 13.2937 25.2307C12.5271 25.4665 11.475 25.6783 10.1347 25.8653C10.3217 24.525 10.5335 23.4729 10.7693 22.7063C11.0145 21.9097 11.3028 21.3577 11.6265 21.034C12.0854 20.5751 12.6434 20.3428 13.3072 20.336C13.9709 20.3297 14.5282 20.5551 14.9866 21.0134ZM11.9051 23.9436L11.8823 24.0971L12.0358 24.0743C12.4143 24.0182 12.7894 23.9237 13.1605 23.7911C13.5274 23.6592 13.7983 23.5182 13.9545 23.362C14.1393 23.1772 14.2404 22.9514 14.2558 22.6899C14.2715 22.4237 14.1838 22.1925 13.9957 22.0043C13.8084 21.8171 13.5784 21.7259 13.3133 21.7333C13.0506 21.7414 12.8236 21.8393 12.638 22.0249C12.4824 22.1805 12.3381 22.4508 12.1997 22.818C12.0595 23.1891 11.9613 23.5643 11.9051 23.9436Z'
        fill='#0873F1'
      />
    </svg>
  );
};

export default iconHomeButtonStartups;
