import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import Input from '../../UI/Input';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'react-datepicker';
import {
  IconArrowDown,
  IconCalendar,
  IconCalendarBlue,
} from '~/presentation/base/icons';
import IconCalendarGrey from '~/presentation/base/icons/iconCalendarGrey';
import getMask from '~/utils/getMask';
import DateInput from '../../UI/DateInput';
import Select from '../../UI/select';
import cepPromise from 'cep-promise';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';

interface ownProps {
  formik: any;
}

const FormEditMember = ({ formik }: ownProps): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date());

  function isValidCEP(cepInput: string) {
    return /^[0-9][0-9][0-9][0-9][0-9]-[0-9][0-9][0-9]$/.test(cepInput);
  }

  const [cepResponse, setCepResponse] = React.useState<CepResponse | undefined>(
    undefined
  );
  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'address',
          `${cepResponseData.street + ', ' + cepResponseData.neighborhood}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  return (
    <HStack columnGap={'16px'}>
      <Stack spacing='4'>
        <FormLabel fontWeight={'700'} fontSize='14px' mb='0px'>
          Nome completo<span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <Input
          id='fullname'
          w='360px'
          name='fullname'
          formik={formik}
          isRequired
        />
        <HStack columnGap={'16px'}>
          <Stack>
            <FormLabel fontWeight={'700'} fontSize='14px' mb='8px'>
              Data de nascimento<span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <DateInput
              formik={formik}
              name='datepicker'
              required
              onChange={(date) => formik.setFieldValue('datepicker', date)}
              selectsEnd
              dateFormat='dd/MM/yyyy'
            />
            {/*  <DatePicker
              selected={startDate}
              onChange={(date) => date && setStartDate(date)}
              customInput={
                <InputGroup>
                  <Input
                    id='datepicker'
                    w='168px'
                    name='datepicker'
                    formik={formik}
                    value={startDate.toLocaleDateString('pt-BR')}
                  />
                  <InputRightElement width={'12.6px'} mr='13px'>
                    <IconCalendarGrey />
                  </InputRightElement>
                </InputGroup>
              }
            /> */}
          </Stack>
          <Stack>
            <Input
              id='rg'
              w='168px'
              name='rg'
              label='RG'
              required
              formik={formik}
            />
          </Stack>
        </HStack>

        <Input
          id='address'
          w='360px'
          name='address'
          label='Endereço'
          required
          formik={formik}
        />
        <Input
          id='email'
          w='360px'
          name='email'
          label='E-mail'
          required
          formik={formik}
        />
        <Input
          id='job'
          w='360px'
          name='job'
          label='Cargo'
          required
          formik={formik}
        />
      </Stack>
      <Stack spacing='4' paddingTop={'0px'}>
        <Select
          id='gender'
          name='gender'
          minW={'168px'}
          required
          onChange={(event) => {
            // eslint-disable-next-line no-console
            console.log('... event', event.target.value);
            formik.setFieldValue('gender', event.target.value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.gender}
          placeholder='Selecione'
          label={'Gênero'}
          formik={formik}
        >
          <option value='MALE'>Masculino</option>
          <option value='FEMALE'>Feminino</option>
          <option value='OTHER'>Outro</option>
        </Select>

        <Input
          id='cpf'
          name='cpf'
          label='CPF'
          required
          w='168px'
          mask={getMask('cpf')}
          formik={formik}
        />
        <Input
          width={'168px'}
          id='cep'
          name='cep'
          label='CEP'
          required
          placeholder='_ _ _ _ _ - _ _ _'
          mask={getMask('cep')}
          formik={formik}
          onBlurCapture={handleOnBlur}
        />
        <Input
          id='phone'
          name='phone'
          label='Telefone'
          required
          w='168px'
          formik={formik}
          mask={getMask('phone')}
        />
        <Select
          id='workload'
          name='workload'
          label='Dedicação'
          required
          w='168px'
          placeholder='Selecione'
          bg='#F9F8FA'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.workload}
          isInvalid={formik.errors.workload && formik.touched.workload}
          formik={formik}
        >
          <option value='20h/semana'>20h/semana</option>
          <option value='40h/semana'>40h/semana</option>
        </Select>
      </Stack>
    </HStack>
  );
};

export default FormEditMember;
