import React from 'react';

const iconArrowRight = () => {
  return (
    <svg
      width='29'
      height='30'
      viewBox='0 0 29 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='14.5'
        cy='15'
        r='12.5'
        fill='#1997F3'
        stroke='#CECECE'
        strokeWidth='3.125'
      />
      <path
        d='M12.5718 19.6875L8.25 15.3657L9.76263 13.8531L12.5718 16.6622L19.7028 9.53125L21.2154 11.0439L12.5718 19.6875Z'
        fill='#F9F8FA'
      />
      <mask
        id='mask0_2300_12532'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='29'
        height='30'
      >
        <circle
          cx='14.5039'
          cy='15'
          r='12.5'
          stroke='#CECECE'
          strokeWidth='3.125'
        />
      </mask>
      <g mask='url(#mask0_2300_12532)'>
        <circle
          cx='14.5037'
          cy='15'
          r='12.5'
          transform='rotate(-90 14.5037 15)'
          fill='#1997F3'
          stroke='#1997F3'
          strokeWidth='3.125'
        />
      </g>
    </svg>
  );
};

export default iconArrowRight;
