const getRoleByTabId = (roleId: number, isLeader: boolean): 'ADMIN' | 'SUPERVISOR' | 'MENTOR' | 'LEADER' | 'MEMBER' => 
{
  if (isLeader) {
    switch (roleId) {
      case 0:
        return 'LEADER';
      case 1:
        return 'MEMBER';
      default:
        return 'MEMBER';
    }
  } else {
    switch (roleId) {
      case 0:
        return 'ADMIN';
      case 1:
        return 'SUPERVISOR';
      case 2:
        return 'MENTOR';
      case 3:
        return 'LEADER';
      case 4:
      default:
        return 'MEMBER';
    }
  }
};

export default getRoleByTabId