import React from 'react';

const IconMenuDashboardWhite = () => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.25 7.25H13.4625C13.2775 5.875 12.705 4.71 11.745 3.755C10.79 2.8 9.625 2.2275 8.25 2.0375M6.75 13.9625V2.0375C5.25 2.2275 4 2.8875 3 4.0175C2 5.1475 1.5 6.475 1.5 8C1.5 9.525 2 10.8525 3 11.9825C4 13.1175 5.25 13.7775 6.75 13.9625ZM8.25 13.9625C9.625 13.7875 10.795 13.22 11.76 12.26C12.72 11.295 13.2875 10.125 13.4625 8.75H8.25M7.5 15.5C6.465 15.5 5.49 15.3025 4.575 14.9075C3.665 14.5175 2.8725 13.985 2.1975 13.31C1.5225 12.635 0.9875 11.84 0.5925 10.925C0.1975 10.015 0 9.04 0 8C0 6.96 0.1975 5.985 0.5925 5.075C0.9875 4.165 1.5225 3.3725 2.1975 2.6975C2.8725 2.0225 3.665 1.4875 4.575 1.0925C5.49 0.6975 6.465 0.5 7.5 0.5C8.535 0.5 9.5075 0.6975 10.4175 1.0925C11.3225 1.4875 12.115 2.025 12.795 2.705C13.48 3.385 14.0175 4.1775 14.4075 5.0825C14.8025 5.9925 15 6.965 15 8C15 9.025 14.8025 9.995 14.4075 10.91C14.0175 11.82 13.485 12.615 12.81 13.295C12.135 13.98 11.34 14.5175 10.425 14.9075C9.515 15.3025 8.54 15.5 7.5 15.5Z'
        fill='#E2E2E2'
      />
    </svg>
  );
};

export default IconMenuDashboardWhite;
