import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetUser as UsecaseRemoteGetUser } from '~/domain/usecases/user/remote';
// import { GetUser as UsecaseReduxGetUser } from '~/domain/usecases/user/redux';

import { RemoteGetUser } from '~/data/repository/user';
// import { ReduxGetUser } from '~/data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetUser = (): UsecaseRemoteGetUser =>
  new RemoteGetUser(makeApiUrl('/users/{user}/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetUser =
  (): UsecaseReduxGetUser =>
    new ReduxGetUser(); */
