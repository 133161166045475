import * as yup from 'yup';

export const schemaRegisterMember = {
  initial: {
    name: '',
    email: '',
    job: '',
    workload: '',
    startup: '',
    notice: undefined,
  },
  validators: yup.object({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório').email('Email inválido'),
    job: yup.string().required('Campo obrigatório'),
    workload: yup.string().required('Campo obrigatório'),
  }),
};
